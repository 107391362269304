/**
 * 登录界面action
 * @return
 */
import { RES_LOGIN } from '../../constants/loginTypes';
import LoginService from '../../services/loginService';
import store from '../../store/index'

/**
 * 登录成功
 * @return
 */
const resLogin = (res) => {
    return {
        type: RES_LOGIN,
        res
    }
}


/**
 * 登录界面	
 * @param {username} 用户名
 * @param {password} 密码
 * @return {登录信息}
 */

export const getUserInfo = () => {
    LoginService.getUserInfo().then (res => {
        if(res.code == 200){
            console.log('success')
            sessionStorage.setItem("loginImg", res.data.pictureUrl)
            store.dispatch(resLogin(res.data));
        }
    }).catch(error => {
        console.log(error)
    })
    // }
} 
