export const detailLink = {
	en_US: {
		RewardProgramV1: 'https://security.tecno.com/SRC/blogdetail/47?lang=en_US',
		SubmissionGuidelines: 'https://security.tecno.com/SRC/blogdetail/5?lang=en_US',
		ProcessFlow: 'https://security.tecno.com/SRC/blogdetail/6?lang=en_US',
		RewardRules: 'https://security.tecno.com/SRC/blogdetail/47?lang=en_US',
		RewardRulesDevice: 'https://security.tecno.com/SRC/blogdetail/241?lang=en_US',
		RewardRulesMobile: 'https://security.tecno.com/SRC/blogdetail/245?lang=en_US', 
		RewardRulesWeb: 'https://security.tecno.com/SRC/blogdetail/242?lang=en_US', 
		ResearchDevice: 'https://security.tecno.com/SRC/blogdetail/34?lang=en_US',
		FAQ: 'https://security.tecno.com/SRC/blogdetail/199?lang=en_US',
		RankList: 'https://security.tecno.com/SRC/leaderboards?lang=en_US',
		EarnBadge: 'https://security.tecno.com/SRC/blogdetail/121?lang=en_US',
		AddBankAccount: 'https://security.tecno.com/SRC/blogdetail/119?lang=en_US',
		AndroidSecurityBulletins:'https://source.android.com/docs/security/bulletin/asb-overview',
    ApplyForCVEIDs:'https://security.tecno.com/SRC/blogdetail/225?lang=en_US',
    
    articleLink:'https://security.tecno.com/SRC/blogdetail/280?lang=en_US',
    inventory:'https://docs.google.com/forms/d/e/1FAIpQLScSRWlrhxEMhZwtzhyLnllZ4KG9wq3GIvv1JZ55oDnIWgOEKA/viewform?usp=sf_link',
    getMoreCredits:'https://security.tecno.com/SRC/blogdetail/282?lang=en_US',
    getCredits:'https://security.tecno.com/SRC/blogdetail/282?lang=en_US'
	},
	zh_CN: {
		RewardProgramV1: 'https://security.tecno.com/SRC/blogdetail/48?lang=zh_CN',
		SubmissionGuidelines: 'https://security.tecno.com/SRC/blogdetail/11?lang=zh_CN',
		ProcessFlow: 'https://security.tecno.com/SRC/blogdetail/12?lang=zh_CN',
		RewardRules: 'https://security.tecno.com/SRC/blogdetail/48?lang=zh_CN',
		RewardRulesDevice: 'https://security.tecno.com/SRC/blogdetail/243?lang=zh_CN',
		RewardRulesMobile: 'https://security.tecno.com/SRC/blogdetail/244?lang=zh_CN',
		RewardRulesWeb: 'https://security.tecno.com/SRC/blogdetail/240?lang=zh_CN',
		ResearchDevice: 'https://security.tecno.com/SRC/blogdetail/13?lang=zh_CN',
		FAQ: 'https://security.tecno.com/SRC/blogdetail/200?lang=zh_CN',
		RankList: 'https://security.tecno.com/SRC/leaderboards?lang=zh_CN',
		EarnBadge: 'https://security.tecno.com/SRC/blogdetail/122?lang=zh_CN',
		AddBankAccount: 'https://security.tecno.com/SRC/blogdetail/120?lang=zh_CN',
		AndroidSecurityBulletins:'https://source.android.com/docs/security/bulletin/asb-overview?hl=zh-cn',
		ApplyForCVEIDs:'https://security.tecno.com/SRC/blogdetail/224?lang=zh_CN',

    articleLink:'https://security.tecno.com/SRC/blogdetail/285?lang=zh_CN',
    inventory:'https://docs.google.com/forms/d/e/1FAIpQLScSRWlrhxEMhZwtzhyLnllZ4KG9wq3GIvv1JZ55oDnIWgOEKA/viewform?usp=sf_link',
		getMoreCredits:'https://security.tecno.com/SRC/blogdetail/283?lang=zh_CN',
    getCredits:'https://security.tecno.com/SRC/blogdetail/283?lang=zh_CN'
	}
};
