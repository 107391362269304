const zh_CN = {
    //Dashboard
    'intl.dashboard.banner.title': "TECNO安全响应中心",
    'intl.dashboard.banner.subtitle': " ",
    'intl.dashboard.banner.submitbutton': "提交漏洞",
    'intl.dashboard.banner.viewRulesButton': "奖励规则",
    'intl.dashboard.banner.menu.blogevent': "公告",
    'intl.dashboard.banner.menu.awards': "奖励政策",
    'intl.dashboard.banner.menu.guide': "操作指引",
    'intl.dashboard.blogevent.title': "公告",
    'intl.dashboard.blogevent.subTitle': " ",
    'intl.dashboard.activity.title': "活动",
    'intl.dashboard.activity.subTitle': " ",
    'intl.dashboard.awards.title': "奖励政策",
    'intl.dashboard.awards.subtitle': "TECNO感激每一位安全研究员为维护TECNO安全所做的贡献",
    'intl.dashboard.awards.subcontent1': "我们相信,与安全研究员的紧密合作可以帮助TECNO打造更好的未来。",
    'intl.dashboard.awards.subcontent2': "TECNO安全响应中心承诺将公平合理地处理每一份安全报告,并对提交有效报告的研究员予以公开致谢。",
    'intl.dashboard.awards.rule': "漏洞奖励政策",
    'intl.dashboard.awards.submitbutton': "提交漏洞",
    'intl.dashboard.fameHall.title': '月度英雄榜',
    'intl.dashboard.fameHall.linkHint1': 'TECNO安全响应中心感激每一位安全研究员为维护TECNO产品和用户安全所做的贡献，并对提交有效报告的研究员予以',
    'intl.dashboard.fameHall.linkHint2': '公开致谢',
    'intl.dashboard.fameHall.value': '基础贡献值：',
    'intl.dashboard.fameHall.jumplink': '月度英雄榜',
    'intl.dashboard.activeModel.title1': '申请即有机会免费获得',
    'intl.dashboard.activeModel.title2': '测试设备',
    'intl.dashboard.activeModel.jumpLink1': '向TECNO安全响应中心发送邮件来加入',
    'intl.dashboard.activeModel.jumpLink2': '了解更多',
    'intl.dashboard.guide.title': "文档",
    'intl.dashboard.guide.submit': "提交指引",
    'intl.dashboard.guide.deal': "审核流程",
    'intl.dashboard.guide.awards': "奖励政策",
    'intl.dashboard.guide.apply': "样机申请",
    'intl.dashboard.guide.normalquestion': "常见问答",
    'intl.dashboard.signup.tip': "谢邀广大安全专家共建TECNO安全生态",
    'intl.dashboard.signup.button': "注册",

    //Header
    'intl.header.home': "首页",
    'intl.header.blog': "公告",
    'intl.header.halloffame': "英雄榜",
    'intl.header.securityupdate': "安全更新",
    'intl.header.swagshop': "积分商城",
    'intl.header.login': "登录",
    'intl.header.signup': "注册",
    'intl.header.menu.vulnerability': "漏洞报告",
    'intl.header.menu.personalinfo': "个人信息",
    'intl.header.menu.exit': "退出登录",
    'intl.header': "提交漏洞",
    'intl.header.securityreporting':"漏洞提交",

    //Footer
    'intl.footer.menu': "导航",
    'intl.footer.home': "首页",
    'intl.footer.blog': "公告",
    'intl.footer.halloffame': "英雄榜",
    'intl.footer.securityupdate': "安全更新",
    'intl.footer.document': "文档",
    'intl.footer.submit': "提交指引",
    'intl.footer.process': "审核流程",
    'intl.footer.award': "奖励政策",
    'intl.footer.apply': "样机申请",
    'intl.footer.normalquestion': "常见问答",
    'intl.footer.contact': "联系我们",
    'intl.footer.privacypolicy': "隐私协议",
    'intl.footer.tecnoweb': "TECNO 官网",

    //login
    'intl.login.title': "登录 TECNO SRC",
    'intl.login.placeholder.email': "输入您的邮箱地址",
    'intl.login.placeholder.password': "输入您的登录密码",
    'intl.login.placeholder.captcha': "输入图形验证码",
    'intl.login.button.gologin': "登录",
    'intl.login.button.gosignup': "注册",
    'intl.login.button.goforgetpassword': "找回密码",
    'intl.login.button.gootherlogin': "其他方式登录",
    'intl.login.message.success': "登录成功",
    'intl.login.message.error.email': "请输入邮箱",
    'intl.login.message.error.password': "请输入密码",
    'intl.login.message.error.captchaCode': "请输入图形验证码",

    //Sign Up
    'intl.signup.title': "注册 TECNO SRC",
    'intl.signup.placeholder.country': "国家/地区",
    'intl.signup.placeholder.email': "输入您的邮箱账号",
    'intl.signup.placeholder.password': "输入您的登录密码",
    'intl.signup.placeholder.captcha.img': "输入图形验证码",
    'intl.signup.placeholder.captcha.email': "输入邮箱验证码",
    'intl.signup.button.getcaptcha': "获取验证码",
    'intl.signup.button.aftergetcaptcha': " s",
    'intl.signup.button.gologin': "登录",
    'intl.signup.button.gosignup': "注册",
    'intl.signup.agreement.agree': "我已阅读并同意遵守",
    'intl.signup.agreement.policy': " 隐私协议 ",
    'intl.signup.agreement.and': "和",
    'intl.signup.agreement.plateform': " 平台服务协议",
    'intl.signup.agreement.content': " ",
    'intl.signup.message.success': "注册成功",
    'intl.signup.message.error.passwordrule': "密码要求8-16位，且必须包含字母、数字、特殊符号",
    'intl.signup.message.error.chooseagreement': "请勾选您已阅读并同意遵守平台协议",
    'intl.signup.message.error.choosecountry': "请设置国家/地区",
    'intl.signup.message.error.email': "请输入邮箱地址",
    'intl.signup.message.error.captchacode': "请输入图形验证码",
    'intl.signup.message.error.emailcaptchacode': "请输入邮箱验证码",
    'intl.signup.password.title': "密码格式",
    'intl.signup.password.rule': "8-16位密码，且必须包含字母、数字、特殊符号",

    //Forget Password
    'intl.forgetpassword.button.back': "返回",
    'intl.forgetpassword.title': "找回密码",
    'intl.forgetpassword.subtitle': "通过校验邮箱验证码来重置您的登录密码",
    'intl.forgetpassword.placeholder.email': "输入您的邮箱地址",
    'intl.forgetpassword.placeholder.password': "设置新的登录密码",
    'intl.forgetpassword.placeholder.captcha.img': "输入图形验证码",
    'intl.forgetpassword.placeholder.captcha.email': "输入邮箱验证码",
    'intl.forgetpassword.button.getcaptcha': "获取验证码",
    'intl.forgetpassword.button.aftergetcaptcha': " s",
    'intl.forgetpassword.button.goforgetpassword': "确定",
    'intl.forgetpassword.message.success': "重置密码成功",
    'intl.forgetpassword.message.error.email': "请输入邮箱地址",
    'intl.forgetpassword.message.error.captchacode': "请输入图形验证码",
    'intl.forgetpassword.message.error.emailcaptchacode': "请输入邮箱验证码",
    'intl.forgetpassword.message.error.passwordrule': "密码要求8-16位，且必须包含字母、数字、特殊符号",
    'intl.forgetpassword.password.title': "密码格式",
    'intl.forgetpassword.password.rule': "8-16位密码，且必须包含字母、数字、特殊符号",

    //Bind Email Pwd
    'intl.bindemailpwd.button.back': "返回",
    'intl.bindemailpwd.title': "绑定邮箱并设置密码",
    'intl.bindemailpwd.subtitle': "为了便于与您沟通漏洞情况，请绑定您的邮箱并设置登录密码",
    'intl.bindemailpwd.placeholder.country': "国家/地区",
    'intl.bindemailpwd.placeholder.email': "输入您的邮箱地址",
    'intl.bindemailpwd.placeholder.password': "设置登录密码",
    'intl.bindemailpwd.placeholder.captcha.img': "输入图形验证码",
    'intl.bindemailpwd.placeholder.captcha.email': "输入邮箱验证码",
    'intl.bindemailpwd.button.getcaptcha': "获取验证码",
    'intl.bindemailpwd.button.aftergetcaptcha': " s",
    'intl.bindemailpwd.button.gobindemailpwd': "确定",
    'intl.bindemailpwd.message.success': "重置密码成功",
    'intl.bindemailpwd.message.error.choosecountry': "请设置国家/地区",
    'intl.bindemailpwd.message.error.email': "请输入邮箱地址",
    'intl.bindemailpwd.message.error.captchacode': "请输入图形验证码",
    'intl.bindemailpwd.message.error.emailcaptchacode': "请输入邮箱验证码",
    'intl.bindemailpwd.message.error.passwordrule': "密码要求8-16位，且必须包含字母、数字、特殊符号",
    'intl.bindemailpwd.message.error.chooseagreement': "请勾选您已阅读并同意遵守平台协议",
    'intl.bindemailpwd.password.title': "密码格式",
    'intl.bindemailpwd.password.rule': "8-16位密码，且必须包含字母、数字、特殊符号",
    'intl.bindemailpwd.agreement.agree': "我已阅读并同意遵守",
    'intl.bindemailpwd.agreement.policy': " 隐私协议 ",
    'intl.bindemailpwd.agreement.and': "和",
    'intl.bindemailpwd.agreement.plateform': " 平台服务协议",
    'intl.bindemailpwd.agreement.content': " ",
  

    //Bug Submit
    'intl.bugsubmit.title': "提交漏洞报告",
    'intl.bugsubmit.ruletitle': "规则说明:",
    'intl.bugsubmit.rulecontent': "请在提交漏洞前仔细阅读漏洞提交指引和奖励政策，请勿对外传播漏洞有关信息，若有违反，用户需承担相应法律责任。",
    'intl.bugsubmit.rulefile.rewardstandard': "TECNO漏洞提交指引",
    'intl.bugsubmit.rulefile.rewardstandard.device': "TECNO 终端 安全漏洞奖励计划 v4.0",
    'intl.bugsubmit.rulefile.rewardstandard.mobile': "TECNO 移动APP 安全漏洞奖励计划 v4.0",
    'intl.bugsubmit.rulefile.rewardstandard.web': "TECNO Web 安全漏洞奖励计划 v3.0",
    'intl.bugsubmit.rulefile.serviceagreement': "TECNO漏洞奖励政策",
    'intl.bugsubmit.form.type': "漏洞类型",
    'intl.bugsubmit.form.subtype': "子类型",
    'intl.bugsubmit.form.severity': "漏洞等级",
    'intl.bugsubmit.form.url': "URL",
    'intl.bugsubmit.form.apk': "APK",
    'intl.bugsubmit.form.apkVersionCode': "版本号",
    'intl.bugsubmit.form.activity': "活动",
    'intl.bugsubmit.form.summary': "漏洞标题",
    'intl.bugsubmit.form.description': "漏洞描述",
    'intl.bugsubmit.form.attachments': "上传附件",
    'intl.bugsubmit.form.captcha': "验证码",
    'intl.bugsubmit.placeholder.subtype': "选择漏洞类型",
    'intl.bugsubmit.placeholder.url': "请选择URL",
    'intl.bugsubmit.placeholder.apk': "请选择APK",
    'intl.bugsubmit.placeholder.versionCode': "请输入版本号",
    'intl.bugsubmit.placeholder.activity': "选择活动",
    'intl.bugsubmit.placeholder.activity.url': "活动规则",
    'intl.bugsubmit.placeholder.vulnerability': "输入漏洞描述",
    'intl.bugsubmit.editor.initialvalue': "<p style='color: #BFBFBF; margin-left: 8px'># 请按照要求填写下方内容，填写完善的报告将加速审核流程。</p><br /><p style='color: #BFBFBF; margin-left: 8px;font-weight:700'>一、详细说明：</p><p style='color: #BFBFBF;margin-left:8px;'># 应用包名<br/># 请提供危害说明和测试步骤</p><br/><p style='color: #BFBFBF;margin-left:8px;font-weight:700;'>二、漏洞证明：</p><p style='color: #BFBFBF;margin-left:8px;'># 请提供截图或视频</p><br/><p style='color: #BFBFBF;margin-left:8px;font-weight:700;'>三、修复方案：</p><p style='color: #BFBFBF;margin-left:8px;'># 请提供修复方案说明</p>",  //<p></p><br/>不翻译
    'intl.bugsubmit.editor.initialvalueweb': "<p style='color: #BFBFBF; margin-left: 8px'># 请按照要求填写下方内容，填写完善的报告将加速审核流程。</p><br /><p style='color: #BFBFBF; margin-left: 8px;font-weight:700'>一、详细说明：</p><p style='color: #BFBFBF;margin-left:8px;'># 请提供危害说明和测试步骤</p><br/><p style='color: #BFBFBF;margin-left:8px;font-weight:700;'>二、漏洞证明：</p><p style='color: #BFBFBF;margin-left:8px;'># 请提供截图或视频</p><br/><p style='color: #BFBFBF;margin-left:8px;font-weight:700;'>三、修复方案：</p><p style='color: #BFBFBF;margin-left:8px;'># 请提供修复方案说明</p>",  //<p></p><br/>不翻译

    'intl.bugsubmit.editor.initialvalueDevice': "<p style='color: #bfbfbf; margin-left: 8px'>  # 请按照要求填写下方内容，填写完善的报告将加速审核流程。</p><br /><p style='color: #bfbfbf; margin-left: 8px; font-weight: 700'>  一、详细说明：</p><p style='color: #bfbfbf; margin-left: 8px'>  # 应用包名</p><p style='color: #bfbfbf; margin-left: 8px'>  # 设备型号</p><p style='color: #bfbfbf; margin-left: 8px'>  # OS版本</p><p style='color: #bfbfbf; margin-left: 8px'>  # 请提供危害说明和测试步骤</p><p style='color: #bfbfbf; margin-left: 8px'><br /><p style='color: #bfbfbf; margin-left: 8px; font-weight: 700'>  二、漏洞证明：</p><p style='color: #bfbfbf; margin-left: 8px'># 请提供截图或视频</p><br /><p style='color: #bfbfbf; margin-left: 8px; font-weight: 700'>三、修复方案：</p><p style='color: #bfbfbf; margin-left: 8px'># 请提供修复方案说明</p>", //<p></p><br/>不翻译
    'intl.bugsubmit.button.selectattachments': "上传附件",
    'intl.bugsubmit.attachmentstips': "支持格式:.doc, .xls, .pdf，.7z, .zip, .mp4, .GZ, .bz2, .png, .jpg, .jpeg和.wmv；大小不超过 10 MB",
    'intl.bugsubmit.placeholder.captcha.img': "输入图形验证码",
    'intl.bugsubmit.agreement.agree': "我已阅读并同意遵守",
    'intl.bugsubmit.agreement.policy': " 平台服务协议",
    'intl.bugsubmit.button.gobugsubmit': "提交",
    'intl.bugsubmit.button.cancelbugsubmit': "取消",
    'intl.bugsubmit.message.success': "提交成功",
    'intl.bugsubmit.message.error': "提交失败",
    'intl.bugsubmit.message.file.type': "仅支持.doc, .xls, .pdf，.7z, .zip, .mp4, .GZ, .bz2, .png, .jpg, .jpeg和.wmv格式的文件",
    'intl.bugsubmit.message.file.size': "附件大小不能超过10M!",
    'intl.bugsubmit.message.error.subtypeSelect': "请选择漏洞类型",
    'intl.bugsubmit.message.error.severityRadio': "请选择漏洞等级",
    'intl.bugsubmit.message.error.urlInput': "请检查URL格式",
    'intl.bugsubmit.message.error.apkSelect': '请选择APK',
    'intl.bugsubmit.message.error.versionCodeInput': '请输入版本号',
    'intl.bugsubmit.message.error.vulnerabilityInput': "请填写漏洞报告标题",
    'intl.bugsubmit.message.error.contextDescription': "请填写漏洞详情",
    'intl.bugsubmit.message.error.chooseagreement': "请勾选您已阅读并同意遵守平台协议",

    //BugSubmitSuccess
    'intl.bugsubmitsuccess.title': "提交漏洞报告",
    'intl.bugsubmitsuccess.content.title': "提交成功",
    'intl.bugsubmitsuccess.button.viewdetails': "查看详情",
    'intl.bugsubmitsuccess.button.submitonemore': "再提一个",

    //BugSubmitFailed
    'intl.bugsubmitfailed.title': "提交漏洞报告",
    'intl.bugsubmitfailed.content.title': "提交失败",
    'intl.bugsubmitfailed.content.subtitle': "请检查网络重新提交!",
    'intl.bugsubmitfailed.button.viewdetails': "查看详情",
    'intl.bugsubmitfailed.button.submitonemore': "重新提交",

    //Bug Submit List
    'intl.bugsubmitlist.vulnerability.title': "漏洞报告",
    'intl.bugsubmitlist.count.Reward': "基础奖金",
    'intl.bugsubmitlist.count.Note': "提示",
    'intl.bugsubmitlist.count.Bonus.tips': "此处仅展示基础奖金总值。激励奖金请前往",
    'intl.bugsubmitlist.count.Bonus.link': "奖励明细",
    'intl.bugsubmitlist.count.Bonus.punctuation': "查询。",
    'intl.bugsubmitlist.count.Bonus.base':'基础奖金',
    'intl.bugsubmitlist.count.Bonus.extra': "激励奖金",
    // 'intl.bugsubmitlist.count.Bonus.extra': "额外奖金",
    'intl.bugsubmitlist.count.Reputation': "基础贡献值",
    'intl.bugsubmitlist.count.Reputation.tips': "此处展示贡献值总值。安全值请前往",
    'intl.bugsubmitlist.count.Reputation.link': "奖励明细",
    'intl.bugsubmitlist.count.Reputation.punctuation': "查询。",
    'intl.bugsubmitlist.count.Approved': "已通过",
    'intl.bugsubmitlist.count.Submitted': "已提交",
    'intl.bugsubmitlist.vulnerabilitylist.title': "漏洞报告列表",
    'intl.bugsubmitlist.table.columns.id': "序号",
    'intl.bugsubmitlist.table.columns.vulnerability': "漏洞标题",
    'intl.bugsubmitlist.table.columns.vulnerabilityno': "漏洞编号",
    'intl.bugsubmitlist.table.columns.severity': "定级",
    'intl.bugsubmitlist.table.columns.status': "状态",
    'intl.bugsubmitlist.table.columns.payouts': "基础奖金",
    'intl.bugsubmitlist.table.columns.reputation': "基础贡献值",
    'intl.bugsubmitlist.table.columns.submittime': "提交时间",
    'intl.bugsubmitlist.table.total': "共",
    'intl.bugsubmitlist.table.totalnum': "条",
    'intl.bugsubmitlist.status.leak_status_submited': "待审核",
    'intl.bugsubmitlist.status.leak_status_pending_review': "待验证",
    'intl.bugsubmitlist.status.leak_status_need_more_info': "待补充",
    'intl.bugsubmitlist.status.leak_status_pending_fix': "待修复",
    'intl.bugsubmitlist.status.leak_status_fixed': "已修复",
    'intl.bugsubmitlist.status.leak_status_suspended': "挂起",
    'intl.bugsubmitlist.status.leak_status_no_risk': "无危的",
    'intl.bugsubmitlist.status.leak_status_duplicate': "重复的",
    'intl.bugsubmitlist.status.leak_status_out_of_scope': "超出范围",
    'intl.bugsubmitlist.status.leak_status_not_reproducible': "无法复现",
    'intl.bugsubmitlist.status.leak_status_spam': "垃圾邮件",
    'intl.bugsubmitlist.status.leak_status_ignore': "已忽略",

    //Bug Submit Detail
    'intl.bugsubmitdetail.title': "漏洞详情",
    'intl.bugsubmitdetail.processingflow': "审核流程",
    'intl.bugsubmitdetail.processingflow.title': "漏洞报告审核流程与状态说明",
    'intl.bugsubmitdetail.table.search': "漏洞编号/漏洞标题",
    'intl.bugsubmitdetail.panel.vulnerabilityinfo': "漏洞信息",
    'intl.bugsubmitdetail.panel.reviewconclusion': "评审结论",
    'intl.bugsubmitdetail.panel.comments': "评论",
    'intl.bugsubmitdetail.vulnerabilityinfo.type': "漏洞类型",
    'intl.bugsubmitdetail.vulnerabilityinfo.subtype': "子类型",
    'intl.bugsubmitdetail.vulnerabilityinfo.severity': "漏洞等级",
    'intl.bugsubmitdetail.vulnerabilityinfo.rewards': "奖励",
    'intl.bugsubmitdetail.vulnerabilityinfo.url': "URL",
    'intl.bugsubmitdetail.vulnerabilityinfo.apk': 'APK',
    'intl.bugsubmitdetail.vulnerabilityinfo.apkVersionCode': '版本号',
    'intl.bugsubmitdetail.vulnerabilityinfo.activity': "活动",
    'intl.bugsubmitdetail.vulnerabilityinfo.summary': "漏洞概要",
    'intl.bugsubmitdetail.vulnerabilityinfo.vulnerabilityinfo': "漏洞描述",
    'intl.bugsubmitdetail.vulnerabilityinfo.attachment': "附件",
    'intl.bugsubmitdetail.reviewconclusion.conclusion': "结论",
    'intl.bugsubmitdetail.reviewconclusion.conclusion.rating': "评定等级",
    'intl.bugsubmitdetail.reviewconclusion.conclusion.reputation': "贡献值",
    'intl.bugsubmitdetail.reviewconclusion.conclusion.payouts': "奖金",
    'intl.bugsubmitdetail.reviewconclusion.saferfedback': "安全员反馈",
    'intl.bugsubmitdetail.reviewconclusion.reviewtime': "审核时间",
    'intl.bugsubmitdetail.status.leak_status_submited': "待审核",
    'intl.bugsubmitdetail.status.leak_status_pending_fix': "待修复",
    'intl.bugsubmitdetail.status.leak_status_fixed': "已修复",
    'intl.bugsubmitdetail.status.leak_status_ignore': "已忽略",
    'intl.bugsubmitdetail.button.fold': "折叠",
    'intl.bugsubmitdetail.button.quote': "回复",
    'intl.bugsubmitdetail.button.addAttachment': "添加附件",
    'intl.bugsubmitdetail.button.send': "提交",
    'intl.bugsubmitdetail.button.cancel': "取消",
    'intl.bugsubmitdetail.prompt.attachments': "附件",
    'intl.bugsubmitdetail.prompt.format': "格式",
    'intl.bugsubmitdetail.prompt.noComments': "暂无评论",
    'intl.bugsubmitdetail.prompt.totalBonus': "总奖金",
    'intl.bugsubmitdetail.prompt.baseBonus': "基础奖金",
    'intl.bugsubmitdetail.prompt.activityBonus': "活动奖金",
    'intl.bugsubmitdetail.prompt.excellentBonus': "特殊奖金",
    'intl.bugsubmitdetail.prompt.totalReputation': "总贡献值",
    'intl.bugsubmitdetail.prompt.baseReputation': "基础贡献值",
    'intl.bugsubmitdetail.prompt.activityReputation': "活动贡献值",
    'intl.bugsubmitdetail.prompt.excellentReputation': "特殊贡献值",
    'intl.bugsubmitdetail.prompt.toPendingReview': "此评论解决了block问题，并将报告状态更改为[等待审核]",
    'intl.bugsubmitdetail.prompt.downloadTips': "下载附件时要小心，因为它们可能会带来意想不到的后果。",
    'intl.bugsubmitdetail.prompt.Download': "下载",

    //Blog
    'intl.blog.title': "公告",
    'intl.blog.introduce': "我们在此发布奖励政策、活动、操作指引等内容",
    'intl.blog.placeholder.search': "奖励政策",
    'intl.blog.type.all': "全部",
    'intl.blog.type.rules': "规则",
    'intl.blog.type.news': "新闻",
    'intl.blog.type.blog': "博客",
    'intl.blog.type.events': "活动",
    'intl.blog.type.rules': "规则",
    'intl.blog.button.viewdetails': "查看详情",
    'intl.blog.table.total': "共",
    'intl.blog.table.totalnum': "条",

    //Blog Detail
    'intl.blogdetail.breadcrumb.blog': "公告",
    'intl.blogdetail.breadcrumb.detail': "详情",
    'intl.blogdetail.type.news': "新闻",
    'intl.blogdetail.type.blog': "博客",
    'intl.blogdetail.type.events': "活动",
    'intl.blogdetail.type.rules': "规则",
    'intl.blogdetail.more.title': "更多",
    'intl.blogdetail.more.up': "上一篇",
    'intl.blogdetail.more.down': "下一篇",

    //Security Updates
    'intl.securityupdates.title': "TECNO安全更新",
    'intl.securityupdates.type.devicescope': "机型范围",
    'intl.securityupdates.type.firmwareupdates': "固件更新",
    'intl.securityupdates.type.securityAdvisory': "安全建议",
    'intl.securityupdates.devicescope.tip': "TECNO一直致力于为每一位用户提供安全、稳定的产品使用体验。我们定期发布安全更新的机型列表。部分机型可能因为区域差异存在延迟，如果对更新有问题，请咨询",
    'intl.securityupdates.devicescope.carlcare': "售后服务",
    'intl.securityupdates.firmwareupdates.tip': "TECNO会持续关注谷歌发布的安卓公共漏洞，定期为Android设备发布安全更新，并在这里公布已修复的安卓公共漏洞信息。有关安全补丁的详细信息，请参阅",
    'intl.securityupdates.firmwareupdates.android': "Android 安全公告",
    'intl.securityupdates.securityAdvisory.tip': "TECNO安全响应中心深入研究所有对TECNO产品产生影响的安全漏洞，并发布安全建议帮您应对安全风险。同时，我们也期望更多安全研究员积极参与",
    'intl.securityupdates.securityAdvisory. apply': "申请CVE",
    'intl.securityupdates.securityAdvisory.nodata': "暂无数据",
    'intl.securityupdates.securityAdvisory.apply': "申请你的CVE ID",
    'intl.securityupdates.placeholder.year': "年份",
    'intl.securityupdates.placeholder.quarter': "月份",

    //Message Center
    'intl.messagecenter.title': "消息中心",
    'intl.messagecenter.type.all': "全部",
    'intl.messagecenter.type.systemnotifications': "公告推送",
    'intl.messagecenter.type.reviewresults': "漏洞相关",
    'intl.messagecenter.type.orderRelated': "订单相关",
    'intl.messagecenter.read.all': "全部",
    'intl.messagecenter.read.yes': "已读",
    'intl.messagecenter.read.no': "未读",
    'intl.messagecenter.button.readall': "全部已读",
    'intl.messagecenter.button.read': "已读",
    'intl.messagecenter.button.viewdetails': "详情",
    'intl.messagecenter.pagination.total': "共",
    'intl.messagecenter.pagination.totalnum': "条",

    //Leaderboards
    'intl.leaderboards.title': "英雄榜",
    'intl.leaderboards.type.alltime': "总榜",
    'intl.leaderboards.type.monthly': "月榜",
    'intl.leaderboards.type.annual': "年榜",
    'intl.leaderboards.table.columns.ranking': "排名",
    'intl.leaderboards.table.columns.country': "国家",
    'intl.leaderboards.table.columns.nickname': "昵称",
    'intl.leaderboards.table.columns.nicknamecard.reputation': "贡献值",
    'intl.leaderboards.table.columns.nicknamecard.submitted': "已提交",
    'intl.leaderboards.table.columns.reputation': "基础贡献值",
    'intl.leaderboards.pagination.total': "共",
    'intl.leaderboards.pagination.totalnum': "条",
 
    //PersonalInfo
    'intl.personalinfo.title': "个人信息",
    'intl.personalinfo.type.personalinfo': "个人信息",
    'intl.personalinfo.type.payoutmethod': "收款方式",
    'intl.personalinfo.type.loginsecurity': "登录与安全",
    'intl.personalinfo.type.personalinfo.button.edit': "编辑",
    'intl.personalinfo.type.personalinfo.button.add': "添加",
    'intl.personalinfo.type.personalinfo.button.submit': "确定",
    'intl.personalinfo.type.personalinfo.button.cancel': "取消",
    'intl.personalinfo.type.personalinfo.placeholder.nameedit': "输入您的社交主页地址",
    'intl.personalinfo.type.personalinfo.notset': "未设置",
    'intl.personalinfo.type.personalinfo.reputation': "贡献值",
    'intl.personalinfo.type.personalinfo.submitted': "已提交",
    'intl.personalinfo.type.personalinfo.bonusalltitle': "奖金",
    'intl.personalinfo.type.personalinfo.bonusbasetitle': "基础",
    'intl.personalinfo.type.personalinfo.bonusextratitle': "额外",
    'intl.personalinfo.type.personalinfo.honoraryalltitle': "贡献值",
    'intl.personalinfo.type.personalinfo.honorarybasetitle': "基础",
    'intl.personalinfo.type.personalinfo.honoraryextratitle': "额外",
    'intl.personalinfo.type.personalinfo.modal.title': "修改头像",
    'intl.personalinfo.message.img.type': "仅支持 png、jpg、jpeg格式的文件",
    'intl.personalinfo.message.img.size': "图片大小不能超过1M!",
    'intl.personalinfo.message.namelength': "名字长度需小于20个字符",
    'intl.personalinfo.type.personalinfo.email': "邮箱",
    'intl.personalinfo.type.personalinfo.socialprofile': "社交主页",
    'intl.personalinfo.type.personalinfo.tip': "填写您的Facebook或Twitter社交主页地址，便于我们更好的认识您",
    'intl.personalinfo.type.loginsecurity.button.edit': "编辑",
    'intl.personalinfo.type.loginsecurity.button.submit': "确定",
    'intl.personalinfo.type.loginsecurity.button.cancel': "取消",
    'intl.personalinfo.type.loginsecurity.notset': "未设置",
    'intl.personalinfo.type.loginsecurity.hasset': "已设置",
    'intl.personalinfo.type.loginsecurity.modal.title': "修改登录密码",
    'intl.personalinfo.type.loginsecurity.password.title': "登录密码",
    'intl.personalinfo.type.loginsecurity.password.tip': "定期修改登录密码有助于您的账户安全",
    'intl.personalinfo.type.loginsecurity.facebook.title': "Facebook",
    'intl.personalinfo.type.loginsecurity.facebook.button': "  Facebook 绑定",
    'intl.personalinfo.type.loginsecurity.facebook.tip': "绑定后可通过Facebook登录",
    'intl.personalinfo.type.loginsecurity.signout.title': "注销账号",
    'intl.personalinfo.type.loginsecurity.signout.tip': "注销账号即代表同意抹去在此平台的所有数据和信息，如需注销账号，请使用注册邮箱联系安全响应中心团队（security.tecno@tecno-mobile.com）。",
    'intl.personalinfo.type.loginsecurity.oldpassword': "旧密码",
    'intl.personalinfo.type.loginsecurity.placeholder.oldpassword': "输入旧密码",
    'intl.personalinfo.type.loginsecurity.newpassword': "新密码",
    'intl.personalinfo.type.loginsecurity.placeholder.newpassword': "设置新的登录密码",
    'intl.personalinfo.type.loginsecurity.verifynewpassword': "校验新密码",
    'intl.personalinfo.type.loginsecurity.placeholder.verifynewpassword': "再次输入新密码",
    'intl.personalinfo.type.loginsecurity.error.twicepassword': "两次密码输入不一致",
    'intl.personalinfo.type.loginsecurity.success.changepassword': "修改成功",
    'intl.personalinfo.type.loginsecurity.success.bindfacebook': "绑定成功",
    'intl.personalinfo.type.loginsecurity.error.bindfacebook': "绑定失败",
    'intl.personalinfo.type.payoutmethod.title': "PayPal",
    'intl.personalinfo.type.payoutmethod.button.edit': "编辑",
    'intl.personalinfo.type.payoutmethod.button.delete': "删除",
    'intl.personalinfo.type.payoutmethod.button.deleteMessage': "删除后，我们会在发放奖金前通过邮件与您确认收款方式，不会影响您收到奖金。",
    'intl.personalinfo.type.payoutmethod.button.submit': "确定",
    'intl.personalinfo.type.payoutmethod.button.cancel': "取消",
    'intl.personalinfo.type.payoutmethod.notset': "未设置",
    'intl.personalinfo.type.payoutmethod.nopaypal': "当漏洞审核通过，我们会在发放奖金前通过邮件与您确认奖金的收款方式。",
    'intl.personalinfo.type.payoutmethod.notsetpaypal': "为了减少不必要的隐私信息收集，仅当您获得了漏洞奖金后才开放PayPal账号填写入口。",
    'intl.personalinfo.type.payoutmethod.hasnotsetpaypal': "在此设置您用于接收奖金的收款账号。",
    'intl.personalinfo.type.payoutmethod.hassetpaypal': "请认真检查，若账号填写错误导致奖金发放出现问题，后果需由您自己承担。",
    'intl.personalinfo.type.payoutmethod.modal.title': "PayPal账号设置",
    'intl.personalinfo.type.payoutmethod.paypal': "PayPal",
    'intl.personalinfo.type.payoutmethod.placeholder.paypal': "输入您的PayPal账号",
    'intl.personalinfo.type.payoutmethod.password': "登录密码",
    'intl.personalinfo.type.payoutmethod.placeholder.password': "TECNO SRC的登录密码",
    'intl.personalinfo.type.payoutmethod.captcha': "Captcha",
    'intl.personalinfo.type.payoutmethod.placeholder.captcha': "输入图形验证码",

    //No Data
    'intl.nodata.label': "No Data",

    //apk download
    'intl.apkDownload.title': "APK下载",
    'intl.apkDownload.search.placeholder': "APK",
    'intl.apkDownload.type.all': "全部",
    'intl.apkDownload.type.googlePlay': "已发布到Google Play",
    'intl.apkDownload.type.others': "其他",
    'intl.apkDownload.sort.default': "默认排序",
    'intl.apkDownload.sort.timeSort': "更新时间",
    'intl.apkDownload.spinning.tips': "下载中...",
    'intl.header.quickEntranceMenu.header': "我的主页",//newly added
    'intl.header.quickEntranceMenu.achievement': "个人成就",//newly added
    'intl.header.quickEntranceMenu.rewardsDetails': "奖励明细",//newly added
    'intl.header.quickEntranceMenu.payoutMethod': "收款方式",//newly added

    'intl.footer.contactCarlcare': "遇到了TECNO产品问题？", //newly added
    'intl.footer.visitCarlcare': "请访问Carlcare",//newly added

    'intl.leaderboards.Acknowkledgements': 'TECNO安全响应中心感激每一位安全研究员为维护TECNO产品和用户安全所做的贡献 ',//newly added
    'intl.leaderboards.Acknowkledgements.Button': '阅读感谢信',//newly added

    'intl.personalinfo.header.messageCenter': "消息中心",//newly added
    'intl.personalinfo.header.reportEntry': "提交漏洞",//newly added
    'intl.personalinfo.header.viewreportEntry': "漏洞报告",//newly added
    'intl.personalinfo.content.quickEntranceTitle': "快速入口",//newly added
    'intl.personalinfo.content.quickEntranceMenu.personalInfo': "我的主页",//newly added
    'intl.personalinfo.content.quickEntranceMenu.achievement': "个人成就",//newly added
    'intl.personalinfo.content.quickEntranceMenu.payoutMethod': "收款方式",//newly added
    'intl.personalinfo.content.quickEntranceMenu.rewardsDetails': "奖励明细",//newly added
    'intl.personalinfo.content.quickEntranceMenu.myRedemption': "我的兑换",//newly added
    'intl.personalinfo.content.settingEntry': '设  置',//newly added
    'intl.personalinfo.content.profileTitle': '我的主页',//newly added
    'intl.personalinfo.content.publicProfileEntry': '查看我的个人展示页 >',//newly added
    'intl.personalinfo.content.statistics': '数据',//newly added

    'intl.personalinfo.content.statistics.bonusTitle': '奖金',//newly added
    'intl.personalinfo.content.statistics.bonusContent': '获得的奖金总额，包括基础奖金和激励奖金。',//newly added
    'intl.personalinfo.content.statistics.reputationTitle': '贡献值',//newly added
    'intl.personalinfo.content.statistics.reputationContent': '贡献值用于计算英雄榜排名。',//newly added
    'intl.personalinfo.content.statistics.badgesTitle': '勋章',//newly added
    'intl.personalinfo.content.statistics.badgesContent': '获得的勋章总数。',//newly added
    'intl.personalinfo.content.statistics.creditTitle': '安全值',//newly added
    'intl.personalinfo.content.statistics.creditcardTitle': '安全值余额',//newly added
    'intl.personalinfo.content.statistics.creditContent': '安全值用于在积分商城兑换礼品。',//newly added
    'intl.personalinfo.content.statistics.vulnerabilityTitle': '有效/提交漏洞数',//newly added
    'intl.personalinfo.content.statistics.vulnerabilityContent': '有效漏洞总数和提交漏洞总数。',//newly added
    'intl.personalinfo.content.statistics.accuracyTitle': '准确率',//newly added
    'intl.personalinfo.content.statistics.accuracyContent': '有效漏洞总数和提交漏洞总数的百分比。',//newly added
    'intl.personalinfo.content.statistics.rankingTitle': '英雄榜 · 总榜',//newly added
    'intl.personalinfo.content.statistics.rankingContent': '英雄榜总榜的当前排名。',//newly added
    'intl.personalinfo.content.statistics.reportTitle': '漏洞报告状态',//newly added
    'intl.personalinfo.content.statistics.severityTitle': '有效漏洞定级',//newly added

    'intl.personalinfo.content.statisticsChart.open': '打开',
    'intl.personalinfo.content.statisticsChart.tiaged': '待修复',
    'intl.personalinfo.content.statisticsChart.fixed': '已修复',
    'intl.personalinfo.content.statisticsChart.rejected': '被拒绝',
    'intl.personalinfo.content.statisticsChart.suspended': '挂起',
    'intl.personalinfo.content.statisticsChart.critical': '严重',
    'intl.personalinfo.content.statisticsChart.high': '高危',
    'intl.personalinfo.content.statisticsChart.medium': '中危',
    'intl.personalinfo.content.statisticsChart.low': '低危',
    'intl.personalinfo.content.statisticsChart.non': '无危',

    'intl.personalinfo.content.achievementTitle': '成就',//newly added
    'intl.personalinfo.content.achievement.leftTitle': '近期勋章',//newly added
    'intl.personalinfo.content.achievement.rightEntry': '更多勋章 >',//newly added
    'intl.personalinfo.medalCard.userCount': " 位用户拥有该枚勋章",//newly added
    'intl.personalinfo.medalCard.wearingTag': "佩戴中",//newly added
    'intl.personalinfo.medalCard.expiredTag': "已过期",//newly added
    'intl.personalinfo.medalCard.unlockedTime': "解锁于 ",//newly added
    'intl.personalinfo.medalCard.expiredTime': "有效至 ",//newly added
    'intl.personalinfo.medalCard.locked': "未解锁",//newly added
    'intl.personalinfo.medalCard.wearBadge': "佩戴勋章",//newly added
    'intl.personalinfo.medalCard.takeOffBadge': "摘下勋章",//newly added
    'intl.personalinfo.medalCard.longTerm': "长期有效",//newly added
    'intl.personalinfo.breadCrumb.personalInfo': "我的主页",//newly added
    'intl.personalinfo.breadCrumb.achievement': "个人成就",//newly added
    'intl.personalinfo.breadCrumb.myRedemption': "兑换记录",//newly added
    'intl.personalinfo.breadCrumb.rewardsDetails': "奖励明细",//newly added
    'intl.personalinfo.breadCrumb.payoutMethod': "设置",//newly added
    'intl.personalinfo.breadCrumb.quickEntrance': "快速入口",//newly added
    'intl.personalinfo.achievement.badgeRules': "TECNO安全响应中心感激每一位安全研究员为维护TECNO产品和用户安全所做的贡献",//newly added
    'intl.personalinfo.achievement.badgeRules.button': "阅读勋章详细介绍",//newly added
    'intl.personalinfo.settings.personalInfo.title': "个人信息",//newly added
    'intl.personalinfo.settings.personalInfo.avatar': "头像：",//newly added
    'intl.personalinfo.settings.personalInfo.nickname': "昵称：",//newly added
    'intl.personalinfo.settings.personalInfo.country': "国家/地区：",//newly added
    'intl.personalinfo.settings.personalInfo.email': "邮箱：",//newly added
    'intl.personalinfo.settings.personalInfo.registDate': "注册日期：",//newly added
    'intl.personalinfo.settings.personalInfo.edit': "编辑",//newly added
    'intl.personalinfo.settings.payoutMethod.title': "收款方式",//newly added
    'intl.personalinfo.settings.payoutMethod.desc': "我们收集的信息仅用于漏洞奖励，不会用于其他目的。",//newly added
    'intl.personalinfo.settings.payoutMethod.link': "添加银行卡注意事项 >",//newly added
    'intl.personalinfo.settings.payoutMethod.addBank': "添加银行卡",//newly added
    'intl.personalinfo.settings.payoutMethod.addBankModal.title': "添加银行卡信息",//newly added
    'intl.personalinfo.settings.payoutMethod.editBankModal.title': "编辑银行卡信息",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.hint': "我们收集的信息仅用于漏洞奖励，不会用于其他目的。",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.hintLink': "查看添加银行卡注意事项",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.country': "银行账户所在国家",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.payeeName': "收款人姓名",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.payeeAccount': "收款银行账号",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankName': "收款银行名称",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.swift': "银卡银行SWIFT码",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankCountry': "收款银行所在国家",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankcity': "收款银行所在城市",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankAddress': "收款银行地址",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.routingNumber': "汇款路线号码",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.interMediary': "中间行名称",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.interMediarySwift': "中间行SWIFT码",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.ID': "收款人身份证号",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.phoneNumber': "收款人手机号",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.ifSign': "好灵工是否签约",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.signOption.yes': "已签约",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.signOption.no': "未签约",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.edit': "编辑",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove': "删除",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove.modalTitle': "确定删除银行账户吗？",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove.modalContent': "删除银行账户后，您将无法获得漏洞奖励。",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove.cancelBtn': "取消",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove.confirmBtn': "删除",//newly added

    'intl.personalinfo.settings.socialMedia.title': "社交媒体账号",//newly added
    'intl.personalinfo.settings.socialMedia.desc': "填写您的Facebook或Twitter社交主页地址，便于我们更好的认识您",//newly added
    'intl.personalinfo.settings.socialMedia.editSave': "保存",//newly added
    'intl.personalinfo.settings.socialMedia.editCancel': "取消",//newly added
    'intl.personalinfo.settings.socialMedia.edit': "编辑",//newly added
    'intl.personalinfo.settings.socialMedia.notSet': "未设置",//newly added
    'intl.personalinfo.settings.socialMedia.addAccount': "添加其他社交媒体账号",//newly added
    'intl.personalinfo.settings.socialMedia.deleteBtn': "删除",//newly added

    'intl.personalinfo.settings.publicProfile.title': "个人展示",//newly added
    'intl.personalinfo.settings.publicProfile.desc': "设计您的个人展示页面，结识更多伙伴！",//newly added
    'intl.personalinfo.settings.publicProfile.link': "设置个人展示页面 >",//newly added

    'intl.personalinfo.settings.login.title': "登录与安全",//newly added
    'intl.personalinfo.settings.login.password.title': "登录密码：",//newly added
    'intl.personalinfo.settings.login.password.hint': "定期修改登录密码有助于您的账户安全",//newly added
    'intl.personalinfo.settings.login.email.title': "邮箱：",//newly added
    'intl.personalinfo.settings.login.facebook.title': "用Facebook账户登录：",//newly added
    'intl.personalinfo.settings.login.facebook.hint': "绑定后可通过Facebook登录",//newly added
    'intl.personalinfo.settings.login.deleteAccount.title': "注销账号",//newly added
    'intl.personalinfo.settings.login.deleteAccount.hint': "注销账号即代表同意抹去在此平台的所有数据和信息，如需注销账号，请使用注册邮箱联系安全响应中心团队（security.tecno@tecno-mobile.com）。",//newly added
    'intl.personalinfo.settings.APKTips': "*本页面所提供的应用版本仅供参考，我们将以安全研究员所在地区的最高版本作为漏洞判定和接收标准。",

    //No Data
    'intl.nodata.label': "暂无数据",

    //Public Profile
    'intl.publicProfile.title': "个人展示", //newly added
    'intl.publicProfile.title.button.edit': "设置个人展示页面", //newly added
    'intl.publicProfile.title.button.save': "保存", //newly added
    'intl.publicProfile.title.button.cancel': "取消", //newly added
    'intl.publicProfile.statistics.title': "数据", //newly added

    'intl.publicProfile.achievements.title': "成就", //newly added
    'intl.publicProfile.socialAccount.title': "社交媒体账号", //newly added
    'intl.publicProfile.editMode.switch.showText': "展示中", //newly added
    'intl.publicProfile.editMode.switch.unshowText': "未展示", //newly added

    'intl.personalInfo.noData.hint': '暂无数据',
    'intl.personalInfo.noData.jump.bug': '去提交漏洞报告！',
    'intl.personalInfo.noData.badge': '"暂无勋章。如何获取勋章？',

    'intl.personalInfo.header.registerDate': '注册于',

    'intl.personalInfo.noData.badge.front': '暂无勋章。 ',
    'intl.personalInfo.noData.badge.latter': '如何获取勋章？',
    'intl.dashboard.medalCard.jumpText': '更多勋章 >',

    'intl.bugSubmitList.tableTitle.all': "全部",
    'intl.bugSubmitList.tableTitle.open': "打开",
    'intl.bugSubmitList.tableTitle.new': "待审核",
    'intl.bugSubmitList.tableTitle.pendingReview': "待验证",
    'intl.bugSubmitList.tableTitle.needsMoreInfo': "待补充",
    'intl.bugSubmitList.tableTitle.Accepted': "已接收",
    'intl.bugSubmitList.tableTitle.Triaged': "待修复",
    'intl.bugSubmitList.tableTitle.Suspended': "挂起",//
    'intl.bugSubmitList.tableTitle.Fixed': "已修复",
    'intl.bugSubmitList.tableTitle.Rejected': "被拒收",
    'intl.bugSubmitList.tableTitle.Informative': "无危的",
    'intl.bugSubmitList.tableTitle.Duplicate': "重复的",
    'intl.bugSubmitList.tableTitle.outOfScope': "超范围",
    'intl.bugSubmitList.tableTitle.notReproducible': "无法重现",
    'intl.bugSubmitList.tableTitle.Spam': "垃圾邮件",
    'intl.bugSubmitList.tableTitle.Ignored': "被忽略",

     //Swagshop
     'intl.swagshop.title': "积分商城",
     'intl.swagshop.placeholder.search': "查找商品",
     'intl.swagshop.filterBox.sortedBy':'排序',
     'intl.swagshop.filterBox.default':'默认',
     'intl.swagshop.filterBox.popular':'热门',
     'intl.swagshop.filterBox.newIn':'最新',
     'intl.swagshop.filterBox.priceHigh':'价格从低到高',
     'intl.swagshop.filterBox.priceLow':'价格从高到低',
     'intl.swagshop.filterBox.creditsRangeTitle':'安全值范围',
     'intl.swagshop.filterBox.creditsRange':'全部',
     'intl.swagshop.filterBox.onlyMy':'显示我可兑换的',
     'intl.swagshop.articleLink':'完整指南：玩转TECNO安全响应中心积分商城',
     'intl.swagshop.cashCoupon':'现金兑换券',
     'intl.swagshop.credits':'安全值',
     'intl.swagshop.questionnaire.comingSoon':'更多精美礼品即将到来，敬请期待！',
     'intl.swagshop.questionnaire.inventory':'写下你的愿望清单',
     'intl.swagshop.table.total': "共",
     'intl.swagshop.table.totalnum': "条",

     //swagshopdetail
    'intl.swagshopdetail.swagshop':'积分商城',
    'intl.swagshopdetail.details':'详情',
    'intl.swagshopdetail.mattersNeedAttention':'注意：每个月8号前提交兑换，我们将在当月发放奖励；每个月8号后申请，我们将在下个月发放奖励。特殊情况奖励发放会有延迟。订单提交后如有疑问，请通过邮箱联系我们',
    'intl.swagshopdetail.punctuation':'。',
    'intl.swagshopdetail.cashCoupon':'现金兑换券',
    'intl.swagshopdetail.credits':'安全值',
    'intl.swagshopdetail.briefIntroduction':'现金兑换奖励通过银行卡转账的方式进行发放。提交订单请确保银行卡信息完整且准确。',
    'intl.swagshopdetail.quantity':'数量',
    'intl.swagshopdetail.totalPrice':'总价',
    'intl.swagshopdetail.creditsBalance':'安全值可用余额',
    'intl.swagshopdetail.payoutMethod':'支付方式',
    'intl.swagshopdetail.bankInfo':'您也可以前往',
    'intl.swagshopdetail.bankLink':'我的主页 > 支付方式',
    'intl.swagshopdetail.bankTips':'来管理银行账户信息。',
    'intl.swagshopdetail.redeem':'兑换',
    'intl.swagshopdetail.tips.notCredits':'您的安全值不足，无法进行此次兑换。',
    'intl.swagshopdetail.tips.notBank':'您尚未填写银行卡信息。',
    'intl.swagshopdetail.tips.order':"您已提交订单！",

     //My Redemption
     'intl.myRedemption.myRedemption': '我的兑换',
     'intl.myRedemption.dataCardmy.creditsBalance': '安全值余额',
     'intl.myRedemption.dataCardmy.totalCredits': '安全值总额',
     'intl.myRedemption.dataCardmy.redeemedCredits': '已兑换安全值',
     'intl.myRedemption.skipButton.goTo': '前往',
     'intl.myRedemption.skipButton.swagshop': '积分商城 >',
     'intl.myRedemption.skipButton.swagshopTips': '更多精美礼品等你来拿！',
     'intl.myRedemption.skipButton.getMore': '获取更多',
     'intl.myRedemption.skipButton.credits': '安全值 >',
     'intl.myRedemption.skipButton.creditsTips': '查看获取安全值的最新活动！',
     'intl.myRedemption.filterBox.Search':'搜索订单号或礼品',
     'intl.myRedemption.filterBox.startTime':'开始时间',
     'intl.myRedemption.filterBox.to':'至',
     'intl.myRedemption.filterBox.endTime':'结束时间',
     'intl.myRedemption.filterBox.status':'状态',
     'intl.myRedemption.table.status.reviewing': '审核中',
     'intl.myRedemption.table.status.processing': '处理中',
     'intl.myRedemption.table.status.cancelled': '已取消',
     'intl.myRedemption.table.status.complete': '已完成',
     'intl.myRedemption.table.status.rejected': '已拒绝',
     'intl.myRedemption.table.columns.orderID': '订单号',
     'intl.myRedemption.table.columns.gifts': '礼品',
     'intl.myRedemption.table.columns.quantity': '数量',
     'intl.myRedemption.table.columns.unitPrice': '单价',
     'intl.myRedemption.table.columns.totlePrice': '总价',
     'intl.myRedemption.table.columns.date': '日期',
     'intl.myRedemption.table.columns.note': '备注',
     'intl.myRedemption.table.columns.status': '状态',
     'intl.myRedemption.table.columns.cancel': '取消',
     'intl.myRedemption.table.columns.operate': '操作',
     'intl.myRedemption.table.popconfirm.title': '你确定要取消这个订单吗?',
     'intl.myRedemption.table.popconfirm.content': "点击“是”后，订单将被取消，不处理订单。",
     'intl.myRedemption.table.popconfirm.yes': "是的，我想取消它",
     'intl.myRedemption.table.popconfirm.no': "否",
     'intl.myRedemption.table.popconfirm.message': "操作成功",
     'intl.myRedemption.table.total':'共',
     'intl.myRedemption.table.totalnum':'条 ',

     //Rewards Details
   'intl.rewardsDetails.filterBox.all':'全部',
   'intl.rewardsDetails.filterBox.bonusDetails':'奖金明细',
   'intl.rewardsDetails.filterBox.reputationDetails':'贡献值明细',
   'intl.rewardsDetails.filterBox.creditDetails':'安全值明细',
   'intl.rewardsDetails.filterBox.year':'年',
   'intl.rewardsDetails.filterBox.month':'月',
   'intl.rewardsDetails.filterBox.startTime':'开始时间',
   'intl.rewardsDetails.filterBox.to':'至',
   'intl.rewardsDetails.filterBox.endTime':'结束时间',
   'intl.rewardsDetails.filterBox.type':'类别',
   'intl.rewardsDetails.filterBox.searchPlaceholder':'搜索变更详情',
   'intl.rewardsDetails.bonusDataCard.totalBonus':'奖金总额',
   'intl.rewardsDetails.bonusDataCard.baseBonus':'基础奖金',
   'intl.rewardsDetails.bonusDataCard.incentivesBonus':'激励奖金',
   'intl.rewardsDetails.bonusDataCard.submitReport':'提交报告 >',
   'intl.rewardsDetails.bonusDataCard.findActivities':'更多活动 >',
   'intl.rewardsDetails.reputationDataCard.reputation':'贡献值',
   'intl.rewardsDetails.reputationDataCard.hOFAll':'英雄榜 · 总榜',
   'intl.rewardsDetails.reputationDataCard.st':'1st',
   'intl.rewardsDetails.reputationDataCard.hOFYear':'英雄榜 ·',
   'intl.rewardsDetails.reputationDataCard.rd':'3rd',
   'intl.rewardsDetails.creditsDataCard.creditsBalance':'安全值余额',
   'intl.rewardsDetails.creditsDataCard.totalCredits':'安全值总额',
   'intl.rewardsDetails.creditsDataCard.redeemedCredits':'已兑换安全值',
   'intl.rewardsDetails.creditsDataCard.goToRedeem':'前往兑换 >',
   'intl.rewardsDetails.creditsDataCard.getCredits':'获取安全值 >',
   'intl.rewardsDetails.creditsDataCard.myRedemption':'我的兑换 >',
   'intl.rewardsDetails.creditsDataCard.note':'提示：当按时间筛选时，您的安全值余额可能显示为负数，这是因为在所选的时间范围内，您兑换的安全值比获取的安全值多。',
   'intl.rewardsDetails.pieChart.creditsRatio':'安全值构成比例',
   'intl.rewardsDetails.pieChart.vulnerabilityReward':'漏洞奖励',
   'intl.rewardsDetails.pieChart.activityReward':'活动奖励',
   'intl.rewardsDetails.pieChart.securityAuthorReward':'安全作家奖励',
   'intl.rewardsDetails.table.columns.vulnerabilityRewardAndActivityReward':'漏洞奖励+活动奖励',
   'intl.rewardsDetails.table.columns.changeDate': '变更日期',
   'intl.rewardsDetails.table.columns.type': '类型',
   'intl.rewardsDetails.table.columns.creditRedemption': '安全值兑换',
   'intl.rewardsDetails.table.columns.vulnerabilityReward': '漏洞奖励',
   'intl.rewardsDetails.table.columns.activityReward': '活动奖励',
   'intl.rewardsDetails.table.columns.securityAuthorReward': '安全作家奖励',
   'intl.rewardsDetails.table.columns.changeDetails': '变更详情',
   'intl.rewardsDetails.table.columns.baseBonus': '基础奖励',
   'intl.rewardsDetails.table.columns.incentivesBonus': '激励奖励',
   'intl.rewardsDetails.table.columns.reputations': '贡献值',
   'intl.rewardsDetails.table.columns.orderID': '订单号：',
   'intl.rewardsDetails.table.columns.product': '礼品：',
   'intl.rewardsDetails.table.columns.note': '备注：',
   'intl.rewardsDetails.table.columns.activity': '活动：',
   'intl.rewardsDetails.table.columns.vulnerability': '漏洞：',
   'intl.rewardsDetails.table.columns.article': '文章：',
   'intl.rewardsDetails.table.columns.credits': '安全值',
   'intl.rewardsDetails.table.columns.operate': '操作',
   'intl.rewardsDetails.table.columns.noteTips': '订单已取消',
   'intl.rewardsDetails.table.total':'共',
   'intl.rewardsDetails.table.totalnum':'条', 

}

export default zh_CN;


// import {useIntl} from 'react-intl';
// const intl = useIntl();
// {
//     intl.formatMessage({
//         id: 'intl.footer.securityupdate'
//     })
// }


