import React, { useEffect,useState } from 'react';
import './style/BugSubmitFailed.css';
import {useIntl} from 'react-intl';
import { Result, Button } from 'antd';
import { useHistory } from "react-router-dom";
import { goRouterPosition } from '../../action/routerposition/routerpositionAction'
import store from '../../store/index'

export default (function (props) {
    const intl = useIntl();
    useEffect(() => {
        (sessionStorage.getItem('lang') == 'zh_CN') && (document.title = '提交失败') ||
        (sessionStorage.getItem('lang') == 'en_US') && (document.title = 'Submit Failed')
        goRouterPosition({routerPosition:"bugSubmitFailed"})
    }, []);
    const history = useHistory()
    // const ReportBugs = () => {
    //     history.push('/SRC/bugSubmitList')
    // };
    const Submitonemore = () => {
        history.push('/SRC/bugSubmit')
    };

    return ( 
        <div className="Container">
            <p className="ReportBugsTitle">
                {
                    intl.formatMessage({
                        id: 'intl.bugsubmitfailed.title'
                    })
                }
            </p>
            <Result
                className= "ReportBugsContent"
                status="error"
                title={
                    intl.formatMessage({
                        id: 'intl.bugsubmitfailed.content.title'
                    })
                }
                subTitle={
                    intl.formatMessage({
                        id: 'intl.bugsubmitfailed.content.subtitle'
                    })
                }
                extra={[
                    // <Button type="primary" onClick={ReportBugs} key="Viewdetails">
                    //     {
                    //     intl.formatMessage({
                    //         id: 'intl.bugsubmitfailed.button.viewdetails'
                    //     })
                    // }
                    // </Button>,
                    <Button key="Submitonemore" onClick={Submitonemore}>
                        {
                            intl.formatMessage({
                                id: 'intl.bugsubmitfailed.button.submitonemore'
                            })
                        }
                    </Button>,
                ]}
            />
        </div>
    );
})