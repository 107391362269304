import React, { useEffect,useState } from 'react';
import './style/BugSubmitSuccess.css';
import {useIntl} from 'react-intl';
import { goRouterPosition } from '../../action/routerposition/routerpositionAction'
import { Result, Button } from 'antd';
import { useHistory } from "react-router-dom";
import store from '../../store/index'

export default (function (props) {
    const intl = useIntl();
    const [ state,setState ] = useState(store.getState().Blog) 
    useEffect(() => {
        (sessionStorage.getItem('lang') == 'zh_CN') && (document.title = '提交成功') ||
        (sessionStorage.getItem('lang') == 'en_US') && (document.title = 'Submit Success')
        goRouterPosition({routerPosition:"bugSubmitSuccess"})
        store.subscribe(storeChange);
    }, []);
    const history = useHistory()
    const storeChange = () => {
        setState(store.getState().Blog)
    };
    const ReportBugs = () => {
        history.push('/SRC/bugSubmitList')
    };
    const Submitonemore = () => {
        history.push('/SRC/bugSubmit')
    };

    return ( 
        <div className="Container">
            <p className="ReportBugsTitle">
                {
                    intl.formatMessage({
                        id: 'intl.bugsubmitsuccess.title'
                    })
                }
            </p>
            <Result
                className= "ReportBugsContent"
                status="success"
                title={
                    intl.formatMessage({
                        id: 'intl.bugsubmitsuccess.content.title'
                    })
                }
                extra={[
                    <Button type="primary" onClick={ReportBugs} key="Viewdetails">
                        {
                            intl.formatMessage({
                                id: 'intl.bugsubmitsuccess.button.viewdetails'
                            })
                        }
                    </Button>,
                    <Button key="Submitonemore" onClick={Submitonemore}>
                        {
                            intl.formatMessage({
                                id: 'intl.bugsubmitsuccess.button.submitonemore'
                            })
                        }
                    </Button>,
                ]}
            />
        </div>
    );
})