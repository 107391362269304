import React, { useEffect, useState, Fragment } from 'react';
import './style/securityUpdates.css';
import { useIntl } from 'react-intl';
import { Select, Radio } from 'antd';
import { scrollToTop } from '../../common/topScorll';
import { goRouterPosition } from '../../action/routerposition/routerpositionAction';
import SecurityUpdatesService from '../../services/securityUpdatesService';
import icNoData from '../../assets/svg/ic-空.svg';
import noDataImg from '../../assets/svg/bg_noData.svg';
import { detailLink } from '../../common/detailLink';

export default (function (props) {
	const intl = useIntl();
	const [securityUpdatesType, setSecurityUpdatesType] =
		useState('Device Scope');
	const [yearList, setYearList] = useState([]);
	const [deviceScopeList, setDeviceScopeList] = useState([]);
	const [deviceScopeListCount, setDeviceScopeCount] = useState(0);
	const [firmwareUpdatesList, setFirmwareUpdatesList] = useState([]);
	const [firmwareUpdatesCount, setFirmwareUpdatesCount] = useState(0);
	const [quarterList, setQuarterList] = useState([]);
	const [yearSelect, setYearSelect] = useState('');
	const [quarterSelect, setQuarterSelect] = useState('');
	const [deviceYearList, setDeviceYearList] = useState([]);
	const [deviceYearSelect, setDeviceYearSelect] = useState('');
	const [cveYearList, setCveYearList] = useState([]);
	const [cveMonthList, setCveMonthList] = useState([]);
	const [cveDataList, setCveDataList] = useState([]);
	const [cveYearSelect, setCveYearSelect] = useState();
	const [cveMonthSelect, setCveMonthSelect] = useState();

	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = '安全更新')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'Security Updates'));
		scrollToTop();
		// sessionStorage.setItem('redirect', window.location.pathname);
		sessionStorage.setItem(
			'redirect',
			`${window.location.pathname}${window.location.search}`
		);
		goRouterPosition({ routerPosition: 'securityUpdates' });
		SecurityUpdatesService.getYearListData()
			.then((res) => {
				let tempYear = res.data[0].year;
				setYearList(res.data);
				setYearSelect(tempYear);
				SecurityUpdatesService.getQuarterListData({ year: tempYear })
					.then((res) => {
						setQuarterList(res.data);
						setQuarterSelect(res.data[0].quarter);
						getFirmwareUpdatesList(tempYear, res.data[0].quarter);
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
		SecurityUpdatesService.getDeviceYearList()
			.then((res) => {
				let temp = res.data[0];
				setDeviceYearList(res.data);
				setDeviceYearSelect(temp);
				SecurityUpdatesService.getDeviceScopeListData({ year: temp })
					.then((res) => {
						setDeviceScopeList(res.data);
						setDeviceScopeCount(res.total);
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
		SecurityUpdatesService.getCveYearList()
			.then((res) => {
				let temp = res.data[0].year;
				setCveYearList(res.data);
				setCveYearSelect(temp);
				SecurityUpdatesService.getCveMonthList({ year: temp })
					.then((res) => {
						setCveMonthList(res.data);
						setCveMonthSelect(res.data[0].month);
						getCveList(temp, res.data[0].month);
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
		const urlType = window.location.search.split('=')[1];
		if (urlType === 'SA') {
			setSecurityUpdatesType('Security Advisory');
		} else if (urlType === 'FU') {
			setSecurityUpdatesType('Firmware Updates');
		}
	}, []);
	const getCveList = (year, month) => {
		SecurityUpdatesService.getCveDataList({
			year: year,
			month: month,
		})
			.then((res) => {
				setCveDataList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const cveYearSelectChange = (value) => {
		setCveYearSelect(value);
		SecurityUpdatesService.getCveMonthList({ year: value })
			.then((res) => {
				setCveMonthList(res.data);
				setCveMonthSelect(res.data[0].month);
				getCveList(value, res.data[0].month);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const cveMonthSelectChange = (value) => {
		setCveMonthSelect(value);
		getCveList(cveYearSelect, value);
	};
	const { Option } = Select;
	const yearSelectChange = (value) => {
		setYearSelect(value);
		SecurityUpdatesService.getQuarterListData({ year: value })
			.then((res) => {
				setQuarterList(res.data);
				setQuarterSelect(res.data[0].quarter);
				getFirmwareUpdatesList(value, res.data[0].quarter);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const quarterSelectChange = (value) => {
		setQuarterSelect(value);
		getFirmwareUpdatesList(yearSelect, value);
	};
	const changesecurityUpdatesType = (e) => {
		setSecurityUpdatesType(e.target.value);
	};
	const deviceYearChange = (value) => {
		setDeviceYearSelect(value);
		SecurityUpdatesService.getDeviceScopeListData({ year: value })
			.then((res) => {
				setDeviceScopeList(res.data);
				setDeviceScopeCount(res.total);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getFirmwareUpdatesList = (year, quarter) => {
		SecurityUpdatesService.getFirmwareUpdatesListData({
			year: year,
			quarter: quarter,
		})
			.then((res) => {
				setFirmwareUpdatesList(res.data);
				setFirmwareUpdatesCount(res.total);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const goDetailLink = (linkContent) => {
		// console.log(detailLink)
		// console.log(linkContent)
		// console.log(detailLink[sessionStorage.getItem('lang')][linkContent])
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
		// window.open(`${detailLink}.${sessionStorage.getItem('lang')}.${linkContent}`)
	};
	return (
		<div className='securityUpdatesContainer'>
			<div className='securityUpdatesHeader'>
				<div className='securityUpdatesHeaderContent'>
					<p className='securityUpdatesTitle'>
						{intl.formatMessage({
							id: 'intl.securityupdates.title',
						})}
					</p>
				</div>
			</div>
			<div className='securityUpdatesContent'>
				<div className='securityUpdatesTypeRadio'>
					<Radio.Group
						value={securityUpdatesType}
						onChange={changesecurityUpdatesType}
					>
						<Radio.Button value='Device Scope'>
							{intl.formatMessage({
								id: 'intl.securityupdates.type.devicescope',
							})}
						</Radio.Button>
						<Radio.Button value='Firmware Updates'>
							{intl.formatMessage({
								id: 'intl.securityupdates.type.firmwareupdates',
							})}
						</Radio.Button>
						<Radio.Button value='Security Advisory'>
							{intl.formatMessage({
								id: 'intl.securityupdates.type.securityAdvisory',
							})}
						</Radio.Button>
					</Radio.Group>

					{/* Device Scope */}
					{securityUpdatesType === 'Device Scope' && (
						<div>
							<p className='securityUpdatesTip'>
								<span>{intl.formatMessage({
									id: 'intl.securityupdates.devicescope.tip',
								})}</span>
                <span className='securityUpdatesLink' 
                onClick={()=>window.open('https://www.tecno-mobile.com/carlcare-global/',"_black")}
                >
                {intl.formatMessage({
									id: 'intl.securityupdates.devicescope.carlcare',
								})}
                </span>
								{sessionStorage.getItem('lang')=='en_US'?'.':'。'}
							</p>
							<Select
								className='yearSelect'
								onChange={deviceYearChange}
								showSearch
								value={deviceYearSelect}
								placeholder={intl.formatMessage({
									id: 'intl.securityupdates.placeholder.year',
								})}
							>
								{deviceYearList.map((data, index) => (
									<Option key={index} value={data}>
										{data}
									</Option>
								))}
							</Select>
							{(deviceScopeListCount == 0 && (
								<div className='noDataDiv'>
									<img className='noDataImg' src={icNoData} />
									<p className='noDataLabel'>
										{intl.formatMessage({
											id: 'intl.nodata.label',
										})}
									</p>
								</div>
							)) ||
								deviceScopeList.map((data, index) => (
									<li key={index} className='DeviceScopeLi'>
										<p className='DeviceScopeTite'>{data.i18n.title}</p>
										<p className='DeviceScopeContent'>{data.i18n.content}</p>
									</li>
								))}
						</div>
					)}

					{/* Firmware Updates */}
					{securityUpdatesType === 'Firmware Updates' && (
						<div>
							<p className='securityUpdatesTip'>
								{intl.formatMessage({
									id: 'intl.securityupdates.firmwareupdates.tip',
								})}
                <span  className='securityUpdatesLink' 
                 onClick={()=>{
                  goDetailLink('AndroidSecurityBulletins')
                }}
                >
                {intl.formatMessage({
									id: 'intl.securityupdates.firmwareupdates.android',
								})}
                </span>
							{sessionStorage.getItem('lang')=='en_US'?'.':'。'}
							</p>
							<Select
								className='yearSelect'
								onChange={yearSelectChange}
								showSearch
								defaultValue={yearSelect}
								placeholder={intl.formatMessage({
									id: 'intl.securityupdates.placeholder.year',
								})}
							>
								{yearList.map((data, index) => (
									<Option key={index} value={data?.year}>
										{data?.year}
									</Option>
								))}
							</Select>
							<Select
								className='quarterSelect'
								onChange={quarterSelectChange}
								showSearch
								value={quarterSelect}
								defaultValue={quarterSelect}
								placeholder={intl.formatMessage({
									id: 'intl.securityupdates.placeholder.quarter',
								})}
							>
								{quarterList.map((data, index) => (
									<Option key={index} value={data.quarter}>
										{data.quarter}
									</Option>
								))}
							</Select>
							{(firmwareUpdatesCount == 0 && (
								<div className='noDataDiv'>
									<img className='noDataImg' src={icNoData} />
									<p className='noDataLabel'>
										{intl.formatMessage({
											id: 'intl.nodata.label',
										})}
									</p>
								</div>
							)) ||
								firmwareUpdatesList.map((data, index) =>
									data.patchInfosI18n.map((data, index) => (
										<li className='FirmwareUpdatesLi'>
											<p className='FirmwareUpdatesTite'>{data.title}</p>
											<p className='FirmwareUpdatesContent'>{data.content}</p>
										</li>
									))
								)}
						</div>
					)}

					{/* Security Advisory */}
					{securityUpdatesType === 'Security Advisory' && (
						<div>
							<p className='securityUpdatesTip'>
								{intl.formatMessage({
									id: 'intl.securityupdates.securityAdvisory.tip',
								})}
                <span  className='securityUpdatesLink'
                  onClick={()=>{
                    goDetailLink('ApplyForCVEIDs')
                  }}
                 >
                {intl.formatMessage({
									id: 'intl.securityupdates.securityAdvisory. apply',
								})}
                </span>
								{sessionStorage.getItem('lang')=='en_US'?'.':'。'}
							</p>
							<Select
								className='yearSelect'
								onChange={cveYearSelectChange}
								showSearch
								defaultValue={cveYearSelect}
								value={cveYearSelect}
								placeholder={intl.formatMessage({
									id: 'intl.securityupdates.placeholder.year',
								})}
							>
								{cveYearList.map((data, index) => (
									<Option key={index} value={data?.year}>
										{data?.year}
									</Option>
								))}
							</Select>
							<Select
								className='quarterSelect'
								onChange={cveMonthSelectChange}
								showSearch
								value={cveMonthSelect}
								defaultValue={cveMonthSelect}
								placeholder={intl.formatMessage({
									id: 'intl.securityupdates.placeholder.quarter',
								})}
							>
								{cveMonthList.map((data, index) => (
									<Option key={index} value={data.month}>
										{data.month}
									</Option>
								))}
							</Select>
							{cveDataList?.length > 0 ? (
								<Fragment>
									{cveDataList?.map((item) =>
										item.cveInfosI18n?.map((data, index) => (
											<li className='FirmwareUpdatesLi'>
												<p
													className='CveTitle'
													onClick={() => {
														window.open(data.url);
													}}
												>
													{data.title}
												</p>

												<p
													className='CveContent'
													dangerouslySetInnerHTML={{ __html: data.content }}
												></p>
											</li>
										))
									)}
								</Fragment>
							) : (
								<div className='nodataDiv'>
									<img src={noDataImg} className='noDataImg' alt='NO DATA' />
									<div className='nodataText'>
										{intl.formatMessage({
											id: 'intl.securityupdates.securityAdvisory.nodata',
										})}
									</div>
									<div
										className='applyText'
										onClick={() => {
											goDetailLink('ApplyForCVEIDs');
										}}
									>
										{intl.formatMessage({
											id: 'intl.securityupdates.securityAdvisory.apply',
										})}
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
});
