import React, { useEffect, useRef, useState } from 'react';
import './style/personalInfo.css';
import { Link, useHistory } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { Popover, Button, Dropdown, message } from 'antd';
import { scrollToTop } from '../../common/topScorll';
import { useIntl } from 'react-intl';
import { detailLink } from '../../common/detailLink';
import { PlusOutlined } from '@ant-design/icons';
import { goRouterPosition } from '../../action/routerposition/routerpositionAction';
import PersonalInfoService from '../../services/personalInfoService';
import avatar from '../../assets/svg/icon_avatar_gray.svg';
import achievementIcon from '../../assets/svg/icon_achievement.svg';
import personalInfoIcon from '../../assets/svg/icon_personalInfo.svg';
import payoutMethodIcon from '../../assets/svg/icon_payoutMethod.svg';
import MyRedemptionIcon from '../../assets/svg/icon_redemptionRecord.svg';
import RewardsDetailsIcon from '../../assets/svg/icon_rewardsDetails.svg';
import bonusLargeIcon from '../../assets/svg/icon_bonusLarge.svg';
import reputationLargeIcon from '../../assets/svg/icon_reputationLarge.svg';
import badgeIcon from '../../assets/svg/icon_badges.svg';
import bugsIcon from '../../assets/svg/icon_bugs.svg';
import creditIcon from '../../assets/svg/icon_credit.svg';
import Solo from '../../assets/svg/solo.svg';
import rankingIcon from '../../assets/svg/icon_ranking.svg';
import ReportGauge from './components/reportGauge.jsx';
import SeverityGauge from './components/serverityGauge.jsx';
import settingIcon from '../../assets/svg/icon_setting.svg';
import Achievements from './Achievements';
import myRedemption from './MyRedemption';
import RewardsDetails from './RewardsDetails';
import PayoutMethod from './PayoutMethod';
import PublicProfile from '../PublicProfile';
import noDataImg from '../../assets/svg/bg_noData.svg';
import navIcon from '../../assets/svg/icon_nav.svg';

import bonusIcon from '../../assets/svg/icon_bonus.svg';
import reputationIcon from '../../assets/svg/icon_reputation.svg';
import OwnerInfo from './OwnerInfo/index';
import PayoutMethodOld from './PayoutMethodOld/index';
import LoginSecurity from './LoginSecurity/index';
import QuickEntryDiv from './components/quickEntryDiv';
import SwagshopService from '../../services/swagshopService';
export default (function (props) {
  // const [creditsData , setCreditsData] = useState([]);
	const intl = useIntl();
	const history = useHistory();
	const [personalInfo, setPersonalInfo] = useState('');
	const [personalStatistics, setPersonalStatistics] = useState();
	const [chosenType, setChosenType] = useState('');
	const [medalData, setMedalData] = useState([]);
	const [bonusAndContribution, setBonusAndContribution] = useState([]);
  useEffect(()=>{
    PersonalInfoService.getPersonalLeak()
    .then((res) => {
      setBonusAndContribution(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
  },[])
	const medalCardContent = (item, type) => {
		return (
			<div className='mdealCardDiv'>
				<div className='medalCardTitleDiv'>
					<img
						src={item.showMedalIconOss}
						className='medalCardTitleImg'
						alt='img'
					/>
					<div className='medalCardTitleText'>
						<div className='medalCardTitleNameDiv'>
							<div className='medalCardTitleName'>
								{item.safetyMedalI18n?.medalName}
							</div>
							{item.status === 2 && type && (
								<div className='medalWearingTag'>
									{intl.formatMessage({
										id: 'intl.personalinfo.medalCard.wearingTag',
									})}
								</div>
							)}
							{item.status === 3 && type && (
								<div className='medalExpiredTag'>
									{intl.formatMessage({
										id: 'intl.personalinfo.medalCard.expiredTag',
									})}
								</div>
							)}
						</div>
						{item.safetyMedalI18n?.activityName && (
							<div
								className='medalCardTitleTag'
								onClick={() => window.open(item.safetyMedalI18n?.activityUrl)}
							>
								# {item.safetyMedalI18n?.activityName}
							</div>
						)}
						<div className='medalCardTitleUserCount'>
							{item.safetyMedalUserCount}
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.userCount',
							})}
						</div>

						{!item.safetyMedalI18n?.activityName && (
							<div style={{ height: '20px' }}></div>
						)}
						{item.status !== 4 ? (
							<div className='medalCardTitleObtainStatus'>
								<label>
									{intl.formatMessage({
										id: 'intl.personalinfo.medalCard.unlockedTime',
									})}
									{item.cardCreateTime}
								</label>
								<label style={{ margin: '0 8px' }}>|</label>
								<label>
									{item.cardEndTime ? (
										<>
											{intl.formatMessage({
												id: 'intl.personalinfo.medalCard.expiredTime',
											})}
											{item.cardEndTime}
										</>
									) : (
										<>
											{intl.formatMessage({
												id: 'intl.personalinfo.medalCard.longTerm',
											})}
										</>
									)}
								</label>
							</div>
						) : (
							<div className='medalCardTitleObtainStatus'>
								{intl.formatMessage({
									id: 'intl.personalinfo.medalCard.locked',
								})}
							</div>
						)}
					</div>
				</div>
				<div className='medalCardIntro'>{item.safetyMedalI18n?.medalIntro}</div>

				{item.status === 1 && type && (
					<div className='medalCardOperateDiv'>
						<Button
							onClick={() => wearBadge(item.id, true)}
							loading={wearBtnLoading}
						>
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.wearBadge',
							})}
						</Button>
					</div>
				)}
				{item.status === 2 && type && (
					<div className='medalCardOperateDiv'>
						<Button
							onClick={() => wearBadge(item.id, false)}
							loading={wearBtnLoading}
						>
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.takeOffBadge',
							})}
						</Button>
					</div>
				)}
			</div>
		);
	};
	// const getCreditsData=()=>{
  //   SwagshopService.rewardStatistics({ 
  //     search:undefined,
  //     createTimeStart:undefined,
  //     createTimeEnd:undefined,
  //     year:undefined,
  //     month:undefined,
  //     type:undefined,
  //     dataType:undefined
  //   }).then(res=>{
  //     if(res.code==200){
  //       setCreditsData(res.data?.list[0])}
  //   })
  // }
	const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
	};
	const [wearBtnLoading, setWearBtnLoading] = useState(false);
	const wearBadge = (id, ifWear) => {
		let formData = new FormData();
		formData.append('grantMedalId', id);
		formData.append('ifWear', ifWear);
		setWearBtnLoading(true);
		PersonalInfoService.wearOrCancelMedal(formData).then((res) => {
			if (res.code === 200) {
				if (ifWear) {
					message.success('You’ve worn the badge!');
				} else {
					message.success('You’ve taken off the badge!');
				}
				PersonalInfoService.getAllMedals({ personalHome: true })
					.then((res) => {
						setMedalData(res.data?.grantSafetyMedalList);
						setWearBtnLoading(false);
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				message.error('Error');
			}
		});
	};
	const tobugSubmit = () => {
		if (sessionStorage.getItem('token') != undefined) {
			history.push('/SRC/bugSubmit');
		} else {
			history.push('/SRC/login');
		}
	};
	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = '个人中心')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'Personal Profile'));

		if (sessionStorage.getItem('personalInfoType')) {
			setChosenType(sessionStorage.getItem('personalInfoType'));
		} else {
			setChosenType('personalInfo');
		}
		PersonalInfoService.getPersonalInfo()
			.then((res) => {
				setPersonalInfo(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		PersonalInfoService.getStatistics({ personalHome: true })
			.then((res) => {
				setPersonalStatistics(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		PersonalInfoService.getAllMedals({ personalHome: true })
			.then((res) => {
				setMedalData(res.data?.grantSafetyMedalList);
			})
			.catch((error) => {
				console.log(error);
			});
		if (localStorage.getItem('payoutJump') !== 'true') {
			scrollToTop();
		}
		// sessionStorage.setItem('redirect', window.location.pathname);
    sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		goRouterPosition({ routerPosition: 'personalInfo' });

		return () => {
			setChosenType('');
		};
	}, []);

//  useEffect(() => {
//   getCreditsData()
//  },[])
	// const menu = (
	// 	<div className='personalInfoQuickEntranceDropDownMenuList'>
  //     	{chosenType != 'personalInfo' && (
	// 			<div
	// 				onClick={() => {
	// 					sessionStorage.setItem('personalInfoType', 'personalInfo');
	// 					setChosenType('personalInfo');
	// 					window.location.reload();
	// 				}}
	// 				className={
	// 					chosenType === 'personalInfo'
	// 						? 'personalInfoQuickEntranceMenuListChosen'
	// 						: 'personalInfoQuickEntranceMenuListUnChosen'
	// 				}
	// 			>
	// 				<img
	// 					src={personalInfoIcon}
	// 					alt='icon'
	// 					className='personalInfoQuickEntranceIcon'
	// 				/>
	// 				{intl.formatMessage({
	// 					id: 'intl.personalinfo.content.quickEntranceMenu.personalInfo',
	// 				})}
	// 			</div>
	// 		)}
	// 		<div
	// 			onClick={() => {
	// 				sessionStorage.setItem('personalInfoType', 'publicProfileEntry');
	// 				setChosenType('publicProfileEntry');
	// 			}}
	// 			className={'personalInfoQuickEntranceMenuListUnChosen'}
	// 		>
	// 			<img
	// 				src={Solo}
	// 				alt='icon'
	// 				className='personalInfoQuickEntranceIcon'
	// 			/>
	// 			{intl.formatMessage({
	// 				id: 'intl.personalinfo.settings.publicProfile.title',
	// 			})}
	// 		</div>
		
	// 		{chosenType != 'achievement' && (
	// 			<div
	// 				onClick={() => {
	// 					sessionStorage.setItem('personalInfoType', 'achievement');
	// 					setChosenType('achievement');
	// 				}}
	// 				className={
	// 					chosenType === 'achievement'
	// 						? 'personalInfoQuickEntranceMenuListChosen'
	// 						: 'personalInfoQuickEntranceMenuListUnChosen'
	// 				}
	// 			>
	// 				<img
	// 					src={achievementIcon}
	// 					alt='icon'
	// 					className='personalInfoQuickEntranceIcon'
	// 				/>
	// 				{intl.formatMessage({
	// 					id: 'intl.personalinfo.content.quickEntranceMenu.achievement',
	// 				})}
	// 			</div>
	// 		)}
	// 		{chosenType != 'payoutMethod' && (
	// 			<div
	// 				onClick={() => {
	// 					sessionStorage.setItem('personalInfoType', 'payoutMethod');
	// 					;
	// 					setChosenType('payoutMethod');
	// 				}}
	// 				className={'personalInfoQuickEntranceMenuListUnChosen'}
	// 			>
	// 				<img
	// 					src={payoutMethodIcon}
	// 					alt='icon'
	// 					className='personalInfoQuickEntranceIcon'
	// 				/>
	// 				{intl.formatMessage({
	// 					id: 'intl.personalinfo.content.quickEntranceMenu.payoutMethod',
	// 				})}
	// 			</div>
	// 		)}

	// 		{chosenType != 'rewardsDetails' && (
	// 			<div
	// 				onClick={() => {
	// 					sessionStorage.setItem('personalInfoType', 'rewardsDetails');
	// 					;
	// 					setChosenType('rewardsDetails');
	// 				}}
	// 				className={'personalInfoQuickEntranceMenuListUnChosen'}
	// 			>
	// 				<img
	// 					src={RewardsDetailsIcon}
	// 					alt='icon'
	// 					className='personalInfoQuickEntranceIcon'
	// 				/>
	// 				{intl.formatMessage({
	// 					id: 'intl.personalinfo.content.quickEntranceMenu.rewardsDetails',
	// 				})}
	// 			</div>
	// 		)}
	// 		{chosenType != 'myRedemption' && (
	// 			<div
	// 				onClick={() => {
	// 					sessionStorage.setItem('personalInfoType', 'myRedemption');
	// 					;
	// 					setChosenType('myRedemption');
	// 				}}
	// 				className={'personalInfoQuickEntranceMenuListUnChosen'}
	// 			>
	// 				<img
	// 					src={MyRedemptionIcon}
	// 					alt='icon'
	// 					className='personalInfoQuickEntranceIcon'
	// 				/>
	// 				{intl.formatMessage({
	// 					id: 'intl.personalinfo.content.quickEntranceMenu.myRedemption',
	// 				})}
	// 			</div>
	// 		)}
	// 	</div>
	// );
  const bonusDetailRef=useRef()
  const contributionDivRef=useRef()
  const mouseEnter=(a)=>{
  if(a=='PersonalInfoContentStatSubDiv'){  
    bonusDetailRef.current.style.display='block'}
    else{
    contributionDivRef.current.style.display='block'
  }
 }
 const mouseLeave=(a)=>{
  if(a=='PersonalInfoContentStatSubDiv'){bonusDetailRef.current.style.display='none'}
  else{contributionDivRef.current.style.display='none'}
}
 const mouseMove=(e,a)=>{ 
  if(a=='PersonalInfoContentStatSubDiv'){
    bonusDetailRef.current.style.left=e.clientX+15+'px'
    bonusDetailRef.current.style.top=e.clientY+15+'px'
  }else{
    contributionDivRef.current.style.left=e.clientX+15+'px'
    contributionDivRef.current.style.top=e.clientY+15+'px'
  }
 }
	return (
		<div className='PersonalInfoContainer'>
			{/* {chosenType === 'personalInfo' && ( */}
				<>
					<div className='PersonalInfoHeaderDiv'>
						<div className='PersonalInfoHeaderContent'>
							<div className='PersonalInfoHeaderLeftContent'>
								<img
									alt='avatar'
									className='PersonalInfoHeaderAvatarImg'
									src={
										personalInfo?.pictureUrl ? personalInfo?.pictureUrl : avatar
									}
								/>
								<div className='PersonalInfoHeaderLeftDetail'>
									<div className='PersonalInfoHeaderLeftDetailName'>
										<label
											style={{
												fontFamily: 'Hurme3-SemiBold',
											}}
										>
											{personalInfo?.alienName}
										</label>
										{personalInfo?.medalIconOss && (
											<Popover
												color='#f8f8f8'
												placement='right'
												content={medalCardContent(
													personalInfo?.grantSafetyMedal,
													false
												)}
												title={null}
											>
												<img
													src={personalInfo?.medalIconOss}
													className='personalInfoWearingMedal'
													alt='medal'
												/>
											</Popover>
										)}
									</div>
									<div className='PersonalInfoHeaderLeftDetailCountryDate'>
										{personalInfo.countryName}
									</div>
									<div className='PersonalInfoHeaderLeftDetailCountryDate'>
										{intl.formatMessage({
											id: 'intl.personalInfo.header.registerDate',
										})}
										{personalInfo.dateJoined}
								
									</div>
								</div>
							</div>
							<div className='PersonalInfoHeaderRightContent'>
								<div
									className='PersonalInfoHeaderRightReport'
									onClick={() => {
										history.push('/SRC/bugSubmit');
									}}>
									<PlusOutlined />
									<div style={{ marginTop: '8px' }}>
										{intl.formatMessage({
											id: 'intl.personalinfo.header.reportEntry',
										})}
									</div>
								</div>
								<div className='PersonalInfoHeaderNavDiv'>
									<div
										className='PersonalInfoHeaderNavReport'
										onClick={() => {
											history.push('/SRC/bugSubmitList');
										}}
									>
										{intl.formatMessage({
											id: 'intl.personalinfo.header.viewreportEntry',
										})}
									</div>
									<div
										className='PersonalInfoHeaderNavMessage'
										onClick={() => {
											history.push('/SRC/messageCenter');
										}}
									>
										{intl.formatMessage({
											id: 'intl.personalinfo.header.messageCenter',
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='PersonalInfoContentDiv'>
						<div className='PersonalInfoContentLeft'>
							<div className='PersonalInfoContentLeftTitle'>
								<label className='PersonalInfoContentProfileTitle'>
									{intl.formatMessage({
										id: 'intl.personalinfo.content.profileTitle',
									})}
								</label>
								<label
									className='PersonalInfoContentPublicProfileEntry'
									onClick={() => {
										window.open('/SRC/publicProfile', '_blank');
										// history.push('/SRC/publicProfile');
									}}
								>
									{intl.formatMessage({
										id: 'intl.personalinfo.content.publicProfileEntry',
									})}
								</label>
							</div>
							<div className='PersonalInfoContentLeftStatTitle'>
								{intl.formatMessage({
									id: 'intl.personalinfo.content.statistics',
								})}
							</div>


							<div className='PersonalInfoContentStatDiv'>
                {/* 奖金 */}
                
								<div className='PersonalInfoContentStatSubDiv'
                onMouseEnter={()=>mouseEnter('PersonalInfoContentStatSubDiv')}
                onMouseLeave={()=>mouseLeave('PersonalInfoContentStatSubDiv')}
                onMouseMove={(e)=>mouseMove(e,'PersonalInfoContentStatSubDiv')}
                onClick={() => {
                  sessionStorage.setItem('personalInfoType', 'rewardsDetails');
                  window.location.href = '/SRC/rewardsDetails?type=bonus';
                }}
                >
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={bonusLargeIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#9254DE' }}
										>
											{/* {personalStatistics?.totalBonusCount}
                       */}
                       {/* {personalStatistics?.userRewardItem?.bonus||0} */}
                       {/* {creditsData?.userRewardItem?.bonus||0} */}
                       {personalStatistics?.bonus||0}
										</div>
									</div>
               
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.bonusTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.bonusContent',
										})}
									</div>
                  <div className='bonusDetail' ref={bonusDetailRef} >
                    <p>
                    {intl.formatMessage({
											id: 'intl.bugsubmitdetail.prompt.totalBonus',
										})}
                    </p>
                    <div className='baseDiv'><div> {intl.formatMessage({
											id: 'intl.bugsubmitlist.count.Bonus.base',
										})}</div><div>
                      {/* {bonusAndContribution?.bonusCount || 0} */}
                      {/* {creditsData?.userRewardItem?.baseBonus||0} */}
                      {bonusAndContribution?.userRewardItem?.baseBonus || 0}
                      </div></div>
                    <div className='incentiveDiv'><div> {intl.formatMessage({
											id: 'intl.bugsubmitlist.count.Bonus.extra',
										})}</div><div>
                      {/* {bonusAndContribution?.extraBonusCount|| 0} */}
                      {/* {creditsData?.userRewardItem?.extraBonus||0} */}
                      {bonusAndContribution?.userRewardItem?.extraBonus || 0}
                      </div></div>
                  </div>
                  {/* </Popover> */}
								</div>

{/* 贡献值 */}
								<div className='PersonalInfoContentStatSubDiv'
                  //  onMouseEnter={()=>mouseEnter()}
                  //  onMouseLeave={()=>mouseLeave()}
                  //  onMouseMove={(e)=>mouseMove(e)}
                   onClick={() => {
                    sessionStorage.setItem('personalInfoType', 'rewardsDetails');
                    window.location.href = '/SRC/rewardsDetails?type=reputation';
                  }}
                >
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={reputationLargeIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#34C759' }}
										>
											{/* {creditsData?.userRewardItem?.baseHonor||0} */}
											{personalStatistics?.baseHonor||0}
										</div>
									</div>
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.reputationTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.reputationContent',
										})}
									</div>
                  {/* <div className='bonusDetail contributionDiv' ref={contributionDivRef} >
                    <p> {intl.formatMessage({
											id: 'intl.bugsubmitdetail.prompt.totalReputation',
										})}</p>
                    <div className='baseDiv'><div> {intl.formatMessage({
											id: 'intl.bugsubmitlist.count.Reputation.base',
										})}</div><div>{bonusAndContribution?.honorValue|| 0}</div></div>
                    <div className='incentiveDiv'><div> {intl.formatMessage({
											id: 'intl.bugsubmitlist.count.Reputation.extra',
										})}</div><div>{bonusAndContribution?.extraHonorValue|| 0}</div></div>
                  </div> */}
								</div>
								<div
									className='PersonalInfoContentStatSubDiv'
                  onClick={() => {
                    sessionStorage.setItem('personalInfoType', 'rewardsDetails');
                    window.location.href = '/SRC/rewardsDetails?type=credit';
                  }}>
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={creditIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#ff529f' }}
										>
											{/* {creditsData?.userRewardItem?.notRedeemedCredit||0} */}
											{personalStatistics?.credit||0}
										</div>
									</div>
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.creditcardTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.creditContent',
										})}
									</div>
								</div>

								<div
									className='PersonalInfoContentStatBugDiv'
									onClick={() => {
										history.push('/SRC/bugSubmitList');
									}}
									style={{ cursor: 'pointer' }}
								>
									<div className='PersonalInfoContentStatBugSubDiv'>
										<div className='PersonalInfoContentStatTitle'>
											<img
												src={bugsIcon}
												className='PersonalInfoContentStatTitleImg'
												alt='bonusLargeIcon'
											/>
											<div
												className='PersonalInfoContentStatTitleText'
												style={{ color: '#02AAF9' }}
											>
												{personalStatistics?.validAndSubmit}
											</div>
										</div>
										<div className='PersonalInfoContentStatSubTitle'>
											{intl.formatMessage({
												id: 'intl.personalinfo.content.statistics.vulnerabilityTitle',
											})}
										</div>
										<div className='PersonalInfoContentStatText'>
											{intl.formatMessage({
												id: 'intl.personalinfo.content.statistics.vulnerabilityContent',
											})}
										</div>
									</div>
									<div className='PersonalInfoContentStatBugSubDiv'>
										<div className='PersonalInfoContentStatTitle'>
											<div
												className='PersonalInfoContentStatTitleText'
												style={{ color: '#02AAF9' }}
											>
												{personalStatistics?.leakAccuracy ?? 'N/A'}
											</div>
										</div>
										<div className='PersonalInfoContentStatSubTitle'>
											{intl.formatMessage({
												id: 'intl.personalinfo.content.statistics.accuracyTitle',
											})}
										</div>
										<div className='PersonalInfoContentStatText'>
											{intl.formatMessage({
												id: 'intl.personalinfo.content.statistics.accuracyContent',
											})}
										</div>
									</div>
								</div>
                <div
									className='PersonalInfoContentStatSubDiv'
									onClick={() => {
										sessionStorage.setItem('personalInfoType', 'achievement');
										// setChosenType('achievement');
                    window.location.href = '/SRC/achievements'
                    
									}}
								>
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={badgeIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#FAAD14' }}
										>
											{personalStatistics?.medalCount}
										</div>
									</div>
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.badgesTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.badgesContent',
										})}
									</div>
								</div>
								{/* <div
									className='PersonalInfoContentStatSubDiv'
									style={{ cursor: 'pointer' }}
									onClick={() => {
										sessionStorage.setItem('leaderBoardType', 'All');
										history.push('/SRC/leaderboards');
									}}
								>
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={rankingIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#FF2DAA' }}
										>
											{personalStatistics?.rank}
										</div>
									</div>
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.rankingTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.rankingContent',
										})}
									</div>
								</div> */}

								<div className='PersonalInfoContentStatReportDiv'>
									<div
										className='PersonalInfoContentLastLineTitle'
										style={{ zIndex: '5000' }}
									>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.reportTitle',
										})}
									</div>
									{personalStatistics?.userLeakReportsStatus?.leakResultOpen ===
										0 &&
									personalStatistics?.userLeakReportsStatus?.leakResultFixed ===
										0 &&
									personalStatistics?.userLeakReportsStatus
										?.leakResultRejected === 0 &&
									personalStatistics?.userLeakReportsStatus
										?.leakResultTriaged === 0 ? (
										<div style={{marginTop: '-20px',textAlign: 'center',boxShadow: '0px 0px',}}>
											<img
												src={noDataImg}
												alt='img'
												className='profileNoDataImg'
												style={{
													boxShadow: '0px 0px',
												}}
											/>
											<div
												className='noDataHintTextInPersonal'
												style={{boxShadow: '0px 0px'}}>
												{intl.formatMessage({id: 'intl.personalInfo.noData.hint',})}
											</div>
											<div
												className='noDataHintTextInPersonal'
												onClick={() => tobugSubmit()}
												style={{
													color: '#0264fa',
													cursor: 'pointer',
													boxShadow: '0px 0px',
												}}
											>
												{intl.formatMessage({
													id: 'intl.personalInfo.noData.jump.bug',
												})}
											</div>
										</div>
									) : (
										<ReportGauge
											value={personalStatistics?.userLeakReportsStatus}
										/>
									)}
								</div>
								<div className='PersonalInfoContentSeverityDiv'>
									<div className='PersonalInfoContentLastLineTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.severityTitle',
										})}
									</div>
									{personalStatistics?.userLeakReportsStatus
										?.leakLevelCritical === 0 &&
									personalStatistics?.userLeakReportsStatus?.leakLevelHigh ===
										0 &&
									personalStatistics?.userLeakReportsStatus?.leakLevelLow ===
										0 &&
									personalStatistics?.userLeakReportsStatus?.leakLevelMed ===
										0 &&
									personalStatistics?.userLeakReportsStatus?.leakLevelNon ===
										0 ? (
										<div
											style={{
												marginTop: '-20px',
												textAlign: 'center',
											}}
										>
											<img
												src={noDataImg}
												alt='img'
												className='profileNoDataImg'
											/>
											<div className='noDataHintTextInPersonal'>
												{intl.formatMessage({
													id: 'intl.personalInfo.noData.hint',
												})}
											</div>
											<div
												className='noDataHintTextInPersonal'
												onClick={() => tobugSubmit()}
												style={{
													color: '#0264fa',
													cursor: 'pointer',
												}}
											>
												{intl.formatMessage({
													id: 'intl.personalInfo.noData.jump.bug',
												})}
											</div>
										</div>
									) : (
										<SeverityGauge
											value={personalStatistics?.userLeakReportsStatus}
										/>
									)}
								</div>
							</div>
							<div className='PersonalInfoContentAchievementsTitle'>
								{intl.formatMessage({
									id: 'intl.personalinfo.content.achievementTitle',
								})}
							</div>
							<div className='PersonalInfoContentAchievementsDiv'>
								<div className='PersonalInfoContentAchievementsDivTitle'>
									<div className='PersonalInfoContentAchievementsDivLeftTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.achievement.leftTitle',
										})}
									</div>
									<div
										className='PersonalInfoContentAchievementsDivRightTitle'
										onClick={() => {
											sessionStorage.setItem('personalInfoType', 'achievement');
											setChosenType('achievement');
										}}
									>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.achievement.rightEntry',
										})}
									</div>
								</div>
								<div className='PersonalInfoContentAchievementsMedalDiv'>
									{medalData?.length > 0 ? (
										medalData.map((item, index) => (
											<Popover
												color='#f8f8f8'
												placement='right'
												content={medalCardContent(item, true)}
												title={null}
											>
												<div
													key={index}
													className='PersonalInfoContentAchievementsMedal'
												>
													<img
														src={item.medalIconOss}
														className='PersonalInfoContentAchievementsMedalImg'
														alt='medalImg'
													/>
													{item.status === 2 && (
														<div className='achievementMedalFlag achievementMedalFlagLeft'>
															{intl.formatMessage({
																id: 'intl.personalinfo.medalCard.wearingTag',
															})}
														</div>
													)}
													<div className='PersonalInfoContentAchievementsMedalTitle'>
														{item.safetyMedalI18n?.medalName}
													</div>
                          {item.label && (
                              <div className='PersonalInfoContentAchievementsMedalLabel'>{item.label}</div>
                            )
                          }
													<div className='PersonalInfoContentAchievementsMedalGetTime'>
														{item.createTime}
													</div>
												</div>
											</Popover>
										))
									) : (
										<div
											style={{
												marginTop: '-16px',
												textAlign: 'center',
												marginLeft: '320px',
											}}
										>
											<img
												src={noDataImg}
												alt='img'
												className='profileNoDataImg'
												style={{
													height: '120px',
												}}
											/>
											<div className='noDataHintTextInPersonal'>
												{intl.formatMessage({
													id: 'intl.personalInfo.noData.hint',
												})}
											</div>
											<div className='noDataHintTextInPersonal'>
												<label>
													{intl.formatMessage({
														id: 'intl.personalInfo.noData.badge.front',
													})}
												</label>
												<label
													onClick={() => {
														goDetailLink('EarnBadge');
													}}
													style={{
														color: '#0264fa',
														cursor: 'pointer',
													}}
												>
													{intl.formatMessage({
														id: 'intl.personalInfo.noData.badge.latter',
													})}
												</label>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className='PersonalInfoContentRight'>
							<div className='personalInfoQuickEntrance'>
								<div className='personalInfoQuickEntranceTitle'>
									{intl.formatMessage({
										id: 'intl.personalinfo.content.quickEntranceTitle',
									})}
								</div>

								<div className='personalInfoQuickEntranceMenuList'>
									{/* <div
										onClick={() => {
											sessionStorage.setItem(
												'personalInfoType',
												'personalInfo'
											);
											setChosenType('personalInfo');
										}}
										className={
											chosenType === 'personalInfo'
												? 'personalInfoQuickEntranceMenuListChosen'
												: 'personalInfoQuickEntranceMenuListUnChosen'
										}>
										<img
											src={personalInfoIcon}
											alt='icon'
											className='personalInfoQuickEntranceIcon'
										/>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.quickEntranceMenu.personalInfo'
										})}
									</div> */}
									{/* 新增item */}
									<div
										onClick={() => {
											sessionStorage.setItem(
												'personalInfoType',
												'publicProfileEntry'
											);
											setChosenType('publicProfileEntry');
                      window.location.href = '/SRC/publicProfile'
										}}
										className={'personalInfoQuickEntranceMenuListUnChosen'}
									>
										<img
											src={Solo}
											alt='icon'
											className='personalInfoQuickEntranceIcon'
										/>
										{intl.formatMessage({
											id: 'intl.personalinfo.settings.publicProfile.title',
										})}
									</div>
									<div
										onClick={() => {
											sessionStorage.setItem('personalInfoType', 'achievement');
											setChosenType('achievement');
                      window.location.href = '/SRC/achievements'
										}}
										className={
											chosenType === 'achievement'
												? 'personalInfoQuickEntranceMenuListChosen'
												: 'personalInfoQuickEntranceMenuListUnChosen'
										}
									>
										<img
											src={achievementIcon}
											alt='icon'
											className='personalInfoQuickEntranceIcon'
										/>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.quickEntranceMenu.achievement',
										})}
									</div>
									<div
										onClick={() => {
											sessionStorage.setItem(
												'personalInfoType',
												'payoutMethod'
											);

											localStorage.setItem('payoutJump', true);
											setChosenType('payoutMethod');
                      window.location.href = '/SRC/payoutMethod'
										}}
										className={'personalInfoQuickEntranceMenuListUnChosen'}
									>
										<img
											src={payoutMethodIcon}
											alt='icon'
											className='personalInfoQuickEntranceIcon'
										/>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.quickEntranceMenu.payoutMethod',
										})}
									</div>
									<div
										onClick={() => {
											sessionStorage.setItem(
												'personalInfoType',
												'rewardsDetails'
											);

											localStorage.setItem('payoutJump', true);
											setChosenType('rewardsDetails');
                      window.location.href = '/SRC/rewardsDetails'
										}}
										className={'personalInfoQuickEntranceMenuListUnChosen'}
									>
										<img
											src={RewardsDetailsIcon}
											alt='icon'
											className='personalInfoQuickEntranceIcon'
										/>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.quickEntranceMenu.rewardsDetails',
										})}
									</div>
									<div
										onClick={() => {
											sessionStorage.setItem(
												'personalInfoType',
												'myRedemption'
											);

											localStorage.setItem('payoutJump', true);
											setChosenType('myRedemption');
                      window.location.href = '/SRC/myRedemption'
										}}
										className={'personalInfoQuickEntranceMenuListUnChosen'}
									>
										<img
											src={MyRedemptionIcon}
											alt='icon'
											className='personalInfoQuickEntranceIcon'
										/>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.quickEntranceMenu.myRedemption',
										})}
									</div>
								</div>
							</div>
							<div
								className='PersonalInfoContentRightSetting'
								onClick={() => {
									localStorage.setItem('payoutJump', false);
									sessionStorage.setItem('personalInfoType', 'payoutMethod');
									setChosenType('payoutMethod');
                  window.location.href = '/SRC/payoutMethod'
								}}
							>
								<img
									alt='setting'
									src={settingIcon}
									className='personalInfoQuickEntranceIcon'
								/>
								{intl.formatMessage({
									id: 'intl.personalinfo.content.settingEntry',
								})}
							</div>
						</div>
					</div>
				</>
			{/* )} */}

			{/* {chosenType === 'achievement' && (
				<div className='personalInfoOtherContainer'>
					<div className='personalInfoOtherContainerHeader'>
						<div className='personalInfoOtherContainerHeaderLeft'>
							<label style={{ color: 'rgba(0,0,0,0.85)' }}>
								{intl.formatMessage({
									id: 'intl.personalinfo.breadCrumb.achievement',
								})}
							</label>
						</div>
						<Dropdown overlay={menu} 
             >
							<div className='personalInfoOtherContainerHeaderRight'>
								<img
									src={navIcon}
									alt='alt'
									style={{
										marginTop: '-2px',
										marginRight: '4px',
										width: '16px',
										height: '16px',
									}}
								/>
								<label
									style={{
										marginLeft: '4px',
										cursor: 'pointer',
									}}
								>
									{intl.formatMessage({
										id: 'intl.personalinfo.breadCrumb.quickEntrance',
									})}
								</label>
							</div>
						</Dropdown>
					</div>
					<Achievements />
				</div>
			)}

			{chosenType === 'payoutMethod' && (
				<div className='personalInfoOtherContainer'>
					<div className='personalInfoOtherContainerHeader'>
						<div className='personalInfoOtherContainerHeaderLeft'>
							<label style={{ color: 'rgba(0,0,0,0.85)' }}>
								{intl.formatMessage({
									id: 'intl.personalinfo.breadCrumb.payoutMethod',
								})}
							</label>
						</div>

						<Dropdown overlay={menu}>
							<div className='personalInfoOtherContainerHeaderRight'>
								<img
									src={navIcon}
									alt='alt'
									style={{
										marginTop: '-1px',
										marginRight: '4px',
										width: '16px',
										height: '16px',
									}}
								/>
								<label
									style={{
										marginLeft: '4px',
										cursor: 'pointer',
									}}
								>
									{intl.formatMessage({
										id: 'intl.personalinfo.breadCrumb.quickEntrance',
									})}
								</label>
							</div>
						</Dropdown>
					</div>
					<PayoutMethod />
				</div>
			)}
			{chosenType === 'publicProfileEntry' && (
				<div className='personalInfoOtherContainer rr'>
					<PublicProfile />
				</div>
			)}
    	{chosenType === 'rewardsDetails' && (
				<div className='personalInfoOtherContainer'>
					<div className='personalInfoOtherContainerHeader'>
						<div className='personalInfoOtherContainerHeaderLeft'>
							<label style={{ color: 'rgba(0,0,0,0.85)' }}>
								{intl.formatMessage({
									id: 'intl.personalinfo.breadCrumb.rewardsDetails',
								})}
							</label>
						</div>
						<Dropdown overlay={menu} 
             >
							<div className='personalInfoOtherContainerHeaderRight'>
								<img
									src={navIcon}
									alt='alt'
									style={{
										marginTop: '-2px',
										marginRight: '4px',
										width: '16px',
										height: '16px',
									}}
								/>
								<label
									style={{
										marginLeft: '4px',
										cursor: 'pointer',
									}}
								>
									{intl.formatMessage({
										id: 'intl.personalinfo.breadCrumb.quickEntrance',
									})}
								</label>
							</div>
						</Dropdown>
					</div>
					<RewardsDetails />
				</div>
			)}
      {chosenType === 'myRedemption' && (
				<div className='personalInfoOtherContainer'>
					<div className='personalInfoOtherContainerHeader'>
						<div className='personalInfoOtherContainerHeaderLeft'>
							<label style={{ color: 'rgba(0,0,0,0.85)' }}>
								{intl.formatMessage({
									id: 'intl.personalinfo.breadCrumb.myRedemption',
								})}
							</label>
						</div>
						<Dropdown overlay={menu} 
             >
							<div className='personalInfoOtherContainerHeaderRight'>
								<img
									src={navIcon}
									alt='alt'
									style={{
										marginTop: '-2px',
										marginRight: '4px',
										width: '16px',
										height: '16px',
									}}
								/>
								<label
									style={{
										marginLeft: '4px',
										cursor: 'pointer',
									}}
								>
									{intl.formatMessage({
										id: 'intl.personalinfo.breadCrumb.quickEntrance',
									})}
								</label>
							</div>
						</Dropdown>
					</div>
          <QuickEntryDiv
            title={intl.formatMessage({ id: 'intl.personalinfo.breadCrumb.myRedemption'})}
            entrance={intl.formatMessage({ id: 'intl.personalinfo.breadCrumb.quickEntrance'})}
            chosenType={chosenType}
            chosenTypechange={chosenTypechange}
          />
					<myRedemption />
				</div>
			)} */}
		</div>
	);
});
