import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class LeaderboardsService {

    /**
     * 登录界面	
     * @param {current} 当前页码
     * @param {pageSize} 一页数量
     * @return {BUG提交列表}
     */
    getLeaderboardsListData(params) {
        return Xhr.get(`/leakRank/${params.Type}?lang=${sessionStorage.getItem('lang')}`, { params:params });
    }
    getYearListData() {
        return Xhr.get(`/leakRank/yearList?lang=${sessionStorage.getItem('lang')}`);
    }
    getMonthListData(params) {
        return Xhr.get(`/leakRank/monthList?lang=${sessionStorage.getItem('lang')}`, { params:params });
    }
}

// 实例化再导出
export default new LeaderboardsService();