import React, {useRef, useEffect, useState} from 'react';
import {
	Form,
	Input,
	Button,
	Checkbox,
	Radio,
	Select,
	Upload,
	message
} from 'antd';
import {ToTopOutlined} from '@ant-design/icons';
import {Editor} from '@tinymce/tinymce-react';
import BugSubmitService from '../../services/bugSubmitService';
import PersonalInfoService from '../../services/personalInfoService';
import './style/BugSubmit.css';
import {detailLink} from '../../common/detailLink';
import {scrollToTop} from '../../common/topScorll';
import {goRouterPosition} from '../../action/routerposition/routerpositionAction';
import {useIntl} from 'react-intl';
import {Link, useHistory} from 'react-router-dom';
import RuleIcon from '../../assets/svg/ic-提交漏洞规则说明.svg';
import rightArrow from '../../assets/svg/ic-rightArrow.svg';
import rightArrowHover from '../../assets/svg/ic-rightArrowHover.svg';

export default (function (props) {
	const intl = useIntl();
	const {TextArea} = Input;
	const [captchaCode, setCaptchaCode] = useState('');
	const [captchaCodeToken, setCaptchaCodeToken] = useState('');
	const editorRef = useRef(null);
	const [contextDescription, setContextDescription] = useState('');
	const [typeList, setTypeList] = useState([]);
	const [typeRadio, setTypeRadio] = useState('');
	const [subtypeList, setSubtypeList] = useState([]);
	const [subtypeSelect, setSubtypeSelect] = useState('');
	const [activityList, setActivityList] = useState([]);
	const [activityUrl, setActivityUrl] = useState('');
	const [activitySelect, setActivitySelect] = useState('');
	const [urlInput, setUrlInput] = useState(undefined);
	const [vulnerabilityInput, setVulnerabilityInput] = useState('');
	const [severityList, setSeverityList] = useState([]);
	const [severityRadio, setSeverityRadio] = useState('');
	const [captchaCodeInput, setCaptchaCodeInput] = useState('');
	const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [apkList, setApkList] = useState([]);
	const [urlList, setUrlList] = useState([]);
	const [apkName, setApkName] = useState('');
	const [apkVersionNumber, setApkVersionNumber] = useState([]);
	const [lurl, setLurl] = useState([]);
	const [arrowImg, setArrowImg] = useState(rightArrow);
	const history = useHistory();
	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = '漏洞提交')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'Security Reporting'));
		scrollToTop();
		// sessionStorage.setItem('redirect', window.location.pathname);
    sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		PersonalInfoService.getPersonalInfo()
			.then((res) => {
				if (!res.data.haveEmail) {
					history.push('/SRC/bindEmailPwd');
				}
			})
			.catch((error) => {
				console.log(error);
			});
		goRouterPosition({routerPosition: 'bugSubmit'});
		BugSubmitService.getCaptchaCode()
			.then((res) => {
				setCaptchaCode(res.data.base64Img);
				setCaptchaCodeToken(res.data.cToken);
			})
			.catch((error) => {
				console.log(error);
			});
		BugSubmitService.getLevel({
			lang: sessionStorage.getItem('lang')
		})
			.then((res) => {
				setSeverityList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		BugSubmitService.getType({
			lang: sessionStorage.getItem('lang')
		})
			.then((res) => {
				setTypeList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		BugSubmitService.getApkOptions()
			.then((res) => {
				setApkList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		BugSubmitService.getUrlOptions()
			.then((res) => {
				setUrlList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	const [form] = Form.useForm();
	const handleEditorChange = (content, editor) => {
		form.setFieldsValue({Description: editorRef.current.getContent()});
		setContextDescription(editorRef.current.getContent());
	};
	const TypeRadioChange = (e) => {
		setUrlInput(undefined);
		setSubtypeSelect('');
		form.setFieldsValue({Subtype: ''});
		setTypeRadio(e.target.value);
		BugSubmitService.getSubType({
			typeId: e.target.value,
			lang: sessionStorage.getItem('lang')
		})
			.then((res) => {
				setSubtypeList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		BugSubmitService.getActivity({
			typeId: e.target.value,
			isActive: true
		})
			.then((res) => {
				setActivityList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const SubtypeSelectChange = (value) => {
		setSubtypeSelect(value);
	};
	const apkNameSelectChange = (value) => {
		setApkName(value);
	};
	const versionCodeInputChange = (e) => {
		setApkVersionNumber(e.target.value);
	};
	const urlSelectChange = (value) => {
		setLurl(value);
	};
	const ActivitySelectChange = (value) => {
		setActivitySelect(value);
		BugSubmitService.getActivityUrl({
			id: value
		})
			.then((res) => {
				setActivityUrl(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const SeverityRadioChange = (e) => {
		setSeverityRadio(e.target.value);
	};
	const VulnerabilityInputChange = (e) => {
		setVulnerabilityInput(e.target.value);
	};
	const CaptchaCodeInputChange = (e) => {
		setCaptchaCodeInput(e.target.value);
	};
	const PrivacyPolicyCheckboxChange = (e) => {
		setPrivacyPolicyCheckbox(e.target.checked);
	};
	const initObj = {
		height: 420,
		width: 638,
		language: sessionStorage.getItem('lang')
			? sessionStorage.getItem('lang')
			: 'en_US',
		menubar: false,
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount'
		],
		toolbar:
			'link ｜ image | undo redo | fontsizeselect|' +
			'bold italic backcolor forecolor | alignleft aligncenter ' +
			'alignright alignjustify | bullist numlist outdent indent | ' +
			'removeformat | help',
		content_style: 'body,p{ font-size:16px;margin:0px; }',
		fontsize_formats: '10px 12px 14px 16px 18px 20px 24px 36px',
		images_upload_handler: function (blobInfo, succFun, failFun) {
			let base64 = 'data:image/png;base64,' + blobInfo.base64();
			succFun(base64);
		}
	};
	const {Option} = Select;
	const layout = {
		labelCol: {span: 4},
		wrapperCol: {span: 20}
	};
	const tailLayout = {
		wrapperCol: {offset: 4, span: 20}
	};
	const versionCodeLayout = {
		labelCol: {span: 10},
		wrapperCol: {span: 18}
	};
	const changeCaptchaCode = () => {
		BugSubmitService.getCaptchaCode()
			.then((res) => {
				setCaptchaCode(res.data.base64Img);
				setCaptchaCodeToken(res.data.cToken);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const goBugSubmit = () => {
		if (subtypeSelect == '') {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.error.subtypeSelect'
				})
			);
			return false;
		}
		if (severityRadio == '') {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.error.severityRadio'
				})
			);
			return false;
		}
		if (typeRadio == 4) {
			if (lurl == '') {
				message.error(
					intl.formatMessage({
						id: 'intl.bugsubmit.message.error.urlInput'
					})
				);
				return false;
			}
		}
		if (typeRadio == 3) {
			if (!apkName) {
				message.error(
					intl.formatMessage({
						id: 'intl.bugsubmit.message.error.apkSelect'
					})
				);
				return false;
			} else if (apkVersionNumber == '') {
				message.error(
					intl.formatMessage({
						id: 'intl.bugsubmit.message.error.versionCodeInput'
					})
				);
				return false;
			}
		}
		if (vulnerabilityInput == '') {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.error.vulnerabilityInput'
				})
			);
			return false;
		}
		if (contextDescription == '') {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.error.contextDescription'
				})
			);
			return false;
		}
		const formData = new FormData();
		// formData.append("TypeRadio",typeRadio);
		formData.append('lang', sessionStorage.getItem('lang'));
		formData.append('leakSubtypeId', subtypeSelect);
		formData.append('activity', activitySelect);
		apkName && formData.append('apkName', apkName);
		apkName && formData.append('apkVersionNumber', apkVersionNumber);
		lurl && formData.append('lurl', lurl);
		formData.append('title', vulnerabilityInput);
		formData.append('leakLevelId', severityRadio);
		formData.append('content', contextDescription);
		fileList.forEach((file) => {
			formData.append('file', file);
		});
		formData.append('cToken', captchaCodeToken);
		formData.append('cCode', captchaCodeInput);
		if (privacyPolicyCheckbox) {
			BugSubmitService.goBugSubmit(formData)
				.then((res) => {
					if (res.code == 200) {
						message.success(
							intl.formatMessage({
								id: 'intl.bugsubmit.message.success'
							})
						);
						history.push('/SRC/bugSubmitSuccess');
					} else {
						message.error(
							intl.formatMessage({
								id: 'intl.bugsubmit.message.error'
							})
						);
						history.push('/SRC/bugSubmitFailed');
					}
				})
				.catch((error) => {
					message.error(error);
					BugSubmitService.getCaptchaCode()
						.then((res) => {
							setCaptchaCode(res.data.base64Img);
							setCaptchaCodeToken(res.data.cToken);
						})
						.catch((error) => {
							console.log(error);
						});
				});
		} else {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.error.chooseagreement'
				})
			);
		}
	};
	const cancelBugSubmit = () => {
		history.push('/SRC/bugSubmitList');
	};
	const onFinish = (values) => {
		console.log('Success:', values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	const onRemove = (file) => {
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
	};
	const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
	};
	const goApkDownload = () => {
		history.push('/SRC/apkDownload');
	};
	const beforeUpload = (file) => {
		let fileType = file.name.split('.');
		const fileDate = fileType.slice(-1);
		if (
			[
				'doc',
				'7z',
				'zip',
				'gz',
				'bz2',
				'png',
				'jpg',
				'jpeg',
				'pdf',
				'xls',
				'mp4',
				'wmv'
			].indexOf(fileDate[0]) < 0
		) {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.file.type'
				})
			);
			setFileList([file]);
			onRemove(file);
			return false;
		}
		const isLt20M = file.size / 1024 / 1024 < 10;
		if (!isLt20M) {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.file.size'
				})
			);
			setFileList([file]);
			onRemove(file);
			return false;
		}
		setFileList([file]);
		return false;
	};
	return (
		<div className='Container'>
			<div className='ContainerContent'>
				<p className='BugSubmitTitle'>
					{intl.formatMessage({
						id: 'intl.bugsubmit.title'
					})}
				</p>
				<div className='BugSubmitRuleDiv'>
					<div className='BugSubmitRuleImgDiv'>
						<img src={RuleIcon} className='BugSubmitRuleIcon' />
						<label className='BugSubmitRuleTitle'>
							{intl.formatMessage({
								id: 'intl.bugsubmit.ruletitle'
							})}
						</label>
					</div>
					<p className='BugSubmitRuleContent'>
						{intl.formatMessage({
							id: 'intl.bugsubmit.rulecontent'
						})}
					</p>
					<div className='BugSubmitRuleFileDiv'>
						<Link
							className='BugSubmitRuleFile'
							onClick={() => {
								goDetailLink('RewardRulesDevice');
							}}>
							{intl.formatMessage({
								id: 'intl.bugsubmit.rulefile.rewardstandard.device'
							})}
						</Link>
						<div className='BugSubmitRuleFile'>
							<Link
								onClick={() => {
									goDetailLink('RewardRulesMobile');
								}}>
								{intl.formatMessage({
									id: 'intl.bugsubmit.rulefile.rewardstandard.mobile'
								})}
							</Link>
							<label
								className='jumpToApkDownload'
								onClick={() => {
									goApkDownload();
								}}
								onMouseEnter={() =>
									setArrowImg(rightArrowHover)
								}
								onMouseLeave={() => setArrowImg(rightArrow)}>
								<label className='jumpToApkDownloadText'>
									APK Download
								</label>
								<img
									src={arrowImg}
									className='jumpToApkDownloadArrow'
								/>
							</label>
						</div>
						<Link
							className='BugSubmitRuleFile'
							onClick={() => {
								goDetailLink('RewardRulesWeb');

							}}>
							{intl.formatMessage({
								id: 'intl.bugsubmit.rulefile.rewardstandard.web'
							})}
						</Link>
						{/* <Link className="BugSubmitRuleFile" onClick={()=>{goDetailLink("SubmissionGuidelines")}}>
                            {
                                intl.formatMessage({
                                    id: 'intl.bugsubmit.rulefile.rewardstandard'
                                })
                            }
                        </Link>
                        <Link className="BugSubmitRuleFile" onClick={()=>{goDetailLink("RewardProgramV1")}}>
                            {
                                intl.formatMessage({
                                    id: 'intl.bugsubmit.rulefile.serviceagreement'
                                })
                            }
              </Link> */}
					</div>
				</div>
				<div className='BugSubmitFormDiv'>
					<Form
						form={form}
						{...layout}
						name='BugSubmitForm'
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}>
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.bugsubmit.form.type'
							})}
							name='Type'
							rules={[{required: true}]}>
							<Radio.Group onChange={TypeRadioChange}>
								{typeList.map((data, index) => (
									<Radio
										key={index}
										value={data.id}
										className='TypeRadio'>
										<div className='TypeRadioDiv'>
											<p className='TypeRadioLabel'>
												{data.name}
											</p>
											<img
												className='TypeRadioImg'
												src={data.pictureUrl}
											/>
										</div>
									</Radio>
								))}
							</Radio.Group>
						</Form.Item>
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.bugsubmit.form.subtype'
							})}
							name='Subtype'
							rules={[{required: true}]}>
							<Select
								onChange={SubtypeSelectChange}
								className='SubtypeSelect'
								showSearch
								placeholder={intl.formatMessage({
									id: 'intl.bugsubmit.placeholder.subtype'
								})}>
								{subtypeList.map((data, index) => (
									<Option key={index} value={data.id}>
										{data.name}
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.bugsubmit.form.severity'
							})}
							name='Severity'
							rules={[{required: true}]}>
							<Radio.Group onChange={SeverityRadioChange}>
								{severityList.map((data, index) => (
									<Radio key={index} value={data.id}>
										{data.name}
									</Radio>
								))}
							</Radio.Group>
						</Form.Item>
						{/* WEB 提交URL */}
						{typeRadio == 4 && (
							<Form.Item
								label={intl.formatMessage({
									id: 'intl.bugsubmit.form.url'
								})}
								name='URL'
								rules={[{required: true}]}>
								<Select
									mode='multiple'
									onChange={urlSelectChange}
									className='apkSelect'
									allowClear
									showSearch
									showArrow='true'
									placeholder={intl.formatMessage({
										id: 'intl.bugsubmit.placeholder.url'
									})}
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}>
									{urlList.map((data, index) => (
										<Option
											key={index}
											value={data.urlList}>
											{data.urlList}
										</Option>
									))}
								</Select>
							</Form.Item>
						)}
						{/* APK 提交APK信息 */}
						{typeRadio == 3 && (
							<Form.Item
								style={{
									display: 'inline-flex',
									marginLeft: '119px'
								}}
								label={intl.formatMessage({
									id: 'intl.bugsubmit.form.apk'
								})}
								name='apkName'
								rules={[{required: true}]}>
								<Select
									onChange={apkNameSelectChange}
									className='apkSelect'
									showSearch
									placeholder={intl.formatMessage({
										id: 'intl.bugsubmit.placeholder.apk'
									})}
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}>
									{apkList.map((data, index) => (
										<Option
											key={index}
											value={data.apkPackageName}>
											{data.apkPackageName}
										</Option>
									))}
								</Select>
							</Form.Item>
						)}
						{typeRadio == 3 && (
							<Form.Item
								{...versionCodeLayout}
								style={{
									display: 'inline-flex',
									marginLeft: '60px'
								}}
								label={intl.formatMessage({
									id: 'intl.bugsubmit.form.apkVersionCode'
								})}
								name='versionCode'
								rules={[{required: true}]}>
								<Input
									placeholder={intl.formatMessage({
										id: 'intl.bugsubmit.placeholder.versionCode'
									})}
									className='versionCodeInput'
									onChange={versionCodeInputChange}
								/>
							</Form.Item>
						)}
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.bugsubmit.form.activity'
							})}
							name='Activity'
							rules={[{required: false}]}>
							<Select
								onChange={ActivitySelectChange}
								className='ActivitySelect'
								showSearch
								allowClear
								placeholder={intl.formatMessage({
									id: 'intl.bugsubmit.placeholder.activity'
								})}>
								{activityList.map((data, index) => (
									<Option key={index} value={data.id}>
										{data.i18ns[0].keyword}
									</Option>
								))}
							</Select>
							{activitySelect && (
								<a
									target='blank'
									href={activityUrl}
									className='activityUrl'>
									{intl.formatMessage({
										id: 'intl.bugsubmit.placeholder.activity.url'
									})}
								</a>
							)}
						</Form.Item>
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.bugsubmit.form.summary'
							})}
							name='Vulnerability'
							rules={[{required: true}]}>
							<TextArea
								showCount
								maxLength={150}
								rows={3}
								onChange={VulnerabilityInputChange}
								className='VulnerabilityInput'
								placeholder={intl.formatMessage({
									id: 'intl.bugsubmit.placeholder.vulnerability'
								})}
							/>
						</Form.Item>
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.bugsubmit.form.description'
							})}
							trigger='handleEditorChange'
							name='Description'
							rules={[{required: true}]}>
							<Editor
								apiKey='dk2m69on93lmu80zzouc0a0hwg0uwcg3hom6lbjnl3zz8pms'
								onInit={(evt, editor) =>
									(editorRef.current = editor)
								}
								initialValue={
									typeRadio == 4
										? intl.formatMessage({
												id: 'intl.bugsubmit.editor.initialvalueweb'
										  })
										: typeRadio == 1
										? intl.formatMessage({
												id: 'intl.bugsubmit.editor.initialvalueDevice'
										  })
										: intl.formatMessage({
												id: 'intl.bugsubmit.editor.initialvalue'
										  })
								}
								init={{...initObj}}
								value={contextDescription}
								onEditorChange={handleEditorChange}
							/>
						</Form.Item>
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.bugsubmit.form.attachments'
							})}
							name='attachments'>
							<Upload
								onRemove={onRemove}
								beforeUpload={beforeUpload}
								fileList={fileList}>
								<Button>
									<ToTopOutlined />
									{intl.formatMessage({
										id: 'intl.bugsubmit.button.selectattachments'
									})}
								</Button>
							</Upload>
							<p className='attachmentsTips'>
								{intl.formatMessage({
									id: 'intl.bugsubmit.attachmentstips'
								})}
							</p>
						</Form.Item>
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.bugsubmit.form.captcha'
							})}
							name='Captcha'
							rules={[{required: true}]}>
							<Input
								className='CaptchaInput'
								placeholder={intl.formatMessage({
									id: 'intl.bugsubmit.placeholder.captcha.img'
								})}
								onChange={CaptchaCodeInputChange}
							/>
							<img
								className='CaptchaImg'
								onClick={changeCaptchaCode}
								src={captchaCode}
							/>
						</Form.Item>

						<Form.Item
							{...tailLayout}
							name='remember'
							valuePropName='checked'
							onChange={PrivacyPolicyCheckboxChange}>
							<Checkbox>
								{intl.formatMessage({
									id: 'intl.bugsubmit.agreement.agree'
								})}
								<Link
									className='LinkPrivacyPolicy'
									onClick={() =>
										window.open(
											'/agreement/' +
												sessionStorage.getItem('lang')
										)
									}>
									{intl.formatMessage({
										id: 'intl.bugsubmit.agreement.policy'
									})}
								</Link>
							</Checkbox>
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button type='primary' onClick={goBugSubmit}>
								{intl.formatMessage({
									id: 'intl.bugsubmit.button.gobugsubmit'
								})}
							</Button>
							{/* <Button className="cancelButton" onClick={cancelBugSubmit}>
                                {
                                    intl.formatMessage({
                                        id: 'intl.bugsubmit.button.cancelbugsubmit'
                                    })
                                }
                            </Button> */}
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	);
});
