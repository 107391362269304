import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class MessageCenterService {

    /**
     * 登录界面	
     * @param {current} 当前页码
     * @param {pageSize} 一页数量
     * @param {type} 公告类型
     * @param {search} 搜索内容
     * @return {公告列表}
     */
    getMessageCenterListData(params) {
        return Xhr.get(`/notice?lang=${sessionStorage.getItem('lang')}`,{params:params});
    }
    readBlogData(params) {
        return Xhr.post(`/notice/readFlag?lang=${sessionStorage.getItem('lang')}`,params);
    }
    readAllBlogData(params) {
        return Xhr.post(`/notice/readAll?lang=${sessionStorage.getItem('lang')}`,params);
    }
}

// 实例化再导出
export default new MessageCenterService();