    // 加载路由视图组件得函数组件 -加载条件-路由路径对应路由视图组件 一一对应得关系   --->获取路由配置表
    import React from 'react';
    // 引入路由内置组件
    import {Route} from 'react-router-dom'
    const RouterView=(props)=>{   // 函数组件得props属性
        // console.log(props.routes);   // 获取路由配置表
        // 一一对应关系  遍历
        return props.routes.map(({path, componentName: ComponentName, exact = true, children = []}, key) => {
            return <Route
              exact={children.length !== 0 && exact}
              key={key}
              path={path}
            ><ComponentName routes={children}/></Route>
        })
    }
    export default RouterView;