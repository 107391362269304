//引入需要用到的页面组件
import Home from '../common/home/index';
import Dashboard from '../containers/Dashboard/index';
import Login from '../containers/login/index';
import SignUp from '../containers/SignUp/index';
import ForgetPassword from '../containers/ForgetPassword/index';
import BindEmailPwd from '../containers/BindEmailPwd/index';
import MessageCenter from '../containers/MessageCenter/index';
import Blog from '../containers/Blog/index';
import BlogDetail from '../containers/BlogDetail/index';
import BugSubmit from '../containers/BugSubmit/index';
import BugSubmitList from '../containers/BugSubmitList/index';
import BugSubmitDetail from '../containers/BugSubmitDetail/index';
import BugSubmitFailed from '../containers/BugSubmitFailed/index';
import BugSubmitSuccess from '../containers/BugSubmitSuccess/index';
import Leaderboards from '../containers/Leaderboards/index';
import SecurityUpdates from '../containers/SecurityUpdates/index';
import PersonalInfo from '../containers/PersonalInfo/index';
import privacyPolicy from '../containers/PrivacyPolicy/index';
import privacyPolicyen from '../containers/PrivacyPolicy/en_US/index';
import privacyPolicyzh from '../containers/PrivacyPolicy/zh_CN/index';
import agreement from '../containers/Agreement/index';
import agreementen from '../containers/Agreement/en_US/index';
import agreementzh from '../containers/Agreement/zh_CN/index';
import ApkDownload from '../containers/ApkDownload/index';
import Swagshop from '../containers/Swagshop/index';
import Swagshopdetail from '../containers/SwagshopDetail/index';


import PublicProfile from '../containers/PublicProfile/index';
import Achievements from '../containers/PersonalInfo/Achievements/index';
import PayoutMethod from '../containers/PersonalInfo/PayoutMethod/index';
import RewardsDetails from '../containers/PersonalInfo/RewardsDetails/index';
import MyRedemption from '../containers/PersonalInfo/MyRedemption/index';

const routers = [
	{
		path: '/dashboard',
		componentName: Dashboard,
		exact: true
	},
	{
		path: '/privacyPolicy',
		componentName: privacyPolicy,
		exact: false,
		children: [
			{
				path: '/privacyPolicy/en_US',
				componentName: privacyPolicyen
			},
			{
				path: '/privacyPolicy/zh_CN',
				componentName: privacyPolicyzh
			}
		]
	},
	{
		path: '/agreement',
		componentName: agreement,
		exact: false,
		children: [
			{
				path: '/agreement/en_US',
				componentName: agreementen
			},
			{
				path: '/agreement/zh_CN',
				componentName: agreementzh
			}
		]
	},
	{
		path: '/SRC',
		componentName: Home,
		exact: false,
		children: [
			{
				path: '/SRC/login',
				componentName: Login
			},
			{
				path: '/SRC/signUp',
				componentName: SignUp
			},
			{
				path: '/SRC/forgetPassword',
				componentName: ForgetPassword
			},
			{
				path: '/SRC/bindEmailPwd',
				componentName: BindEmailPwd
			},
			{
				path: '/SRC/messageCenter',
				componentName: MessageCenter
			},
			{
				path: '/SRC/blog',
				componentName: Blog
			},
			{
				path: '/SRC/blogdetail/:id',
				componentName: BlogDetail
			},
			{
				path: '/SRC/bugSubmit',
				componentName: BugSubmit
			},
			{
				path: '/SRC/bugSubmitList',
				componentName: BugSubmitList
			},
			{
				path: '/SRC/bugSubmitDetail/:id',
				componentName: BugSubmitDetail
			},
			{
				path: '/SRC/bugSubmitSuccess',
				componentName: BugSubmitSuccess
			},
			{
				path: '/SRC/bugSubmitFailed',
				componentName: BugSubmitFailed
			},
			{
				path: '/SRC/leaderboards',
				componentName: Leaderboards
			},
			{
				path: '/SRC/securityUpdates',
				componentName: SecurityUpdates
			},
			{
				path: '/SRC/swagshop',
				componentName: Swagshop
			},
      {
				path: '/SRC/swagshopdetail/:id',
				componentName: Swagshopdetail
			},
      {
				path: '/SRC/myRedemption',
				componentName: MyRedemption
			},
      {
				path: '/SRC/achievements',
				componentName: Achievements
			},
      {
				path: '/SRC/payoutMethod',
				componentName: PayoutMethod
			},
      {
				path: '/SRC/rewardsDetails',
				componentName: RewardsDetails
			},
			{
				path: '/SRC/personalInfo',
				componentName: PersonalInfo
			},
			{
				path: '/SRC/apkDownload',
				componentName: ApkDownload
			},
			{
				path: '/SRC/publicProfile',
				componentName: PublicProfile
			}
		]
	}
];
export default routers;
