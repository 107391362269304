import React, {useEffect, useState} from 'react';
import './style/myRedemption.css';
import {Button, Popconfirm, message,Input,Select,DatePicker,Table,Pagination,Tooltip,Empty,Icon} from 'antd';
import {MinusOutlined,SearchOutlined} from '@ant-design/icons';
import {useIntl} from 'react-intl';
import {detailLink} from '../../../common/detailLink';
import SwagshopService from '../../../services/swagshopService';
import PersonalInfoService from '../../../services/personalInfoService';
import CreditIcon from '../../../assets/svg/icon_credit.svg';
import QuickEntryDiv from '../components/quickEntryDiv';
import { Lang } from '../../../reducer';
import Rectangle from '../../../assets/svg/Rectangle.svg';
import noDataImg from '../../../assets/svg/空状态.svg';
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import {goRouterPosition} from '../../../action/routerposition/routerpositionAction';
dayjs.extend(localeData)
dayjs.extend(weekday)
// import CreditIcon from '../../../assets/svg/ic-漏洞列表-贡献值@2x.png';

export default (function (props) {
  const [messageApi, contextHolder] = message.useMessage();
  const { RangePicker } = DatePicker;
	const intl = useIntl();
	const [creditsData , setCreditsData] = useState([]);
  const [tableData, setTableData] = useState([])
  const [hasFirstEffectRun, setHasFirstEffectRun] = useState(false);
  const [searchParams, setSearchParams] = useState({
    search: undefined,
    createTime:[],
    status: undefined,
  });
  const [options, setOptions] = useState([
    {value:'101',label:intl.formatMessage({ id: 'intl.myRedemption.table.status.reviewing'})},
    {value: '102',label:intl.formatMessage({ id: 'intl.myRedemption.table.status.processing'})},
    {value: '105',label:intl.formatMessage({ id: 'intl.myRedemption.table.status.cancelled'})},
    {value: '103',label:intl.formatMessage({ id: 'intl.myRedemption.table.status.complete'})},
    {value: '104',label:intl.formatMessage({ id: 'intl.myRedemption.table.status.rejected'})}
  ])
  let color={
    101:'#FAAD14',
    102:'#2DC8CC',
    105:'#FF4D4F',
    103:'#52C41A',
    104:'#9254DE'
 } 
  let tableText={
    101:intl.formatMessage({ id: 'intl.myRedemption.table.status.reviewing'}),
    102:intl.formatMessage({ id: 'intl.myRedemption.table.status.processing'}),
    105:intl.formatMessage({ id: 'intl.myRedemption.table.status.cancelled'}),
    103:intl.formatMessage({ id: 'intl.myRedemption.table.status.complete'}),
    104:intl.formatMessage({ id: 'intl.myRedemption.table.status.rejected'})
  } 
  const [currentPagination, setCurrentPagination] = useState(1);
	const [pageSizePagination, setPageSizePagination] = useState(10);
	const [totalPagination, setTotalPagination] = useState(0);
	const columns = [
		{
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.orderID'}),
			dataIndex: 'orderNumber',
			width: 150,
			align: 'left',
			render: (text) => (
				<div>
          {text}
				</div>
			)
		},
		{
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.gifts'}),
			dataIndex: 'product',
			width: 160,
			align: 'left',
      ellipsis: {
        showTitle: true,
      },
			render: (text, record) => (
        <Tooltip title={record.i18n.productName} placement="topLeft">
				<label>
          {record.i18n.productName}
				</label>
        </Tooltip>
			)
		},
		{
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.quantity'}),
			dataIndex: 'quantity',
			width: 94,
			align: 'left',
			render: (text) => <label>{text}</label>
		},
		{
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.unitPrice'}),
			dataIndex: 'unitPrice',
			width: 94,
			align: 'left',
			render: (text) => <label>{text}</label>
		},
    {
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.totlePrice'}),
			dataIndex: 'exchangeCredit',
			width: 98,
			align: 'left',
			render: (text,record) => <label>{text}</label>
		},
		{
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.date'}),
			dataIndex: 'createTime',
			width: 140,
			align: 'left',
			render: (text) => <label>{text}</label>
		},
		{
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.status'}),
			dataIndex: 'status',
			width: 140,
			align: 'left',
			render: (text) => <label style={{color:color[text]}}>{tableText[text]}</label>
		},
    	{
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.note'}),
			dataIndex: 'remark',
			width: 106,
			align: 'left',
      ellipsis: {
        showTitle: true,
      },
      render: (text, record) => (
        <Tooltip title={record.i18n.remark} 
        placement="topLeft"
        overlayInnerStyle={{ maxWidth: '156px' }}
        >
				<label >
          {record.i18n.remark||'-'}
				</label>
        </Tooltip>
			)
		},
		{
			title: intl.formatMessage({id: 'intl.myRedemption.table.columns.operate'}),
			width: 105,
			align: 'left',
			render: (_, record) => 
      <Popconfirm
      title= {
        <>
       <p>{intl.formatMessage({id: 'intl.myRedemption.table.popconfirm.title'})}</p>  
       <p style={{color:'red',width:'300px'}}>{intl.formatMessage({id: 'intl.myRedemption.table.popconfirm.content'})}</p>
       </>
    } 
      onConfirm={()=>cancelProductOrder(record.id)}
      okText={intl.formatMessage({id: 'intl.myRedemption.table.popconfirm.yes'})}
      cancelText={intl.formatMessage({id: 'intl.myRedemption.table.popconfirm.no'})}
    >
      {record.status=='101'&&<label style={{color: '#0264FA',cursor: 'pointer'}}>{intl.formatMessage({id: 'intl.myRedemption.table.columns.cancel'})}</label>}
      {/* {(record.status=='101'||record.status=='102')&&<label style={{color: '#0264FA',cursor: 'pointer'}}>{intl.formatMessage({id: 'intl.myRedemption.table.columns.cancel'})}</label>} */}
       </Popconfirm>
		},
	];

  const getTableData = (c, p) => {
    SwagshopService.getproductOrderList({
      lang: sessionStorage.getItem('lang'),
      status: searchParams.status,
      search: searchParams.search,
      createTimeEnd: searchParams.createTime.length > 0 ? dayjs(searchParams.createTime[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
      createTimeStart: searchParams.createTime.length > 0 ? dayjs(searchParams.createTime[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
      current: c,
      pageSize: p
  }).then(res=>{
      setTableData(res.data.list)
      setTotalPagination(res.data.total)
    })
  }
  const cancelProductOrder = (id) => {
    let formData=new FormData();
    formData.append('id',id)
    SwagshopService.cancelProductOrder(formData).then(res=>{
      if (res.code === 200) {
        getTableData(1,pageSizePagination);
        getCreditsData()
        message.success( intl.formatMessage({id:'intl.myRedemption.table.popconfirm.message'}))
      }else{
        message.error(res.msg)
      }

   } )
  }
  const changePagination = (pageNumber) => {
    getTableData(pageNumber, pageSizePagination);
		setCurrentPagination(pageNumber);
	};
	const getCreditsData=()=>{
    SwagshopService.rewardStatistics({ 
      search:searchParams.search,
      createTimeStart:searchParams.createTime.length > 0 ? dayjs(searchParams.createTime[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
      createTimeEnd:searchParams.createTime.length > 0 ? dayjs(searchParams.createTime[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
      year:undefined,
      month:undefined,
      type:undefined,
      dataType:undefined
    }).then(res=>{
      setCreditsData(res.data.list[0])
    })
  }
  const changePaginationSize = (current, pageSize) => {
    getTableData(current, pageSize);
		setCurrentPagination(current);
		setPageSizePagination(pageSize);
		
	};
  const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
	};
  useEffect(() => {
    goRouterPosition({routerPosition: 'myRedemption'});
    let UrlParams=new URLSearchParams(window.location.search).get('search')
    if(UrlParams){
      setSearchParams({
        ...searchParams,
        search: UrlParams
      })
      setHasFirstEffectRun(true);
    }
    getCreditsData()
  }, []);
	useEffect(() => {
    if(localStorage.getItem('swagshopDetailMessage')){
      messageApi.open({
        content:intl.formatMessage({id: 'intl.swagshopdetail.tips.order'}),
        type: 'success',
        onClose: () => {
          localStorage.removeItem('swagshopDetailMessage')
        }
      })
    }

    getTableData(1,pageSizePagination);
 
	}, [searchParams,hasFirstEffectRun]);

	return (
    
		<div className='myRedemptionContainer'>
      {contextHolder}
            <QuickEntryDiv
            title={intl.formatMessage({ id: 'intl.personalinfo.breadCrumb.myRedemption'})}
            chosenType='myRedemption'
          />
			<div className='myRedemptionTopDiv'>
        <div className='balanceDetailsAllDiv'>
           <div className='balanceDiv'>
             <div>{intl.formatMessage({ id: 'intl.myRedemption.dataCardmy.creditsBalance'})}</div>
             <div className='myRedemptionQuantityDiv' style={{color:'#2C61F3'}}>{creditsData?.userRewardItem?.notRedeemedCredit||0}</div>
           </div> 
           <img src={Rectangle} alt="" />
           <div className='balanceDiv'>
             <div>{intl.formatMessage({ id: 'intl.myRedemption.dataCardmy.totalCredits'})}</div>
             <div className='myRedemptionQuantityDiv' style={{color:'#000'}}>{creditsData?.userRewardItem?.credit||0}</div>
           </div>
           <img src={Rectangle} alt="" />
           <div className='balanceDiv' style={{width:'140px'}}>
            <div >{intl.formatMessage({ id: 'intl.myRedemption.dataCardmy.redeemedCredits'})}</div>
            <div className='myRedemptionQuantityDiv'  style={{color:'#000'}}>{creditsData?.userRewardItem?.redeemedCredit||0}</div>
           </div>
        </div>
        <div className='goToRedeemDiv' onClick={()=>window.location.href = '/SRC/swagshop'} >
           <p>{intl.formatMessage({ id: 'intl.myRedemption.skipButton.goTo'})}</p>
           <p>{intl.formatMessage({ id: 'intl.myRedemption.skipButton.swagshop'})}</p>
           <span>{intl.formatMessage({ id: 'intl.myRedemption.skipButton.swagshopTips'})}</span>
       
        </div>
        <div className='earnMoreCreditsDiv' onClick={()=>goDetailLink('getMoreCredits')}>
          <p>{intl.formatMessage({ id: 'intl.myRedemption.skipButton.getMore'})}</p>
          <p>{intl.formatMessage({ id: 'intl.myRedemption.skipButton.credits'})}</p>
          <span>{intl.formatMessage({ id: 'intl.myRedemption.skipButton.creditsTips'})}</span>
        </div>
			</div>
      <div className='myRedemptionSearchDiv'> 
      <Input
      style={{width:306}}
      placeholder={intl.formatMessage({ id: 'intl.myRedemption.filterBox.Search'})}
      allowClear
      onChange={(e)=>setSearchParams(Object.assign({},searchParams,{search:e.target.value}))}
      value={searchParams.search}
      suffix={<SearchOutlined />}/>
      <RangePicker 
      placeholder={[intl.formatMessage({ id: 'intl.myRedemption.filterBox.startTime'}),intl.formatMessage({ id: 'intl.myRedemption.filterBox.endTime'})]}
       onChange={((value, dateString) => {
        value ? setSearchParams(Object.assign({}, searchParams, { createTime: [dayjs(value[0]).hour(0).minute(0).second(0), dayjs(value[1]).hour(23).minute(59).second(59)] })) : setSearchParams(Object.assign({}, searchParams, { createTime: [] }))
      })}
      value={searchParams.createTime}
      style={{ width: 285 }}
      />

      <Select
      style={{ width: 160 }}
      optionFilterProp="children"
      showSearch
      allowClear
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      placeholder={intl.formatMessage({ id: 'intl.myRedemption.filterBox.status'})}
      onChange={(e)=>setSearchParams(Object.assign({},searchParams,{status:e}))}
      options={options}
      />
      </div>
      <div className='tableDiv'>
      <Table
						className='leaderboardsTable'
						columns={columns}
						rowKey={(record) => record.id}
						dataSource={tableData}
            pagination={false}
            size='small'
            locale={{
              emptyText: (
                <Empty
                  image={noDataImg}
                />
              ),
            }}
			 />
          	{tableData.length !== 0 && (
              //noDataImg
						<div className='myRedemptionContentListPaginationdiv'>
							<label className='myRedemptionContentListTotal'>
								{intl.formatMessage({
									id: 'intl.myRedemption.table.total',
								})}{' '}
								{totalPagination}{' '}
								{intl.formatMessage({
									id: 'intl.myRedemption.table.totalnum',
								})}
							</label>
							<Pagination
								showQuickJumper
                showSizeChanger={true}
								key={currentPagination}
								current={currentPagination}
								defaultPageSize={pageSizePagination}
								total={totalPagination}
                pageSizeOptions={['10', '20', '30', '40']}
								onChange={changePagination}
								onShowSizeChange={changePaginationSize}
							/>
						</div>
     
					)}
      </div>
	

		</div>
	);
});
