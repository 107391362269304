import React, { useEffect,useState } from 'react';
import './style/signUp.css';
import { goRouterPosition } from '../../action/routerposition/routerpositionAction'
import {useIntl} from 'react-intl';
import { scrollToTop } from '../../common/topScorll'
import {Input,Select,Checkbox,Popover,Progress,message} from 'antd'
import SignUpService from '../../services/signUpService'
import { useHistory } from 'react-router-dom'
import { Link } from "react-router-dom";
import signUpBg from '../../assets/svg/bg.svg'
import store from '../../store/index'
import { JSEncrypt } from 'jsencrypt'

export default (function (props) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYpKIowTM/+WFirmaZuU9e0BK7zyQRBuPUG4CYWl6S/VsiDoAMlqKHdG/9G9+Wd2ZDIzAfQM+tZ2cutQotNlxyd/d7LZm71xy+zPvpcGJQo4iQ8cylZfjZ2gQO3+7nEqo11eVYlrI8MyOhgRLSzvpNHaBPlEPd9zKCh8baLF5NywIDAQAB");
    const intl = useIntl();
    const [ countDownSecond,setCountDownSecond ] = useState(60)
    const [ captchaCode,setCaptchaCode ] = useState("")
    const [ errorMsg,setErrorMsg ] = useState("")
    const [ captchaCodeToken,setCaptchaCodeToken ] = useState("")
    const [ countrySelectList,setCountrySelectList ] = useState([])
    const [ countrySelect,setCountrySelect ] = useState("")
    const [ emailAdressInput,setEmailAdressInput ] = useState("")
    const [ passwordInput,setPasswordInput ] = useState("")
    const [ passwordVerifyInput,setPasswordVerifyInput ] = useState("")
    const [ captchaCodeInput,setCaptchaCodeInput ] = useState("")
    const [ emailCaptchaCodeInput,setEmailCaptchaCodeInput ] = useState("")
    const [ agreementCheckbox,setAgreementCheckbox ] = useState(false)
    const [ percentProgress,setPercentProgress ] = useState(0)
    const { Option } = Select;
    const history = useHistory()
    useEffect(() => {
        (sessionStorage.getItem('lang') == 'zh_CN') && (document.title = '注册') ||
        (sessionStorage.getItem('lang') == 'en_US') && (document.title = 'Sign Up')
        scrollToTop();
        goRouterPosition({routerPosition:"signUp"})
        SignUpService.getCaptchaCode().then (res => {
            setCaptchaCode(res.data.base64Img)
            setCaptchaCodeToken(res.data.cToken)
        }).catch(error => {
            console.log(error)
        })
        SignUpService.getCountryList({
            lang: sessionStorage.getItem('lang')
        }).then (res => {
            setCountrySelectList(res.data)
        }).catch(error => {
            console.log(error)
        })
    }, []);
    //60s倒计时
    const handleCountDown = (seconds = 60) => {
        let second = seconds;
        const countDown = ()=> {
            // timer && clearTimeout(timer); //清除计时器
            if( second > 0){
                second--;
                setCountDownSecond( second );
            }
            if( second === 0 ){
                second = 60;
                setCountDownSecond( second );
                // clearTimeout( timer ); //清除计时器
                return;
            }
            setTimeout( countDown,1000 );
        };
        setTimeout( countDown,1000 );
    };
    const SignUp = ()=> {
        let parmes = {}
        parmes={
            countryId: countrySelect,
            email: encrypt.encrypt(emailAdressInput),
            passCode: encrypt.encrypt(passwordInput),
            // passCodeConfirm: passwordVerifyInput,
            mCode: encrypt.encrypt(emailCaptchaCodeInput),
        }
        if(countrySelect){
            if(emailAdressInput == ""){
                setErrorMsg(intl.formatMessage({
                    id: 'intl.signup.message.error.email'
                }));
                return false
            } else if(emailCaptchaCodeInput == ""){
                setErrorMsg(intl.formatMessage({
                    id: 'intl.signup.message.error.emailcaptchacode'
                }));
                return false
            }
            if(agreementCheckbox){
                // if(passwordInput==passwordVerifyInput){
                    if(percentProgress == 100){
                        SignUpService.goSignUp(parmes).then (res => {
                            message.success(intl.formatMessage({
                                id: 'intl.signup.message.success'
                            }));
                            history.push('/SRC/login')
                        }).catch(error => {
                            changeCaptchaCode();
                            setErrorMsg(error);
                        })
                    }else {
                        setErrorMsg(intl.formatMessage({
                            id: 'intl.signup.message.error.passwordrule'
                        }));
                    }
                // }else{
                //     message.error('两次密码不一致');
                // }
            }else{
                setErrorMsg(intl.formatMessage({
                    id: 'intl.signup.message.error.chooseagreement'
                }));
            }
        }else {
            setErrorMsg(intl.formatMessage({
                id: 'intl.signup.message.error.choosecountry'
            }));
        }
    }
    const getEmailCaptcha = ()=> {
        if(captchaCodeInput == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.signup.message.error.captchacode'
            }));
            return false
        }
        SignUpService.goEmailCaptcha({
            mail: emailAdressInput,
            cToken: captchaCodeToken,
            cCode: captchaCodeInput,
            type: "mail_register"
        }).then (res => {
            handleCountDown();
        }).catch(error => {
            setErrorMsg(error);
        })
    }
    const changeCaptchaCode = ()=> {
        SignUpService.getCaptchaCode().then (res => {
            setCaptchaCode(res.data.base64Img)
            setCaptchaCodeToken(res.data.cToken)
        }).catch(error => {
            console.log(error)
        })
    }
    const countrySelectChange = (value)=> {
        setCountrySelect(value)
    }
    const emailAdressInputChange = (e)=> {
        setEmailAdressInput(e.target.value)
    }
    const passwordInputChange = (e)=> {
        setPasswordInput(e.target.value)
        let precent = 0;
        console.log(e.target.value,"e.target.value")
        if (e.target.value.length > 7 && e.target.value.length < 17) {
            precent = 25
        }
        if (/\d/.test(e.target.value)) {
            precent = precent+25
        } //数字
        if ((/[a-z]/.test(e.target.value)) || (/[A-Z]/.test(e.target.value))) {
            precent = precent+25
        } //小写/大写
        if (/\W/.test(e.target.value)) {
            precent = precent+25
        } //特殊字符
        setPercentProgress(precent)
    }
    const passwordInputCheck = (e)=> {
        let precent = 0;
        if (e.target.value.length > 7 && e.target.value.length < 17) {
            precent = 25
        }
        if (/\d/.test(e.target.value)) {
            precent = precent+25
        } //数字
        if ((/[a-z]/.test(e.target.value)) || (/[A-Z]/.test(e.target.value))) {
            precent = precent+25
        } //小写/大写
        if (/\W/.test(e.target.value)) {
            precent = precent+25
        } //特殊字符
        if(precent != 100) {
            setErrorMsg(intl.formatMessage({
                id: 'intl.signup.password.rule'
            }))
        }else {
            setErrorMsg("");
        }
    }
    const passwordVerifyInputChange = (e)=> {
        setPasswordVerifyInput(e.target.value)
    }
    const captchaCodeInputChange = (e)=> {
        setCaptchaCodeInput(e.target.value)
    }
    const emailCaptchaCodeInputChange = (e)=> {
        setEmailCaptchaCodeInput(e.target.value)
    }
    const agreementCheckboxChange = (e)=> {
        if (e.target.checked) {
            setErrorMsg("")
        }
        setAgreementCheckbox(e.target.checked)
    }
    const passwordPopoverTitle = <span className="PopoverProgressTitle">
        {
            intl.formatMessage({
                id: 'intl.signup.password.title'
            })
        }
    </span>;
    const passwordPopover = (
        <div>
          <Progress
            status={(percentProgress<100)?"exception":"success"}
            percent={percentProgress}
            />
            <p className="PopoverProgress">
                {
                    intl.formatMessage({
                        id: 'intl.signup.password.rule'
                    })
                }
            </p>
        </div>
    );
    return ( 
        <div className="signUpContainer">
            <div className="signUpHeader">
                <div className="signUpHeaderContent">
                    <img src={ signUpBg }/>
                </div> 
            </div>
            <div className="signUpContent">
                <p className="signUpTitle">
                    {
                        intl.formatMessage({
                            id: 'intl.signup.title'
                        })
                    }
                </p>
                <Select
                    onChange={countrySelectChange}
                    className="signUpSelect"
                    size="large"
                    showSearch
                    placeholder={
                        intl.formatMessage({
                            id: 'intl.signup.placeholder.country'
                        })
                    }
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        countrySelectList.map((data,index)=>(
                            <Option key={index} value={data.id}>{data.name}</Option>
                        ))
                    }
                </Select>
                <Input className="signUpInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.signup.placeholder.email'
                        })
                    } onChange={emailAdressInputChange}/>
                <Popover placement="right" overlayClassName='passwordPopoverOverlay' title={passwordPopoverTitle} content={passwordPopover} trigger="focus">
                    <Input.Password className="signUpInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.signup.placeholder.password'
                        })
                    } onChange={passwordInputChange} onBlur={passwordInputCheck}/>
                </Popover>
                {/* <Input.Password className="signUpInput" placeholder="Verify the password" onChange={passwordVerifyInputChange}/> */}
                <Input className="signUpInput captchaInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.signup.placeholder.captcha.img'
                        })
                    } onChange={captchaCodeInputChange}/>
                <img className="captchaCode" onClick={changeCaptchaCode} src={ captchaCode }/>
                <Input className="signUpInput captchaInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.signup.placeholder.captcha.email'
                        })
                    } onChange={emailCaptchaCodeInputChange}/>
                <button className={countDownSecond == 60 ? "captchaButton" : "captchaButtonDis"} onClick={getEmailCaptcha} disabled={countDownSecond == 60 ? false : true}>
                    {  
                        countDownSecond == 60 ? intl.formatMessage({
                            id: 'intl.signup.button.getcaptcha'
                        }) : `${countDownSecond}${intl.formatMessage({
                            id: 'intl.signup.button.aftergetcaptcha'
                        })}`
                        
                    }
                </button>
                <Checkbox className="signUpCheckbox" onChange={agreementCheckboxChange}>
                    {
                        intl.formatMessage({
                            id: 'intl.signup.agreement.agree'
                        })
                    }
                    <Link className="LinkSignUp" onClick={()=>window.open("/privacyPolicy/"+sessionStorage.getItem('lang'))}>
                        {
                            intl.formatMessage({
                                id: 'intl.signup.agreement.policy'
                            })
                        }
                    </Link>
                    {
                        intl.formatMessage({
                            id: 'intl.signup.agreement.and'
                        })
                    }
                    <Link className="LinkSignUp" onClick={()=>window.open("/agreement/"+sessionStorage.getItem('lang'))}>
                        {
                            intl.formatMessage({
                                id: 'intl.signup.agreement.plateform'
                            })
                        }   
                    </Link>
                    {
                        intl.formatMessage({
                            id: 'intl.signup.agreement.content'
                        })
                    }
                </Checkbox>
                <p className="errorMessage">{errorMsg}</p>
                <p className="signUpButton" onClick={SignUp}>
                    {
                        intl.formatMessage({
                            id: 'intl.signup.button.gosignup'
                        })
                    }
                </p>
                <div className="SFDiv">
                    <Link className="LinkSignUp" to={`/SRC/login`}>
                        {
                            intl.formatMessage({
                                id: 'intl.signup.button.gologin'
                            })
                        }
                    </Link>
                    {/* <Link className="LinkForgetPassword" to={`/SRC/forgetPassword`}>Forget Password</Link> */}
                </div>
            </div> 
        </div> 
    );
})