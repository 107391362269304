import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class BlogDetailService {

    /**
     * 公告详情界面	
     * @param {id} 公告id
     * @return {公告详情}
     */
    getBlogDetailData(params) {
        return Xhr.get(`/article/${params.id}?lang=${params.lang}`);
    }
    getBlogDetailPreData(params) {
        return Xhr.get(`/article/${params.id}/pre?lang=${sessionStorage.getItem('lang')}`);
    }
    getBlogDetailNextData(params) {
        return Xhr.get(`/article/${params.id}/next?lang=${sessionStorage.getItem('lang')}`);
    }
}

// 实例化再导出
export default new BlogDetailService();