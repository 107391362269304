import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class SwagshopService {
	/**
	 * 登录界面
	 * @param {current} 当前页码
	 * @param {pageSize} 一页数量
	 * @param {type} 公告类型
	 * @param {search} 搜索内容
	 * @return {公告列表}
	 */

	getProduct(params) {
		return Xhr.get(`product/`, {params: params});
	}


	getSwagshopDetail(params) {
		return Xhr.get(
			`product/${params.id}?lang=${sessionStorage.getItem('lang')}`,
			{params: params}
		);
	}
	productOrder(params) {
		return Xhr.post('productOrder/',params);
	}
	getproductOrderList(params) {
		return Xhr.get(	'productOrder/', {params: params});
	}
	rewardStatistics(params) {
		return Xhr.get(	'userRewardRecord/rewardStatistics/', {params: params});
	}
  cancelProductOrder(params) {
  return Xhr.post('productOrder/cancel/',params);
  }
  getRewardRecord(params){
    return Xhr.get('userRewardRecord/', {params: params});
  }
  getYear(params){
    return Xhr.get('userRewardRecord/yearList', {params: params});
  }
  getmonth(params){
    return Xhr.get('userRewardRecord/monthList', {params: params});
  }
  getuserRank(params){
    return Xhr.get('userRewardRecord/userRank', {params: params});
  }

}

// 实例化再导出
export default new SwagshopService();
