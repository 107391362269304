import React, { useEffect,useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import {useIntl} from 'react-intl';
import { scrollToTop } from '../../common/topScorll'
import { goRouterPosition } from '../../action/routerposition/routerpositionAction'
import './style/login.css';
import {Input,message} from 'antd'
import { getUserInfo } from '../../action/login/loginAction'
import { useHistory } from 'react-router-dom'
import LoginService from '../../services/loginService'
import { Link } from "react-router-dom";
import store from '../../store/index'
import loginBg from '../../assets/svg/bg.svg'
import { JSEncrypt } from 'jsencrypt'

export default (function (props) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYpKIowTM/+WFirmaZuU9e0BK7zyQRBuPUG4CYWl6S/VsiDoAMlqKHdG/9G9+Wd2ZDIzAfQM+tZ2cutQotNlxyd/d7LZm71xy+zPvpcGJQo4iQ8cylZfjZ2gQO3+7nEqo11eVYlrI8MyOhgRLSzvpNHaBPlEPd9zKCh8baLF5NywIDAQAB");
    const intl = useIntl();
    const [ state,setState ] = useState(store.getState().Login) 
    const [ captchaCode,setCaptchaCode ] = useState("")
    const [ captchaCodeShow,setCaptchaCodeShow ] = useState(false)
    const [ errorMsg,setErrorMsg ] = useState("")
    const [ captchaCodeToken,setCaptchaCodeToken ] = useState("")
    const [ emailAdressInput,setEmailAdressInput ] = useState("")
    const [ passwordInput,setPasswordInput ] = useState("")
    const [ captchaCodeInput,setCaptchaCodeInput ] = useState("")
    const [ facebookToken,setFacebookToken ] = useState("")
    const history = useHistory()
    useEffect(() => {
        (sessionStorage.getItem('lang') == 'zh_CN') && (document.title = '登陆') ||
        (sessionStorage.getItem('lang') == 'en_US') && (document.title = 'Log In')
        scrollToTop();
        goRouterPosition({routerPosition:"login"})
        store.subscribe(storeChange);
        LoginService.getCaptchaCode().then (res => {
            setCaptchaCode(res.data.base64Img)
            setCaptchaCodeToken(res.data.cToken)
        }).catch(error => {
            console.log(error)
        })
    }, []);
    const storeChange = () => {
        setState(store.getState().Login)
    };
    const responseFacebook = (response) => {
        setFacebookToken(response.accessToken);
        LoginService.goFbLogin({accessToken:response.accessToken}).then (res => {
            if(res.code == 200){
                sessionStorage.setItem("token", res.data.token)
                getUserInfo();
                message.success(
                    intl.formatMessage({
                        id: 'intl.login.message.success'
                    })
                );
                history.push('/dashboard')
            }
        }).catch(error => {
            setErrorMsg(error)
            console.log(error)
        })
        // goLogin({accessToken:response.accessToken})
        console.log(response.accessToken,'accessToken');
    }
    const changeCaptchaCode = ()=> {
        LoginService.getCaptchaCode().then (res => {
            setCaptchaCode(res.data.base64Img)
            setCaptchaCodeToken(res.data.cToken)
        }).catch(error => {
            console.log(error)
        })
    }
    const emailAdressInputChange = (e)=> {
        setEmailAdressInput(e.target.value)
        setCaptchaCodeShow();
        setErrorMsg("");
    }
    const passwordInputChange = (e)=> {
        setPasswordInput(e.target.value)
        setErrorMsg("");
    }
    const captchaCodeInputChange = (e)=> {
        setCaptchaCodeInput(e.target.value)
    }
    const loginIn = ()=> {
        if(emailAdressInput == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.login.message.error.email'
            }))
            return false
        }else if(passwordInput == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.login.message.error.password'
            }))
            return false
        }else if(captchaCodeShow && captchaCodeInput =="") {
            setErrorMsg(intl.formatMessage({
                id: 'intl.login.message.error.captchaCode'
            }))
            return false
        }
        let params = {}
        console.log(facebookToken)
        params={
            username: encrypt.encrypt(emailAdressInput),
            password: encrypt.encrypt(passwordInput),
            cCode: captchaCodeInput,
            cToken: captchaCodeToken
        }
        LoginService.goLogin(params).then (res => {
            if(res.code == 200){
                console.log('success')
                sessionStorage.setItem("token", res.data.token)
                getUserInfo();
                message.success(intl.formatMessage({
                    id: 'intl.login.message.success'
                }));
                if(sessionStorage.getItem('redirect')) {
                    history.push(sessionStorage.getItem('redirect'))
                }else {
                    history.push('/dashboard')
                }
            }
        }).catch(error => {
            console.log(error)
            changeCaptchaCode();
            console.log(captchaCodeShow)
            setCaptchaCodeShow(true);
            setErrorMsg(error)
        })
    }

    return ( 
        <div className="loginContainer">
            <div className="loginHeader">
                <div className="loginHeaderContent">
                    <img src={ loginBg }/>
                </div> 
            </div>
            <div className="loginContent">
                <p className="loginTitle">
                    {
                        intl.formatMessage({
                            id: 'intl.login.title'
                        })
                    }
                </p>
                <Input className="loginInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.login.placeholder.email'
                        })
                    } onChange={emailAdressInputChange}/>
                <Input.Password className="loginInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.login.placeholder.password'
                        })
                    } onChange={passwordInputChange}/>
                {
                    captchaCodeShow && <Input className="loginInput captchaInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.login.placeholder.captcha'
                        })
                    } onChange={captchaCodeInputChange}/>
                }
                {
                    captchaCodeShow &&
                    <img className="captchaCode" onClick={changeCaptchaCode} src={ captchaCode }/>
                }
                {(errorMsg!="") && <p className="errorMessage">{errorMsg}</p>}
                <p className="loginButton" onClick={loginIn}>
                    {
                        intl.formatMessage({
                            id: 'intl.login.button.gologin'
                        })
                    }
                </p>
                <div className="SFDiv">
                    <Link className="LinkSignUp" to={`/SRC/signUp`}>
                        {
                            intl.formatMessage({
                                id: 'intl.login.button.gosignup'
                            })
                        }
                    </Link>
                    <Link className="LinkForgetPassword" to={`/SRC/forgetPassword`}>
                        {
                            intl.formatMessage({
                                id: 'intl.login.button.goforgetpassword'
                            })
                        }
                    </Link>
                </div>
                <p className="otherLoginLabel">
                    {
                        intl.formatMessage({
                            id: 'intl.login.button.gootherlogin'
                        })
                    }
                </p>
                <div className="otherLoginDiv">
                <FacebookLogin
                    appId="456290888761210"
                    // autoLoad={true}
                    // response_type="code"
                    fields="name,email,picture"
                    scope="public_profile"
                    cssClass="btnFacebook"
                    callback={responseFacebook}
                    // icon="fa-facebook" 
  
                    textButton = ""   />
                </div>
            </div> 
        </div> 
    );
})