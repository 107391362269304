import React, {useRef, useEffect, useState} from 'react';
import './style/ApkDownload.css';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {scrollToTop} from '../../common/topScorll';
import {goRouterPosition} from '../../action/routerposition/routerpositionAction';
import PersonalInfoService from '../../services/personalInfoService';
import {LoadingOutlined} from '@ant-design/icons';
import {Radio, Pagination, Spin} from 'antd';
import ApkDownloadService from '../../services/apkDownloadService';
import searchIcon from '../../assets/svg/ic-apkDownloadSearch.svg';
import defaultSortIcon from '../../assets/svg/ic-sortDefault.svg';
import hoverSortIcon from '../../assets/svg/ic-sortHover.svg';
import ascendSortIcon from '../../assets/svg/ic-sortAscend.svg';
import descendSortIcon from '../../assets/svg/ic-sortDescend.svg';
import icNoData from '../../assets/svg/ic-空.svg';

export default (function (props) {
	const intl = useIntl();
	const history = useHistory();
	const [apkType, setApkType] = useState('All');
	const [search, setSearch] = useState();
	const [sortIconImg, setSortIconImg] = useState(defaultSortIcon);
	const [sortClickCount, setSortClickCount] = useState(0);
	const [sortText, setSortText] = useState();
	const [sortOrder, setSortOrder] = useState();
	const [apkDownloadData, setApkDownloadData] = useState();
	const [apkDownloadPageTotal, setApkDownloadPageTotal] = useState(0);
	const [apkDownloadCurrentPage, setApkDownloadCurrentPage] = useState(1);
	const [apkDownloadPagesize, setApkDownloadPagesize] = useState(5);
	// const [ downloadLoading, setDownloadLoading] = useState(false)
	const changeSearchContent = (e) => {
		setSearch(e.target.value);
		ApkDownloadService.getApkDownloadData({
			current: 1,
			pageSize: apkDownloadPagesize,
			classify: apkType,
			search: e.target.value,
			order: sortOrder
		}).then((res) => {
			setApkDownloadData(res.data.list);
			setApkDownloadPageTotal(res.data.total);
		});
	};
	const changeApkType = (e) => {
		setApkDownloadCurrentPage(1);
		setApkType(e.target.value);
		ApkDownloadService.getApkDownloadData({
			current: 1,
			pageSize: apkDownloadPagesize,
			classify: e.target.value,
			search: search,
			order: sortOrder
		}).then((res) => {
			setApkDownloadData(res.data.list);
			setApkDownloadPageTotal(res.data.total);
		});
	};
	const changeSortType = (isClick) => {
		let tempOrder = '';
		let tempCount = sortClickCount;
		let tempCurrent = apkDownloadCurrentPage;
		if (isClick) {
			tempCount++;
			setSortClickCount(tempCount);
			tempCurrent = 1;
			setApkDownloadCurrentPage(1);
		}
		switch (tempCount % 3) {
			case 0:
				setSortIconImg(defaultSortIcon);
				setSortText(
					intl.formatMessage({
						id: 'intl.apkDownload.sort.default'
					})
				);
				setSortOrder('');
				tempOrder = '';
				break;
			case 1:
				setSortIconImg(descendSortIcon);
				setSortText(
					intl.formatMessage({
						id: 'intl.apkDownload.sort.timeSort'
					})
				);
				setSortOrder('-sorting');
				tempOrder = '-sorting';
				break;
			case 2:
				setSortIconImg(ascendSortIcon);
				setSortText(
					intl.formatMessage({
						id: 'intl.apkDownload.sort.timeSort'
					})
				);
				setSortOrder('sorting');
				tempOrder = 'sorting';
				break;
			default:
				setSortIconImg(defaultSortIcon);
				setSortText(
					intl.formatMessage({
						id: 'intl.apkDownload.sort.default'
					})
				);
				setSortOrder('');
				tempOrder = '';
				break;
		}
		if (isClick) {
			ApkDownloadService.getApkDownloadData({
				current: tempCurrent,
				pageSize: apkDownloadPagesize,
				classify: apkType,
				search: search,
				order: tempOrder
			}).then((res) => {
				setApkDownloadData(res.data.list);
				setApkDownloadPageTotal(res.data.total);
			});
		}
	};
	const jumpToApkUrl = (type, link) => {
		if (type == '其他应用') {
			if (sessionStorage.getItem('token')) {
				// setDownloadLoading(true)
				window.open(`https://security.tecno.com/slm/apkDownload/getFile?path=${link}&token=${sessionStorage.getItem('token')}`)
				// window.open(
				// 	`https://tools.transsion.com:8091/slm/apkDownload/getFile?path=${link}&token=${sessionStorage.getItem(
				// 		'token'
				// 	)}`
				// );
				// ApkDownloadService.downloadApk({path: link}).then(res => {
				//     if(res.code == 200) {
				//         setDownloadLoading(false)
				//     }
				// }).catch(e => {
				//     setDownloadLoading(false)
				// })
			}
		} else if (type == 'GP应用') {
			window.open(link);
		}
	};
	const getApkDownloadDataFn = (current, pageSize) => {
		ApkDownloadService.getApkDownloadData({
			current: current,
			pageSize: pageSize,
			classify: apkType,
			search: search,
			order: sortOrder
		}).then((res) => {
			setApkDownloadData(res.data.list);
			setApkDownloadPageTotal(res.data.total);
		});
	};
	const changePagination = (pageNumber) => {
		setApkDownloadCurrentPage(pageNumber);
		getApkDownloadDataFn(pageNumber, apkDownloadPagesize);
	};
	const changePaginationSize = (current, pageSize) => {
		setApkDownloadCurrentPage(current);
		setApkDownloadPagesize(pageSize);
		getApkDownloadDataFn(current, pageSize);
	};
	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = 'APK下载')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'APK Download'));
		scrollToTop();
		// sessionStorage.setItem('redirect', window.location.pathname);
    sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		PersonalInfoService.getPersonalInfo()
			.then((res) => {
				if (!res.data.haveEmail) {
					history.push('/SRC/bindEmailPwd');
				}
			})
			.catch((error) => {
				console.log(error);
			});
		goRouterPosition({routerPosition: 'ApkDownload'});
		setSortText(
			intl.formatMessage({
				id: 'intl.apkDownload.sort.default'
			})
		);
		getApkDownloadDataFn(1, 5);
	}, []);
	return (
		<div className='apkDownloadContainer'>
			<div className='apkDownloadContainerContent'>
				<div className='apkDownloadTitleDiv'>
					<p className='apkDownloadTitle'>
						{intl.formatMessage({
							id: 'intl.apkDownload.title'
						})}
					</p>
					<div className='apkDownloadSearchDiv'>
						<input
							onChange={changeSearchContent}
							className='apkDownloadSearch'
							placeholder={intl.formatMessage({
								id: 'intl.apkDownload.search.placeholder'
							})}
						/>
						<img
							src={searchIcon}
							className='apkDownloadSearchIcon'
						/>
					</div>
				</div>
				<div className='apkDownloadContentDiv'>
					<div className='apkDownloadTypeDiv'>
						<Radio.Group value={apkType} onChange={changeApkType}>
							<Radio.Button value='All'>
								{intl.formatMessage({
									id: 'intl.apkDownload.type.all'
								})}
							</Radio.Button>
							<Radio.Button value='GP'>
								{intl.formatMessage({
									id: 'intl.apkDownload.type.googlePlay'
								})}
							</Radio.Button>
							<Radio.Button value='Others'>
								{intl.formatMessage({
									id: 'intl.apkDownload.type.others'
								})}
							</Radio.Button>
						</Radio.Group>
						<div
							className='apkDownloadSortDiv'
							onMouseEnter={() => setSortIconImg(hoverSortIcon)}
							onMouseLeave={() => changeSortType(false)}
							onClick={() => changeSortType(true)}>
							<label className='apkDownloadSortText'>
								{sortText}
							</label>
							<img
								src={sortIconImg}
								className='apkDownloadSortIcon'
							/>
						</div>
					</div>
					{/* <Spin 
                spinning={downloadLoading} 
                tip={
                    intl.formatMessage({
                        id: 'intl.apkDownload.spinning.tips'
                    })
                }> */}
					<div className='apkDownloadContentListDiv'>
						<ul className='apkDownloadListul'>
							{(apkDownloadPageTotal == 0 && (
								<div className='noDataDiv'>
									<img className='noDataImg' src={icNoData} />
									<p className='noDataLabel'>
										{intl.formatMessage({
											id: 'intl.nodata.label'
										})}
									</p>
								</div>
							)) ||
								apkDownloadData?.map((data, index) => (
									<li
										key={index}
										className='apkDownloadListli'>
										<div className='apkDownloadLiContent'>
											<div className='apkDownloadLiLeft'>
												<img
													src={data.ossIconUrl}
													className='apkDownloadLeftImg'
												/>
												<div className='apkDownloadLeftText'>
													<div className='apkDownloadLeftTitle'>
														{data.packageName}
													</div>
													<div
														className={
															data.classify ==
															'其他应用'
																? 'apkDownloadLeftVersion'
																: 'apkDownloadLeftVersionVis'
														}>
														{data.version}
													</div>
													<div className='apkDownloadLeftTime'>
														{
															data.updateTime.split(
																' '
															)[0]
														}
													</div>
												</div>
											</div>
											{data.classify == '其他应用' ? (
												<div
													className='apkDownloadLiRightNormalBtn'
													onClick={() =>
														jumpToApkUrl(
															data.classify,
															data.link
														)
													}>
													<div className='apkDownloadRightNormalImg'></div>
													<label className='apkDownloadRightText'>
														{data.size}
													</label>
												</div>
											) : (
												<div
													className='apkDownloadLiRightGoogleBtn'
													onClick={() =>
														jumpToApkUrl(
															data.classify,
															data.link
														)
													}>
													<div className='apkDownloadRightGoogleImg'></div>
													<label className='apkDownloadRightText'>
														Google Play
													</label>
												</div>
											)}
										</div>
									</li>
								))}
						</ul>
						{apkDownloadPageTotal != 0 && (
							<div className='apkDownloadListPaginationdiv'>
								<label className='apkDownloadListTotal'>
									{intl.formatMessage({
										id: 'intl.blog.table.total'
									})}{' '}
									{apkDownloadPageTotal}{' '}
									{intl.formatMessage({
										id: 'intl.blog.table.totalnum'
									})}
								</label>
								<Pagination
									className='apkDownloadListPagination'
									showQuickJumper
									key={apkDownloadCurrentPage}
									current={apkDownloadCurrentPage}
									defaultPageSize={apkDownloadPagesize}
									total={apkDownloadPageTotal}
									onChange={changePagination}
									onShowSizeChange={changePaginationSize}
								/>
							</div>
						)}
            <div className='apkDownloadListTotal'>
            {intl.formatMessage({
										id: 'intl.personalinfo.settings.APKTips'
									})}
            </div>
					</div>

					{/* </Spin> */}
				</div>
			</div>
		</div>
	);
});
