import React from 'react';
// 引入加载路由视图得函数组件

import {Route,Redirect} from 'react-router-dom'
import RouterView from '../../router/index'
import store from '../../store/index'
// 引入路由配置表

class Home extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	componentDidMount() {
		(sessionStorage.getItem('lang') == 'zh_CN') && (document.title = '平台服务协议') ||
		(sessionStorage.getItem('lang') == 'en_US') && (document.title = 'Platform Agreement')
	}
	render() {
		let {match, routes} = this.props;
		return (
			<div>
                <Route path='/privacyPolicy' exact render={()=> (
                    <Redirect to={'/privacyPolicy/'+sessionStorage.getItem('lang')}/>
                )}/>
				<RouterView routes={routes}/>
			</div>
		)
	}

}
export default Home