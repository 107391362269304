import React, { useEffect,useState } from 'react';
import './style/blogdetail.css';
import {useIntl} from 'react-intl';
import store from '../../store/index'
import { scrollToTop } from '../../common/topScorll'
import { goRouterPosition } from '../../action/routerposition/routerpositionAction'
import {getBlogDetail,getBlogDetailPre,getBlogDetailNext} from '../../action/blogdetail/blogdetailAction'
import { Tag,message } from 'antd';
import { Link,useParams,useHistory } from "react-router-dom";
import viewCount from '../../assets/svg/ic-公告详情-浏览量.svg'
import topIcon from "../../assets/svg/ic-公告-置顶.svg";
import { FileTextOutlined,LeftOutlined,RightOutlined } from '@ant-design/icons';
import facebookIcon from '../../assets/svg/icon_facebook.svg';
import copyIcon from '../../assets/copy.png';
import avstar from '../../assets/svg/ic-个人中心-头像.svg';
import twitterimg from '../../assets/svg/icon_x.svg';
import copy from 'copy-to-clipboard'
export default (function (props) {
    const intl = useIntl();
    const [ state,setState ] = useState(store.getState().BlogDetail) 
    const {id} = useParams();
    const history = useHistory()
    useEffect(() => {
        (sessionStorage.getItem('lang') == 'zh_CN') && (document.title = '公告详情') ||
        (sessionStorage.getItem('lang') == 'en_US') && (document.title = 'Resource Detail')
        scrollToTop();
        // sessionStorage.setItem("redirect", window.location.pathname)
        sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
        goRouterPosition({routerPosition:"blogdetail"})
        getBlogDetail(id)
        getBlogDetailPre(id)
        getBlogDetailNext(id)
        store.subscribe(storeChange);
    }, []);
    const urlchange=`${window.location.pathname}${window.location.search}`
    useEffect(() => { 
    //   console.log('1',urlchange)
      sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
    }, [urlchange])
    const storeChange = () => {
        setState(store.getState().BlogDetail)
    };
    const goBlog = (id) => {
        history.push(`/SRC/blogdetail/${id}?lang=${sessionStorage.getItem('lang')}`)
        window.location.reload();
    };
    return ( 
        <div className="blogdetailContainer">
            <div className="blogdetailHeader">
                <div className="blogdetailHeaderContent">
                <div className="BreadcrumbBlogDiv">
                    <Link className="BreadcrumbBlog" to={`/SRC/blog`}>
                        {
                            intl.formatMessage({
                                id: 'intl.blogdetail.breadcrumb.blog'
                            })
                        }
                    </Link>
                    <label className="BreadcrumbLine">/</label>
                    <Link className="BreadcrumbBlogDetail">
                        {
                            intl.formatMessage({
                                id: 'intl.blogdetail.breadcrumb.detail'
                            })
                        }
                    </Link>
                </div>
                </div> 
            </div>

            <div className="blogdetailContentDiv">
              <div className='blogdetailContentLeftAll'>
                <div className="blogdetailContentLeft">
                    <p className="blogdetailtitle">{state.blogDetail.title}</p>
                    <div className="blogdetaillabeltimediv">
                        {
                            (state.blogDetail.type?.name == "News") && <Tag color="orange">
                                {
                                    intl.formatMessage({
                                        id: 'intl.blogdetail.type.news'
                                    })
                                }
                            </Tag> ||
                            (state.blogDetail.type?.name == "Blogs") && <Tag color="cyan">
                            {
                                intl.formatMessage({
                                    id: 'intl.blogdetail.type.blog'
                                })
                            }
                        </Tag> ||
                            (state.blogDetail.type?.name == "Events") && <Tag color="blue">
                            {
                                intl.formatMessage({
                                    id: 'intl.blogdetail.type.events'
                                })
                            }
                        </Tag> ||(state.blogDetail.type?.name == "Rules") && <Tag color="red">
                            {
                                intl.formatMessage({
                                    id: 'intl.blogdetail.type.rules'
                                })
                            }
                                         </Tag>
                        }
                        <label className="blogdetailtime">{state.blogDetail.firstPublishTime?.split(' ')[0]}</label>
                       {state.blogDetail.userName&&
                       <label className="blogdetailuserPicture" 
                       onClick={()=>{window.open(	state.blogDetail.userId
                        ? `/SRC/publicProfile/?userId=${state.blogDetail.userId}`
                        : `/SRC/publicProfile`)}}
                       ><div className="cutOffRuleDiv"></div> <img className="blogdetailImg" src={state.blogDetail.userPicture?state.blogDetail.userPicture:avstar} />{state.blogDetail.userName}</label>}
                        {/* <label className="blogdetailviewcount"><img className="blogdetailviewcountimg"src={viewCount} />{state.blogDetail.viewCounts ? state.blogDetail.viewCounts : 0}</label> */}
                    </div>
                    <div className="blogdetailcontent blogdetailcontentA" dangerouslySetInnerHTML={{ __html: state.blogDetail.content }}></div>
                    {/* <div className="blogdetailfiles">
                        {(state.blogDetail.files != []) && state.blogDetail.files.map((data,index)=>(
                            <Tag key={index} className="blogdetailfile" icon={<FileTextOutlined />} color="default">
                                { data.name }
                            </Tag>
                        ))}
                    </div> */}
                </div> 

                <div className='blogdetailfootDiv'>
                   {/* {state.blogDetail.type?.name == "Blogs" && <div className='amountOfReadingDiv'>{state.blogDetail.amountOfReading} Views</div>} */}
                    <div className='iconDiv'>
                       Share:
                       {/* <img src={facebookIcon} 	alt='img'/>
                       <img src={twitterimg} 	alt='img'/>
                       <img src={facebookIcon} 	alt='img'/> */}
                       <img src={copyIcon} 	alt='img' className='copyIconDiv' onClick={()=>{
                         copy(window.location.href)
                         message.success('Copy Success!')
                       }}/>
                    </div>
                </div>
              </div>

                <div className="blogdetailContentRight">
                    <p className="blogdetailContentRightMore">
                        {
                            intl.formatMessage({
                                id: 'intl.blogdetail.more.title'
                            })
                        }
                    </p>
                    {
                        state.blogDetailUp.id &&
                        <div className="blogdetailContentRightUp">
                            <div className="blogdetailContentLeftButtonDiv">
                                <LeftOutlined className="blogdetailContentLeftIcon" onClick={()=>goBlog(state.blogDetailUp.id)}/>
                                <label className="blogdetailContentRightButton" onClick={()=>goBlog(state.blogDetailUp.id)}>
                                    {
                                        intl.formatMessage({
                                            id: 'intl.blogdetail.more.up'
                                        })
                                    }
                                </label>
                            </div>
                            <div className="blogdetailContentRightContentDiv" onClick={()=>goBlog(state.blogDetailUp.id)}>
                                <div className="blogdetailContentRightTitleDiv">
                                    <p className="blogdetailContentRightTitle">
                                        {state.blogDetailUp.priority>0 && <img className="blogdetailContentRightImg" src={topIcon}/>}
                                        { state.blogDetailUp.title }
                                    </p>
                                    <p className="blogdetailContentRightTime">{ state.blogDetailUp.creatTime?.split(' ')[0] }</p>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        state.blogDetailDown.id &&
                        <div className="blogdetailContentRightDown">
                            <div className="blogdetailContentRightButtonDiv">
                                <label className="blogdetailContentRightButton" onClick={()=>goBlog(state.blogDetailDown.id)}>
                                    {
                                        intl.formatMessage({
                                            id: 'intl.blogdetail.more.down'
                                        })
                                    }
                                </label>
                                <RightOutlined className="blogdetailContentRightIcon" onClick={()=>goBlog(state.blogDetailDown.id)}/>
                            </div>
                            <div className="blogdetailContentRightContentDiv" onClick={()=>goBlog(state.blogDetailDown.id)}>
                                <div className="blogdetailContentRightTitleDiv">
                                    <p className="blogdetailContentRightTitle">
                                        {state.blogDetailDown.priority>0 && <img className="blogdetailContentRightImg" src={topIcon}/>}
                                        { state.blogDetailDown.title }
                                    </p>
                                    <p className="blogdetailContentRightTime">{ state.blogDetailDown.creatTime?.split(' ')[0] }</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>                
            </div>

           
        </div> 
    )
})