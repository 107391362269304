/**
 * 提交漏洞列表界面action
 * @return
 */
import { RES_GETBUGSUBMITLIST,RES_GETLEAKINFO,RES_CHANGEPAGINATION,RES_CHANGEFILTERS,RES_CHANGESORTS,RES_CHANGETOTAL } from '../../constants/bugSubmitListTypes';
import store from '../../store/index'
import BugSubmitListService from '../../services/bugSubmitListService';
 
 /**
  * 提交漏洞列表
  * @return
  */
const resGetBugSubmitList = (res) => {
    return {
        type: RES_GETBUGSUBMITLIST,
        res
    }
}
const resGetLeakInfo = (res) => {
    return {
        type: RES_GETLEAKINFO,
        res
    }
}
const resChangePagination = (res) => {
    return {
        type: RES_CHANGEPAGINATION,
        res
    }
}
const resChangeFilters = (res) => {
    return {
        type: RES_CHANGEFILTERS,
        res
    }
}
const resChangeSorts = (res) => {
    return {
        type: RES_CHANGESORTS,
        res
    }
}

export const getBugSubmitList = (pagination, filters, sorter, extra, search, timeRange, status) => {
    const params = {};
    params["search"] = search;
    params["startTime"] = timeRange && timeRange[0];
    params["endTime"] = timeRange && timeRange[1];
    params["status"] = status;
    params["lang"] = sessionStorage.getItem('lang')
    if(extra == undefined){
        params["current"] = 1
        params["pageSize"] = 10
        params["sort"] = `-creatTime`
        store.dispatch(resChangePagination({
            current: 1,
            pageSize: 10,
            total:0,
            showSizeChanger:true,
            showQuickJumper:true,
        }))
    }else {
        if(extra.action == "paginate"){
            store.dispatch(resChangePagination(pagination))
        }else if(extra.action == "filter"){
            store.dispatch(resChangePagination(pagination))
            store.dispatch(resChangeFilters(filters))
        }else if(extra.action == "sort"){
            store.dispatch(resChangeSorts(sorter))
        }
        params["current"] = pagination.current
        params["pageSize"] = pagination.pageSize
        if(filters.status != null){
            params["statusConsts"] = filters.status
        }
        console.log(sorter.order)
        if(sorter.order!=undefined){
            if(sorter.order == "descend"){
                params["sort"] = `-${sorter.field}`
            }else if(sorter.order == "ascend") {
                params["sort"] = sorter.field
            }
        }else {
            store.dispatch(resChangeSorts({
                order: "descend",
                field: "creatTime"
            }))
            params["sort"] = `-creatTime`
        }
    }
    BugSubmitListService.getBugSubmitListData(params).then (res => {
        store.dispatch(resGetBugSubmitList(res.data.list));
        store.dispatch(resChangePagination({
            current: store.getState().BugSubmitList.pagination.current,
            pageSize: store.getState().BugSubmitList.pagination.pageSize,
            total:res.data.total,
            showSizeChanger:true,
            showQuickJumper:true,
        }))
    }).catch(error => {
        console.log(error)
    })
}
export const getPersonalLeak = () => {
    BugSubmitListService.getPersonalLeakData().then (res => {
        store.dispatch(resGetLeakInfo(res.data));
    }).catch(error => {
        console.log(error)
    })
}

export const getTotalMenu = (search, timeRange) => {
    return BugSubmitListService.getTotalMenu(search, timeRange);
}