import React, { useEffect, useState, useRef } from 'react';
import './style/publicProfile.css';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Popover, message, Input, Switch } from 'antd';
import { scrollToTop } from '../../common/topScorll';
import { PlusOutlined } from '@ant-design/icons';
import { goRouterPosition } from '../../action/routerposition/routerpositionAction';
import avatar from '../../assets/svg/icon_avatar_gray.svg';
import PublicProfileService from '../../services/publicProfileService';
import bugsIcon from '../../assets/svg/icon_bugs.svg';
import reputationLargeIcon from '../../assets/svg/icon_reputationLarge.svg';
import badgeIcon from '../../assets/svg/icon_badges.svg';
import rankingIcon from '../../assets/svg/icon_ranking.svg';
import facebookIcon from '../../assets/svg/icon_facebook.svg';
import twitterIcon from '../../assets/svg/icon_socialXLogo.svg';
import otherIcon from '../../assets/svg/icon_otherSocial.svg';
import unchosenIcon from '../../assets/svg/icon_circleUnchosen.svg';
import chosenIcon from '../../assets/svg/icon_circleChosen.svg';
import _ from 'lodash';
import publicProfileService from '../../services/publicProfileService';
import SwagshopService from '../../services/swagshopService';
export default (function (props) {
	const intl = useIntl();
	const history = useHistory();
	const [userId, setUserId] = useState();
	const [pageType, setPageType] = useState('own');
	const [isEdit, setIsEdit] = useState(false);
	const [personalInfo, setPersonalInfo] = useState();
	const [personalStatistics, setPersonalStatistics] = useState();
	const [medalData, setMedalData] = useState([]);
	const [saveLoading, setSaveLoading] = useState(false);
	const medalCardContent = (item) => {
		return (
			<div className='mdealCardDiv'>
				<div className='medalCardTitleDiv'>
					<img
						src={item.showMedalIconOss}
						className='medalCardTitleImg'
						alt='img'
					/>
					<div className='medalCardTitleText'>
						<div className='medalCardTitleNameDiv'>
							<div className='medalCardTitleName'>
								{item.safetyMedalI18n?.medalName}
							</div>
						</div>

						{item.safetyMedalI18n?.activityName && (
							<div
								className='medalCardTitleTag'
								onClick={() => window.open(item.safetyMedalI18n?.activityUrl)}
							>
								# {item.safetyMedalI18n?.activityName}
							</div>
						)}

						<div className='medalCardTitleUserCount'>
							{item.safetyMedalUserCount}
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.userCount',
							})}
						</div>
						{pageType === 'own' && (
							<>
								{!item.safetyMedalI18n?.activityName && (
									<div style={{ height: '20px' }}></div>
								)}
								{item.status !== 4 ? (
									<div className='medalCardTitleObtainStatus'>
										<label>
											{intl.formatMessage({
												id: 'intl.personalinfo.medalCard.unlockedTime',
											})}
											{item.cardCreateTime}
										</label>
										<label style={{ margin: '0 8px' }}>|</label>
										<label>
											{item.cardEndTime ? (
												<>
													{intl.formatMessage({
														id: 'intl.personalinfo.medalCard.expiredTime',
													})}
													{item.cardEndTime}
												</>
											) : (
												<>
													{intl.formatMessage({
														id: 'intl.personalinfo.medalCard.longTerm',
													})}
												</>
											)}
										</label>
									</div>
								) : (
									<div className='medalCardTitleObtainStatus'>
										{intl.formatMessage({
											id: 'intl.personalinfo.medalCard.locked',
										})}
									</div>
								)}
							</>
						)}
					</div>
				</div>
				<div className='medalCardIntro'>{item.safetyMedalI18n?.medalIntro}</div>
			</div>
		);
	};
	const init = () => {
		let tempData = { personalHome: false };
		let search = window.location.search.substring(1);
		let reg = new RegExp(`(^|&)userId=([^&]*)(&|$)`);
		let result = search.match(reg);
		if (result === null) {
			setPageType('own');
		} else {
			setPageType('other');
			tempData.userId = result[2];
		}
		PublicProfileService.getPersonalInfo(tempData)
			.then((res) => {
				setPersonalInfo(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		PublicProfileService.getStatistics(tempData)
			.then((res) => {
				setPersonalStatistics(res.data);
				if (JSON.stringify(res.data) === '{}') {
					setStatisticsShow(false);
					setAccuracyShow(false);
					setReputationShow(false);
					setBadgesShow(false);
					setHallOfFameShow(false);
				} else {
					setStatisticsShow(true);
					if (res.data.leakAccuracy === undefined) {
						setAccuracyShow(false);
					} else {
						setAccuracyShow(true);
					}
					if (res.data.totalHonorValue === undefined) {
						setReputationShow(false);
					} else {
						setReputationShow(true);
					}
					if (res.data.medalCount === undefined) {
						setBadgesShow(false);
					} else {
						setBadgesShow(true);
					}
					if (res.data.rank === undefined) {
						setHallOfFameShow(false);
					} else {
						setHallOfFameShow(true);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
		PublicProfileService.getAllMedals({
			edit: false,
			personalHome: false,
			...tempData,
		})
			.then((res) => {
				if (res.data.grantSafetyMedalList.length === 0) {
					setAchievementShow(false);
				} else {
					setAchievementShow(true);
				}
				setMedalData(res.data.grantSafetyMedalList);
			})
			.catch((error) => {
				console.log(error);
			});

		getSocialData(false);
    // getCreditsData(result[2]||undefined)
	};
	const [fbValue, setFbValue] = useState();
	const [twiValue, setTwiValue] = useState();
	const [allSocialData, setAllSocialData] = useState([]);
	const addSocialMedia = () => {
		let tempData = _.cloneDeep(allSocialData);
		tempData.push({
			name:
				sessionStorage.getItem('lang') === 'en_US'
					? 'Other Social Media Account'
					: '其他社交帐号',
			link: '',
			ifShow: true,
		});
		setAllSocialData(tempData);
	};
	const socialMediaValueChange = (e, id, type, flag) => {
		let tempData = _.cloneDeep(allSocialData);
		if (id === 'FaceBook' || id === 'Twitter') {
			let index = _.findIndex(tempData, ['name', id]);
			tempData[index].link = e.target.value;
		} else {
			if (flag) {
				let index = _.findIndex(tempData, ['id', id]);
				if (type === 'link') {
					tempData[index].link = e.target.value;
				} else if (type === 'name') {
					tempData[index].name = e.target.value;
				}
			} else {
				if (type === 'link') {
					tempData[id].link = e.target.value;
				} else if (type === 'name') {
					tempData[id].name = e.target.value;
				}
			}
		}

		setAllSocialData(tempData);
	};
	const deleteSocialMedia = (index) => {
		let tempData = _.cloneDeep(allSocialData);
		tempData.splice(index, 1);
		setAllSocialData(tempData);
	};
	const saveEditPublicProfile = async () => {
		let tempData = {};
		tempData.statisticsReputation = reputationShow;
		tempData.statisticsHofAll = hallOfFameShow;
		tempData.statisticsBadges = badgesShow;
		tempData.statisticsAccuracy = accuracyShow;
		if (!reputationShow && !hallOfFameShow && !badgesShow && !accuracyShow) {
			tempData.statisticsShow = false;
		} else {
			tempData.statisticsShow = statisticsShow;
		}
		let tempMedalList = [];
		let medalFlag = false;
		medalData.forEach((item) => {
			if (!medalFlag && item.display) {
				medalFlag = true;
			}
			tempMedalList.push({
				id: item.id,
				display: item.display,
			});
		});
		tempData.editAchievementList = tempMedalList;
		if (!medalFlag) {
			tempData.achievementShow = false;
		} else {
			tempData.achievementShow = achievementShow;
		}
		let socialFlag = false;
		allSocialData.forEach((item) => {
			if (!socialFlag && item.ifShow) {
				socialFlag = true;
			}
		});
		if (!socialFlag) {
			tempData.socialMediaShow = false;
		} else {
			tempData.socialMediaShow = socialMediaShow;
		}
		setSaveLoading(true);
		await publicProfileService.editPublicProfile(tempData).then((res) => {
			// console.log(res.data);
		});
		await publicProfileService
			.editSocialAccount(allSocialData)
			.then((res) => {
				if (res.code === 200) {
					message.success('Set successfully!');
				}
			})
			.finally(() => {
				setSaveLoading(false);
				if (!medalFlag) {
					tempData.achievementShow = false;
					setAchievementShow(false);
				}
			});
		setIsEdit(false);
		init();
	};
	const changeSocialDisplay = (type, value) => {
		let tempData = _.cloneDeep(allSocialData);
		if (type === 'fb') {
			let i = _.findIndex(tempData, { name: 'FaceBook' });
			tempData[i].ifShow = value;
			setFbValue(tempData[i]);
		} else if (type === 'twi') {
			let i = _.findIndex(tempData, { name: 'Twitter' });
			tempData[i].ifShow = value;
			setTwiValue(tempData[i]);
		} else {
			tempData[type].ifShow = value;
		}
		setAllSocialData(tempData);
	};
	const getSocialData = (type) => {
		let tempData = { personalHome: false };
		let search = window.location.search.substring(1);
		let reg = new RegExp(`(^|&)userId=([^&]*)(&|$)`);
		let result = search.match(reg);
		if (result === null) {
			setPageType('own');
		} else {
			setPageType('other');
			tempData.userId = result[2];
		}
		PublicProfileService.getSocialMedia({
			edit: type,
			...tempData,
		}).then((res) => {
			if (res.data?.accountList.length === 0) {
				setSocialMediaShow(false);
			} else {
				setSocialMediaShow(true);
			}
			setFbValue(_.find(res.data?.accountList, { name: 'FaceBook' }));
			setTwiValue(_.find(res.data?.accountList, { name: 'Twitter' }));
			setAllSocialData(res.data?.accountList);
		});
	};
	const changeToEdit = async () => {
		let tempData = {};
		if (userId) {
			setPageType('other');
			tempData.userId = userId;
		} else {
			setPageType('own');
		}
		getSocialData(true);
		await PublicProfileService.getStatistics({
			edit: true,
			personalHome: false,
			...tempData,
		})
			.then((res) => {
				setPersonalStatistics(res.data);
				setStatisticsShow(res.data.statisticsShow);
				setAccuracyShow(res.data.statisticsAccuracy);
				setReputationShow(res.data.statisticsReputation);
				setBadgesShow(res.data.statisticsBadges);
				setHallOfFameShow(res.data.statisticsHofAll);
			})
			.catch((error) => {
				console.log(error);
			});
		await PublicProfileService.getAllMedals({
			edit: true,
			personalHome: false,
			...tempData,
		})
			.then((res) => {
				setAchievementShow(res.data.achievementShow);
				setMedalData(res.data.grantSafetyMedalList);
			})
			.catch((error) => {
				console.log(error);
			});

		setIsEdit(true);
	};
	const [statisticsShow, setStatisticsShow] = useState(false);
	const [accuracyShow, setAccuracyShow] = useState(false);
	const [reputationShow, setReputationShow] = useState(false);
	const [badgesShow, setBadgesShow] = useState(false);
	const [hallOfFameShow, setHallOfFameShow] = useState(false);
	const [achievementShow, setAchievementShow] = useState(false);
	const [socialMediaShow, setSocialMediaShow] = useState(false);
  // const [creditsData , setCreditsData] = useState([]);
	const setMedalIsShow = (index, value) => {
		let tempData = _.cloneDeep(medalData);
		tempData[index].display = value;
		setMedalData(tempData);
	};
  // const getCreditsData=(id)=>{
  //   SwagshopService.rewardStatistics({ 
  //     search:undefined,
  //     createTimeStart:undefined,
  //     createTimeEnd:undefined,
  //     year:undefined,
  //     month:undefined,
  //     type:undefined,
  //     dataType:undefined,
  //     userId:id
  //   }).then(res=>{
  //     if(res.code==200){
  //       setCreditsData(res.data?.list[0])}
  //   })
  // }
	useEffect(() => {
		(sessionStorage.getItem('lang') === 'zh_CN' &&
			(document.title = '公共主页')) ||
			(sessionStorage.getItem('lang') === 'en_US' &&
				(document.title = 'Public Profile'));
		scrollToTop();
		// sessionStorage.setItem('redirect', window.location.pathname);
    sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		goRouterPosition({ routerPosition: 'publicProfile' });
		init();

		return () => {
			setFbValue();
			setTwiValue();
			setAllSocialData();
		};
	}, []);

	const scrollToPayout = () => {
		const target = document.getElementById('href');
		target.click();
		localStorage.setItem('payoutJump', false);
	};
	useEffect(() => {
		setTimeout(() => {
			if (localStorage.getItem('payoutJump') === 'true') {
				scrollToPayout();
			}
		}, 200);
	});
	return (
		<div className='publicProfileContainer'>
			<div className='PublicProfileHeaderDiv'>
				<div className='PublicProfileHeaderContent'>
					<img
						alt='avatar'
						className='PublicProfileHeaderAvatarImg'
						src={personalInfo?.pictureUrl ? personalInfo?.pictureUrl : avatar}
					/>
					<div className='PublicProfileHeaderLeftDetailName'>
						{personalInfo?.alienName}
						{personalInfo?.medalIconOss && (
							<Popover
								color='#f8f8f8'
								placement='right'
								content={medalCardContent(personalInfo?.grantSafetyMedal)}
								title={null}
                // mouseEnterDelay={0} mouseLeaveDelay={100}
							>
								<img
									src={personalInfo?.medalIconOss}
									className='personalInfoWearingMedal'
									alt='medal'
								/>
							</Popover>
						)}
					</div>

					<div className='PublicProfileHeaderRightText'>
						<label>{personalInfo?.rankYearStr}</label>
						<label style={{ marginLeft: '8px' }}>{personalInfo?.rankStr}</label>
					</div>
				</div>
			</div>
			<div className='PublicProfileContentDiv'>
				<div className='PublicProfileTitleDiv'>
					<div className='PublicProfileTitleText'>
						{intl.formatMessage({
							id: 'intl.publicProfile.title',
						})}
					</div>
					{pageType === 'own' && (
						<div className='PublicProfileTitleBtnDiv'>
							{isEdit ? (
								<>
									<Button
										onClick={() => {
											setIsEdit(false);
											init();
										}}
									>
										{intl.formatMessage({
											id: 'intl.publicProfile.title.button.cancel',
										})}
									</Button>
									<Button
										loading={saveLoading}
										type='primary'
										onClick={() => saveEditPublicProfile()}
										style={{ marginLeft: '16px' }}
									>
										{intl.formatMessage({
											id: 'intl.publicProfile.title.button.save',
										})}
									</Button>
								</>
							) : (
								<Button type='primary' onClick={() => changeToEdit()}>
									{intl.formatMessage({
										id: 'intl.publicProfile.title.button.edit',
									})}
								</Button>
							)}
						</div>
					)}
				</div>
				{(statisticsShow || isEdit) && (
					<>
						<div className='PublicProfileSubTitleDiv'>
							<div className='PublicProfileSubTitle'>
								{intl.formatMessage({
									id: 'intl.publicProfile.statistics.title',
								})}
							</div>

							{isEdit && (
								<div>
									{statisticsShow ? (
										<label className='editSwitchTitleText'>
											{intl.formatMessage({
												id: 'intl.publicProfile.editMode.switch.showText',
											})}
										</label>
									) : (
										<label className='editSwitchTitleText'>
											{intl.formatMessage({
												id: 'intl.publicProfile.editMode.switch.unshowText',
											})}
										</label>
									)}
									<Switch
										checked={statisticsShow}
										onChange={(e) => setStatisticsShow(e)}
									/>
								</div>
							)}
						</div>

						<div className='PublicProfileStatisticsDisplayDiv'>
							{(accuracyShow || isEdit) && (
								<div className='PublicProfileContentStatSubDiv'>
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={bugsIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#02AAF9' }}
										>
											{personalStatistics?.leakAccuracy ?? 'N/A'}
										</div>
									</div>
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.accuracyTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.accuracyContent',
										})}
									</div>
									{isEdit && (
										<div
											className='PublicProfileCheckImgDiv'
											onClick={() => setAccuracyShow(!accuracyShow)}
										>
											{accuracyShow ? (
												<img
													src={chosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											) : (
												<img
													src={unchosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											)}
										</div>
									)}
								</div>
							)}
							{(reputationShow || isEdit) && (
								<div className='PublicProfileContentStatSubDiv'>
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={reputationLargeIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#34C759' }}
										>
											{/* {creditsData?.userRewardItem?.baseHonor} */}
											{personalStatistics?.baseHonor||0}
										</div>
									</div>
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.reputationTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.reputationContent',
										})}
									</div>
									{isEdit && (
										<div
											className='PublicProfileCheckImgDiv'
											onClick={() => setReputationShow(!reputationShow)}
										>
											{reputationShow ? (
												<img
													src={chosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											) : (
												<img
													src={unchosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											)}
										</div>
									)}
								</div>
							)}
							{(badgesShow || isEdit) && (
								<div className='PublicProfileContentStatSubDiv'>
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={badgeIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#FAAD14' }}
										>
											{personalStatistics?.medalCount}
										</div>
									</div>
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.badgesTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.badgesContent',
										})}
									</div>
									{isEdit && (
										<div
											className='PublicProfileCheckImgDiv'
											onClick={() => setBadgesShow(!badgesShow)}
										>
											{badgesShow ? (
												<img
													src={chosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											) : (
												<img
													src={unchosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											)}
										</div>
									)}
								</div>
							)}
							{(hallOfFameShow || isEdit) && (
								<div className='PublicProfileContentStatSubDiv'>
									<div className='PersonalInfoContentStatTitle'>
										<img
											src={rankingIcon}
											className='PersonalInfoContentStatTitleImg'
											alt='bonusLargeIcon'
										/>
										<div
											className='PersonalInfoContentStatTitleText'
											style={{ color: '#FF2DAA' }}
										>
											{personalStatistics?.rank}
										</div>
									</div>
									<div className='PersonalInfoContentStatSubTitle'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.rankingTitle',
										})}
									</div>
									<div className='PersonalInfoContentStatText'>
										{intl.formatMessage({
											id: 'intl.personalinfo.content.statistics.rankingContent',
										})}
									</div>
									{isEdit && (
										<div
											className='PublicProfileCheckImgDiv'
											onClick={() => setHallOfFameShow(!hallOfFameShow)}
										>
											{hallOfFameShow ? (
												<img
													src={chosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											) : (
												<img
													src={unchosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											)}
										</div>
									)}
								</div>
							)}
						</div>
					</>
				)}

				{(achievementShow || isEdit) && (
					<>
						<a id='href' href='#achievement'></a>
						<div className='PublicProfileSubTitleDiv' id='achievement'>
							<div className='PublicProfileSubTitle'>
								{intl.formatMessage({
									id: 'intl.publicProfile.achievements.title',
								})}
							</div>
							{isEdit && (
								<div>
									{achievementShow ? (
										<label className='editSwitchTitleText'>
											{intl.formatMessage({
												id: 'intl.publicProfile.editMode.switch.showText',
											})}
										</label>
									) : (
										<label className='editSwitchTitleText'>
											{intl.formatMessage({
												id: 'intl.publicProfile.editMode.switch.unshowText',
											})}
										</label>
									)}
									<Switch
										checked={achievementShow}
										onChange={(e) => setAchievementShow(e)}
									/>
								</div>
							)}
						</div>
						<div className='PublicProfileAchievementMedalDisplayDiv'>
							{medalData?.length > 0 &&
								medalData.map((item, index) =>
									isEdit ? (
										<div key={index} className='PublicProfileMedalDiv'>
											<img
												src={item.medalIconOss}
												className='PublicContentAchievementsMedalImg'
												alt='medalImg'
												onClick={() => {
													if (isEdit) {
														setMedalIsShow(index, !item.display);
													}
												}}
											/>
											{item.status === 2 && (
												<div className='achievementMedalFlag achievementMedalFlagLeft'>
													{intl.formatMessage({
														id: 'intl.personalinfo.medalCard.wearingTag',
													})}
												</div>
											)}
											<div
												className='PublicContentAchievementsMedalTitle'
												title={item.safetyMedalI18n?.medalName}
											>
												{item.safetyMedalI18n?.medalName}
											</div>
                      {item.label && (
													<div className='PublicContentAchievementsMedalLabel'>
														{item.label}
													</div>
												)}
											<div className='PublicContentAchievementsMedalGetTime'>
												{item.createTime}
											</div>
											{isEdit && (
												<div
													className='PublicProfileCheckImgDiv'
													style={{
														top: '16px',
														right: '16px',
													}}
												>
													{item.display ? (
														<img
															src={chosenIcon}
															className='cardIsChosenImg'
															alt='img'
														/>
													) : (
														<img
															src={unchosenIcon}
															className='cardIsChosenImg'
															alt='img'
														/>
													)}
												</div>
											)}
										</div>
									) : (
										<Popover
											color='#f8f8f8'
											placement='right'
											content={medalCardContent(item)}
                      // trigger="hover" mouseEnterDelay={0.1} mouseLeaveDelay={100}
											title={null}>
											<div key={index} className='PublicProfileMedalDiv'>
												<img
													src={item.medalIconOss}
													className='PublicContentAchievementsMedalImg'
													alt='medalImg'
												/>
												{item.status === 2 && (
													<div className='achievementMedalFlag achievementMedalFlagLeft'>
														{intl.formatMessage({
															id: 'intl.personalinfo.medalCard.wearingTag',
														})}
													</div>
												)}
												<div className='PublicContentAchievementsMedalTitle'>
													{item.safetyMedalI18n?.medalName}
												</div>
												{item.label && (
													<div className='PublicContentAchievementsMedalLabel'>
														{item.label}
													</div>
												)}
												<div className='PublicContentAchievementsMedalGetTime'>
													{item.createTime}
												</div>
												{isEdit && (
													<div
														className='PublicProfileCheckImgDiv'
														style={{
															top: '16px',
															right: '16px',
														}}
														onClick={() => setMedalIsShow(index, !item.display)}
													>
														{item.display ? (
															<img
																src={chosenIcon}
																className='cardIsChosenImg'
																alt='img'
															/>
														) : (
															<img
																src={unchosenIcon}
																className='cardIsChosenImg'
																alt='img'
															/>
														)}
													</div>
												)}
											</div>
										</Popover>
									)
								)}
						</div>
					</>
				)}

				{(socialMediaShow || isEdit) && (
					<>
						<div className='PublicProfileSubTitleDiv'>
							<div className='PublicProfileSubTitle'>
								{intl.formatMessage({
									id: 'intl.publicProfile.socialAccount.title',
								})}
							</div>
							{isEdit && (
								<div>
									{socialMediaShow ? (
										<label className='editSwitchTitleText'>
											{intl.formatMessage({
												id: 'intl.publicProfile.editMode.switch.showText',
											})}
										</label>
									) : (
										<label className='editSwitchTitleText'>
											{intl.formatMessage({
												id: 'intl.publicProfile.editMode.switch.unshowText',
											})}
										</label>
									)}
									<Switch
										checked={socialMediaShow}
										onChange={(e) => setSocialMediaShow(e)}
									/>
								</div>
							)}
						</div>
						{isEdit ? (
							<>
								<div className='settingSocialMediaDiv'>
									<img
										src={facebookIcon}
										className='socialMediaIconImg'
										alt='img'
									/>
									<div>
										<div className='settingSocialMediaName'>Facebook</div>
										<Input
											style={{ width: '1000px' }}
											defaultValue={fbValue?.link}
											placeholder={intl.formatMessage({
												id: 'intl.personalinfo.settings.socialMedia.notSet',
											})}
											onChange={(e) =>
												socialMediaValueChange(e, 'FaceBook', 'link')
											}
										/>
									</div>
									{isEdit && (
										<div
											className='PublicProfileCheckImgDiv'
											style={{
												top: '16px',
												right: '16px',
											}}
											onClick={() =>
												changeSocialDisplay('fb', !fbValue?.ifShow)
											}
										>
											{fbValue?.ifShow ? (
												<img
													src={chosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											) : (
												<img
													src={unchosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											)}
										</div>
									)}
								</div>
								<div className='settingSocialMediaDiv'>
									<img
										src={twitterIcon}
										className='socialMediaIconImg'
										alt='img'
									/>
									<div>
										<div className='settingSocialMediaName'>Twitter</div>
										<Input
											style={{ width: '1000px' }}
											defaultValue={twiValue?.link}
											placeholder={intl.formatMessage({
												id: 'intl.personalinfo.settings.socialMedia.notSet',
											})}
											onChange={(e) =>
												socialMediaValueChange(e, 'Twitter', 'link')
											}
										/>
									</div>
									{isEdit && (
										<div
											className='PublicProfileCheckImgDiv'
											style={{
												top: '16px',
												right: '16px',
											}}
											onClick={() =>
												changeSocialDisplay('twi', !twiValue.ifShow)
											}
										>
											{twiValue?.ifShow ? (
												<img
													src={chosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											) : (
												<img
													src={unchosenIcon}
													className='cardIsChosenImg'
													alt='img'
												/>
											)}
										</div>
									)}
								</div>
								{allSocialData?.length > 2 &&
									allSocialData.map((item, index) => {
										return (
											item.name !== 'FaceBook' &&
											item.name !== 'Twitter' && (
												<div className='settingSocialMediaDiv' key={index}>
													<img
														src={otherIcon}
														className='socialMediaIconImg'
														alt='img'
													/>
													<div>
														<div className='settingSocialMediaName'>
															<Input
																style={{
																	width: '1000px',
																}}
																defaultValue={item.name}
																value={item.name}
																placeholder={intl.formatMessage({
																	id: 'intl.personalinfo.settings.socialMedia.notSet',
																})}
																onChange={(e) =>
																	socialMediaValueChange(
																		e,
																		item.id ? item.id : index,
																		'name',
																		item.id ? true : false
																	)
																}
															/>
														</div>
														<Input
															style={{
																width: '1000px',
															}}
															defaultValue={item?.link}
															value={item?.link}
															placeholder={intl.formatMessage({
																id: 'intl.personalinfo.settings.socialMedia.notSet',
															})}
															onChange={(e) =>
																socialMediaValueChange(
																	e,
																	item.id ? item.id : index,
																	'link',
																	item.id ? true : false
																)
															}
														/>
													</div>
													<div
														className='socialMediaDeleteBtn'
														onClick={() => deleteSocialMedia(index)}
													>
														{intl.formatMessage({
															id: 'intl.personalinfo.settings.socialMedia.deleteBtn',
														})}
													</div>

													{isEdit && (
														<div
															className='PublicProfileCheckImgDiv'
															style={{
																top: '16px',
																right: '16px',
															}}
															onClick={() =>
																changeSocialDisplay(index, !item.ifShow)
															}
														>
															{item?.ifShow ? (
																<img
																	src={chosenIcon}
																	className='cardIsChosenImg'
																	alt='img'
																/>
															) : (
																<img
																	src={unchosenIcon}
																	className='cardIsChosenImg'
																	alt='img'
																/>
															)}
														</div>
													)}
												</div>
											)
										);
									})}
								{allSocialData?.length < 5 && (
									<div
										className='socialAddDiv'
										onClick={() => addSocialMedia()}
									>
										<PlusOutlined />

										<div>
											{intl.formatMessage({
												id: 'intl.personalinfo.settings.socialMedia.addAccount',
											})}
										</div>
									</div>
								)}
							</>
						) : (
							<>
								{fbValue && (
									<div className='settingSocialMediaDiv'>
										<img
											src={facebookIcon}
											className='socialMediaIconImg'
											alt='img'
										/>
										<div>
											<div className='settingSocialMediaName'>Facebook</div>
											<div className='settingSocialMediaDesc'>
												{fbValue?.link ? (
													fbValue?.link
												) : (
													<>
														{intl.formatMessage({
															id: 'intl.personalinfo.type.loginsecurity.notset',
														})}
													</>
												)}
											</div>
										</div>
									</div>
								)}
								{twiValue && (
									<div className='settingSocialMediaDiv'>
										<img
											src={twitterIcon}
											className='socialMediaIconImg'
											alt='img'
										/>
										<div>
											<div className='settingSocialMediaName'>Twitter</div>
											<div className='settingSocialMediaDesc'>
												{twiValue?.link ? (
													twiValue?.link
												) : (
													<>
														{intl.formatMessage({
															id: 'intl.personalinfo.type.loginsecurity.notset',
														})}
													</>
												)}
											</div>
										</div>
									</div>
								)}

								{allSocialData?.map((item, index) => {
									return (
										item.name !== 'FaceBook' &&
										item.name !== 'Twitter' &&
										item.ifShow && (
											<div className='settingSocialMediaDiv' key={index}>
												<img
													src={otherIcon}
													className='socialMediaIconImg'
													alt='img'
												/>
												<div>
													<div className='settingSocialMediaName'>
														{item.name}
													</div>
													<div className='settingSocialMediaDesc'>
														{item?.link ? (
															item?.link
														) : (
															<>
																{intl.formatMessage({
																	id: 'intl.personalinfo.type.loginsecurity.notset',
																})}
															</>
														)}
													</div>
												</div>
											</div>
										)
									);
								})}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
});
