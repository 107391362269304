/**
 * 提交漏洞列表界面action
 * @return
 */
import { RES_GETVULNERABILITYINFORMATION,RES_GETREVIEWCONCLUSION } from '../../constants/bugSubmitDetailTypes';
import store from '../../store/index'
import sanitizeHtml from 'sanitize-html';
import BugSubmitDetailService from '../../services/bugSubmitDetailService';
 
 /**
  * 提交漏洞列表
  * @return
  */
const resGetVulnerabilityInformation = (res) => {
    return {
        type: RES_GETVULNERABILITYINFORMATION,
        res
    }
}
const resGetReviewConclusion = (res) => {
    return {
        type: RES_GETREVIEWCONCLUSION,
        res
    }
}

export const getVulnerabilityInformation = (id) => {
    BugSubmitDetailService.getVulnerabilityInformationData({id:id}).then (res => {
        const temp = res.data
        // temp.content = sanitizeHtml(temp.content)
        temp.content = temp.content
        store.dispatch(resGetVulnerabilityInformation(temp))
    }).catch(error => {
        console.log(error)
    })
}
export const getReviewConclusion = (id) => {
    BugSubmitDetailService.getReviewConclusionData({id:id}).then (res => {
        store.dispatch(resGetReviewConclusion([]))
    }).catch(error => {
        store.dispatch(resGetReviewConclusion({
            Conclusion: {
                Rating: "Serious",
                Reputation: "999",
                Payouts: "999"
            },
            SaferFedback: "This is a long detailed description.This is a long detailed description.This is a long detailed description.This is a long detailed description.This is a long detailed description.This is a long detailed description.This is a long detailed description.",
            Reviewtime: "2021/4/27 15:08"
        }))
        console.log(error)
    })
}

export const submitComments = (params) => {
    return BugSubmitDetailService.submitComments(params)
}

export const getCaptcha = () => {
    return BugSubmitDetailService.getCaptcha();
} 
//下载文件

export const download = (params) => {
      return BugSubmitDetailService.download(params)
} 
export const downloadReview = (params) => {
      return BugSubmitDetailService.downloadReview(params)
} 