import React from 'react';
import '../style/agreement.css';
export default (function (props) {
    return (
        <div className="AgreementDiv">
            <h2 className="Agreementh2">TECNO安全响应中心平台用户协议</h2>
            <p className="Agreementp">本协议版本最后更新于：2022年9月</p>
            <p className="Agreementp">首部及导言</p>
            <p className="Agreementp">欢迎您使用TECNO 安全响应中心平台！</p>
            <p className="Agreementp">TECNO 安全响应中心用户协议（以下简称“本协议”）系由您与上海传英信息技术有限公司（以下简称“TECNO 安全响应中心”、“我们”或“我们的”））就TECNO 安全响应中心的使用许可和运营管理服务而订立的法律协议，我们在此特别提醒您审慎阅读、充分理解本协议各项条款，特别是免除与限制责任条款、隐私保护、使用规则、法律适用和争议解决条款，以及其他可能以加粗或以大写等显著形式提示您注意的重要条款。您必须达到您所在地区适用法律规定的法定成年年龄才能访问和签署本协议。如果您未达到法定的成年年龄，请您在您的法定监护人陪同下阅读并充分理解本协议，并在明确征得法定监护人的同意后使用TECNO 安全响应中心平台及相关服务。</p>
            <p className="Agreementp">您通过网络页面/页面弹窗自主选择点击同意和/或实际使用TECNO 安全响应中心软件和服务的行为，视为您已阅读、理解并同意接受本协议的约束。如果您不同意本协议，请不要使用任何TECNO 安全响应中心平台。</p>
            <p className="Agreementp">如果您对本协议有任何的疑问和建议，请通过本协议所附联系⽅式与我们沟通并进行反馈，我们非常愿意为您进行解答。</p>
            <p className="Agreementp">联系方式：【security.tecno@tecno-mobile.com】</p>
            <h3 className="Agreementh4">一、关于本协议的基本概述</h3>
            <p className="Agreementp">1.1 关于本服务：是指我们通过TECNO 安全响应中心平台为您提供账号服务、提交产品安全漏洞服务。</p>
            <p className="Agreementp">1.2 适用范围：本协议适用于TECNO 安全响应中心平台提供的所有产品和服务，包括适用于电脑、移动智能终端的应用程序、小程序、网页、供第三方网站和应用程序使用的软件开发工具包（SDK）和应用程序编程接口（API）或其他未来可能出现的形式，并请以TECNO 安全响应中心平台提供的具体服务为准。</p>
            <p className="Agreementp">1.3 保护未成年人：如果您未满18周岁（或您未达您使用TECNO 安全响应中心平台所在法域中的法定成年年龄），请您在您父母或法定监护人的陪同下仔细阅读并充分理解本协议。您同意和实际使用TECNO 安全响应中心平台和服务的行为，则表示您已与父母或法定监护人一起阅读了本协议，且您和您的父母或监护人理解并同意本协议。如果您是允许未成年人使用TECNO 安全响应中心的父母或监护人，则您同意：（i）监督未成年人对TECNO 安全响应中心的使用； （ii）承担未成年人使用TECNO 安全响应中心相关的所有风险，包括但不限于通过互联网与第三方之间进行内容传输的风险； （iii）承担由于未成年人使用TECNO 安全响应中心而引起的任何责任； （iv）确保未成年人提交的所有信息的准确性和真实性； （v）承担本协议项下未成年人使用TECNO 安全响应中心的责任，并受本协议约束。</p>
            <p className="Agreementp">1.4 协议更新：为了改善您的使用体验或提升产品安全性能，我们将不断努力开发新的软件及服务，并及时为您提供软件的更新（这些更新包括但不限于软件版本升级、修改、功能完善等形式）或修复，我们有权遵照适用的法律法规，在必要时修改本协议，更新后的协议条款将代替原来的协议，并在适用法律规定的期限届满后正式生效，如果适用法律没有特别规定的，则本协议自更新之日起生效。更新后的协议条款会在TECNO 安全响应中心软件及服务上公布，您可以随时查看。本协议更新后，如果您继续使用TECNO 安全响应中心软件及服务的，即视为您已接受更新后的协议，如果您不接受更新后的协议，则您有权停止使用TECNO 安全响应中心产品及服务。</p>
            <h3 className="Agreementh4">二、关于TECNO 安全响应中心服务</h3>
            <p className="Agreementp">2.1 TECNO 安全响应中心提供的主要服务包括但不限于：</p>
            <p className="Agreementp1">（1）账号注册、登录服务；</p>
            <p className="Agreementp1">（2）申请测试样机，提交产品安全漏洞；</p>
            <p className="Agreementp1">（3）根据平台规则获取安全漏洞奖金；</p>
            <p className="Agreementp">2.2 您理解并同意，在您使用本软件及相关服务过程中，我们可能会向您推送具有相关性的信息、⼴告发布或品牌推⼴服务，且我们将在本软件及相关服务中展⽰本软件及相关服务和/或第三⽅供应商、合作伙伴的商业⼴告、推⼴或信息（包括商业或⾮商业信息）。如您不愿意接收具有个性化关联的⼴告，您有权选择关闭该个性化⼴告信息，但您仍有可能在我们的产品中收到⾮个性化的⼴告信息。如果您是通过电⼦邮件或短信等⽅式收到⼴告的，您有权提出退订。 </p>
            <p className="Agreementp">2.3 您理解并同意，我们可基于自身业务发展或基于司法、监管部门、监督机构等部门的要求，暂停、中断或停止本软件及本服务的提供。</p>
            <p className="Agreementp">2.4 除非适用的法律允许且获得我们的书面授权，您不得使用或者以其他方式出口或转出口本软件及本服务。</p>

            <h3 className="Agreementh4">三、本软件及本服务的使用限制</h3>
            <p className="Agreementp">3.1 您在使用本软件及本服务时不得实施下列行为，包括但不限于：</p>
            <p className="Agreementp1">（1）发布、传送、传播、储存违反法律法规、政策、社会公序良俗、社会公德的内容，如侮辱、诽谤、暴力、违犯宗教等；发布、传送、传播、储存虚假信息、广告信息等误导、欺骗、影响他人；</p>
            <p className="Agreementp1">（2）出租、出借、复制、修改、链接、转载、汇编、发表、出版本软件以及类似行为；</p>
            <p className="Agreementp1">（3）对本软件进行越狱、反向工程、反向汇编、反向编译，或者以其他方式尝试破坏本软件的行为；或使用插件、外挂或非经我们授权的第三方工具/服务接入或干扰本软件的行为；</p>
            <p className="Agreementp1">（4）对本软件及本服务或者本软件运行过程中释放到任何设备内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，或通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
            <p className="Agreementp1">（5）从事其他违反法律法规、政策及公序良俗、社会公德或未经我们书面许可的行为。</p>
            <p className="Agreementp">3.2 您不得且您同意不会或促使他⼈复制(本许可明示允许的除外)、反汇编、倒序制造、拆装、企图导出其源代码、解码、修改TECNO 安全响应中心软件、TECNO 安全响应中心软件提供的任何服务或其任何部分（适⽤法律或可能包含在TECNO 安全响应中心软件内的开放源代码组件使⽤许可证条款禁⽌除外）或制造其它衍生作品。您同意不删除、遮蔽或更改可能附加于或包含在TECNO 安全响应中心软件中的任何专有权通知（包括但不限于商标和版权通知）。</p>
            <h3 className="Agreementh4">四、知识产权及所有权</h3>
            <p className="Agreementp">我们享有本平台及其相关的所有信息内容（包括但不限于UI界面设计、图片、字体、音频等）的知识产权、所有权及所有未明示授予给您的权利，包括但不限于著作权、商标权、专利权，并且受到可适用的知识产权、反不正当竞争及其他方面的法律法规、以及相关的国际条约保护。未经本协议明确授权的事项如商业性销售、复制、许可任何第三方使用本软件的，您应另行取得我们的书面许可。</p>          
            <h3 className="Agreementh4">五、保密条款</h3>
            <p className="Agreementp">5.1 您知悉并同意，未经TECNO 安全响应中心平台同意，您不得对外披露、或为实现其他目的使用TECNO 安全响应中心平台以任何方式对您披露的信息（简称“保密信息”）。</p>
            <p className="Agreementp">5.2 我们将在您提交漏洞后与您沟通相关漏洞处理事宜。除法律另有规定外，为保证产品终端用户的安全，您承诺在安全漏洞完全修复前，不得对外提供、披露漏洞细节。如在特殊情况下，出于用户安全、社会利益的考量，需要对外提供、披露漏洞细节的，应由TECNO 安全响应中心平台决定是否披露以及披露的方式。</p>
            <p className="Agreementp">5.3 在漏洞修复后，如您因个人需要公开安全漏洞的，应由TECNO 安全响应中心平台对公开的内容进行审核。</p>
            <p className="Agreementp">5.4 您知悉并同意，您将善意的、负责的提交漏洞报告，不得利用漏洞报告获取漏洞奖励、平台英雄榜积分以外的奖励；</p>
            <p className="Agreementp">5.5 如您在测试安全漏洞过程中获取、访问产品终端用户的数据的，您不得以任何方式修改、删除、转移或对外提供此类数据或未经授权收集、处理此类数据；</p>
            <p className="Agreementp">5.6 您知悉并同意，您将承担无期限保密义务，直至TECNO 安全响应中心平台宣布解密或保密信息已实际公开；</p>
            <p className="Agreementp">5.7 您知悉并同意，TECNO 安全响应中心平台用户中心有权要求您的删除、销毁或归还保密信息，并有权同时要求您提供其已删除、销毁相关保密信息的证明或书面承诺。</p>
            <p className="Agreementp">5.8 您知悉并同意，在本协议第五条保密条款中所约定的权利和义务持续有效，除另有约定外，不因任何原因（包括但不限于协议终止、解除等）终止或解除。</p>
            <p className="Agreementp">5.9 您知悉并同意，如您违反本协议保密义务，应赔偿TECNO 安全响应中心平台的经济损失。</p>
            <h3 className="Agreementh4">六、您使用本软件及本服务的注意事项</h3>
            <p className="Agreementp">6.1 您充分理解并同意：您在使用本软件某一特定服务时可能需要获得您设备的相关权限、接口等以实现相应的功能，某些特定服务可能还需同意单独的用户协议等，您在使用该项服务前请仔细阅读前述相关协议、规则；</p>
            <p className="Agreementp">6.2 我们有权在本服务提供过程中向您展现各种信息，包括但不限于获得授权的提示、展示广告信息及宣传信息等，该信息可能以系统消息或弹出窗口等形式出现。若您不同意接收的，您可以取消相关服务，最终可能会导致相关服务功能无法实现；</p>
            <p className="Agreementp">6.3 您理解并同意：我们将会尽合理商业努力保障您在使用本服务的过程中数据存储安全，但是，我们并不能就此提供完全保证，包括但不限于以下情形：</p>
            <p className="Agreementp1">（1）在法律允许的最大范围内，我们不对您在本软件及服务中相关数据的删除或储存失败负责；</p>
            <p className="Agreementp1">（2）我们将根据业务开展所需信息的最短存储期限要求来进行存储，除非您同意延长保留期或受到法律的允许，同时我也有权自行决定您在本软件中数据的最大存储空间等，您可根据自己的需要自行备份本软件及服务中的相关数据。</p>
            <p className="Agreementp">6.4 您使用本服务时，具体显示的内容可能会因您使用软件版本的不同而有差别，您理解并同意：当您在使用本软件的某些版本或进入我们开发的应用程序相关页面时，为向您提供更好的服务体验，可能会调整您终端设备上已经安装应用程序的安装位置、大小、UI界面等必要信息，具体以我们实际提供的版本为准。</p>
            <p className="Agreementp">6.5 您充分了解并同意，如在使用本软件需要注册账号时，您有责任妥善保管注册账户信息及账户密码的安全，您对您注册账号下发生的行为负责，包括您所发表的任何内容以及由此产生的任何后果。</p>
            <p className="Agreementp">6.6 您应对使用本服务时接触到的内容自行加以判断，并承担因使用内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。我们无法且不会对您因前述风险而导致的任何损失或损害承担责任。    </p>
            <p className="Agreementp">6.7 如果我们发现或收到他人举报您有违反本协议约定的，我们有权不经通知随时：对相关的内容进行删除、屏蔽；暂停、终止您使用相应账号或全部服务；追究法律责任等措施。若因您违反约定，导致任何第三方损害的，您应自行承担责任；我们因此遭受损失（包括但不限于可能遭受的行政处罚、律师费、调查取证费）的，您也应当一并赔偿。</p>
            <h3 className="Agreementh4">七、第三方提供的服务</h3>
            <p className="Agreementp">7.1 为便于您使用TECNO安全响应中心软件及服务备，本软件或本服务可能会使用第三方服务，使用或访问的结果由该第三方提供（包括但不限于您通过本软件或本服务跳转到的第三方提供的服务及内容，第三方通过TECNO安全响应中心开放平台接入的服务及内容等）。您在使用本软件中第三方提供的服务时，除遵守本协议约定外，还应遵守第三方的用户协议，我们无法保证第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，在适用法律允许的最大范围内，您应自行承担相关风险和责任。</p>
            <p className="Agreementp">7.2 不论第三方服务是预置于TECNO安全响应中心平台中，还是由您自行开通或订购，您均理解并同意，我们无法对第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险提供任何明示或默示的保证。</p>
            <p className="Agreementp">7.3 您与上述提供软件及服务的第三方发生纠纷的，应由您与该第三方自行解决，相关责任由您自行承担。</p>
            <h3 className="Agreementh4">八、隐私政策</h3>
            <p className="Agreementp">当您使用TECNO安全响应中心软件和服务时，我们可能需要收集个人数据才能为您提供服务。我们非常重视保护您的个人数据，并制定了TECNO安全响应中心隐私政策（具体可参见《隐私政策》），其中阐明了TECNO 安全响应中心收集、使用、存储、转移和披露您的个人数据方式的详细信息。您也可以在[TECNO 安全响应中心 &gt;官网底部 &gt;隐私政策]中查阅“隐私政策”的详细信息，隐私政策构成本协议的一部分。您下载、安装或使用该服务表明您同意受本协议的条款及其隐私政策的约束，并且我们将根据本隐私政策保护您的隐私。</p>
            <h3 className="Agreementh4">九、协议终止及违约责任</h3>
            <p className="Agreementp">我们有权判断您的行为是否符合本协议条款规定，如果您被认定为违反有关法律法规或者本协议、相关规则的任何条款和规定，根据您违规情形的严重程度，TECNO安全响应中心及其许可人有权在不通知您的情况下对您采取删除违规信息、限制、中止、终止用户使用本软件和服务等措施。同时，您将为您的不当行为承担责任。本协议终止后，我们将会根据适用法律法规的要求，继续保存您留存于TECNO安全响应中心软件的各类信息，并在合理期限内从我们的数据库中删除您在本软件使用过程中产生的信息。您同意，在适用法律允许的最大范围内，我们无需就此删除行为向您承担任何责任。</p>
            <h3 className="Agreementh4">十、赔偿</h3>
            <p className="Agreementp">因您以下行为引起的任何及所有第三方索赔、诉讼、损失、损害、责任、判决、费用和支出（包括合理的律师费），您同意向我们、我们的许可方、我们的代理人以及所有高级职员、董事、员工进行赔偿、为其抗辩并使其免受损失：(i) 您或您允许使用服务的任何人在使用服务时未遵守本协议的规定，(ii) 您或您允许使用服务的任何人违反本协议的规定，或 (iii) 您或您允许使用服务的任何人违反法律、法规或侵犯任何第三方的权利。</p>
            <h3 className="Agreementh4">十一、免责声明</h3>            
            <p className="Agreementp"><strong>11.1 在适用的法律允许的最大范围内，我们的软件及服务按“现状”和“可提供”的基础上提供，并在此特别声明，我们不做任何（包括明示的、默示的或法定的）的保证或其他承诺，包括（但不限于）对与本软件及服务有关的适销性、适用于某特定用途、响应的准确性或完整性、结果 、技艺精湛的成果、无病毒和无疏忽的默示保证、责任或条件（如果有的话）。</strong></p>
            <p className="Agreementp"><strong>11.2 本软件不含有任何旨在破坏您设备数据和获取您隐私信息的恶意代码，我们也会尽最大努力确保本软件及本服务安全、及时、有效、准确、可靠及持续性运营，但受限于现有技术，本软件及相关服务可能受到各种不稳定因素的影响或干扰，我们不能保证与所有的软硬件系统完全兼容，不能保证本软件完全没有错误或不受任何干扰，也不能保证本软件及相关服务中任何错误都将能得到更正。如果出现不兼容及软件错误等相关情况，请您联系我们，以获得技术⽀持。如果⽆法解决兼容性问题，您可以删除本软件。由此给您造成损失的，我们在适用法律允许的最大范围内免于承担责任。</strong></p>
            <h3 className="Agreementh4">十二、责任限制</h3>        
            <p className="Agreementp"><strong>在适用法律不禁止的范围内,在任何情况下,对于因下述原因造成的任何间接的、附带的、特别的、后果性的或惩罚性的损害赔偿,包括但不限于有关利润损失、商誉损害、使用损失、数据丢失的损失、业务中断的损失或任何其他无形损失的损害赔偿，无论其成因及基于何种责任理论(合同、侵权或其它), 我们及其关联公司、高级管理人员、董事、员工、代理人不对您承担任何责任（即使我们已知悉可能发生上述损害赔偿的话）。该原因包括：（1）您使用或无法使用本服务；（2）您传输的内容或资料未经授权而被访问或被更改；（3）您通过使用本服务传输的内容或数据被删除、被损坏或无法进行使用；（4）任何第三方在本服务中所作的行为；以及（5）与本服务相关的任何其他事宜。有些国家地区不允许对人身伤害,或附带或后果性损害赔偿排除或附加责任进行限制，则此限制可能并不适用于您。</strong></p>
            
            
            <h3 className="Agreementh4">十三、管辖法律及争议解决</h3>      
            <p className="Agreementp">13.1 管辖法律：本协议的确认、履行、解释、修改和争议解决应受中华人民共和国香港特别行政区法律的管辖，不适用其法律冲突条款。您同意本协议签署于香港。</p>
            <p className="Agreementp">13.2 仲裁：若您和我们之间基于本协议发生任何纠纷或争议，首先应友好协商解决；友好协商不成的，您无条件同意将任何由我们主体、其董事、代理人及雇员产生、与其有关及关联的纠纷或争议（无论是合同、侵权或其他纠纷及争议）提交给香港国际仲裁中心（HKIAC），并按照HKIAC的仲裁规则进行仲裁。仲裁由一（1）名仲裁员进行裁决。仲裁应在中国香港进行，仲裁程序以及所有诉状和书面证据均应使用英文，仲裁员做出的裁决可以在任何具有管辖权的法院执行。本协议项下的任何仲裁均将基于个人进行，您无权基于集体或代表身份提起任何索赔仲裁，并且您将不会对我们提起或参与任何集体诉讼。</p>

            <h3 className="Agreementh4">十四、一般性条款</h3>      
            <p className="Agreementp">14.1 标题：本协议所有条款的标题仅供参考使用，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
            <p className="Agreementp">14.2 可分割性：本协议条款或规定无论因何种原因被任何司法或行政机构认定为部分无效或不可执行，此认定将不影响本协议其余条款和规定，本协议其余条款和规定仍有效且应根据其条款进行解释。</p>
            <p className="Agreementp">14.3 语言：若本协议有英文、阿拉伯文等多个语言版本，相应内容不一致的，均以英文版内容为准。</p>
            <p className="Agreementp">14.4 不弃权条款： 在任何时候，我们未能执行TECNO安全响应中心服务的任何规定，或我们未能要求您履行TECNO安全响应中心服务相关的任何规定，绝不应被解释为是当前或将来对此类规定的放弃，也不会以任何方式影响我们之后执行此类规定的权利。 我们对TECNO安全响应中心服务的任何规定、条件或要求的明确放弃并不构成对将来履行该规定、条件或要求的任何义务的放弃。</p>
            <p className="Agreementp">14.5 完整协议：本协议构成您与我们之间关于软件使用的全部协议，并取代您与TECNO安全响应中心之间有关软件使用的任何先前协议。当您使用或购买开源软件、第三方内容或其他服务时，您可能还会受到其他条款和条件的约束。</p>
        </div>
    );
})