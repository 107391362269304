import React, { useEffect, useState,useRef} from 'react';
import './style/swagshop.css';
import { useIntl } from 'react-intl';
import {Pagination,Button,Select,Checkbox ,Slider} from 'antd';
import {goRouterPosition} from '../../action/routerposition/routerpositionAction';
import icNoData from '../../assets/svg/ic-空.svg';
import noDataImg from '../../assets/svg/空状态.svg';
import {DownOutlined,CloseCircleFilled} from '@ant-design/icons';
import searchIcon from '../../assets/svg/ic-Search.svg';
import SwagshopService from '../../services/swagshopService';
import {detailLink} from '../../common/detailLink';
export default (function (props) {
	const intl = useIntl();
	const [currentPagination, setCurrentPagination] = useState(1);
	const [pageSizePagination, setPageSizePagination] = useState(12);
	const [totalPagination, setTotalPagination] = useState(0);
	const [swagshopList, setSwagshopList] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [hasFirstEffectRun, setHasFirstEffectRun] = useState(false);
  const [options, setOptions] = useState([
    { value:'sort', label: intl.formatMessage({id: 'intl.swagshop.filterBox.default'})},
    { value:'-sales', label: intl.formatMessage({id: 'intl.swagshop.filterBox.popular'})},
    { value:'-shelf_time', label: intl.formatMessage({id: 'intl.swagshop.filterBox.newIn'})},
    { value:'credit', label: intl.formatMessage({id: 'intl.swagshop.filterBox.priceHigh'})},
    { value:'-credit', label: intl.formatMessage({id: 'intl.swagshop.filterBox.priceLow'})}
    // { value:'sort', label: intl.formatMessage({id: 'intl.swagshop.filterBox.sortedBy'})},

  ]);
  const creditsRangDivRef = useRef(null);
  const [searchParams,setSearchParams] = useState({
    search:undefined,
    userCredit:undefined,
    order:'credit',
    credit:undefined,
    page: 1,
    page_size: 10
    
  })
const getSwagshopList=(c,p)=>{
  SwagshopService.getProduct({
    lang:sessionStorage.getItem('lang'),
    search:searchParams.search,
    credit:searchParams.credit,
    userCredit:searchParams.userCredit,
    order:searchParams.order,
    current:c,
    pageSize:p,
  }).then(res=>{
   console.log(res.data.list)
   setSwagshopList(res.data.list)
   setTotalPagination(res.data.total)
  })
 
}
	const changePagination = (pageNumber) => {
    console.log(pageNumber)
		setCurrentPagination(pageNumber);
		getSwagshopList(pageNumber, pageSizePagination);
	};
	const changePaginationSize = (current, pageSize) => {
		setCurrentPagination(current);
		setPageSizePagination(pageSize);
		getSwagshopList(current, pageSize);
	};
  const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
	};
	useEffect(() => {
    let UrlParams=new URLSearchParams(window.location.search).get('search')
    if(UrlParams){
      setSearchParams({
        ...searchParams,
        search: UrlParams
      })
     setHasFirstEffectRun(true);
    }
	}, []);
	useEffect(() => {
    getSwagshopList(1, pageSizePagination)
	}, [searchParams,hasFirstEffectRun]);

 
  useEffect(() => {
    goRouterPosition({routerPosition: 'swagshop'});
    // 监听页面的点击事件
    document.addEventListener('click', handleClickOutside);

    return () => {
      // 在组件卸载时移除事件监听
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    // 判断点击的位置是否在 div 外部
    if ((creditsRangDivRef.current && !creditsRangDivRef.current.contains(event.target))) {
      setIsShow(false);
    }
  };
  const [isHovered, setIsHovered] = useState(false);
	return (
		<div className='swagshopContainer'>
			<div className='swagshopHeader'>
				<div className='swagshopHeaderContent'>
					<p className='swagshopTitle'>
						{intl.formatMessage({
							id: 'intl.swagshop.title',
						})}
					</p>
					<div className='swagshopSearchDiv'>
						<input
							// onChange={changeSearchContent}
							onChange={(e)=>setSearchParams(Object.assign({},searchParams,{search:e.target.value}))}
							className='swagshopSearch'
              value={searchParams.search}
							placeholder={intl.formatMessage({
								id: 'intl.swagshop.placeholder.search',
							})}
						/>
						<img src={searchIcon} className='swagshopSearchIcon' />
					</div>
				</div>
			</div>
			<div className='swagshopContent'>

				<div className='swagshopContentList'>
          <div className='swagshopContentHead'>
            <div className='swagshopContentHeadLeft'>
            <span style={{marginRight:'5px'}}>{intl.formatMessage({id: 'intl.swagshop.filterBox.sortedBy'})}：</span>
            <Select
              defaultValue={intl.formatMessage({id: 'intl.swagshop.filterBox.default'})}
              style={{ width:180,border:'none'}}
              onChange={(e)=>setSearchParams(Object.assign({},searchParams,{order:e}))}
              options={options}
              allowClear
              
            />
            <div className='creditsRangTitleDiv'>{intl.formatMessage({id: 'intl.swagshop.filterBox.creditsRangeTitle'})}:</div>
            <div className='creditsRangDiv' style={{borderColor:isShow?'#cce0fe':'#fff'}}  
              ref={creditsRangDivRef}>
              <div className='creditsRangPriceDiv' 
               onClick={(e)=>setIsShow(!isShow)}
               onMouseEnter={() => setIsHovered(true)}
               onMouseLeave={() => setIsHovered(false)}
               > 
                 <div> 
                  {searchParams.credit&&<span>0—</span>}
                  {searchParams.credit||intl.formatMessage({id: 'intl.swagshop.filterBox.creditsRange'})}</div>
                 {isHovered && searchParams.credit? <CloseCircleFilled style={{fontSize:'12px',color:'#8c8c8c'}} 
                  onClick={() => setSearchParams({...searchParams, credit: undefined})}
                  />:<DownOutlined  style={{fontSize:'12px',color:'#c7c7c7'}}/>
                  }
              </div>
              {isShow&&<div className='SliderDiv'>
                 <div className='SliderTiexDiv'>{searchParams.credit} {intl.formatMessage({id: 'intl.swagshop.credits'})}</div>
                  <Slider defaultValue={0} onChange={(value)=>{
                    setSearchParams(Object.assign({},searchParams,{credit: value}))}}
                  max={5000} 
                  value={searchParams.credit}
                    />
               </div>} 
            </div>
            {/* <Checkbox onChange={(e)=>setSearchParams(Object.assign({},searchParams,{userCredit: e.target.checked}))}>{intl.formatMessage({id: 'intl.swagshop.filterBox.onlyMy'})}</Checkbox> */}
            </div>
            <div className='swagshopContentHeadRight' onClick={()=>goDetailLink('articleLink')}>{intl.formatMessage({id: 'intl.swagshop.articleLink'})}</div>
          </div>
          {(totalPagination == 0 && (
							<div className='noDataDiv'>
								{/* <img className='noDataImg' src={icNoData} /> */}
								<img className='noDataImg' src={noDataImg} style={{marginTop:'60px'}} />
								<p className='noDataLabel'>
									{intl.formatMessage({
										id: 'intl.nodata.label',
									})}
								</p>
							</div>
						)) ||
            <div className='cardListDiv'> 
           {
             swagshopList.map((item, index) => (
              <div className='cardDiv' key={index} onClick={()=>{window.location.href = `/SRC/swagshopdetail/${item.id}?lang=${sessionStorage.getItem('lang')}`}}>
                <img src={item.goodsPictureOssUrl} alt="" />
                <div className='cardTitleDiv'>{item.i18n.name}</div>
                <div className='cardContentDiv'>{item.credit} {intl.formatMessage({id: 'intl.swagshop.credits'})}</div>
              </div>
            ))
            }
            </div>
            }
					{swagshopList.length !== 0 && (
						<div className='swagshopContentListPaginationdiv'>
							<label className='swagshopContentListTotal'>
								{intl.formatMessage({
									id: 'intl.swagshop.table.total',
								})}{' '}
								{totalPagination}{' '}
								{intl.formatMessage({
									id: 'intl.swagshop.table.totalnum',
								})}
							</label>
							<Pagination
								className='swagshopContentListPagination'
								showQuickJumper
                // showSizeChanger={true}
								key={currentPagination}
								current={currentPagination}
								defaultPageSize={pageSizePagination}
								total={totalPagination}
                // pageSizeOptions={['6', '10', '12']}
								onChange={changePagination}
								onShowSizeChange={changePaginationSize}
							/>
						</div>
     
					)}
				</div>
       
			</div>
      <div className='SwagshopJumpAcknowledgementDiv'>
				<div className='SwagshopJumpAcknowledgementText'>
        {intl.formatMessage({id: 'intl.swagshop.questionnaire.comingSoon'})}
				</div>
				<Button type='primary' onClick={()=>goDetailLink('inventory')} style={{height:'32px',width:'241px'}}>
				{intl.formatMessage({id: 'intl.swagshop.questionnaire.inventory'})}
				</Button>
        <div className='round1'></div>
        <div className='round2'></div>
			</div>
		</div>
	);
});
