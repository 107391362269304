import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class apkDownloadService {

    /**
     * 公告详情界面	
     * @param {id} 公告id
     * @return {公告详情}
     */
    getApkDownloadData(params) {
        return Xhr.get(`/apkDownload`, {params:params});
    }
    downloadApk(params) {
        return Xhr.get(`/apkDownload/getFile`, {params:params,headers:{token: sessionStorage.getItem("token")}});
    }
}

// 实例化再导出
export default new apkDownloadService();