import React, { useEffect,useState } from 'react';
import './style/swagshopDetail.css';
import {useIntl} from 'react-intl';
import { Tag,message,Button,Divider,Modal,Dropdown} from 'antd';
import { Link,useParams,useHistory } from "react-router-dom";
import { MinusOutlined,PlusOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import PersonalInfoService from '../../services/personalInfoService';
import SwagshopService from '../../services/swagshopService';
import BankAccountModal from '../PersonalInfo/components/bankAccountModal';
export default (function (props) {
  const {confirm} = Modal;
  let { id } = useParams()
  const intl = useIntl();
  const [count,setCount] = useState(1)
  const [bankData, setBankData] = useState();
  const [bankModalVis, setBankModalVis] = useState(false);
  const [bankModalType, setBankModalType] = useState();
  const [showTips, setShowTips] = useState(true);
  const [showTipsOne, setShowTipsOne] = useState(true);
  const [total, setTotal] = useState(0);
  const [creditsData , setCreditsData] = useState([]);
  const [swagshopDetail,setSwagshopDetail] = useState({})
  const [redeemLoading,setRedeemLoading]= useState(false)
  const getSwagshopDetail = (id) => {
    SwagshopService.getSwagshopDetail({id}).then((res) => {
      res.data.creditAvailable=60
      setSwagshopDetail(res.data)
      getCreditsData(res.data.credit)
    })
  }
  const getCreditsData=(credit)=>{
    SwagshopService.rewardStatistics({ 
      search:undefined,
      createTimeStart:undefined,
      createTimeEnd:undefined,
      year:undefined,
      month:undefined,
      type:undefined,
      dataType:undefined
    }).then(res=>{
      if(res.code==200){
        setCreditsData(res.data?.list[0])
        if(credit > res.data.list[0]?.userRewardItem?.notRedeemedCredit){
          setShowTipsOne(false);
          }else{
         setShowTipsOne(true);
          }
      }
    })
  }
  const handleGetBankAccountData = () => {
		PersonalInfoService.getBankAccountData()
			.then((res) => {
				setBankData(res.data);
        if(JSON.stringify(res.data)== '{}'){
          setShowTips(false)
        }else{
          setShowTips(true)
        }
       
			})
			.catch((error) => {
				console.log(error);
			});
	};
  const confirmDeleteAccount = () => {
		confirm({
			title: (
				<label className='settingBankCardRemoveHintTitle'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove.modalTitle'
					})}
				</label>
			),
			icon: <ExclamationCircleOutlined />,
			content: (
				<label className='settingBankCardRemoveHintContent'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove.modalContent'
					})}
				</label>
			),
			okText: intl.formatMessage({
				id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove.cancelBtn'
			}),
			cancelText: intl.formatMessage({
				id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove.confirmBtn'
			}),

			onCancel() {
				confirmRemoveAccount();
			}
		});
	};
  const confirmRemoveAccount = () => {
		PersonalInfoService.deleteBankAccount()
			.then((res) => {
				message.success(res.data);
				handleGetBankAccountData();
				Modal.destroyAll();
			})
			.catch((error) => {
				console.log(error);
			});
	};
  const openBankModal = (type) => {
		setBankModalType(type);
		setBankModalVis(true);
	};
	const closeBankModal = () => {
		setBankModalVis(false);
	};
  // let timer = null;
  const submitInformation=()=>{
    setRedeemLoading(true)
  // clearTimeout(timer)
  // timer = setTimeout(() => {
    if(JSON.stringify(bankData)!== '{}'&&showTipsOne){
      let formData = new FormData();
      formData.append('productId', swagshopDetail.id);
      formData.append('quantity', count);
      SwagshopService.productOrder(formData).then((res)=>{
       if(res.code===200){
        localStorage.setItem('swagshopDetailMessage',true)
      //  message.success( intl.formatMessage({id: 'intl.swagshopdetail.tips.order'}))
      //  window.open('/SRC/myRedemption')
       window.location.href = '/SRC/myRedemption'
       setShowTips(true)
       setShowTipsOne(true)
       setRedeemLoading(false)
      }
       
       else{
        message.error(res.data.msg)
        setRedeemLoading(false)
       }
      }).catch((err)=>{
        setRedeemLoading(false)
      })
   
    }else{
      setShowTips(bankData?.bankName?true:false)
      // setShowTipsOne(false)//显示
    }
  // },500)
  }
    useEffect(() => {
       getSwagshopDetail(id)
      handleGetBankAccountData();
    }, []);
    return ( 
        <div className="swagshopDetailContainer">
            <div className="swagshopDetailHeader">
                <div className="swagshopDetailHeaderContent">
                <div className="BreadcrumbBlogDiv">
                    <Link className="BreadcrumbBlog" to={`/SRC/swagshop`}>
                        {intl.formatMessage({id: 'intl.swagshopdetail.swagshop'})}
                    </Link>
                    <label className="BreadcrumbLine">/</label>
                    <Link className="BreadcrumbBlogDetail">
                    {intl.formatMessage({id: 'intl.swagshopdetail.details'})}
                    </Link>
                </div>
                </div> 
            </div>

            <div className="swagshopDetailContentDiv">
              <div className='swagshopDetailContentLeftAll'>
                   <img src={swagshopDetail.goodsPictureOssUrl} alt="" />
                    <div className='productsIntroduction'>
                    {intl.formatMessage({id: 'intl.swagshopdetail.mattersNeedAttention'})}
                    <a href='mailto:security.tecno@tecno-mobile.com'>security.tecno@tecno-mobile.com</a>
                    {intl.formatMessage({id: 'intl.swagshopdetail.punctuation'})}
                    </div>        
              </div>
              <div className='swagshopDetailContentRightAll'>
                <div className='membershipDiv'>{swagshopDetail.i18n?.name}</div>
                <div className='membershipPeriodDiv'>{swagshopDetail.i18n?.description}</div>
                <div className='CreditsDiv'>{swagshopDetail.credit} {intl.formatMessage({id: 'intl.swagshopdetail.credits'})}</div>

                <div className='quantityDiv'> {intl.formatMessage({id: 'intl.swagshopdetail.quantity'})}:</div>
                <div className='countAllDiv'>
                  <div className='countLeftDiv'>
                    <button disabled={count>1?false:true} 
                    onClick={()=>{
                      setCount(count - 1) 
                      setTotal(count*swagshopDetail.credit)
                      setShowTipsOne((count-1) *swagshopDetail.credit<creditsData?.userRewardItem?.notRedeemedCredit?true:false)
                      // console.log((count-1) *swagshopDetail.credit,creditsData?.userRewardItem?.notRedeemedCredit)
                    }}
                    style={{backgroundColor: count>1? '#fff': '#f0f0f0'}}
                    >
                      <MinusOutlined />
                    </button>
                    <input type="text" className='countDiv' 
                    onChange={(e)=>{
                      setShowTipsOne(e.target.value *swagshopDetail.credit<creditsData?.userRewardItem?.notRedeemedCredit?true:false)
                      const value = e.target.value;
                      if (!isNaN(value)) {
                          setCount(value);
                      }
                  }}
                    value={count}
                    />
                    <button onClick={()=>{
                      setCount(count + 1) 
                      setTotal(count *swagshopDetail.credit)
                      setShowTipsOne((count+1) *swagshopDetail.credit<creditsData?.userRewardItem?.notRedeemedCredit?true:false)
                      // console.log((count+1) *swagshopDetail.credit,creditsData?.userRewardItem?.notRedeemedCredit)
                    }}
                    style={{backgroundColor: count<swagshopDetail.maxExchangeQuantity? '#fff': '#f0f0f0'}}
                    disabled={count<swagshopDetail.maxExchangeQuantity?false:true} 
                    >
                      <PlusOutlined />
                    </button>
                  </div>
                  <div className='countRighttDiv'>Maximum {swagshopDetail.maxExchangeQuantity}</div>
                </div>
                <Divider/>
                <div className='totalPriceAlldiv'><span>{intl.formatMessage({id: 'intl.swagshopdetail.totalPrice'})}</span>：<span>{count *swagshopDetail.credit} {intl.formatMessage({id: 'intl.swagshopdetail.credits'})}</span> </div>
                <div className='redeemableCreditAlldiv'><span>{intl.formatMessage({id: 'intl.swagshopdetail.creditsBalance'})}</span>：<span>{ creditsData?.userRewardItem?.notRedeemedCredit||0} {intl.formatMessage({id: 'intl.swagshopdetail.credits'})}</span></div>
                <div className='tipsDiv'  style={{opacity:showTipsOne?0:1}}><ExclamationCircleOutlined style={{color:"red",marginRight:'5px'}} />{intl.formatMessage({id: 'intl.swagshopdetail.tips.notCredits'})}</div>
                <Divider style={{marginTop:'10px'}}/>
                <div className='totalPriceAlldiv'>{intl.formatMessage({id: 'intl.swagshopdetail.payoutMethod'})}</div>
                <div className='redeemableCreditAlldiv'>{intl.formatMessage({id: 'intl.swagshopdetail.bankInfo'})} <a href='/SRC/payoutMethod#bankCard'>{intl.formatMessage({id: 'intl.swagshopdetail.bankLink'})} </a> {intl.formatMessage({id: 'intl.swagshopdetail.bankTips'})}</div>     
                <div className='swagshopDetailPayoutMainDiv'>
				          {JSON.stringify(bankData) !== '{}' ? (
				          // {bankData?.bankName !== null ? (
				          	<div className='swagshopDetailShowBankCard'>
				          		<div className='swagshopDetailShowBankCardBankName'>
				          			{bankData?.bankName}
				          		</div>
				          		<div className='swagshopDetailShowBankCardBankNumber'>
				          			{bankData?.accountNumber}
				          		</div>
				          		<div className='swagshopDetailShowBankCardBankPayeeName'>
				          			{bankData?.accountName}
				          		</div>
				          		<div className='swagshopDetailShowBankCardBankActionDiv'>
				          			<label
				          				className='swagshopDetailShowBankCardBankActionText'
				          				style={{marginRight: '40px'}}
				          				onClick={() => openBankModal('edit')}>
				          				{intl.formatMessage({
				          					id: 'intl.personalinfo.settings.payoutMethod.bankCard.edit'
				          				})}
				          			</label>
				          			<label
				          				className='swagshopDetailShowBankCardBankActionText'
				          				onClick={() => confirmDeleteAccount()}>
				          				{intl.formatMessage({
				          					id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove'
				          				})}
				          			</label>
				          		</div>
				          	</div>) : (
				          	<div
				          		className='swagshopDetailAddBankCardDiv'
				          		onClick={() => openBankModal('add')}>
				          		<PlusOutlined />
				          		<div style={{marginTop: '8px'}}>
				          			{intl.formatMessage({id: 'intl.personalinfo.settings.payoutMethod.addBank'})}
				          		</div>
				          	</div>
				            )}
		         
              	</div>
                <div className='tipsDiv' style={{opacity:showTips?0:1}}><ExclamationCircleOutlined style={{color:"red",marginRight:'5px'}} />{intl.formatMessage({id: 'intl.swagshopdetail.tips.notBank'})}</div>
                <Button type='primary' 
                loading={redeemLoading}
                disabled={!showTipsOne || !showTips}
                 style={{width:'228px',height:'57px',marginTop:'10px',fontSize:'18px'}}
                 onClick={() => submitInformation()}
                >{intl.formatMessage({id: 'intl.swagshopdetail.redeem'})}</Button>
              </div>
            </div>
              {bankModalVis && (
		        	<BankAccountModal
		        		bankModalVis={bankModalVis}
		        		type={bankModalType}
		        		closeModal={closeBankModal}
		        		refresh={handleGetBankAccountData}
		        	/>
		        )}
           
        </div> 
    )
})