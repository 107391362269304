/**
 * 切换语言界面action
 * @return
 */
import { RES_LANG, INITIAL_STATE } from '../../constants/langTypes';
import store from '../../store/index'
import { loading } from '../index';

/**
 * 切换语言成功
 * @return
 */
const resLang = (res) => {
    return {
        type: RES_LANG,
        res
    }
}

/**
 * 初始化数据
 * @return
 */
export const initialState = () => {
    console.log("initialState")
    return {
        type: INITIAL_STATE
    }
}

/**
 * 登录界面	
 * @param {lang} 语言
 */

export const goLang = (params) => {
    sessionStorage.setItem("lang",params.lang)
    store.dispatch(resLang(params));
} 
