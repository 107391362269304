import React from 'react';
import '../style/privacyPolicy.css';
export default (function (props) {
	return (
		<div className='PrivacyPolicyDiv'>
			<h2>TECNO 安全响应中心隐私政策</h2>
			<p className='PolicyDate'>本协议版本更新日期：2023年9月20日</p>
			<br className='clear' />
			<p className='PolicyDate'>本协议版本生效日期：2023年9月20日</p>
			<br className='clear' />
			<p className='PolicyDate'>此协议版本号：20220920V3</p>
			<br className='clear' />
			<p>
				上海传英信息技术有限公司（下文简称“TECNO
				安全响应中心”、“我们”或“我们的”）是TECNO 安全响应中心
				应用软件及服务（以下简称“本软件”）的提供者与运营者，我们充分尊重您的隐私权，并会尽全力保障您的个人信息安全。在为您提供产品或者服务（统称为“本服务”）的过程中，我们可能会基于您的同意或者依据可适用的法律法规的相关规定来收集、使用您的个人信息。本隐私政策（以下简称“本政策”）旨在帮助您了解我们如何收集、使用、披露、处理及保护您在使用TECNO
				安全响应中心产品或服务时所提供的个人信息，您对这些个人信息所拥有的权利以及我们如何维护您的这些权利。本政策适用于TECNO
				安全响应中心提供的所有产品和服务，包括适用于电脑、移动智能终端的应用程序、小程序、网页、供第三方网站和应用程序使用的软件开发工具包（SDK）和应用程序编程接口（API）或其他未来可能出现的形式。
			</p>
			<p>
				<strong>
					需要特别说明的是，本政策不适用于其他第三方向您提供的服务。您可能会收到来自第三方提供的内容或网络链接，请您谨慎选择是否访问第三方提供的链接、内容、产品和服务。在向第三方提交个人信息之前，请认真阅读第三方的隐私政策。
				</strong>
			</p>
			<p>
				<strong>
					为确保您充分理解本隐私政策并作出自主选择，我们尽量以清晰易懂的表述向您阐述，并将重点阅读内容进行明确标识或突出显示（尤其是加粗、大写、下划线等形式），亦在您首次使用我们的服务时进行弹窗提示，并引导您关注了解本隐私政策的核心内容。
				</strong>
			</p>
			<p>
				请您在使用我们的产品或服务，向我们提交个人信息或者同意我们收集您的个人信息之前，仔细阅读本政策，了解我们针对您的个人信息的做法，并请经常访问以了解本政策的更新。如果本隐私政策更新，我们会在相关产品或网站上发布通知，以便让您了解我们的更新内容。如您在我们发出此类通知后访问或使用我们的服务，即表示您同意更新后的隐私政策。您可以查看隐私政策顶部的“更新日期”来确认隐私政策的最新更新时间。
			</p>
			<p>I如有任何疑问，请随时通过如下方式与我们联系：</p>
			<p>Privacy@TECNO.com</p>
			<p>您也可以通过如下方式访问本隐私政策：</p>
			<p>TECNO 安全响应中心 &gt;官网底部 &gt;隐私政策</p>
			<p>
				<strong>本隐私政策将帮助您了解以下内容：</strong>
			</p>
			<p>一、我们如何收集和使用您的个人信息</p>
			{/* <p>二、我们如何使用 Cookies 和同类技术</p> */}
			<p>二、我们如何存储和保护您的个人信息</p>
			<p>三、我们如何共享、转让及披露您的个人信息</p>
			<p>四、您的个人信息如何跨境转移</p>
			<p>五、您对自身个人信息所拥有的权利</p>
			<p>六、我们如何处理儿童的个人信息</p>
			<p>七、第三方服务提供商及其服务</p>
			<p>八、本政策的补充和更新</p>
			<p>九、如何联系我们</p>
			<p>
				<strong>一、我们如何收集和使用您的个人信息</strong>
			</p>
			<p>
				<strong>（一）一般概述</strong>
			</p>
			<p>
				TECNO
				安全响应中心坚信，基本隐私权不应因您居住在世界各地而异，因此，我们认为无论自然人居住在何处，TECNO
				安全响应中心均将已识别或可识别的该自然人有关的任何信息，尤其是该自然人的姓名、身份信息、位置数据、在线标识符或特定于该自然人的身体、生理、遗传、心理，或与之相关的数据视作“个人信息”。
			</p>
			<p>
				我们只会收集特定、具体、明确和合法目的所需的信息，并应确保不会以与这些目的不相符的方式对您的信息进行进一步处理。如果我们有意将您的个人信息用于本隐私政策未明确的其它目的，我们会再次征得您的同意。
			</p>
			<p>
				<strong>
					在收集您的个人信息时，我们会通过以下三种方式进行收集，包括1）您直接向我们提供的信息（例如您在登录账号时、参与我们举办的活动中，主动向我们提供的昵称、头像、姓名、电话号码信息）；2)
					我们通过自动化手段采集您在使用本软件过程中产生的信息(例如设备信息、服务日志信息)；3)我们从其他来源获得的信息。
				</strong>
			</p>
			<p>
				您有权选择在使用TECNO
				安全响应中心产品或服务时或在我们要求时选择是否提供个人信息。您并非必须向TECNO
				安全响应中心提供个人信息，但在一些情况下，如果您选择不提供，TECNO
				安全响应中心将无法为您提供相关产品或服务，也无法回应或解决您所遇到的问题。我们仅会出于本政策所述目的收集和使用您的个人信息。下文举例说明了我们收集的您的个人信息：
			</p>
			<p>
				<strong>
					（二）TECNO 安全响应中心主要业务功能和收集使用场景
				</strong>
			</p>
			<p>
				<strong>
					本软件的主要业务功能是为您提供线上账号、安全漏洞报告等服务。
				</strong>
			</p>
			<p>
				<strong>1.为您提供帐号注册、登录的服务</strong>
			</p>
			<p>
				为方便您更充分地使用本软件的功能与服务，您可以通过邮箱、密码的方式方式进行账号注册、登录。在注册过程中，我们可能需要您提供您所在的国家/地区。同时，您可以通过第三方账号登录（例如Facebook账号）我们的平台，此时我们将获取您的OPEN
				ID，并建立相应的绑定关联。您如果拒绝提供该信息，您将无法注册TECNO
				安全响应中心的帐号并使用相关功能，但您仍然可以在游客模式下（即在未成为注册用户的情况下）浏览、使用本软件的基本业务功能。
			</p>
			<p>
				在登录注册、使用本软件过程中，
				<strong>
					您可以自主选择补充提供、填写、修改您的昵称（含真实姓名）、头像（含真实肖像）与您的社交链接。
				</strong>
				但您拒绝提供补充信息并不会影响您体验注册与登录功能。
			</p>
			<p>
				如您登录我们的平台，我们将获取您的IP地址，以确保您在安全的环境使用本服务，并根据您的IP地址生成统计类型数据（例如，我们只需要了解用户分布的国家和地区，而不需要知道具体是哪位用户）。
			</p>
			<p>
				<strong>2.为您提供安全漏洞测试平台</strong>
			</p>
			<p>
				TECNO
				安全响应中心致力于不断提成TECNO产品的安全性能，因为我们将邀请您检测产品安全漏洞。我们仅向安全研究人员开放安全漏洞测试平台，为了验证您的安全研究员资历，您需要提供您的技术文章地址、参赛经历证明，我们将根据您的安全研究员资历判断您是否满足发放样机的标准。如根据您的资历，我们评估您满足发放样机的标准，您需要提供您的邮箱、姓名、电话号码、收件地址，以上信息用于为您寄送样机。
			</p>
			<p>
				如您发现产品的安全漏洞，您可以通过TECNO安全漏洞平台提供漏洞信息，此时我们将获取您提交的漏洞标准、漏洞类型、漏洞子类型、漏洞等级、漏洞详情描述以及其他证明漏洞信息的相关文件。根据您提交的漏洞信息，我们将评估您的贡献值并在英雄榜中展现您的排名。
			</p>
			<p>
				如根据相关规则，您可获得漏洞奖金。出于发放奖金的目的，我们将需要您提供相应的银行账户信息。具体而言，如您为中国大陆的用户，您需提供您的收款人信息（
				<strong>姓名、身份证号、手机号</strong>）、收款银行信息（
				<strong>银行账号、银行名称、银行所在国家</strong>
				）以及好灵工平台的签约状态。如您为非中国大陆用户，我们将获取您的
				<strong style={{fontFamily: 'Hurme3-Bold'}}>
					Bank Account Country, Payee's Name, Payee's Account Number,
					Bank Name, Bank's SWIFT CODE, Bank Country, Bank City, Bank
					Address, Bank Routing Number, Intermediary Bank Name,
					Intermediary Bank‘s SWIFT CODE.
				</strong>
				以上信息为发放奖金所必须，如您拒绝提供此类信息，我们将可能无法为您正常发放奖励，但您仍可正常使用本网站其他服务。
			</p>
			<p>
				<strong>（三）其他可能收集您信息的情况</strong>
			</p>
			<p>
				在一些法律允许的情况下，我们可能从其他个人、商业主体、公共渠道或者其他您指示的第三方处，其他为提供我们的产品或服务及协助我们确保信息安全和防止欺诈的合作伙伴和其他合法途径获取您的个人信息。我们会在收集时向您说明，并获得您的同意。
			</p>
			<p>
				我们也可能通过其他方式收集有关您、您的设备或使用服务情况的其他信息，这些方式会在收集时向您说明并征得您的同意。
			</p>
			<p>
				为了更好地改善我们的产品布局，以便向您提供更优质的服务，我们可能会对所收集的信息以匿名化的方式进行研究、统计分析和预测（包括使用匿名数据进行机器学习或模型算法训练），我们会对所收集的个人信息采取技术措施和其他必要措施进行处理，使得该等数据无法重新识别到特定的个人，也无法进行复原。根据所适用的法律法规，此类处理后数据的使用无需另行向您通知并征得您的同意。
			</p>
			<p>
				<strong>二、我们如何存储和保护您的个人信息</strong>
			</p>
			<p>1. 我们如何存储您的个人信息</p>
			<p>
				基于您同意而收集的个人信息，我们会根据相关国家的个人隐私数据保护法律法规的规定进行存储。在当地法律法规并不禁止的情况下，我们也可能将收集的个人信息存储在业务所在国以外的国家或地区。
			</p>
			<p>
				目前，TECNO
				安全响应中心可能将您的个人信息存储于：中国大陆、俄罗斯、印度。具体而言：
			</p>
			<p>
				我们在中国大陆运营中收集和生成的个人信息将被存储在位于中国大陆的服务器中。
			</p>
			<p>
				我们在俄罗斯运营中收集和生成的个人信息将被存储在位于俄罗斯的服务器中。
			</p>
			<p>
				我们在中国大陆与俄罗斯以外运营中收集和生成的个人信息将被存储在位于印度的服务器中。
			</p>
			<p>
				后续根据业务情况，我们有可能引入其他地区或国家的服务器，届时我们将更新本政策。
			</p>
			<p>
				无论是将个人信息存储在业务所在国，还是在不违反法律规定的情况存储在其他国家或地区，我们都将按照相关法律法规关于数据存储的规定来进行保存。我们同时会考虑到个人信息处理的特点、范围、目的、处理中可能遇到的风险，以及一旦造成损害产生的严重后果等，落实必要的安全保障措施。例如：我们会采取加密和去标识化等手段来存储收集的个人信息。同时，我们将根据业务开展所需信息的最短存储期限需求来进行存储，除非您同意延长保留期或受到法律的允许。超过存储期限的数据，或者您要求我们删除的数据，我们都将在不违反相关法律法规规定的情况下予以删除或采取其他适宜的措施。
			</p>
			<p>2. 我们如何保护您的个人信息</p>
			<p>
				我们还将采取其他的必要手段来保障您个人信息的安全，以防止数据被滥用、
				未经授权的访问，以及未经授权的被修改、披露或者销毁等。
			</p>
			<p>
				我们实施旨在保护您的个人信息的安全保护措施，并定期监控我们的系统是否存在可能的漏洞和攻击，并且我们会利用管理、技术和物理保护措施，通过考量个人信息的性质、处理方式和安全性来保护您的个人信息。我们持续不断努力地改进这些保护措施，以确保您的个人数据安全。
			</p>
			<p>
				当然，需要提醒您的是，即使采取再如何完善的措施都无法绝对保证避免数据存储安全事故的发生，若一旦发生安全泄露事件，我们将按照相关法律法规的要求，向相应的监管机构进行汇报，并根据监管机构的要求提供数据泄露涉及的个人数据的类型、数据主体的数量、数据泄露可能导致的后果，以及我们拟采取的补救措施。我们会采取尽可能的措施来补救或者减轻数据泄露带来的损害及后果。在法律法规允许或者要求的情况下，及时将数据泄露可能对您产生的后果或者重大损害向您进行告知，以方便您能够及时采取适宜的措施，尽可能地保护您的权利。
			</p>
			<p>
				由于任何网站、Internet传输、计算机系统或无线连接都不是绝对安全的，除了我们采取必要的安全保护措施之外，用户也需要加强隐私安全风险意识。例如，通过我们的服务跳转到第三方的网站、链接、产品或服务，对于这些网站、链接、产品或服务所收集的任何数据信息的安全，请您务必小心谨慎并仔细阅读该网站、链接、产品或服务的协议条款，对该类第三方导致的数据安全问题我们不承担任何法律责任。如果您发现我们的网站、产品或者服务的某些内容、广告或者功能可能由第三方提供，且可能危及您的个人隐私及安全，请您及时联系我们，我们会依法依规予以优先处理。
			</p>
			<p>
				<strong>三、我们如何共享、转让及披露您的个人信息</strong>
			</p>
			<p>
				在共享、转让、披露您的个人信息方面，我们秉持最小化、必要性、法律强制性的原则和要求。通常情形下，我们不会对外共享、转让或披露您的个人信息，但是在相关法律法规适用或允许的情况下，我们可能在以下情形共享、转让、披露您的个人信息：
			</p>
			<p>1. 我们如何共享您的个人信息</p>
			<p>
				我们不会将任何个人信息售卖给第三方。为了向您提供及时的服务，您的个人信息可能会与TECNO
				安全响应中心的关联公司共享。我们仅会出于合法和明确的目的在TECNO
				安全响应中心的关联公司内共享您的信息，并且只会共享提供服务所必要的信息，且受本隐私政策中所声明目的的约束。如相关目的未在本政策中予以包含，则我们会在相应的具体产品或服务的隐私政策或通知中予以说明。
			</p>
			<p>
				我们可能会与授权的第三方合作伙伴（包括技术服务提供商、数据存储服务商、售后服务商、营销推广服务商、第三方SDK服务商等）共享必要的个人信息，以执行某些功能或为您提供更好的服务和用户体验。具体而言，我们的云服务商将帮助我们确保网站正常运行；如您为中国大陆用户，当我们进行奖金发放时候，我们的服务提供方好灵工平台将根据付款所需的信息帮助我们完成奖金发放。我们秉持最小化、必要性、法律允许的原则进行该类共享活动。在进行信息共享前，我们会与授权的合作伙伴签署严格的保密协议，要求他们按照本政策以及您所属司法管辖区可适用的法律采取相关的保密和安全措施来处理个人信息。此外，我们还将要求他们提供数据安全功能和信息安全资质（例如等级保护评估，信息安全管理系统等）。当我们在本软件中嵌入授权合作伙伴的SDK或其他类似的应用程序，我们会尽到审慎提示义务，要求获取信息的SDK保护您的数据安全。
			</p>
			<p>
				为提供评估、分析或其他商业服务，我们还可能与第三方共享您的非个人信息，例如：我们可能将归纳整理的信息用于帮助合作伙伴（如广告服务商）了解其服务的成效、反馈情况及使用趋势等。
			</p>
			<p>
				在涉及合并、收购或破产清算、资产转让等相关交易时，如涉及到个人信息转让，我们会通过协议或者其他适当的措施要求新持有您个人信息的公司、组织继续受此隐私政策的约束，并要求该类主体采取不低于本政策要求的保密和安全措施来处理个人信息。
			</p>
			<p>2.我们如何转让您的个人信息</p>
			<p>
				除非有以下情况，否则TECNO
				安全响应中心不会将您的信息转让给任何其他方：
			</p>
			<p>（1）获得您的明确同意。</p>
			<p>
				（2）如果TECNO
				安全响应中心涉及其全部或部分资产的合并、收购或出售，并可能会影响您的个人信息时，我们将通过电子邮件和/或我们的网站或其他适当方法发布醒目的通知，以告知您对个人信息的所有权、权利以及您对个人信息的选择的任何变更。
			</p>
			<p>（3）在本隐私政策中已说明或通过其他方式通知您的情况下。</p>
			<p>3.我们如何披露您的个人信息</p>
			<p>
				根据法律要求、法定程序、诉讼和/或政府部门和当局的要求，TECNO
				安全响应中心可能需要披露您的个人信息。如果披露对于国家安全、执法或其他具有公共重要性的事项是必要或适当的，我们也可能会披露有关您的信息。
			</p>
			<p>此外，我们可能会与以下人员分享您的个人信息：</p>
			<p>
				（1）当我们要求他们提供专业建议时，我们可能会与我们的律师，会计师，审计师或类似的顾问分享您的个人信息。
			</p>
			<p>
				（2）投资者和其他相关第三方，如果发生了实际或潜在的销售或其他的公司交易，并且与我们集团内的实体有关。
			</p>
			<p>（3）其他第三方，例如，在您的授权下披露特定信息。</p>
			<p>
				<strong>四、您的个人信息如何跨境转移</strong>
			</p>
			<p>
				为了本隐私协议中所述的目的，在遵守您所在地区适用法律法规并且获得您的授权同意后，我们可能会将您的个人信息转移到本政策第三条所提及的资源或服务器中进行存储或者处理，我们还可能将您的个人信息转移给第三方服务提供商和业务合作伙伴，因此您的数据也可能会被转移到其他国家或地区。这些国家或地区的隐私保护法律可能不尽相同，并且这些服务器所在的法域可能会也可能不会按照与您所在法域相同的标准来保护个人信息。但是，我们仍将努力遵守我们的隐私政策以保护您的个人信息，并且我们将会按照个人信息所在国家或地区的法律要求，进行相应的个人信息的跨境转移和其他处理。
			</p>
			<p>
				如果我们将个人信息转移至您所在的法域以外的地区，无论是将其转移至我们的关联公司还是第三方服务提供商，我们都将遵守相关的适用法律。我们将采取适当的安全和保护措施以确保所有此类传输均符合适用的当地数据保护法律的要求。
			</p>
			<p>
				<strong>五、您对自身个人信息所拥有的权利</strong>
			</p>
			<p>
				作为数据主体，您对个人信息拥有确认、访问、更正、删除、撤回同意、投诉（以下简称“请求”）等一系列权利，我们充分理解并尊重您的这些权利，根据适用法律，此类权利也将受到特定排除和例外的约束。我们也将采取积极的措施，保证您的这些权利得以有效保障。但是，请您谅解并注意的是，出于安全考虑，在处理您的请求前，我们可能需要验证您的身份。对于您合法合理的请求，我们原则上不收取费用。但对多次重复、超出合理限度的请求，我们将视情况基于实际管理支出，在适用法律允许的范围内收取一定的合理费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，抑或是违反法律强制性规定的请求，我们可能会予以拒绝。
			</p>
			<p>
				<strong>1. 确认和访问权：</strong>{' '}
				我们通过该隐私政策或者具体产品服务场景下的隐私政策或者通知的形式向您告知我们如何收集和处理个人信息。您有权通过勾选等方式对此收集和处理行为进行确认或拒绝。但当您拒绝后，部分需要您明确授权同意之后才能够使用的功能可能因此无法使用。对于您已经提供给我们的个人信息，您可以在我们的产品或服务的界面中予以直接查询。对于无法查询的情形，您可以发送邮件至Privacy@TECNO.com，在收到您的邮件后，我们将及时予以处理。
			</p>
			<p>
				<strong>2. 更正和删除权：</strong>{' '}
				当您的个人信息发生变化时，或者当您发现我们收集并处理的您的个人信息有错误或者不完整的情形时，您有权要求我们做出更正或进行补充。对于您的部分个人信息，您可以在产品或服务的相关功能页面直接进行更正、修改。在我们收集您的个人信息已经达到收集目的或者不再具有必要性时，或者当您发现我们违反法律、行政法规的规定或者本隐私政策的约定收集、处理您的个人信息的，您有权要求我们删除相关个人信息。对于暂未向您提供更正或删除渠道的个人信息，您可以通过联系Privacy@TECNO.com要求更正或删除。
			</p>
			<p>
				但是在一些情况下我们可能不会批准您的删除请求，例如当您要求我们删除交易数据，而TECNO
				安全响应中心在法律上有义务保留该交易的记录以符合适用的当地法律时。另请注意，如果您请求从我们的产品和服务中删除您的信息，由于某些适用的当地法律和技术限制，我们可能不会立即从备份系统中删除相应的信息，但是我们将安全地存储您的个人信息并防止其被进一步处理，直到备份系统更新、可以删除您的信息或将您的信息匿名化为止。
			</p>

			<p>
				<strong>3. 撤回同意权：</strong>{' '}
				我们不同产品或服务要实现相应的功能，我们将根据适用的当地法律规定，需要必要的个人信息。本隐私政策中“我们如何收集和使用您的个人信息”对此予以了说明。您可以通过删除信息、关闭设备功能、撤回同意请求的方式来撤销之前基于特定目的而授予我们的同意，包括收集、使用和/或披露我们掌握或控制的您的个人信息。您可以通过邮件发送至Privacy@TECNO.com联系我们进行相关操作。我们将在您发送请求后的合理时间内处理您的请求，此后将不会根据您的请求收集、使用和/或披露您的个人信息。
			</p>
			<p>
				如果您撤回同意，您可能将无法继续获得TECNO
				安全响应中心产品和服务的部分和/或全部功能。撤回您的同意或授权将不会影响我们根据您的同意进行的先前处理活动的有效性。
			</p>
			<p>
				<strong>4. 投诉权：</strong>{' '}
				您有权通过向Privacy@TECNO.com发送邮件联系我们进行投诉。在收到您的投诉后我们将及时做出答复。如果您对我们的答复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向当地法定的个人数据保护部门进行投诉或举报，或向有管辖权法院提起诉讼。
			</p>
			<p>
				<strong>5. 管理隐私设置的权利</strong>
			</p>
			<p>
				您有权对您的隐私设置进行控制，您可以通过这些隐私设置，来限制我们对您的个人信息进行收集、使用、披露或处理，例如您可以选择打开或关闭“位置访问”、“相机”、“短信息”的功能与权限；您也可以在“设置”中获取有关设备安全状态的更多详细信息。如果您此前已同意开启对应的权限或同意我们基于前述业务场景使用您的个人信息的，您可以通过
				设置 &gt; 应用管理 &gt; 权限管理
				来进行关闭或通过Privacy@TECNO.com与我们联系来更改您的决定。
			</p>
			<p>
				<strong>6. 注销服务或帐号的权利t</strong>
			</p>
			<p>
				<strong>
					您可以通过本服务中提供的注销路径或通过Privacy@TECNO.com联系我们来提出注销您的帐号的要求。{' '}
				</strong>
				请您谨慎作出注销帐号的行为，您知悉并理解，在您注销帐号后，基于该帐号所提供的产品和服务，我们将会一并停止。除非您所在地区所适用的法律另有特殊要求外，我们会同时删除您帐号下相应的个人数据。如果您是通过第三方帐号授权登录本服务的，请您向第三方申请注销帐号。
			</p>
			<p>
				<strong>7. 某些法域的权利</strong>
			</p>
			<p>
				请特别注意：根据某些法域的适用法律，您可能具有以下数据主体权利：
			</p>
			<p>
				（1）限制处理权。您有权要求我们限制您对个人信息的处理。我们将考察有关您的限制要求的理由。如果理由符合适用的数据保护法律，我们将仅在此类法律的适用情况下处理您的个人信息，并在解除处理限制之前通知您。
			</p>
			<p>
				（2）自动化决策权。您有权拒绝基于自动决策的信息处理，包括性能分析和其他可能对您产生法律或类似影响的自动处理。
			</p>
			<p>
				（3）数据可携带权。您有权申请以结构化且常用的格式将您的个人信息传输到另一个数据控制者。
			</p>
			<p>
				我们有权拒绝处理您的请求，我们有权在适用豁免的情况下或符合适用法律的情况下拒绝您的某些请求。例如，某些请求显然没有根据或明显超过限度，或者需要披露第三方信息。
			</p>
			<p>
				<strong>六、我们如何处理儿童的个人信息</strong>
			</p>
			<p>
				TECNO
				安全响应中心的产品和服务不向14周岁或在您所处法域法律规定的相当年龄以下的儿童提供（不同国家或者地区的法律规定儿童年龄的认定标准不尽相同，我们将以具体业务所在国或者地区的法律法规规定为主来定义儿童），我们不会主动收集儿童个人数据，也不会将儿童的个人信息用于营销的目的。如果您发现相关儿童信息被传递至了我们，请发送邮件至Privacy@TECNO.com联系我们，我们将尽快删除相关信息。如果我们发现自己收集了儿童的个人信息，我们也会设法尽快删除相关内容。
			</p>
			<p>
				<strong>七、第三方服务提供商及其服务</strong>
			</p>
			<p>
				在您使用TECNO
				安全响应中心产品或者服务时（例如生活服务、线上客服的服务等），您可能会收到来自我们以外的第三方的网站链接或者第三方提供的内容和服务，且一些第三方的产品或服务可能以SDK和其他方式进行。您可以基于自身判断选择是否访问或接受第三方提供的网站、产品和服务。我们的隐私政策并不适用于任何第三方提供的产品和服务。因为我们无法在实质上控制第三方，故无法对该第三方收集和处理您的个人信息负责，在您向该第三方提交任何信息时，请您认真了解该第三方的隐私政策，并留意保护自己的个人信息。
			</p>
			<p>
				<strong>八、本政策的补充和更新</strong>
			</p>
			<p>
				根据我们的业务、科技、适用法律及操作的变化，我们保留不时更新或修改本政策的权利。如果本政策有修改，我们会将最新政策发布在这里（本页面），或通过在我们官网https://security.TECNO.com/中发布，或者向您单独发布的方式通知您。请您定期关注本页面或我们向您发出的通知，以保持对该隐私政策更新的及时知晓。本隐私政策虽然会有调整，但未经您明示同意，我们不会削弱您按照本隐私政策所享有的权利。
			</p>
			<p>
				当我们向您收集其他个人信息或者当我们出于新的目的使用或披露您的个人信息时，我们将根据适用法律再次征求您的明确同意。如您继续使用在网站、移动设备和/或任何其他设备上的产品和服务，则您将受更新的隐私政策所约束。
			</p>
			<p>
				本协议中所述的TECNO
				安全响应中心产品或服务的内容，有可能会根据您所使用的产品类型、系统版本或当地的法律法规的要求而有所变化。
			</p>
			<p>
				<strong>九、如何联系我们</strong>
			</p>
			<p>
				如果您对我们的隐私政策或与个人信息保护的做法有任何建议、问题或疑虑，请通过以下方式联系我们：
			</p>
			<p>
				电子邮箱：
				Privacy@TECNO.com（注：本邮箱仅用于接收与我们的隐私政策或我们在个人信息保护方面有关的邮件）
			</p>
			<p>
				我们将尽快审核您的隐私或个人信息请求，并在验证您用户身份后的15-30天内回复您。如果您的请求本身涉及复杂或重大问题，我们可能会要求您提供更多信息。
			</p>
			<p>
				如果您对我们的答复不满意，尤其是当您的合法权益受我们侵害时，您可以向您所在法域的相关数据保护监管机构寻求解决方案。如果您向我们提出上述咨询，我们将根据您的情况提供可能适用的相关投诉渠道的信息。
			</p>
			<p>感谢您抽出宝贵的时间阅读我们的隐私政策！</p>
		</div>
	);
});
