import { React, Component } from 'react';
// 引入路由内置组件
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
// 引入加载路由视图得函数组件
import RouterView from './router/index';
// 引入路由配置表
import config from './router/routers';
import { ConfigProvider, BackTop } from 'antd';
import en_US from 'antd/lib/locale-provider/en_US';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { goLang } from './action/lang/langAction';
import { IntlProvider } from 'react-intl';
import enUS from './i18n/en-US';
import zhCN from './i18n/zh-CN';
import store from './store/index';
import './App.css';
import './assets/fonts/font.css';
//antd平台国际化
const localeConfig = {
	zh_CN: zh_CN,
	en_US: en_US,
};
//配置本地语言
const langMap = {
	zh_CN: zhCN,
	en_US: enUS,
};
const getQueryString = (name) => {
	var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
	var r = window.location.search.substr(1).match(reg);
	if (r != null) return unescape(r[2]);
	return null;
};
class App extends Component {
	constructor(props) {
		super(props);
		this.state = store.getState().Lang;
		this.storeChange = this.storeChange.bind(this); //转变this指向
	}

	componentDidMount() {
		const langtemp = getQueryString('lang');
		if (langtemp) {
			sessionStorage.setItem('lang', langtemp);
			goLang({ lang: langtemp });
			if (langtemp === 'zh_CN') {
				sessionStorage.setItem('changeLanguage', 'EN');
			} else if (langtemp === 'en_US') {
				sessionStorage.setItem('changeLanguage', 'CN');
			}
		} else {
			if (sessionStorage.getItem('lang')) {
				goLang({ lang: sessionStorage.getItem('lang') });
			} else {
				goLang({ lang: 'en_US' });
				sessionStorage.setItem('lang', 'en_US');
			}
		}
		store.subscribe(this.storeChange); //订阅Redux的状态
	}
	storeChange() {
		this.setState(store.getState().Lang);
	}
	render() {
		return (
			<div className='appDiv'>
				<ConfigProvider
					locale={
						sessionStorage.getItem('lang')
							? localeConfig[sessionStorage.getItem('lang')]
							: localeConfig['en_US']
					}
				>
					<IntlProvider
						locale={navigator.language}
						messages={
							sessionStorage.getItem('lang')
								? langMap[sessionStorage.getItem('lang')]
								: langMap['en_US']
						}
					>
						<BackTop />
						<BrowserRouter>
							<Route path='/' exact render={() => <Redirect to='/SRC' />} />
							<RouterView routes={config} />
						</BrowserRouter>
					</IntlProvider>
				</ConfigProvider>
			</div>
		);
	}
}
export default App;
