import React, { useEffect,useState } from 'react';
import './style/forgetPassword.css';
import {useIntl} from 'react-intl';
import { scrollToTop } from '../../common/topScorll'
import {Input,Popover,Progress,message} from 'antd'
import { goRouterPosition } from '../../action/routerposition/routerpositionAction'
import { useHistory } from 'react-router-dom'
import ForgetPasswordService from '../../services/forgetPasswordService'
import { Link } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import { JSEncrypt } from 'jsencrypt'

export default (function (props) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYpKIowTM/+WFirmaZuU9e0BK7zyQRBuPUG4CYWl6S/VsiDoAMlqKHdG/9G9+Wd2ZDIzAfQM+tZ2cutQotNlxyd/d7LZm71xy+zPvpcGJQo4iQ8cylZfjZ2gQO3+7nEqo11eVYlrI8MyOhgRLSzvpNHaBPlEPd9zKCh8baLF5NywIDAQAB");
    const intl = useIntl();
    const [ countDownSecond,setCountDownSecond ] = useState(60)
    const [ captchaCode,setCaptchaCode ] = useState("")
    const [ errorMsg,setErrorMsg ] = useState("")
    const [ captchaCodeToken,setCaptchaCodeToken ] = useState("")
    const [ emailAdressInput,setEmailAdressInput ] = useState("")
    const [ passwordInput,setPasswordInput ] = useState("")
    const [ passwordVerifyInput,setPasswordVerifyInput ] = useState("")
    const [ captchaCodeInput,setCaptchaCodeInput ] = useState("")
    const [ emailCaptchaCodeInput,setEmailCaptchaCodeInput ] = useState("")
    const [ percentProgress,setPercentProgress ] = useState(0)
    const history = useHistory()
    useEffect(() => {
        (sessionStorage.getItem('lang') == 'zh_CN') && (document.title = '忘记密码') ||
        (sessionStorage.getItem('lang') == 'en_US') && (document.title = 'Forget Passcode')
        scrollToTop();
        // sessionStorage.setItem("redirect", window.location.pathname)
        sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
        goRouterPosition({routerPosition:"forgetPassword"})
        ForgetPasswordService.getCaptchaCode().then (res => {
            setCaptchaCode(res.data.base64Img)
            setCaptchaCodeToken(res.data.cToken)
        }).catch(error => {
            console.log(error)
        })
    }, []);
    //60s倒计时
    const handleCountDown = (seconds = 60) => {
        let second = seconds;
        const countDown = ()=> {
            // timer && clearTimeout(timer); //清除计时器
            if( second > 0){
                second--;
                setCountDownSecond( second );
            }
            if( second === 0 ){
                second = 60;
                setCountDownSecond( second );
                // clearTimeout( timer ); //清除计时器
                return;
            }
            setTimeout( countDown,1000 );
        };
        setTimeout( countDown,1000 );
    };
    const forgetPassword = ()=> {
        if(emailAdressInput == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.forgetpassword.message.error.email'
            }));
            return false
        } else if(emailCaptchaCodeInput == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.forgetpassword.message.error.emailcaptchacode'
            }));
            return false
        }
        let parmes = {}
        parmes={
            email: encrypt.encrypt(emailAdressInput),
            passCode: encrypt.encrypt(passwordInput),
            // passCodeConfirm: passwordVerifyInput,
            mCode: encrypt.encrypt(emailCaptchaCodeInput),
        }
        // if(passwordInput==passwordVerifyInput){
            if(percentProgress == 100){
                ForgetPasswordService.goForgetPassword(parmes).then (res => {
                    message.success(intl.formatMessage({
                        id: 'intl.forgetpassword.message.success'
                    }));
                    history.push('/SRC/login')
                }).catch(error => {
                    changeCaptchaCode();
                    setErrorMsg(error);
                })
            }else {
                setErrorMsg(intl.formatMessage({
                    id: 'intl.forgetpassword.message.error.passwordrule'
                }));
            }
        // }else{
        //     message.error('两次密码不一致');
        // }
    }
    const getEmailCaptcha = ()=> {
        if(captchaCodeInput == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.forgetpassword.message.error.captchacode'
            }));
            return false
        }
        ForgetPasswordService.goEmailCaptcha({
            mail: emailAdressInput,
            cToken: captchaCodeToken,
            cCode: captchaCodeInput,
            type: "mail_reset_password"
        }).then (res => {
            handleCountDown();
        }).catch(error => {
            setErrorMsg(error);
            console.log(error)
        })
    }
    const changeCaptchaCode = ()=> {
        ForgetPasswordService.getCaptchaCode().then (res => {
            setCaptchaCode(res.data.base64Img)
            setCaptchaCodeToken(res.data.cToken)
        }).catch(error => {
            console.log(error)
        })
    }
    const emailAdressInputChange = (e)=> {
        setEmailAdressInput(e.target.value)
    }
    const passwordInputChange = (e)=> {
        setPasswordInput(e.target.value)
        let precent = 0;
        console.log(e.target.value,"e.target.value")
        if (e.target.value.length > 7 && e.target.value.length < 17) {
            precent = 25
        }
        if (/\d/.test(e.target.value)) {
            precent = precent+25
        } //数字
        if ((/[a-z]/.test(e.target.value)) || (/[A-Z]/.test(e.target.value))) {
            precent = precent+25
        } //小写/大写
        if (/\W/.test(e.target.value)) {
            precent = precent+25
        } //特殊字符
        setPercentProgress(precent)
    }
    const passwordInputCheck = (e)=> {
        let precent = 0;
        if (e.target.value.length > 7 && e.target.value.length < 17) {
            precent = 25
        }
        if (/\d/.test(e.target.value)) {
            precent = precent+25
        } //数字
        if ((/[a-z]/.test(e.target.value)) || (/[A-Z]/.test(e.target.value))) {
            precent = precent+25
        } //小写/大写
        if (/\W/.test(e.target.value)) {
            precent = precent+25
        } //特殊字符
        if(precent != 100) {
            setErrorMsg(intl.formatMessage({
                id: 'intl.signup.password.rule'
            }))
        }else {
            setErrorMsg("");
        }
    }
    const passwordVerifyInputChange = (e)=> {
        setPasswordVerifyInput(e.target.value)
    }
    const captchaCodeInputChange = (e)=> {
        setCaptchaCodeInput(e.target.value)
    }
    const emailCaptchaCodeInputChange = (e)=> {
        setEmailCaptchaCodeInput(e.target.value)
    }
    const passwordPopoverTitle = <span className="PopoverProgressTitle">
        {
            intl.formatMessage({
                id: 'intl.forgetpassword.password.title'
            })
        }
    </span>;
    const passwordPopover = (
        <div>
          <Progress
            status={(percentProgress<100)?"exception":"success"}
            percent={percentProgress}
            />
            <p className="PopoverProgress">
                {
                    intl.formatMessage({
                        id: 'intl.forgetpassword.password.rule'
                    })
                }
            </p>
        </div>
    );
    return ( 
        <div className="forgetPasswordContainer">
            <div className="backLogin">
                <LeftOutlined className="backIcon"/>
                <Link className="backLoginLabel" to={`/SRC/login`}>
                    {
                        intl.formatMessage({
                            id: 'intl.forgetpassword.button.back'
                        })
                    }
                </Link>
            </div>
            <div className="forgetPasswordContent">
                <p className="forgetPasswordContentTitle">
                    {
                        intl.formatMessage({
                            id: 'intl.forgetpassword.title'
                        })
                    }
                </p>
                <p className="forgetPasswordContentSubTitle">
                    {
                        intl.formatMessage({
                            id: 'intl.forgetpassword.subtitle'
                        })
                    }
                </p>
                <Input className="forgetPasswordInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.forgetpassword.placeholder.email'
                        })
                    } onChange={emailAdressInputChange}/>
                <Popover placement="right" overlayClassName='passwordPopoverOverlay' title={passwordPopoverTitle} content={passwordPopover} trigger="focus">
                    <Input.Password className="forgetPasswordInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.forgetpassword.placeholder.password'
                        })
                    } onChange={passwordInputChange} onBlur={passwordInputCheck}/>
                </Popover>
                {/* <Input.Password className="forgetPasswordInput" placeholder="Verify the password" onChange={passwordVerifyInputChange}/> */}
                <Input className="forgetPasswordInput captchaInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.forgetpassword.placeholder.captcha.img'
                        })
                    } onChange={captchaCodeInputChange}/>
                <img className="captchaCode" onClick={changeCaptchaCode} src={ captchaCode }/>
                <Input className="forgetPasswordInput captchaInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.forgetpassword.placeholder.captcha.email'
                        })
                    } onChange={emailCaptchaCodeInputChange}/>
                <button className={countDownSecond == 60 ? "captchaButton" : "captchaButtonDis"} onClick={getEmailCaptcha} disabled={countDownSecond == 60 ? false : true}>
                    {  
                        countDownSecond == 60 ? intl.formatMessage({
                            id: 'intl.forgetpassword.button.getcaptcha'
                        }) : `${countDownSecond}${intl.formatMessage({
                            id: 'intl.forgetpassword.button.aftergetcaptcha'
                        })}`
                        
                    }
                </button>
                <p className="errorMessage">{errorMsg}</p>
                <p className="forgetPasswordButton" onClick={forgetPassword}>
                    {
                        intl.formatMessage({
                            id: 'intl.forgetpassword.button.goforgetpassword'
                        })
                    }
                </p>
            </div> 
        </div> 
    );
})