import { fromJS } from 'immutable';
import { INITIAL_STATE, RES_LANG } from '../../constants/langTypes';

// 初始化state数据
const initialState = {
    langInfo: {lang:'en_US'}
};

/**
 * 登录界面reducer
 * @return
 */
const Lang = (state = initialState, action) => {
    switch(action.type) {
        case INITIAL_STATE: // 初始化state数据
            return fromJS(state).merge({langInfo: {lang:'en_US'}}).toJS();
        case RES_LANG: // 切换语言成功
            return fromJS(state).merge({langInfo: action.res}).toJS();
        default:
            return state;
    }
}

export default Lang;