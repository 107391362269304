import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class LoginService {
	/**
	 * 登录界面
	 * @param {username} 用户名
	 * @param {password} 密码
	 * @return {登录信息}
	 */
	goLogin(params) {
		return Xhr.post(
			`/login?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	goLogout() {
		return Xhr.post(
			`/user/loginOut?lang=${sessionStorage.getItem('lang')}`
		);
	}
	refreshToken() {
		return Xhr.post(
			`/user/refreshToken?lang=${sessionStorage.getItem('lang')}`
		);
	}
	goFbLogin(params) {
		return Xhr.post(
			`/fb/token?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	getUserInfo() {
		return Xhr.get(
			`/userInfo/selectUserInfo?lang=${sessionStorage.getItem('lang')}`
		);
	}
	getCaptchaCode(params) {
		return Xhr.get(`/captcha?lang=${sessionStorage.getItem('lang')}`);
	}
}

// 实例化再导出
export default new LoginService();
