import React, { useEffect, useState } from 'react';
import './style/blog.css';
import { useIntl } from 'react-intl';
import { scrollToTop } from '../../common/topScorll';

import { Radio, Pagination, Tag } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { goRouterPosition } from '../../action/routerposition/routerpositionAction';
import store from '../../store/index';
import searchIcon from '../../assets/svg/ic-Search.svg';
import topIcon from '../../assets/svg/ic-公告-置顶.svg';
import icNoData from '../../assets/svg/ic-空.svg';
import BlogService from '../../services/blogService';
export default (function (props) {
	const intl = useIntl();
	const history = useHistory();
	const [state, setState] = useState(store.getState().Blog);
	const [blogType, setBlogType] = useState('All');
	const [searchContent, setSearchContent] = useState('');
	const [currentPagination, setCurrentPagination] = useState(1);
	const [pageSizePagination, setPageSizePagination] = useState(5);
	const [totalPagination, setTotalPagination] = useState(0);
	const [blogList, setBlogList] = useState([]);
	const [subLabelList, setSubLabelList] = useState([]);
	const [subType, setSubType] = useState('All');
	const [chosenSubTagId, setChosenSubTagId] = useState('');
	const getBlogList = (current, pageSize) => {
		setCurrentPagination(current);
		setPageSizePagination(pageSize);
		let params = {};
		if (blogType == 'All') {
			params = {
				current: current,
				pageSize: pageSize,
				search: searchContent,
			};
		} else {
			params = {
				tagName: subType,
				current: current,
				pageSize: pageSize,
				typeName: blogType,
				search: searchContent,
			};
		}
		BlogService.getBlogListData(params)
			.then((res) => {
				// store.dispatch(resChangePagination(current));
				setBlogList(res.data.list);
				setTotalPagination(res.data.total);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const changeBlogType = (e) => {
		setCurrentPagination(1);
		let tempSubType = 'All';
		let tempType = '';
		if (e == 'News') {
			setBlogType(e);
			tempType = e;
		} else {
			setBlogType(e.target.value);
			tempType = e.target.value;
		}
		if (sessionStorage.getItem('lang') == 'en_US') {
			setSubType('All');
		} else if (sessionStorage.getItem('lang') == 'zh_CN') {
			setSubType('全部');
		}
		let params = {};
		if (tempType == 'All') {
			params = {
				current: 1,
				pageSize: pageSizePagination,
				search: searchContent,
			};
		} else {
			params = {
				tagName: tempSubType,
				current: 1,
				pageSize: pageSizePagination,
				typeName: tempType,
				search: searchContent,
			};
		}
		BlogService.getBlogListData(params)
			.then((res) => {
				// store.dispatch(resChangePagination(current));
				setBlogList(res.data.list);
				setTotalPagination(res.data.total);
			})
			.catch((error) => {
				console.log(error);
			});
		if (e !== 'News' && e.target.value === 'All') {
			setSubLabelList([]);
		} else {
			getBlogSubLabelList(tempType);
		}
	};
	//获取二级标签
	const getBlogSubLabelList = (tempType) => {
		BlogService.getBlogSubLabel({
			lang: sessionStorage.getItem('lang'),
			typeName: tempType,
		})
			.then((res) => {
				setSubLabelList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const changePagination = (pageNumber) => {
		setCurrentPagination(pageNumber);
		getBlogList(pageNumber, pageSizePagination);
	};
	const changePaginationSize = (current, pageSize) => {
		setCurrentPagination(current);
		setPageSizePagination(pageSize);
		getBlogList(current, pageSize);
	};
	const clearSearchContent = () => {
		setSearchContent('');
	};
	const changeSearchContent = (e) => {
		setCurrentPagination(1);
		setSearchContent(e.target.value);
		// getBlogList(1, pageSizePagination);
    let params = {};
    if (blogType == 'All') {
			params = {
				current: 1,
				pageSize: pageSizePagination,
				search: e.target.value,
			};
		} else {
			params = {
				tagName: subType,
				current: 1,
				pageSize: pageSizePagination,
				typeName: blogType,
				search: e.target.value,
			};
		}
		// let params = {
		// 	tagName: subType,
		// 	current: 1,
		// 	pageSize: pageSizePagination,
		// 	typeName: blogType,
		// 	search: e.target.value,
		// };
		BlogService.getBlogListData(params)
			.then((res) => {
				// store.dispatch(resChangePagination(current));
				setBlogList(res.data.list);
				setTotalPagination(res.data.total);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	//切换二级标签
	const changeBlogSubType = (value) => {
		setCurrentPagination(1);
		setSubType(value.tagName);
		setChosenSubTagId(value.id);
		let params = {
			tagName: value.tagName,
			current: 1,
			pageSize: pageSizePagination,
			typeName: blogType,
			search: searchContent,
		};
		BlogService.getBlogListData(params)
			.then((res) => {
				// store.dispatch(resChangePagination(current));
				setBlogList(res.data.list);
				setTotalPagination(res.data.total);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	//跳转切换二级标签
	const jumpChangeBlogSubType = (value) => {
		setCurrentPagination(1);
		setSubType(value);
		let params = {
			tagName: value,
			current: 1,
			pageSize: pageSizePagination,
			typeName: blogType,
			search: searchContent,
		};
		BlogService.getBlogListData(params)
			.then((res) => {
				// store.dispatch(resChangePagination(current));
				setBlogList(res.data.list);
				setTotalPagination(res.data.total);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' && (document.title = '公告')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'Resource'));
		if (
			sessionStorage.getItem('blogType') == 'News' &&
			sessionStorage.getItem('subType') == 'Acknowledgements'
		) {
			changeBlogType('News');
			jumpChangeBlogSubType('Acknowledgements');
		}
		if (
			sessionStorage.getItem('blogType') == 'News' &&
			sessionStorage.getItem('subType') == '致谢'
		) {
			changeBlogType('News');
			jumpChangeBlogSubType('致谢');
		}
		scrollToTop();
		// sessionStorage.setItem("redirect", window.location.pathname)
		sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		goRouterPosition({ routerPosition: 'blog' });
		store.subscribe(storeChange);
    //新增奖励明细跳转逻辑
    const urlParamsTuype = new URLSearchParams(window.location.search).get('type')
    if(urlParamsTuype){
      setBlogType(urlParamsTuype)
    }
    if(urlParamsTuype){
      let tempSubType = 'All';
      let params = {};
        params = {
          tagName: tempSubType,
          current: 1,
          pageSize: pageSizePagination,
          typeName: urlParamsTuype,
          search: searchContent,
        };
      
      BlogService.getBlogListData(params)
        .then((res) => {
          setBlogList(res.data.list);
          setTotalPagination(res.data.total);
        })
        .catch((error) => {
          console.log(error);
        });
        getBlogSubLabelList(urlParamsTuype);
      }else{
      getBlogList(1, 5);
      }

    // getBlogList(1, 5);
		return () => {
			clearSearchContent();
		};
	}, []);
	const storeChange = () => {
		setState(store.getState().Blog);
		sessionStorage.removeItem('blogType');
		sessionStorage.removeItem('subType');
	};
	const goShowDetail = (data) => {
		window.open(
			`/SRC/blogdetail/${data.id}?lang=${sessionStorage.getItem('lang')}`
		);
	};

	return (
		<div className='blogContainer'>
			<div className='blogHeader'>
				<div className='blogHeaderContent'>
					<p className='blogTitle'>
						{intl.formatMessage({
							id: 'intl.blog.title',
						})}
					</p>
					{/* <p className="blogIntroduce">
                        {
                            intl.formatMessage({
                                id: 'intl.blog.introduce'
                            })
                        }
                    </p> */}
					<div className='blogSearchDiv'>
						<input
							onChange={changeSearchContent}
							className='blogSearch'
							placeholder={intl.formatMessage({
								id: 'intl.blog.placeholder.search',
							})}
              value={searchContent}
						/>
						<img src={searchIcon} className='blogSearchIcon' />
					</div>
				</div>
			</div>
			<div className='blogContent'>
				<div className='blogContentTypeRadio'>
					<div className='blogContentMainType'>
						<Radio.Group value={blogType} onChange={changeBlogType}>
							<Radio.Button value='All'>
								{intl.formatMessage({
									id: 'intl.blog.type.all',
								})}
							</Radio.Button>
							<Radio.Button value='Rules'>
								{intl.formatMessage({
									id: 'intl.blog.type.rules',
								})}
							</Radio.Button>
							<Radio.Button value='Events'>
								{intl.formatMessage({
									id: 'intl.blog.type.events',
								})}
							</Radio.Button>
							<Radio.Button value='News'>
								{intl.formatMessage({
									id: 'intl.blog.type.news',
								})}
							</Radio.Button>
							<Radio.Button value='Blogs'>
								{intl.formatMessage({
									id: 'intl.blog.type.blog',
								})}
							</Radio.Button>
						</Radio.Group>
					</div>
					{subLabelList?.length !== 0 && (
						<div className='blogContentMainSubType'>
							{subLabelList.map((item, index) => (
								<div
									onClick={() => {
										changeBlogSubType(item);
									}}
									key={index.id}
									className={
										subType == item.tagName
											? 'blogContentMainSubLabel blogContentMainSubTypeChosenLabal'
											: 'blogContentMainSubLabel blogContentMainSubTypeUnchosenLabal'
									}
								>
									{item.tagName}
								</div>
							))}
						</div>
					)}
					{/* <Radio.Group value={blogType} onChange={changeBlogType}>
                        <Radio.Button value="All">
                            {
                                intl.formatMessage({
                                    id: 'intl.blog.type.all'
                                })
                            }
                        </Radio.Button>
                        <Radio.Button value="News">
                            {
                                intl.formatMessage({
                                    id: 'intl.blog.type.news'
                                })
                            }
                        </Radio.Button>
                        <Radio.Button value="Blog">
                            {
                                intl.formatMessage({
                                    id: 'intl.blog.type.blog'
                                })
                            }
                        </Radio.Button>
                        <Radio.Button value="Events">
                            {
                                intl.formatMessage({
                                    id: 'intl.blog.type.events'
                                })
                            }
                        </Radio.Button>
                    </Radio.Group> */}
				</div>
				<div className='blogContentList'>
					<ul className='blogContentListul'>
						{(totalPagination == 0 && (
							<div className='noDataDiv'>
								<img className='noDataImg' src={icNoData} />
								<p className='noDataLabel'>
									{intl.formatMessage({
										id: 'intl.nodata.label',
									})}
								</p>
							</div>
						)) ||
							blogList.map((data, index) => (
								<li key={index} className='blogContentListli'>
									<div className='blogContentListlititlediv'>
										{(data.type.name == 'News' && (
											<Tag className='BugSubmitDetailTagsDiv' color='orange'>
												{intl.formatMessage({
													id: 'intl.blog.type.news',
												})}
											</Tag>
										)) ||
											(data.type.name == 'Blogs' && (
												<Tag className='blogContentListlitag' color='cyan'>
													{intl.formatMessage({
														id: 'intl.blog.type.blog',
													})}
												</Tag>
											)) ||
											(data.type.name == 'Events' && (
												<Tag className='blogContentListlitag' color='blue'>
													{intl.formatMessage({
														id: 'intl.blog.type.events',
													})}
												</Tag>
											)) ||
											(data.type.name == 'Rules' && (
												<Tag className='blogContentListlitag' color='red'>
													{intl.formatMessage({
														id: 'intl.blog.type.rules',
													})}
												</Tag>
											))}
										{data.priority > 0 && (
											<img className='blogContentListliimg' src={topIcon} />
										)}
										<p
											className='blogContentListlititle'
											onClick={() => goShowDetail(data)}
										>
											{data.title}
										</p>
									</div>
									<p className='blogContentListlicontent'>{data.preface}</p>
									<p className='blogContentListlitime'>
										{data.firstPublishTime?.split(' ')[0]}
									</p>
									{/* <div className="blogContentListlitimediv">
                                        <p className="blogContentListlitime">{ data.creatTime }</p>
                                        <Link className="blogContentListliviewdetail" to={`/SRC/blogdetail/${data.id}?lang=${sessionStorage.getItem('lang')}`}>
                                            {
                                                intl.formatMessage({
                                                    id: 'intl.blog.button.viewdetails'
                                                })
                                            }
                                        </Link>
                                    </div> */}
								</li>
							))}
					</ul>
					{blogList.length !== 0 && (
						<div className='blogContentListPaginationdiv'>
							<label className='blogContentListTotal'>
								{intl.formatMessage({
									id: 'intl.blog.table.total',
								})}{' '}
								{totalPagination}{' '}
								{intl.formatMessage({
									id: 'intl.blog.table.totalnum',
								})}
							</label>
							<Pagination
								className='blogContentListPagination'
								showQuickJumper
								key={currentPagination}
								current={currentPagination}
								defaultPageSize={pageSizePagination}
								total={totalPagination}
								onChange={changePagination}
								onShowSizeChange={changePaginationSize}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
});
