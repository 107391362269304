import React, {useEffect, useState} from 'react';
import './style/rewardsDetails.css';
import {Button, Popover, message,Input,Select,DatePicker,Table,Pagination,Tooltip,Empty} from 'antd';
import {QuestionCircleFilled} from '@ant-design/icons';
import {useIntl} from 'react-intl';
import {detailLink} from '../../../common/detailLink';
import SwagshopService from '../../../services/swagshopService';
import QuickEntryDiv from '../components/quickEntryDiv';
import CreditIcon from '../../../assets/svg/icon_credit.svg'
import {MinusOutlined,SearchOutlined} from '@ant-design/icons';
import noDataImg from '../../../assets/svg/空状态.svg';
import PieGauge from './components/pieGauge';
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import {goRouterPosition} from '../../../action/routerposition/routerpositionAction';
dayjs.extend(localeData)
dayjs.extend(weekday)
export default (function (props) {

  const { RangePicker } = DatePicker;
	const intl = useIntl();
	const [userRankDetail, setUserRankDetail] = useState({});
  const [tableData, setTableData] = useState([])
  const [state, setState]=useState('')
  const [groupList, setGroupList] = useState([
    {label:intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.all'}),value:''},
    {label:intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.bonusDetails'}),value:'bonus'},
    {label:intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.reputationDetails'}),value:'reputation'},
    {label:intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.creditDetails'}),value:'credit'},
  ])
  const [options, setOptions] = useState([
    {label:intl.formatMessage({ id: 'intl.rewardsDetails.table.columns.creditRedemption'}),value:'301'},
    {label:intl.formatMessage({ id: 'intl.rewardsDetails.table.columns.vulnerabilityReward'}),value:'302,305'},
    {label:intl.formatMessage({ id: 'intl.rewardsDetails.table.columns.activityReward'}),value:'303,305'},
    {label:intl.formatMessage({ id: 'intl.rewardsDetails.table.columns.securityAuthorReward'}),value:'304'}
    // {label:intl.formatMessage({ id: 'intl.rewardsDetails.table.columns.vulnerabilityRewardAndActivityReward'}),value:'305'}
  ])
  const [show,setShow]=useState(false)
  const [lang,setLang]=useState(true)
  const [yearOptions, setYeaOptions] = useState([  ])
  const [monthOptions, setMonthOptions] = useState([])
  const [searchParams, setSearchParams] = useState({
    search: undefined,
    year:undefined,
    month:undefined,
    type:undefined,
    createTime:[],
  });
  const [creditsData , setCreditsData] = useState({});
  const [pieGaugeValue , setPieGaugeValue] = useState({});
  const [currentPagination, setCurrentPagination] = useState(1);
	const [pageSizePagination, setPageSizePagination] = useState(10);
	const [totalPagination, setTotalPagination] = useState(0);
  const isValidUrl=(string) =>{
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }
	const columns = [
		{
			title: intl.formatMessage({
				id: 'intl.rewardsDetails.table.columns.changeDate'
			}),
			dataIndex: 'createTime',
			width: 196,
			align: 'left',
			render: (text) => (
				<div>
          {text}
				</div>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.rewardsDetails.table.columns.type'
			}),
			// dataIndex: 'type',
      // ellipsis: {
      //   showTitle: true,
      // },
			width: 199,
			align: 'left',
			render: (text, record) => (
        // <Tooltip title={record.i18n.typeName} placement="topLeft">
				<label>
          {record.i18n.typeName}
				</label>
        // </Tooltip>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.rewardsDetails.table.columns.changeDetails'
			}),
			dataIndex: 'reasons',
			width:325,
			align: 'left',
      ellipsis: {
        showTitle: true,
      },
			render: (_,record) =>
        <>
        {record.type==301&& <div > 
         <Tooltip  placement="topLeft" title={
          <div>
            <div>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.orderID'})}{record.orderNumber}</div>
            <div>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.product'})}{record.i18n?.productName}</div>
            {record.credit>0&&<div>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.note'})}{record.i18n?.remark||intl.formatMessage({id: 'intl.rewardsDetails.table.columns.noteTips'})}</div>}  
         </div>
         }
         overlayInnerStyle={{ maxWidth: '260px' }}
         >
           <div className='ellipsis'>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.orderID'})}<a href={`/SRC/myRedemption?search=${record.orderNumber}`} rel="noreferrer">{record.orderNumber}</a></div>
           <div className='ellipsis'>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.product'})} <a href={`/SRC/swagshop?search=${record.i18n.productName}`}  rel="noreferrer">{record.i18n?.productName}</a></div>
           {record.credit>0&&<div>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.note'})}{record.i18n?.remark||intl.formatMessage({id: 'intl.rewardsDetails.table.columns.noteTips'})}</div>}  
         </Tooltip>
        </div>
        }
        {record.type==302&&<div> 
          <Tooltip 
          placement="topLeft"
          overlayInnerStyle={{ maxWidth: '260px' }}
          title={
          <>
          <div>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.vulnerability'})}{record.i18n?.vulnerabilityNumber}</div>
          </>
          }
          
          >
         <div className='ellipsis'>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.vulnerability'})}<a href={`/SRC/BugSubmitDetail/${record.vulnerabilityId}`} rel="noreferrer">{record.i18n?.vulnerabilityNumber}</a></div> 
          </Tooltip>
        </div>
        }

        {record.type==303&&<div> 
          <Tooltip 
          placement="topLeft"
          overlayInnerStyle={{ maxWidth: '260px' }}
          title={
            <>
            <div>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.activity'})}{record.mrActivityName}</div>
            </> 
           }>
         <div className='ellipsis'>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.activity'})} 
           { 
            isValidUrl(record.mrActivityLink) ? 
            <a href={record.mrActivityLink}>{record.mrActivityName}</a> : 
            <span style={{color: 'black'}}>{record.mrActivityName}</span> 
           }
         </div>
          </Tooltip>
        </div>
        }
  
       {record.type==304&&<div> 
        <Tooltip 
        placement="topLeft"
        overlayInnerStyle={{ maxWidth: '260px' }}
        title={
          <>
          <div>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.article'})} {record.scActivityName}</div> 
          </>
        
          }>
          <div className='ellipsis'>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.article'})}  
          { 
            isValidUrl(record.scActivityLink) ? 
            <a href={record.scActivityLink}>{record.scActivityName}</a> : 
            <span style={{color: 'black'}}>{record.scActivityName}</span> 
           }
          </div>
          </Tooltip>
        </div>
        }
        {record.type==305&&<div> 
          <Tooltip 
          placement="topLeft"
          overlayInnerStyle={{ maxWidth: '260px' }}
          title={
          <div>
            <div>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.vulnerability'})}{record.i18n?.vulnerabilityNumber}</div>
            <div> {intl.formatMessage({id: 'intl.rewardsDetails.table.columns.activity'})}{record.leakActivityName}</div>
         </div>
         }>
          <div className='ellipsis'>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.vulnerability'})}<a href={`/SRC/BugSubmitDetail/${record.vulnerabilityId}`} rel="noreferrer">{record.i18n?.vulnerabilityNumber}</a></div>
          <div className='ellipsis'>{intl.formatMessage({id: 'intl.rewardsDetails.table.columns.activity'})}
          { 
            isValidUrl(record.leakActivityLink) ? 
            <a href={record.leakActivityLink}>{record.leakActivityName}</a> : 
            <span style={{color: 'black'}}>{record.leakActivityName}</span> 
           }
          </div> 
          </Tooltip>
        </div>
        }
    
       
        </>
		},
		{
			title: intl.formatMessage({
				id: 'intl.rewardsDetails.table.columns.baseBonus'
			}),
			dataIndex: 'bonus',
			width: 97,
			align: 'left',
			render: (text) => <label className='tabelLabel'>{text||'-'}</label>
		},
    {
			title: intl.formatMessage({
				id: 'intl.rewardsDetails.table.columns.incentivesBonus'
			}),
			dataIndex: 'extraBonus',
			width: 102,
			align: 'left',
			render: (text) => <label className='tabelLabel'>{text||'-'}</label>
		},
		{
			title: intl.formatMessage({
				id: 'intl.rewardsDetails.table.columns.reputations'
			}),
			dataIndex: 'reputation',
			width: 115,
			align: 'left',
			render: (text) => <label className='tabelLabel'>{text||'-'}</label>
		},
		{
			title: intl.formatMessage({
				id: 'intl.rewardsDetails.table.columns.credits'
			}),
			dataIndex: 'credit',
			width:94,
			align: 'left',
			render: (text) => <label className='tabelLabel' style={{color:creditTextColor(text)}}>{text||'-'}</label>
		},
	];
  const creditTextColor = (text) => {
    if (text > 0) {
      return '#70B602';
    } else if (text < 0) {
      return '#FE485F';
    } else {
      return '#333';
    }
  }
  const getCreditsData=()=>{
    SwagshopService.rewardStatistics({ 
      search:searchParams.search,
      createTimeStart:searchParams.createTime.length>0?searchParams.createTime[0].format('YYYY-MM-DD'):undefined,
      createTimeEnd:searchParams.createTime.length>0?searchParams.createTime[1].format('YYYY-MM-DD'):undefined,
      year:searchParams.year,
      month:searchParams.month,
      type:searchParams.type,
      dataType:state,
    }).then(res=>{
      if(res.code==200){
        setCreditsData(res.data?.list[0])}
      let obj={
      'activityReward':res.data.list[0]?.userRewardItem?.activityCredit||0,
      'securityAuthorProgram':res.data.list[0]?.userRewardItem?.securityCredit||0,
      // 'vulnerabilityReward':res.data.list[0]?.userRewardItem?.vulnerabilityCredit||0
    }
      for (let key in obj) {
        if (obj[key] !== 0) {
          setShow(true);
            break;
        }
    }
      setPieGaugeValue(obj)
    })
  }
  const getTableData = (c, p) => {
    SwagshopService.getRewardRecord({
      lang:sessionStorage.getItem('lang'),
      search:searchParams.search,
      createTimeStart:searchParams.createTime.length>0?searchParams.createTime[0].format('YYYY-MM-DD'):undefined,
      createTimeEnd:searchParams.createTime.length>0?searchParams.createTime[1].format('YYYY-MM-DD'):undefined,
      year:searchParams.year,
      month:searchParams.month,
      type:searchParams.type,
      dataType:state,
      current:c,
      pageSize:p
    }).then(res=>{
      console.log(res.data.list)
      setTableData(res.data.list)
      setTotalPagination(res.data.total)
    })
   
  }
  const content=(content)=>{
    return <div style={{width:"250px"}}>{content}</div>
  }
  const changePagination = (pageNumber) => {
		setCurrentPagination(pageNumber);
		getTableData(pageNumber, pageSizePagination);
	};
	const changePaginationSize = (current, pageSize) => {
		setCurrentPagination(current);
		setPageSizePagination(pageSize);
		getTableData(current, pageSize);
	};
  const getYearList=()=>{
    SwagshopService.getYear({}).then(res=>{
      let arr=[]
      res.data.map(item=>{
       arr.push({label:item.year.toString(),value:item.year})
      })
      setYeaOptions(arr)
    })
  }
  const getMonthList=(year)=>{
    SwagshopService.getmonth({
      search:searchParams.search,
      createTimeStart:searchParams.createTime.length>0?searchParams.createTime[0].format('YYYY-MM-DD'):undefined,
      createTimeEnd:searchParams.createTime.length>0?searchParams.createTime[1].format('YYYY-MM-DD'):undefined,
      year:year,
      type:searchParams.type,
      dataType:state
    }).then(res=>{
      let arr=[]
      res.data.map(item=>{
        arr.push({label:item.month.toString(),value:item.month})
      })

      setMonthOptions(arr)
    })
  }
  const getuserRankList=()=>{
    SwagshopService.getuserRank({}).then(res=>{
    setUserRankDetail(res.data[0])

    })
  }
  const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
  }
    const getOrdinalSuffix=(num)=>{
      let j = num % 10,
          k = num % 100;
       if (j == 1 && k != 11) {
         return "st";
       }
       if (j == 2 && k != 12) {
         return "nd";
       }
       if (j == 3 && k != 13) {
         return "rd";
       }
       return "th";
     }
	useEffect(() => {
    getTableData(1,pageSizePagination);
    getCreditsData()
	}, [searchParams,state]);
  useEffect(() => {
    goRouterPosition({routerPosition: 'rewardsDetails'});
    sessionStorage.getItem('lang') === 'en_US' ? setLang(true) : setLang(false)
    const urlParamsTuype = new URLSearchParams(window.location.search).get('type')
    setState(urlParamsTuype||'')
    getYearList()
    getuserRankList()
  },[])
  // const history = useHistory();
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   return history.listen((location) => { 
  //     // 创建一个 action
  //     const action = { type: 'RES_ROUTER', res: location.pathname };
  //     // 分发 action
  //     dispatch(action);
  //   }); 
  // }, [history, dispatch]);

	return (
		<div className='rewardsDetailsContainer'>
       <QuickEntryDiv
          title={intl.formatMessage({ id: 'intl.personalinfo.breadCrumb.rewardsDetails'})}
           chosenType='rewardsDetails'/>
      <div className='rewardsDetailsTypeDiv'>
      {groupList.map((item,index)=>(
      <div key={index} className={state===item.value?'bottonChosen':'bottonUnChosen'} onClick={()=>setState(item.value)}>{item.label}</div>
      ))}
      </div>
      <div className='rewardsDetailsSearchDiv'> 
        <Select
       style={{ width:110 }}
       optionFilterProp="children"
       placeholder={intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.year'})}
       showSearch
       allowClear
       filterOption={(input, option) =>
         (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
       }
       onChange={(e)=>{setSearchParams(Object.assign({},searchParams,{year:e}))
            if(!e){
              setSearchParams(Object.assign({},searchParams,{month:undefined,year:e}))
            }  
            getMonthList(e)}}
       options={yearOptions}
      />
       <Select
       style={{ width:120 }}
       optionFilterProp="children"
       placeholder={intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.month'})}
       showSearch
       allowClear
       filterOption={(input, option) =>
         (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
       }
       onChange={(e)=>setSearchParams(Object.assign({},searchParams,{month:e}))}
       options={monthOptions}
       value={searchParams.month}
      />
      <RangePicker 
        onChange={((value, dateString) => {
        value ? setSearchParams(Object.assign({}, searchParams, { createTime: [dayjs(value[0]).hour(0).minute(0).second(0), dayjs(value[1]).hour(23).minute(59).second(59)] })) : setSearchParams(Object.assign({}, searchParams, { createTime: [] }))
       })}
       placeholder={[intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.startTime'}),intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.endTime'})]}
       value={searchParams.createTime}
        style={{ width: 285 }}
      />
      <Select
       style={{ width: 300 }}
       optionFilterProp="children"
       placeholder={intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.type'})}
       showSearch
       allowClear
       filterOption={(input, option) =>
         (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
       }
       onChange={(e)=>setSearchParams(Object.assign({},searchParams,{type:e}))}
       options={options}
      />
        <Input
       style={{width:320}}
       placeholder={intl.formatMessage({ id: 'intl.rewardsDetails.filterBox.searchPlaceholder'})}
       onChange={(e)=>setSearchParams(Object.assign({},searchParams,{search:e.target.value}))}
       allowClear
       suffix={<SearchOutlined />}/>
      </div>
				
      <div className='rewardsDetailsCardAllDiv'>
        {(state==''||state=='bonus')&&<div className='rewardsDetailsCardLeftDiv'>
         <div className='balanceDetailsAllDiv'>
          <div className='credtsBalanceAlldiv'>
            <div className='balanceDiv'>
              <div>{intl.formatMessage({ id: 'intl.rewardsDetails.bonusDataCard.totalBonus'})}</div>
              <div className='rewardsDetailsQuantityDiv' style={{color:'#2C61F3'}}>{creditsData?.userRewardItem?.bonus||0}</div>
            </div> 
            </div>
            <div style={{fontSize:'40px',margin:'8px 0 0 -30px'}}>=</div>
            <div className='balanceDiv'>
             <div>{intl.formatMessage({ id: 'intl.rewardsDetails.bonusDataCard.baseBonus'})}</div>
              <div className='rewardsDetailsQuantityDiv'style={{color:'#000'}}>{creditsData?.userRewardItem?.baseBonus||0}</div>
              <a href='/SRC/bugSubmit'>{intl.formatMessage({ id: 'intl.rewardsDetails.bonusDataCard.submitReport'})}</a>
            </div>
            <div style={{fontSize:'40px',margin:'8px 0 0 -30px'}}>+</div>
            <div className='balanceDiv'>
              <div>{intl.formatMessage({ id: 'intl.rewardsDetails.bonusDataCard.incentivesBonus'})}</div>
              <div className='rewardsDetailsQuantityDiv'style={{color:'#000'}}>{creditsData?.userRewardItem?.extraBonus||0}</div>
              <a href="/SRC/blog?type=Events">{intl.formatMessage({ id: 'intl.rewardsDetails.bonusDataCard.findActivities'})}</a>
            </div>
         </div>
       </div>}
       {(state==''||state=='reputation')&& <div className='rewardsDetailsCardRightDiv'>
            <div>{intl.formatMessage({ id: 'intl.rewardsDetails.reputationDataCard.reputation'})}</div>
            <div className='reputationData'>{creditsData?.userRewardItem?.baseHonor||0}</div>
            <div>
              <span className='rankDiv'>{intl.formatMessage({ id: 'intl.rewardsDetails.reputationDataCard.hOFAll'})}</span>
              <span>{userRankDetail?.userRewardItem?.hofAllRank||0}
             {getOrdinalSuffix(userRankDetail?.userRewardItem?.hofAllRank || 0)}
             </span>
            </div>
            <div>
              <span className='rankDiv'>{intl.formatMessage({ id: 'intl.rewardsDetails.reputationDataCard.hOFYear'})} {userRankDetail?.userRewardItem?.hofYear||'2024'}</span>
             <span>{userRankDetail?.userRewardItem?.hofYearRank||0}
              {getOrdinalSuffix(userRankDetail?.userRewardItem?.hofYearRank || 0)}
              </span>
            </div>
        </div>}
		  </div>
    
      {(state==''||state=='credit')&&<div className='rewardsDetailsCardAllDiv'>
       <div className='rewardsDetailsCardLeftDiv rewardsDetailsCardLeftDiv2'>
         <div className='balanceDetailsAllDiv'>
           <div className='credtsBalanceAlldiv'>
             {/* <img src={CreditIcon} alt="" /> */}
             <div className='balanceDiv'>
               <div>{intl.formatMessage({ id: 'intl.rewardsDetails.creditsDataCard.creditsBalance'})}</div>
               <div className='rewardsDetailsQuantityDiv' style={{color:'#2C61F3'}}>{creditsData?.userRewardItem?.notRedeemedCredit||0}</div>
               <a href='/SRC/swagshop'>{intl.formatMessage({ id: 'intl.rewardsDetails.creditsDataCard.goToRedeem'})}</a>
             </div> 
           </div>
           <div style={{fontSize:'40px',margin:'8px 0 0 -28px'}}>=</div>
           <div className='balanceDiv'>
             <div>{intl.formatMessage({ id: 'intl.rewardsDetails.creditsDataCard.totalCredits'})}</div>
             <div className='rewardsDetailsQuantityDiv' style={{color:'#000'}}>{creditsData?.userRewardItem?.credit||0}</div>
             <div className='getCreditsDiv' onClick={()=>goDetailLink('getCredits')}>{intl.formatMessage({ id: 'intl.rewardsDetails.creditsDataCard.getCredits'})}</div>
           </div>
           <div style={{fontSize:'40px',margin:'6px 0 0 -30px'}}>
               -
           </div>
           <div className='balanceDiv'>
             <div>{intl.formatMessage({ id: 'intl.rewardsDetails.creditsDataCard.redeemedCredits'})}</div>
             <div className='rewardsDetailsQuantityDiv'style={{color:'#000'}}>{creditsData?.userRewardItem?.redeemedCredit||0}</div>
             <a href='/SRC/myRedemption'>{intl.formatMessage({ id: 'intl.rewardsDetails.creditsDataCard.myRedemption'})}</a>
           </div>
         </div>
         <div style={{margin:"10px"}}>
            <Popover
							arrowPointAtCenter
							placement='bottomRight'
							trigger='hover'
							content={content(intl.formatMessage({ id: 'intl.rewardsDetails.creditsDataCard.note'})) }
              >
							<QuestionCircleFilled className='BonusDetailPopover' />
						</Popover>
         </div>
        </div>

        <div className='pieGaugeDiv'>
            <div className='pieGaugeTitleDiv'>{intl.formatMessage({ id: 'intl.rewardsDetails.pieChart.creditsRatio'})}</div>
              { !show?(
                    <div style={{marginTop: '-20px',textAlign: 'center',}}>
											<img
												src={noDataImg}
												alt='img'
												className='profileNoDataImg'
												style={{
													boxShadow: '0px 0px',width:'85px'
												}}
											/>
                      	<div
												className='noDataHintTextInPersonal'
												style={{boxShadow: '0px 0px',marginTop:'-12px',color:'#cecece',fontSize:'12px'}}>
												{intl.formatMessage({id: 'intl.personalInfo.noData.hint',})}
											</div>
										</div>
                    ):
                    (<div  className= 'picture'>
                         <PieGauge value={pieGaugeValue}/>
                    </div>)}
            
        </div>
		  </div>}

      <div className='tableDiv'>
      <Table
				className='leaderboardsTable'
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={tableData}
        pagination={false}
				locale={{
          emptyText: (
            <Empty
              image={noDataImg}
            />
          ),
        }}
			/>
       	{tableData.length !== 0 && (
				<div className='myRedemptionContentListPaginationdiv'>
					<label className='myRedemptionContentListTotal'>
						{intl.formatMessage({
							id: 'intl.rewardsDetails.table.total',
						})}{' '}
						{totalPagination}{' '}
						{intl.formatMessage({
							id: 'intl.rewardsDetails.table.totalnum',
						})}
					</label>
					<Pagination
						showQuickJumper
						key={currentPagination}
            showSizeChanger={true}
						current={currentPagination}
						defaultPageSize={pageSizePagination}
						total={totalPagination}
            pageSizeOptions={['10', '20', '30', '40']}
						onChange={changePagination}
						onShowSizeChange={changePaginationSize}
					/>
				</div>
				)}
      </div>
		</div>
	);
});
