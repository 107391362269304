import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class BugSubmitListService {

    /**
     * 登录界面	
     * @param {current} 当前页码
     * @param {pageSize} 一页数量
     * @return {BUG提交列表}
     */
     getVulnerabilityInformationData(params) {
        return Xhr.get(`/leak/${params.id}?lang=${sessionStorage.getItem('lang')}`);
    }
    getReviewConclusionData(params) {
        return Xhr.get(`/ReviewConclusion?lang=${sessionStorage.getItem('lang')}`, { params:params });
    }

    submitComments(params) {
        return Xhr.post(`/leak/reply/`, params);
    }

    getCaptcha() {
        return Xhr.get('/captcha/');
    }
    //下载
    download(params) {
      return Xhr.get(`/leak/download/${params.id}`, { responseType: 'blob' })
  }
    downloadReview(params) {
      return Xhr.get(`leak/reply/download/${params.id}`, { responseType: 'blob' })
  }
}

// 实例化再导出
export default new BugSubmitListService();