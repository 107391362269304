import React from 'react';
import '../style/privacyPolicy.css';
export default (function (props) {
	return (
		<div className='PrivacyPolicyDiv'>
			<h2>TECNO Security Response Center Privacy Policy</h2>
			<p className='PolicyDate'>Updated on: September 20, 2023</p>
			<br className='clear' />
			<p className='PolicyDate'>Effective Date: September 20, 2023</p>
			<br className='clear' />
			<p className='PolicyDate'>Version No.: 20220920V3</p>
			<br className='clear' />
			<p>
				Shanghai Transsion Co., Ltd.(hereinafter referred to as "TECNO
				Security Response Center", "we", "us", or "our") is the provider
				and operator of the application software and services of TECNO
				Security Response Center (hereinafter referred to as "the
				Software"). We fully respect your privacy rights and will try
				our utmost to keep your personal information secure. As part of
				the products or services (collectively referred to as "the
				Services"), we may collect and use your personal information
				with your consent or as required by applicable law. The Privacy
				Policy (hereinafter referred to as "the Policy") is intended to
				help you understand how we collect, use, disclose, process, and
				protect the personal information you provide when you are using
				the products or services of TECNO Security Response Center, your
				rights to such personal information, and how we safeguard your
				rights. The Policy applies to all products and services provided
				by TECNO Security Response Center, including the applications,
				mini programs, and webpages for PCs and mobile intelligent
				terminals, software development kits (SDKs) and application
				programming interfaces (APIs) for third-party websites and
				applications, or those that may emerge in other forms in the
				future.
			</p>
			<p>
				<span className='bold'>
					It should be specially noted that the Policy is not
					applicable to the services offered to you by any third
					parties. You can receive contents or network links from
					third parties. Please be cautious about whether to access
					the links, contents, products, and services provided by
					third parties. Before submitting your personal information
					to any third party, please carefully review its privacy
					policy.
				</span>
			</p>
			<p>
				<span className='bold'>
					To ensure that you fully understand the Policy and make your
					own decisions, we will explain the Policy as clearly as
					possible by underlying or highlighting the critical
					information (especially in bold, capital, underlined, and
					other forms). Moreover, when you use our services for the
					first time, prompts will pop up, reminding you to pay
					attention to and understand the core contents of the Policy.
				</span>
			</p>
			<p>
				Please read the Policy carefully to understand our practices
				with respect to your personal information before using our
				products or services, submitting personal information to us, or
				granting us consent to collect your personal information, and
				please visit often to stay informed of updates to the Policy. We
				will post changes to the Policy through relevant products or
				websites. By accessing our services after such notice, you agree
				to the updated privacy policy. You can view the "Update Date" at
				the top of the Privacy Policy page to confirm the latest update
				time.
			</p>
			<p>
				If you have any questions, please feel free to contact us via:
			</p>
			<p>Privacy@TECNO.com</p>
			<p>You can also access the Policy via:</p>
			<p>
				<span className='bold'>TECNO Security Response Center</span>{' '}
				&gt; Bottom of the Official Website &gt;{' '}
				<span className='bold'>Privacy Policy</span>
			</p>
			<p>
				<span className='bold'>
					The Privacy Policy will help you understand the following:
				</span>
			</p>
			<p>I.How we collect and use your personal information</p>
			{/* <p>.How do we use cookies and others similar technologies</p> */}
			<p>II.How we store and protect your personal information</p>
			<p>
      III.How we share, transfer, and disclose your personal
				information
			</p>
			<p>IV.How is your personal information transferred across borders</p>
			<p>V.Your rights to your own personal information</p>
			<p>VI.How we process children's personal information</p>
			<p>VII.Third-party service providers and their services</p>
			<p>VIII.Supplements to and updates of the Policy</p>
			<p>IX.How to contact us</p>
			<p>
				<span className='bold'>
					I.How we collect and use your personal information
				</span>
			</p>
			<p>
				<span className='bold'>i.Overview</span>
			</p>
			<p>
				TECNO Security Response Center believes spanly that fundamental
				privacy rights shall not differ depending on where you live in
				the world. That's why we treat any information that relates to
				an identified or identifiable individual as "personal
				information", no matter where the individual lives, particularly
				those of name, identify, location, online identifier or
				physical, physiological, genetic, mental, or any other related
				characteristics that can reasonably be used to identify the
				individual.
			</p>
			<p>
				We only collect the information necessary for special, specific,
				explicit, and legitimate purposes and shall ensure that your
				information will not be further processed for any other
				purposes. We will request your consent again if we intend to use
				your personal information for any purposes not specified herein.
			</p>
			<p>
				<span className='bold'>
					We will collect your personal information, including 1) the
					information you provide directly (such as the nickname,
					avatar, name, and telephone number you provide when you sign
					in to the account and sign up for our marketing activities);
					2) the information generated during your use of the Software
					(such as device information and service log information),
					which will be automatically collected by us; and 3) the
					information we acquire from other sources.
				</span>
			</p>
			<p>
				You have the right to decide whether to provide your personal
				information when using our products or services or when
				requested by us. You are not obliged to provide us with your
				personal information, but in some cases, if you decide not to
				provide your personal information, we may be unable to offer you
				the relevant products or services, nor respond to or resolve the
				problems you might encounter with. We will only collect and use
				your personal information for the purposes stated herein. The
				following are examples explaining why we collect your personal
				information:
			</p>
			<p>
				<span className='bold'>
					ii.Main Service Functions and Information Collection and Use
					Scenarios of TECNO Security Response Center
				</span>
			</p>
			<p>
				<span className='bold'>
					The Software is mainly to provide you with online accounts,
					security vulnerability reports, and other services.
				</span>
			</p>
			<p>
				<span className='bold'>1.Account Sign Up and Sign In</span>
			</p>
			<p>
				To make full use of the functions and services of the Software,
				you can sign up for an account and sign in with your email
				address and password. For signing up, we may request you to
				select or enter your country/region. Moreover, you can sign in
				to our platform using a third-party account (for example, a
				Facebook account). In this case, we will access your OPEN ID and
				establish a corresponding binding association. If you refuse to
				provide the said information, you would not be able to sign up
				for an account on the TECNO Security Response Center and use
				some functions, but you can still browse and use the basic
				business functions of the Software in guest mode (i.e., you are
				not a registered user).
			</p>
			<p>
				For signing up, signing in, and use of the Software,{' '}
				<span className='bold'>
					you can choose to supplement, enter, and modify your
					nickname (including real name), avatar (including real
					portrait), and social connections at your own discretion.
				</span>{' '}
				Nonetheless, your refusal to supplement the said information
				will not impair the sign-up and sign-in experience.
			</p>
			<p>
				If you sign in to our platform, we will acquire your IP address
				to ensure that you are using the Services in a secure
				environment, and we will generate data statistics based on your
				IP address (notice that we only need to analyze the countries
				and regions where our users are distributed, and we do not need
				to know the identity of any individual user).
			</p>
			<p>
				<span className='bold'>
					2.Security Vulnerability Test Platform
				</span>
			</p>
			<p>
				As we are committed to continuously improving the security
				performance of our products, we would invite you to detect
				product security vulnerabilities. Our security vulnerability
				test platform is open to security researchers only. To verify
				that you are qualified for security research, you need to
				provide the links to your technical articles and publications
				and the proof of your participation in any contests and
				competitions. We will determine whether you are qualified to
				obtain a prototype based on your submissions. If we decide that
				you are qualified, you need to submit your email address, name,
				phone number, and delivery address so that we can send a
				prototype to you.
			</p>
			<p>
				If you find any security vulnerability of the product, you can
				submit the related information to the TECNO Security
				Vulnerability Platform. In this case, we will acquire the
				information you submit, including the vulnerability standard,
				type, sub-type, level, and description, as well as other
				relevant documents proving such vulnerability. We will evaluate
				your contribution and include your name in the list of
				contributors according to the vulnerability information you have
				submitted.
			</p>
			<p>
				In case that you can receive vulnerability feedback rewards
				according to relevant rules, we will need your corresponding
				bank account information to issue the rewards. Specifically, if
				you are a user in Mainland China, you need to provide your payee
				information (
				<span className='bold'>
					name, ID number, and mobile phone number
				</span>
				), receiving bank information (
				<span className='bold'>
					bank account number, bank name, and country of the bank
				</span>
				), and the signing status on the ipayun platform. If you are not
				a user in Mainland China, we will obtain your{' '}
				<span className='bold'>
					Bank Account Country, Payee's Name, Payee's Account Number,
					Bank Name, Bank's SWIFT CODE, Bank Country, Bank City, Bank
					Address, Bank Routing Number, Intermediary Bank Name, and
					Intermediary Bank's SWIFT CODE
				</span>
				. The above information is necessary for issuing rewards. If you
				refuse to provide such information, we may not be able to issue
				rewards to you properly, but you can still use other services on
				this website normally.
			</p>
			<p>
				<span className='bold'>iii.Other Cases</span>
			</p>
			<p>
				Where permitted by law, we may collect your personal information
				from other individuals, business entities, public channels, or
				other third parties indicated by you, as well as other partners
				who help us provide our products or services or assist us in
				ensuring information security and preventing frauds, and other
				legitimate avenues. We will inform you and collect your
				information with your consent.
			</p>
			<p>
				We may also collect other information about you, your device, or
				your use of the Services in other ways that will be described to
				you at the time of collection for your consent.
			</p>
			<p>
				To improve our product layout and offer you better services, we
				may carry out researches, statistical analyses, and predictions
				on the collected information in an anonymized manner (including
				machine learning or model algorithm training based on anonymized
				data). We will take technical measures and other necessary
				actions to process the collected personal information so that
				such information cannot identify any individual person and
				cannot be recovered. As permitted by applicable laws and
				regulations, we do not need to inform you separately for your
				consent for the use of such anonymized data.
			</p>
			<p>
				<span className='bold'>
					II.How we store and protect your personal information
				</span>
			</p>
			<p>1. How we store your personal information</p>
			<p>
				We will store your personal information that we collected upon
				your consent in accordance with the relevant national laws and
				regulations concerning personal privacy and data protection. We
				may also store the personal information we collect in countries
				or regions other than the country in which we do business,
				provided that local laws and regulations do not prohibit such
				storage.
				<br />
				At present, we may store your personal information in Mainland
				China, Russia, and India. Specifically:
			</p>
			<p>
				The personal information we collect and generate in the course
				of our operations in Mainland China will be stored on servers
				located in Mainland China.
			</p>
			<p>
				The personal information we collect and generate in the course
				of our operations in Russia will be stored on servers located in
				Russia.
			</p>
			<p>
				The personal information we collect and generate in the course
				of our operations outside Mainland China and Russia will be
				stored on servers located in India.
			</p>
			<p>
				We may use the servers in other areas or countries depending on
				our business development, and in such cases, we will update the
				Policy accordingly.
			</p>
			<p>
				Whether personal information is stored in the country where we
				do business or in another country or region without violating
				the law, we will store it in accordance with the relevant laws
				and regulations regarding data storage. We will take necessary
				security measures considering the characteristics, scope,
				purposes of personal information processing, the possible risks
				in processing personal information, and the serious consequences
				in case of any damages. For example, we will encrypt and
				de-identify the collected personal information before storage.
				We will store the information only necessary for our business
				development within the minimum retention period unless otherwise
				permitted by laws or you agree to extend the retention period.
				We will delete or take other appropriate measures to delete data
				that exceeds the retention period or that you request us to
				delete, provided that this does not violate the relevant laws
				and regulations.
			</p>
			<p>2. How we protect your personal information</p>
			<p>
				We will also take other necessary measures to protect your
				personal information from misuse, unauthorized access,
				modification, disclosure, or destruction.
			</p>
			<p>
				We implement security measures designed to protect your personal
				information and regularly monitor our systems for possible
				vulnerabilities and attacks, and we use administrative,
				technical and physical safeguards to protect your personal
				information by considering the nature, processing methods, and
				security of the personal information. We will continuously
				strive to improve these measures to keep your personal data
				secure.
			</p>
			<p>
				You should be reminded that no measures can absolutely guarantee
				the avoidance of data storage security incidents. In the event
				of a security breach, we will report to the appropriate
				regulator in accordance with the requirements of relevant laws
				and regulations, and provide the type of personal data involved
				in the data breach, the number of data subjects, the possible
				consequences of the data breach, and the remedial measures we
				intend to take in accordance with the requirements of the
				regulator. We will take all possible measures to remedy or
				mitigate the damages and consequences caused by a data breach.
				If permitted or required by law, we will promptly inform you of
				the consequences or significant damage that may be caused to you
				as a result of a data breach, so that you can take appropriate
				measures to protect your rights to the greatest extent.
			</p>
			<p>
				Since no website, Internet transmission, computer system, or
				wireless connection is absolutely secure, in addition to the
				necessary security measures we take, users need to be more aware
				of privacy and security risks. For example, if you are
				redirected to a third party's website, link, product, or service
				through our service, please be careful and read the terms of
				agreement of such website, link, product, or service carefully
				for the security of any data information collected by such
				website, link, product or service, and we will not be liable for
				any data security issues caused by such third party. If you find
				that certain content, advertisements, or features of our
				website, products, or services may be provided by third parties
				and may endanger your privacy and security, please contact us
				promptly and we will give priority to dealing with them in
				accordance with the law.
			</p>
			<p>
				<span className='bold'>
					III.How we share, transfer, and disclose your personal
					information
				</span>
			</p>
			<p>
				We will share, transfer, and disclose your personal information
				to the minimum extent and only when it is necessary for
				operations or required by law. We will not share, transfer, or
				disclose your personal information unless otherwise required or
				permitted by applicable laws and regulations in the following
				circumstances:
			</p>
			<p>1. How we share your personal information</p>
			<p>
				We do not sell any personal information to third parties. To
				offer you timely services, we may share your personal
				information with our affiliates. We will only share your
				information within TECNO Security Response Center affiliates for
				lawful and explicit purposes, and only as necessary to provide
				the Services and subject to the stated purposes in this Policy.
				For any purposes not covered herein, we will make explicit
				statements in the privacy policy or notice for the specific
				product or service.
			</p>
			<p>
				We may share necessary personal information with authorized
				third-party partners (including technical service providers,
				data storage service providers, after-sales service providers,
				marketing service providers, and third-party SDK providers,
				among others) to perform certain functions or to provide you
				with better services and user experience. Specifically, our
				cloud service provider will help us ensure the normal operation
				of the website. If you are a user in Mainland China, our service
				provider, the ipayun platform, will help us issue rewards based
				on the information required for payment when doing so. We will
				share those information to the minimum extent and only when it
				is necessary for operations or required by law. Before sharing,
				we will sign stringent confidentiality agreements with our
				authorized partners, requiring them to take relevant
				confidentiality and security measures to process personal
				information in accordance with this Policy and the applicable
				laws of your jurisdiction. Moreover, we will require them to
				provide data security functions and information security
				qualifications (such as classified protection assessment,
				information security management system, etc.). When embedding
				SDKs or any other similar applications of our authorized
				partners in the Software, we will do our due diligence to
				request the SDKs that obtain the information to protect the
				security of your data.
			</p>
			<p>
				We may also share your non-personal information with third
				parties to provide evaluation, analysis, or other business
				services. For example, we may use the aggregated information to
				help our partners (such as advertising service providers)
				understand the effectiveness, feedback, and usage trends of
				their services.
			</p>
			<p>
				In the event of a merger, acquisition or bankruptcy and
				liquidation, transfer of assets, or other related transactions
				involving the transfer of personal information, we will require
				by agreement or other appropriate measures that the company or
				organization newly holding your personal information continues
				to be bound by the Policy and that such subject takes
				confidentiality and security measures no less stringent than
				those required by the Policy to handle personal information.
			</p>
			<p>2. How we transfer your personal information</p>
			<p>
				The TECNO Security Response Center will not transfer your
				information to any other parties unless:
			</p>
			<p>(1) with your express consent;</p>
			<p>
				(2) we post a prominent notice via email and/or our website or
				other appropriate methods to inform you of any changes in your
				ownership of, rights to, and choices regarding your personal
				information when TECNO Security Response Center is involved in a
				merger, acquisition or sale of all or part of its assets that
				may affect your personal information; or
			</p>
			<p>
				(3) as provided for in this Privacy Policy or otherwise notified
				to you.
			</p>
			<p>3. How we disclose your personal information</p>
			<p>
				The TECNO Security Response Center may disclose your personal
				information as required by law, legal process, litigation,
				and/or government departments and authorities. We may also
				disclose your personal information if the disclosure is
				necessary or appropriate for national security, law enforcement,
				or other matters of public importance.
			</p>
			<p>
				In addition, we may share your personal information with the
				following people:
			</p>
			<p>
				(1) our lawyers, accountants, auditors, or similar consultants,
				when we request them to provide professional advice.
			</p>
			<p>
				(2) investors and other related third parties, if actual or
				potential sales or other transactions with another company occur
				and are related to entities within the TECNO group.
			</p>
			<p>
				(3) other third parties, for example, when specific information
				is disclosed to them under your authorization.
			</p>
			<p>
				<span className='bold'>
					IV.How is your personal information transferred across
					borders
				</span>
			</p>
			<p>
				For the purposes described in this Privacy Policy, we may
				transfer your personal information to the resources or servers
				mentioned in Article III of the Policy for storage or
				processing, and we may also transfer your personal information
				to third-party service providers and business partners, and
				therefore your data may be transferred to other countries or
				regions, subject to compliance with applicable laws and
				regulations in your region and with your authorized consent. The
				privacy laws in these countries or regions may differ, and the
				jurisdictions in which these servers are located may or may not
				protect personal information to the same extent as those in your
				jurisdiction. However, we will still strive to protect your
				personal information based on our Privacy Policy and carry out
				the cross-border transfer and other processing of corresponding
				information as required by the laws of the country or region
				where the personal information is located.
			</p>
			<p>
				If we transfer personal information outside of your
				jurisdiction, whether to our affiliates or to a third-party
				service provider, we will comply with the relevant applicable
				laws. We will take appropriate security and protection measures
				to ensure that all such transfers are subject to the
				requirements of applicable local data protection laws.
			</p>
			<p>
				<span className='bold'>
					V.Your rights to your own personal information
				</span>
			</p>
			<p>
				As a data subject, you have a number of rights with respect to
				personal information such as confirmation, access, correction,
				deletion, withdrawal of consent, complaints (hereinafter
				referred to as "requests"), etc. We fully understand and respect
				these rights, which are also subject to specific exclusions and
				exceptions in accordance with applicable law. We will also take
				active steps to ensure that your rights are effectively
				guaranteed. However, please understand and note that for
				security reasons, we may need to verify your identity before
				processing your request. In principle, we charge no fees for
				your legitimate requests. However, for repeated requests
				exceeding reasonable limits, we will charge a reasonable fee to
				the extent permitted by applicable laws based on actual
				administrative costs as appropriate. We may deny requests that
				are unwarrantedly repetitive, require excessive technical means
				(for example, requiring the development of new systems or
				fundamental changes in current practices), pose a risk to the
				legal rights of others or are highly impractical, or violate
				mandatory legal requirements.
			</p>
			<p>
				<span className='bold'>1. Right to confirm and access:</span> We
				inform you of how we collect and process your personal
				information via this Privacy Policy or through privacy policies
				or notices for specific product service scenarios. You have the
				right to confirm or reject such collection and processing by
				checking the box or via other methods. However, if you reject
				the service, some functions that require your express authorized
				consent before use may no longer be available. The personal
				information you have provided to us can be accessed directly
				from the interface of our products or services. In case of
				failure to access, you can email us at Privacy@TECNO.com, and we
				will deal with it promptly.
			</p>
			<p>
				<span className='bold'>2. Right to correct and delete:</span>{' '}
				When your personal information changes, or when you find that
				the information we collected and processed is inaccurate or
				incomplete, you have the right to request us to make corrections
				or additions. For some of your personal information, you can
				correct and amend it directly on the relevant function page of
				the product or service. You have the right to request us to
				delete your personal information when we have achieved the
				purpose of collecting your personal information or it is no
				longer necessary, or when you find that we have collected or
				processed your personal information in violation of the
				provisions of laws and regulations or the agreement of this
				Privacy Policy. For any information that you have no access to
				correct or delete, you can email us at Privacy@TECNO.com to
				request for correction or deletion.
			</p>
			<p>
				However, we may not approve your request for deletion in some
				cases, such as when you ask us to delete transaction data, and
				the TECNO Security Response Center is legally obliged to keep
				records of the transaction to comply with applicable local laws.
				Please also note that if you request the deletion of your
				information from our products and services, due to certain
				applicable local laws and technical limitations, we may not
				immediately delete the corresponding information from our backup
				systems, but we will securely store your personal information
				and prevent its further processing until the backup systems are
				updated and your information can be deleted or anonymized.
			</p>

			<p>
				<span className='bold'>3. Right to withdraw consent:</span> For
				our different products or services to perform the corresponding
				functions, we will require the necessary personal information in
				accordance with the applicable local laws. This is described in
				the "How we collect and use your personal information" of this
				Privacy Policy. You may withdraw consent previously granted to
				us for specific purposes, including the collection, use, and/or
				disclosure of your personal information in our possession or
				control, by deleting the information, disabling the
				functionality of the device, or withdrawing the consent request.
				You can email us at Privacy@TECNO.com for relevant operations.
				We will process your request within a reasonable time after you
				send it, and then will not collect, use, and/or disclose your
				personal information according to your request.
			</p>
			<p>
				If you withdraw your consent, you may not continue to have
				access to some and/or all of the features of TECNO Security
				Response Center products and services. The withdrawal of your
				consent or authorization will not affect the validity of our
				previous processing activities based on your consent.
			</p>
			<p>
				<span className='bold'>4. Right to complain:</span> You have the
				right to email us at Privacy@TECNO.com for a complaint. We will
				respond promptly to your complaint. If you are not satisfied
				with our response, especially if our personal information
				processing practices have harmed your legitimate rights and
				interests, you may also file a complaint or report to the local
				statutory personal data protection authority, or file a lawsuit
				with a court of competent jurisdiction.
			</p>
			<p>
				<span className='bold'>
					5. Right to control your privacy settings
				</span>
			</p>
			<p>
				You have the right to control your privacy settings, which allow
				you to restrict our collection, use, disclosure, or processing
				of your personal information. For example, you can choose to
				turn on or off the functions and permissions of{' '}
				<span className='bold'>Location Access, Camera</span>, and{' '}
				<span className='bold'>SMS</span>. You can also get more
				detailed information about the security status of your device in{' '}
				<span className='bold'>Settings</span>. If you have previously
				agreed to enable the corresponding permissions or agreed to our
				use of your personal information based on the aforementioned
				business scenario, you can change the permission via{' '}
				<span className='bold'>
					Settings > Application Management > Permission Management
				</span>
				or by contacting us at Privacy@TECNO.com.
			</p>
			<p>
				<span className='bold'>
					6. Right to cancel a service or an account
				</span>
			</p>
			<p>
				<span className='bold'>
					You can request to cancel your account via the cancellation
					path provided in the Services or by contacting us at
					Privacy@TECNO.com.{' '}
				</span>
				Please be careful when you cancel your account. You understand
				that after you cancel your account, we will stop providing
				products and services based on that account. Unless otherwise
				required by applicable laws in your region, we will delete the
				corresponding personal data under your account at the same time.
				If you sign in to the Services through a third-party account,
				please request the third party to cancel your account.
			</p>
			<p>
				<span className='bold'>7. Right in some jurisdictions</span>
			</p>
			<p>
				Please pay attention that you may have the following data
				subject rights under applicable laws in certain jurisdictions:
			</p>
			<p>
				(1) Processing Restriction: You have the right to request our
				restriction on your personal information processing. We will
				review the reasons for your request. If the reasons are in
				accordance with applicable data protection laws, we will process
				your personal information only to the extent permitted by such
				laws and will notify you before lifting the restriction on
				processing.
			</p>
			<p>
				(2) Automated Processing: You have the right to refuse automated
				processing of your information, including performance analysis
				and other automated processing that may have a legal or similar
				impact on you.
			</p>
			<p>
				(3) Data Portability: You have the right to request the transfer
				of your personal information to another data controller in a
				structured and commonly used format.
			</p>
			<p>
				While we also have the right to refuse to process your request.
				We may reject some of your requests where an exemption applies
				or where we comply with applicable laws. For example, some
				requests are manifestly unfounded or excessive or require
				disclosure of third-party information.
			</p>
			<p>
				<span className='bold'>
					How we process children's personal information
				</span>
			</p>
			<p>
				The TECNO Security Response Center does not provide its products
				and services for children under the age of 14 or the equivalent
				legal age under the laws in your jurisdiction (the age of a
				child varies from country to country, and we will define a child
				based on the laws and regulations of the country or region in
				which we do business). We do not actively collect personal data
				from children and do not use it for marketing purposes. If you
				find that you have transferred information about such children
				to us, email us at Privacy@TECNO.com, and we will remove the
				information as soon as possible. If we find that we have
				collected children's personal information, we will also remove
				the relevant content as soon as possible.
			</p>
			<p>
				<span className='bold'>
					Third-party service providers and their services
				</span>
			</p>
			<p>
				When you use products or services (such as life service and
				online customer service) of the TECNO Security Response Center,
				you may receive links to third-party websites or content and
				services provided by third parties other than us, and some
				products or services may be provided in the form of SDKs or
				other means. You can choose whether to access or accept
				websites, products, and services provided by third parties based
				on your judgment. Our Privacy Policy does not apply to products
				and services provided by any third party. Since we do not have
				substantial control over a third party, we cannot be responsible
				for the collection and processing of your personal information
				by that third party. When you submit any information to that
				third party, please be aware of that third party's privacy
				policy and take care to protect your personal information.
			</p>
			<p>
				<span className='bold'>
					VIII.Supplements to and updates of the Policy
				</span>
			</p>
			<p>
				We reserve the right to update or modify the Policy from time to
				time as our business, technology, applicable laws, and
				operations change. For any changes to the Policy, we will notify
				you by posting the updated policy here (on this page), by
				posting it on our website at https://security.TECNO.com/, or by
				sending it to you as a separate notice. Please regularly check
				this page or the notifications we send you to stay informed of
				updates to this Privacy Policy. This Privacy Policy is subject
				to change, but we will not diminish your rights under this
				Policy without your express consent.
			</p>
			<p>
				We will seek your express consent as required by applicable laws
				before we collect other information from you or use or disclose
				your information for a new purpose. If you continue to use
				products and services on the website, mobile device, and/or any
				other devices, you will be bound by the updated Privacy Policy.
			</p>
			<p>
				The content of products or services of the TECNO Security
				Response Center stated in the Policy may change depending on the
				type of product you are using, the version of the system, or the
				requirements of local laws and regulations.
			</p>
			<p>
				<span className='bold'>IX.How to contact us</span>
			</p>
			<p>
				If you have any suggestions, questions, or concerns about our
				Privacy Policy or practices regarding the protection of personal
				information, contact us at:
			</p>
			<p>
				Email: Privacy@TECNO.com (Note: The email is used for receiving
				emails related to our Privacy Policy or our information
				protection only)
			</p>

			<p>
				We will review your request regarding privacy or personal
				information as soon as possible and reply to you within 15–30
				days after verifying your user identity. If your request
				involves complex or significant issues, we may ask you for
				further information.
			</p>
			<p>
				If you are not satisfied with our response, especially if your
				legitimate interests have been violated by us, you can seek a
				solution from the relevant data protection supervisory authority
				in your jurisdiction. If you make the above inquiry to us, we
				will provide you with information on the relevant complaint
				channels that may apply to your case.
			</p>
			<p>Thank you for your time to read our Privacy Policy!</p>
		</div>
	);
});
