import React, {useEffect, useState} from 'react';
import './style/leaderboards.css';
import {useIntl} from 'react-intl';
import {scrollToTop} from '../../common/topScorll';
import {Select, Radio, Table, Button, Popover} from 'antd';
import {
	getLeaderboardsList,
	changeleaderboardsType,
	changeYearSelect,
	changeMonthSelect
} from '../../action/leaderboards/leaderboardsAction';
import {useHistory} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {goRouterPosition} from '../../action/routerposition/routerpositionAction';
import LeaderboardsService from '../../services/leaderboardsService';
import store from '../../store/index';
import avstar from '../../assets/svg/ic-个人中心-头像.svg';
import goldMedal from '../../assets/svg/icon_goldMedal.svg';
import silverMedal from '../../assets/svg/icon_silverMedal.svg';
import bronzeMedal from '../../assets/svg/icon_bronzeMedal.svg';
import ReputationImg from '../../assets/svg/ic-漏洞列表-贡献值.svg';
import SubmittedImg from '../../assets/svg/ic-漏洞列表-已提交.svg';

export default (function (props) {
	const intl = useIntl();
	const [state, setState] = useState(store.getState().Leaderboards);
	const [yearList, setYearList] = useState([]);
	const [monthList, setMonthList] = useState([]);
	const history = useHistory();
	const {Option} = Select;

	const medalCardContent = (item, userId) => {
		return (
			<div className='mdealCardDiv'>
				<div className='medalCardTitleDiv'>
					<img
						src={item.showMedalIconOss}
						className='medalCardTitleImg'
						alt='img'
					/>
					<div className='medalCardTitleText'>
						<div className='medalCardTitleNameDiv'>
							<div className='dashBoardMedalCardTitleName'>
								<div>{item.safetyMedalI18n?.medalName}</div>
								<div
									style={{
										color: '#0264fa',
										cursor: 'pointer',
										fontSize: '14px'
									}}
									onClick={() => {
                    localStorage.setItem('payoutJump', true)
										window.open(
											userId
												? `/SRC/publicProfile/?userId=${userId}`
												: `/SRC/publicProfile`
										);
									}}>
									{intl.formatMessage({
										id: 'intl.dashboard.medalCard.jumpText'
									})}
								</div>
							</div>
						</div>

						{item.safetyMedalI18n?.activityName && (
							<div
								className='medalCardTitleTag'
								onClick={() =>
									window.open(
										item.safetyMedalI18n?.activityUrl
									)
								}>
								# {item.safetyMedalI18n?.activityName}
							</div>
						)}
						<div className='medalCardTitleUserCount'>
							{item.safetyMedalUserCount}
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.userCount'
							})}
						</div>

						{!item.safetyMedalI18n?.activityName && (
							<div style={{height: '20px'}}></div>
						)}
						<div className='medalCardTitleObtainStatus'>
							<label>
								{intl.formatMessage({
									id: 'intl.personalinfo.medalCard.unlockedTime'
								})}
								{item.cardCreateTime}
							</label>
							<label style={{margin: '0 8px'}}>|</label>
							<label>
								{item.cardEndTime ? (
									<>
										{intl.formatMessage({
											id: 'intl.personalinfo.medalCard.expiredTime'
										})}
										{item.cardEndTime}
									</>
								) : (
									<>
										{intl.formatMessage({
											id: 'intl.personalinfo.medalCard.longTerm'
										})}
									</>
								)}
							</label>
						</div>
					</div>
				</div>
				<div className='medalCardIntro'>
					{item.safetyMedalI18n?.medalIntro}
				</div>
			</div>
		);
	};
	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = '排行榜')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'Hall of Fame'));
		scrollToTop();
		// sessionStorage.setItem('redirect', window.location.pathname);
    sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		goRouterPosition({routerPosition: 'leaderboards'});
		if (sessionStorage.getItem('leaderBoardType') === 'All') {
			changeleaderboardsType({target: {value: 'All Time'}});
		}
		LeaderboardsService.getYearListData()
			.then((res) => {
				setYearList(res.data);
				changeYearSelect(res.data[0].year);
				LeaderboardsService.getMonthListData({
					year: res.data[0].year
				})
					.then((res) => {
						setMonthList(res.data);
						changeMonthSelect(res.data[0].month);
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
		getLeaderboardsList();
		store.subscribe(storeChange);
	}, []);
	const storeChange = () => {
		setState(store.getState().Leaderboards);
	};
	const yearSelectChange = (value) => {
		changeYearSelect(value);
		LeaderboardsService.getMonthListData({
			year: value
		})
			.then((res) => {
				setMonthList(res.data);
				changeMonthSelect(res.data[0].month);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const monthSelectChange = (value) => {
		changeMonthSelect(value);
	};
	const jumpToAcknowledgements = () => {
		sessionStorage.setItem('blogType', 'News');
		if (sessionStorage.getItem('lang') == 'zh_CN') {
			sessionStorage.setItem('subType', '致谢');
		} else {
			sessionStorage.setItem('subType', 'Acknowledgements');
		}
		history.push('/SRC/blog');
	};
	const columns = [
		{
			title: intl.formatMessage({
				id: 'intl.leaderboards.table.columns.ranking'
			}),
			dataIndex: 'rank',
			width: 224,
			align: 'left',
			render: (text) => (
				<div>
					{(text == 1 && <img src={goldMedal} />) ||
						(text == 2 && <img src={silverMedal} />) ||
						(text == 3 && <img src={bronzeMedal} />) ||
						(text != 1 && text != 2 && text != 3 && (
							<label className='tabelLabel RankingLabel'>
								{text}
							</label>
						))}
				</div>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.leaderboards.table.columns.nickname'
			}),
			dataIndex: 'alienName',
			width: 423,
			align: 'left',
			render: (text, record) => (
				<div
					className='NicknameDiv'
					onClick={() => {
						window.open(
							record.userId
								? `/SRC/publicProfile/?userId=${record.userId}`
								: `/SRC/publicProfile`
						);
					}}>
					{/**个人卡片 */}
					{/* <Popover
                        placement="topLeft"
                        trigger="hover"
                        content={
                            (
                                <div>
                                    <div className="PopoverNicknameDiv">
                                        <div className="PopoverNicknameImgDiv">
                                            <img src={record.pictureUrl?record.pictureUrl:avstar} className="PopoverNicknameImg"/>
                                        </div>
                                        <label className="PopoverNickname">{record.alienName}</label>
                                    </div>
                                    <div className="PopoverReputationDiv">
                                        <label className="PopoverReputationLabel">
                                        {
                                            intl.formatMessage({
                                                id: 'intl.leaderboards.table.columns.nicknamecard.reputation'
                                            })
                                        }
                                        </label>
                                        <div className="PopoverReputationNumDiv">
                                            <img src={ReputationImg} className="PopoverReputationImg"/>
                                            <label className="PopoverReputationNum">{record?.userLeakInfo?.honorValue}</label>
                                        </div>
                                    </div>
                                    <div className="PopoverSubmittedDiv">
                                        <label className="PopoverSubmittedLabel">
                                        {
                                            intl.formatMessage({
                                                id: 'intl.leaderboards.table.columns.nicknamecard.submitted'
                                            })
                                        }
                                        </label>
                                        <div className="PopoverSubmittedNumDiv">
                                            <img src={SubmittedImg} className="PopoverSubmittedImg"/>
                                            <label className="PopoverSubmittedNum">{record?.userLeakInfo?.leakCount}</label>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    >
                        <div className="NicknameImgDiv">
                            <img src={record.pictureUrl?record.pictureUrl:avstar} className="NicknameImg"/>
                        </div>
                    </Popover> */}
          <div className='NicknameDiv1'>
					<div className='NicknameImgDiv'>
						<img
							src={record.pictureUrl ? record.pictureUrl : avstar}
							className='NicknameImg'
							alt='avatar'
						/>
					</div>
					<label
						className='tabelLabel NicknameLabel'
						style={{cursor: 'pointer'}}>
						{record?.alienName}
					</label>
          </div>
					{record.grantSafetyMedal?.medalIconOss && (
						<Popover
							color='#f8f8f8'
							placement='right'
							content={medalCardContent(
								record?.grantSafetyMedal,
								record?.userId
							)}
							title={null}>
							<img
								src={record?.grantSafetyMedal?.medalIconOss}
								alt='medal'
								className='dashBoardFameHallMedalImg'
							/>
						</Popover>
					)}
				</div>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.signup.placeholder.country'
			}),
			dataIndex: 'countryName',
			width: 473,
			align: 'left',
			render: (text) => <label className='tabelLabel'>{text||'--'}</label>
		},
		{
			title: intl.formatMessage({
				id: 'intl.leaderboards.table.columns.reputation'
			}),
			dataIndex: 'honorValue',
			width: 473,
			align: 'left',
			render: (text) => <label className='tabelLabel'>{text}</label>
		}
	];
	const onChange = (pagination, filters, sorter, extra) => {
		console.log('params', pagination, filters, sorter, extra);
	};
	return (
		<div className='leaderboardsContainer'>
			<div className='leaderboardsHeader'>
				<div className='leaderboardsHeaderContent'>
					<p className='leaderboardsTitle'>
						{intl.formatMessage({
							id: 'intl.leaderboards.title'
						})}
					</p>
				</div>
			</div>
			<div className='leaderboardsContent'>
				<div className='leaderboardsTypeRadio'>
					<Radio.Group
						value={state.leaderboardsType}
						onChange={changeleaderboardsType}>
						<Radio.Button value='Monthly'>
							{intl.formatMessage({
								id: 'intl.leaderboards.type.monthly'
							})}
						</Radio.Button>
						<Radio.Button value='Annual'>
							{intl.formatMessage({
								id: 'intl.leaderboards.type.annual'
							})}
						</Radio.Button>
						<Radio.Button value='All Time'>
							{intl.formatMessage({
								id: 'intl.leaderboards.type.alltime'
							})}
						</Radio.Button>
					</Radio.Group>
					<div>
						{(state.leaderboardsType == 'Monthly' ||
							state.leaderboardsType == 'Annual') && (
							<Select
								className='yearSelect'
								onChange={yearSelectChange}
								showSearch
								value={state.yearSelect}
								placeholder='year'>
								{yearList.map((data, index) => (
									<Option key={index} value={data.year}>
										{data.year}
									</Option>
								))}
							</Select>
						)}
						{state.leaderboardsType == 'Monthly' && (
							<Select
								className='monthSelect'
								onChange={monthSelectChange}
								showSearch
								value={state.monthSelect}
								placeholder='month'>
								{monthList.map((data, index) => (
									<Option key={index} value={data.month}>
										{data.month}
									</Option>
								))}
							</Select>
						)}
					</div>
					<Table
						className='leaderboardsTable'
						columns={columns}
						rowKey={(record) => record.id}
						dataSource={state.leaderboardsList}
						pagination={state.pagination}
						onChange={getLeaderboardsList}
					/>

					{state.leaderboardsList.length == 0 && (
						<label className='ListTotal'>
							{intl.formatMessage({
								id: 'intl.leaderboards.pagination.total'
							})}{' '}
							{state.pagination.total}{' '}
							{intl.formatMessage({
								id: 'intl.leaderboards.pagination.totalnum'
							})}
						</label>
					)}
				</div>
			</div>
			<div className='leaderboardsJumpAcknowledgementDiv'>
				<div className='leaderboardsJumpAcknowledgementText'>
					{intl.formatMessage({
						id: 'intl.leaderboards.Acknowkledgements'
					})}
				</div>
				<Button type='primary' onClick={() => jumpToAcknowledgements()}>
					{intl.formatMessage({
						id: 'intl.leaderboards.Acknowkledgements.Button'
					})}
				</Button>
			</div>
		</div>
	);
});
