import React from 'react';
// 引入加载路由视图得函数组件

import {Route,Redirect} from 'react-router-dom'
import RouterView from '../../router/index'
import Footer from '../footer/index'
import Header from '../header/index'
// 引入路由配置表

class Home extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		let {match, routes} = this.props;
		return (
			<div className="homeContainer">
                <Route path='/SRC' exact render={()=> (
                    <Redirect to='/dashboard'/>
                )}/>
                <Header></Header>
				<RouterView routes={routes}/>
                <Footer></Footer>
			</div>
		)
	}

}
export default Home