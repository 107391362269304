import {React} from 'react';
import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import lds from 'lodash';
import {useIntl} from 'react-intl';
export default (function (props) {
	const chartRef = useRef();
  const intl = useIntl();
	let options = {
		tooltip: {
			trigger: 'item',
      textStyle: {
        fontFamily:'HurmeGeometricSans3',
      },
		},
		color: ['#02AAF9', '#6CE5E8', '#0264FA'],
		legend: {
			top: '30%',
			left: '0',
			orient: 'verticalAlign',
			icon: 'circle',
			itemWidth:8,
			formatter: function (name) {
				let temp;
				if (name === intl.formatMessage({id: 'intl.rewardsDetails.pieChart.activityReward'})) {
					temp = props.value?.activityReward;
				} else if (name === intl.formatMessage({id: 'intl.rewardsDetails.pieChart.securityAuthorReward'})) {
					temp = props.value?.securityAuthorProgram;
				} 
        // else if (name === 'Vulnerability Reward') {
				// 	temp = props.value?.vulnerabilityReward;
				// }
				var arr = ['{a|' + name + '}', '{c|' + temp + '}'];
				return arr.join('  ');
			},
			textStyle: {
				rich: {
					a: {
						fontSize: 14,
            fontFamily:'HurmeGeometricSans3',
						width:70
					},
					c: {
						fontSize: 14,
            fontFamily:'HurmeGeometricSans3',
						padding: [0, 0, 0, 80]
					}
				}
			},
		},
		series: [
			{
				name: 'Status of my reports',
				type: 'pie',
				center: ['82%', '45%'],
				// radius:'70%',
				radius:['55%', '80%'],
				avoidLabelOverlap: false,
				label: {
					show: false,
					position: 'center'
				},
				data: [
					{value: 0, name:intl.formatMessage({id: 'intl.rewardsDetails.pieChart.activityReward'})},
					{value: 0, name:intl.formatMessage({id: 'intl.rewardsDetails.pieChart.securityAuthorReward'})},
					// {value: 0, name:'Vulnerability Reward' }
				]
			}
		]
	};
	const initChartOption = () => {
		const tempOptions = lds.cloneDeep(options);
		tempOptions.series[0].data = [
			{value: props.value?.activityReward, name:intl.formatMessage({id: 'intl.rewardsDetails.pieChart.activityReward'})},
			{value: props.value?.securityAuthorProgram, name:intl.formatMessage({id: 'intl.rewardsDetails.pieChart.securityAuthorReward'})},
			// {value: props.value?.vulnerabilityReward, name: 'Vulnerability Reward'}

		];
		return tempOptions;
	};
	useEffect(() => {
		let initOption = initChartOption();
		const chart = echarts.init(chartRef.current);
		chart.setOption(initOption);
		window.addEventListener('resize', () => {
			chart.resize();
		});
		return () => {};
	});
	return (
		<div
			ref={chartRef}
			style={{width: '100%', height: '100%', boxShadow: '0px 0px'}}></div>
	);
});
