const en_US = {
    //Dashboard
    'intl.dashboard.banner.title': "TECNO",
    'intl.dashboard.banner.subtitle': "Security Response Center",
    'intl.dashboard.banner.submitbutton': "Security Reporting",
    'intl.dashboard.banner.viewRulesButton': "View Rules",
    'intl.dashboard.banner.menu.blogevent': "Resources",
    'intl.dashboard.banner.menu.awards': "Bounty Program",
    'intl.dashboard.banner.menu.guide': "Documentations",
    'intl.dashboard.blogevent.title': "Resources",
    'intl.dashboard.blogevent.subTitle': "News and Blogs",
    'intl.dashboard.activity.title': "Activities",
    'intl.dashboard.activity.subTitle': "Regular Activities",
    'intl.dashboard.awards.title': "Bounty Program",
    'intl.dashboard.awards.subtitle': "TECNO appreciates every security researcher to help improve the security.",
    'intl.dashboard.awards.subcontent1': "We believe that close partnership with researchers can make us better.",
    'intl.dashboard.awards.subcontent2': "TECNO security response center promises to handle every report you submitted in a fair manner and offers public recognition for those who submit valid reports.",
    'intl.dashboard.awards.rule': "Reward Program Rules",
    'intl.dashboard.awards.submitbutton': "Security Reporting",
    'intl.dashboard.fameHall.title': 'Monthly Hall of Fame',
    'intl.dashboard.fameHall.linkHint1': 'TECNO appreciates the contribution of every security researcher. ',
    'intl.dashboard.fameHall.linkHint2': 'Read the acknowledgements',
    'intl.dashboard.fameHall.value': 'Base reputation：',
    'intl.dashboard.fameHall.jumplink': 'Rank List',
    'intl.dashboard.activeModel.title1': 'Apply for ',
    'intl.dashboard.activeModel.title2': 'Testing Devices',
    'intl.dashboard.activeModel.jumpLink1': 'Email to TECNO Security Response Center to join the ',
    'intl.dashboard.activeModel.jumpLink2': 'Learn More',
    'intl.dashboard.guide.title': "Documentations",
    'intl.dashboard.guide.submit': "Submission Guidelines",
    'intl.dashboard.guide.deal': "Process Flow",
    'intl.dashboard.guide.awards': "Reward Rules",
    'intl.dashboard.guide.apply': "Research Device",
    'intl.dashboard.guide.normalquestion': "FAQ",
    'intl.dashboard.signup.tip': "Be part of the commitment to TECNO security ",
    'intl.dashboard.signup.button': "Sign Up",

    //Header
    'intl.header.home': "Home",
    'intl.header.blog': "Resources",
    'intl.header.halloffame': "Hall of Fame",
    'intl.header.securityupdate': "Security Updates",
    'intl.header.swagshop': "Swagshop",
    'intl.header.login': "Log In",
    'intl.header.signup': "Sign Up",
    'intl.header.menu.vulnerability': "Vulnerability",
    'intl.header.menu.personalinfo': "Personal Profile",
    'intl.header.menu.exit': "Sign Out",
    'intl.header.securityreporting': "Security Reporting",
    'intl.header.quickEntranceMenu.header': "My Homepage",//newly added
    'intl.header.quickEntranceMenu.achievement': "My Achievement",//newly added
    'intl.header.quickEntranceMenu.rewardsDetails': "Rewards Details",//newly added
    'intl.header.quickEntranceMenu.payoutMethod': "Payout Method",//newly added

    //Footer
    'intl.footer.menu': "Navigation",
    'intl.footer.home': "Home",
    'intl.footer.blog': "Resources",
    'intl.footer.halloffame': "Hall of Fame",
    'intl.footer.securityupdate': "Security Updates",
    'intl.footer.document': "Documentations",
    'intl.footer.submit': "Submission Guidelines",
    'intl.footer.process': "Process Flow",
    'intl.footer.award': "Reward Rules",
    'intl.footer.apply': "Research Device",
    'intl.footer.normalquestion': "FAQ",
    'intl.footer.contact': "Contact Us",
    'intl.footer.privacypolicy': "Privacy Rules",
    'intl.footer.tecnoweb': "TECNO",
    'intl.footer.contactCarlcare': "Look for product support ?", //newly added
    'intl.footer.visitCarlcare': "Visit Carlcare",//newly added

    //login
    'intl.login.title': "Log in to TECNO SRC",
    'intl.login.placeholder.email': "Email",
    'intl.login.placeholder.password': "Password",
    'intl.login.placeholder.captcha': "Captcha",
    'intl.login.button.gologin': "Log In",
    'intl.login.button.gosignup': "Sign Up",
    'intl.login.button.goforgetpassword': "Forgot password?",
    'intl.login.button.gootherlogin': "Or",
    'intl.login.message.success': "Log in successfully",
    'intl.login.message.error.email': "Please enter email",
    'intl.login.message.error.password': "Please enter password",
    'intl.login.message.error.captchaCode': "Please enter captcha",

    //Sign Up
    'intl.signup.title': "Sign up to TECNO SRC",
    'intl.signup.placeholder.country': "Country/Region",
    'intl.signup.placeholder.email': "Email",
    'intl.signup.placeholder.password': "Set password",
    'intl.signup.placeholder.captcha.img': "Captcha",
    'intl.signup.placeholder.captcha.email': "Verification Code",
    'intl.signup.button.getcaptcha': "Get OTP",
    'intl.signup.button.aftergetcaptcha': " s",
    'intl.signup.button.gologin': "Log In",
    'intl.signup.button.gosignup': "Sign Up",
    'intl.signup.agreement.agree': "I have read and agree to the ",
    'intl.signup.agreement.policy': "Privacy Policy",
    'intl.signup.agreement.and': " and ",
    'intl.signup.agreement.plateform': "Platform Agreement",
    'intl.signup.agreement.content': " ",
    'intl.signup.message.success': "Succeeded",
    'intl.signup.message.error.passwordrule': "Password must be 8-16 characters and contain letters, numbers and special symbols",
    'intl.signup.message.error.chooseagreement': "Please check that you have read and agree to the platform agreement",
    'intl.signup.message.error.choosecountry': "Please check the country and region",
    'intl.signup.message.error.email': "Please enter email",
    'intl.signup.message.error.captchacode': "Please enter the captcha",
    'intl.signup.message.error.emailcaptchacode': "Please enter email verification code",
    'intl.signup.password.title': "Format requirements",
    'intl.signup.password.rule': "Password must be 8-16 characters and contain letters, numbers and special symbols",

    //Forget Password
    'intl.forgetpassword.button.back': "Back",
    'intl.forgetpassword.title': "Forgot Password",
    'intl.forgetpassword.subtitle': "Reset your login password by verifying your email verification code",
    'intl.forgetpassword.placeholder.email': "Email",
    'intl.forgetpassword.placeholder.password': "New password",
    'intl.forgetpassword.placeholder.captcha.img': "Captcha",
    'intl.forgetpassword.placeholder.captcha.email': "Verification Code",
    'intl.forgetpassword.button.getcaptcha': "Get OTP",
    'intl.forgetpassword.button.aftergetcaptcha': " s",
    'intl.forgetpassword.button.goforgetpassword': "Confirm",
    'intl.forgetpassword.message.success': "Succeeded",
    'intl.forgetpassword.message.error.email': "Please enter email",
    'intl.forgetpassword.message.error.captchacode': "Please enter the captcha",
    'intl.forgetpassword.message.error.emailcaptchacode': "Please enter email verification code",
    'intl.forgetpassword.message.error.passwordrule': "Password must be 8-16 characters and contain letters, numbers and special symbols",
    'intl.forgetpassword.password.title': "Format requirements",
    'intl.forgetpassword.password.rule': "Password must be 8-16 characters and contain letters, numbers and special symbols",

    //Bind Email Pwd
    'intl.bindemailpwd.button.back': "Back",
    'intl.bindemailpwd.title': "Bind email and set login password",
    'intl.bindemailpwd.subtitle': "Please bind your email before submitting the vulnerability",
    'intl.bindemailpwd.placeholder.country': "Country/Region",
    'intl.bindemailpwd.placeholder.email': "Email",
    'intl.bindemailpwd.placeholder.password': "Password",
    'intl.bindemailpwd.placeholder.captcha.img': "Captcha",
    'intl.bindemailpwd.placeholder.captcha.email': "Verification Code",
    'intl.bindemailpwd.button.getcaptcha': "Get OTP",
    'intl.bindemailpwd.button.aftergetcaptcha': " s",
    'intl.bindemailpwd.button.gobindemailpwd': "Confirm",
    'intl.bindemailpwd.message.success': "Succeeded",
    'intl.bindemailpwd.message.error.choosecountry': "Please check the country and region",
    'intl.bindemailpwd.message.error.email': "Please enter email",
    'intl.bindemailpwd.message.error.captchacode': "Please enter the captcha",
    'intl.bindemailpwd.message.error.emailcaptchacode': "Please enter email verification code",
    'intl.bindemailpwd.message.error.passwordrule': "Password must be 8-16 characters and contain letters, numbers and special symbols",
    'intl.bindemailpwd.message.error.chooseagreement': "Please check that you have read and agree to the platform agreement",
    'intl.bindemailpwd.password.title': "Format requirements",
    'intl.bindemailpwd.password.rule': "Password must be 8-16 characters and contain letters, numbers and special symbols",
    'intl.bindemailpwd.agreement.agree': "I have read and agree to the",
    'intl.bindemailpwd.agreement.policy': " Privacy Policy ",
    'intl.bindemailpwd.agreement.and': "and",
    'intl.bindemailpwd.agreement.plateform': " Platform Agreement",
    'intl.bindemailpwd.agreement.content': " ",

    //Bug Submit
    'intl.bugsubmit.title': "Security Reporting",
    'intl.bugsubmit.ruletitle': "Rule Description:",
    'intl.bugsubmit.rulecontent': "Please read the reward program rules and submission guidelines carefully before submitting the vulnerability. Do not disclose vulnerability details publicly. Please make sure you understand and accept all legal points in platform agreement.",
    'intl.bugsubmit.rulefile.rewardstandard': "Submission Instructions",
    'intl.bugsubmit.rulefile.rewardstandard.device': "TECNO Device Security Vulnerability Reward Rules V4.0",
    'intl.bugsubmit.rulefile.rewardstandard.mobile': "TECNO Mobile APP Security Vulnerability Reward Rules V4.0",
    'intl.bugsubmit.rulefile.rewardstandard.web': "TECNO Web Security Vulnerability Reward Rules V3.0",
    'intl.bugsubmit.rulefile.serviceagreement': "Reward Program Rules",
    'intl.bugsubmit.form.type': "Type",
    'intl.bugsubmit.form.subtype': "Subtype",
    'intl.bugsubmit.form.severity': "Severity",
    'intl.bugsubmit.form.url': "URL",
    'intl.bugsubmit.form.apk': "APK",
    'intl.bugsubmit.form.apkVersionCode': "Version number",
    'intl.bugsubmit.form.activity': "Activity",
    'intl.bugsubmit.form.summary': "Summary",
    'intl.bugsubmit.form.description': "Description",
    'intl.bugsubmit.form.attachments': "Add attachments",
    'intl.bugsubmit.form.captcha': "Captcha",
    'intl.bugsubmit.placeholder.subtype': "Select vulnerability subtype",
    'intl.bugsubmit.placeholder.url': "Select URL",
    'intl.bugsubmit.placeholder.apk': "Select APK",
    'intl.bugsubmit.placeholder.versionCode': "Enter version number",
    'intl.bugsubmit.placeholder.activity': "Select activity",
    'intl.bugsubmit.placeholder.activity.url': "Activity rules",
    'intl.bugsubmit.placeholder.vulnerability': "Enter vulnerability summary",
    'intl.bugsubmit.editor.initialvalue': "<p style='color: #BFBFBF;margin-left:8px'># Please complete the following fields as required. Detailed report will be given priority for review.</p><br /><p style='color: #BFBFBF;margin-left:8px;font-weight:700'>I. Proof of concept：</p><p style='color: #BFBFBF;margin-left:8px'># Package name<br/># Describe the vulnerability with reproduction steps.</p><br/><p style='color: #BFBFBF;margin-left:8px;font-weight:700'>II. Attachments：</p><p style='color: #BFBFBF;margin-left:8px'># Provide screenshots or videos as evidence</p><br/><p style='color: #BFBFBF;margin-left:8px;font-weight:700'>III. Remediation plan：</p><p style='color: #BFBFBF;margin-left:8px'># Please illustrate how would you fix it</p>", //<p></p><br/>不翻译
    'intl.bugsubmit.editor.initialvalueweb': "<p style='color: #BFBFBF;margin-left:8px;'># Please complete the following fields as required. Detailed report will be given priority for review.</p><br /><p style='color: #BFBFBF;margin-left:8px;font-weight:700'>I. Proof of concept：</p><p style='color: #BFBFBF;margin-left:8px;'># Describe the vulnerability with reproduction steps.</p><br/><p style='color: #BFBFBF;margin-left:8px;font-weight:700'>II. Attachments：</p><p style='color: #BFBFBF;margin-left:8px;'># Provide screenshots or videos as evidence</p><br/><p style='color: #BFBFBF;margin-left:8px;font-weight:700'>III. Remediation plan：</p><p style='color: #BFBFBF;margin-left:8px;'># Please illustrate how would you fix it</p>", //<p></p><br/>不翻译

    'intl.bugsubmit.editor.initialvalueDevice': "<p style='color: #bfbfbf; margin-left: 8px'>  # Please complete the following fields as required. Detailed report will be given priority for review.</p><br /><p style='color: #bfbfbf; margin-left: 8px; font-weight: 700'>  I. Proof of concept：</p><p style='color: #bfbfbf; margin-left: 8px'>  # Package name：</p><p style='color: #bfbfbf; margin-left: 8px'>  # Device model:</p><p style='color: #bfbfbf; margin-left: 8px'>  # OS version:</p><p style='color: #bfbfbf; margin-left: 8px'>  # Describe the vulnerability with reproduction steps.</p><p style='color: #bfbfbf; margin-left: 8px'><br /><p style='color: #bfbfbf; margin-left: 8px; font-weight: 700'>  II. Attachments：</p><p style='color: #bfbfbf; margin-left: 8px'># Provide screenshots or videos as evidence</p><br /><p style='color: #bfbfbf; margin-left: 8px; font-weight: 700'>III. Remediation plan：</p><p style='color: #bfbfbf; margin-left: 8px'># Please illustrate how would you fix it</p>", //<p></p><br/>不翻译
    'intl.bugsubmit.button.selectattachments': "Select Attachments",
    'intl.bugsubmit.attachmentstips': "Format: .doc, .xls, .pdf, .7z, .zip, .mp4, .gz, .bz2, .png, .jpg, .jpeg and .wmv; Size: Less than 10 MB",
    'intl.bugsubmit.placeholder.captcha.img': "Captcha",
    'intl.bugsubmit.agreement.agree': "I have read and agree to the",
    'intl.bugsubmit.agreement.policy': " Platform Agreement",
    'intl.bugsubmit.button.gobugsubmit': "Submit",
    'intl.bugsubmit.button.cancelbugsubmit': "Cancel",
    'intl.bugsubmit.message.success': "Submitted successfully",
    'intl.bugsubmit.message.error': "Submission failed",
    'intl.bugsubmit.message.file.type': "Only .doc, .xls, .pdf, .7z, .zip, .mp4, .gz, .bz2, .png, .jpg, .jpeg and .wmv files are supported",
    'intl.bugsubmit.message.file.size': "Attachment size cannot exceed 10 MB",
    'intl.bugsubmit.message.error.subtypeSelect': "Select vulnerability type",
    'intl.bugsubmit.message.error.severityRadio': "Select vulnerability severity",
    'intl.bugsubmit.message.error.urlInput': "Please check the URL format",
    'intl.bugsubmit.message.error.apkSelect': 'Select APK',
    'intl.bugsubmit.message.error.versionCodeInput': 'Enter version number',
    'intl.bugsubmit.message.error.vulnerabilityInput': "Enter vulnerability summary",
    'intl.bugsubmit.message.error.contextDescription': "Enter a detailed description of the vulnerability",
    'intl.bugsubmit.message.error.chooseagreement': "Please read and agree with the platform agreement",

    //BugSubmitSuccess
    'intl.bugsubmitsuccess.title': "Security Reporting",
    'intl.bugsubmitsuccess.content.title': "Submitted successfully!",
    'intl.bugsubmitsuccess.button.viewdetails': "View details",
    'intl.bugsubmitsuccess.button.submitonemore': "New submission",

    //BugSubmitFailed
    'intl.bugsubmitfailed.title': "Security Reporting",
    'intl.bugsubmitfailed.content.title': "Submission failed!",
    'intl.bugsubmitfailed.content.subtitle': "Please check the network and resubmit",
    'intl.bugsubmitfailed.button.viewdetails': "View details",
    'intl.bugsubmitfailed.button.submitonemore': "Resubmit",

    //Bug Submit List
    'intl.bugsubmitlist.vulnerability.title': "My Reports",
    'intl.bugsubmitlist.count.Reward': "Base Bonus",
    'intl.bugsubmitlist.count.Note': "Note",
    'intl.bugsubmitlist.count.Bonus.tips': "It shows the total amount of the Base Bonus. Incentives Bonus can be checked on the ",
    'intl.bugsubmitlist.count.Bonus.link': "Rewards Details",
    'intl.bugsubmitlist.count.Bonus.punctuation': ".",
    'intl.bugsubmitlist.count.Bonus.base':'Base Bonus',
    'intl.bugsubmitlist.count.Bonus.extra': "Incentive Bonus",
    // 'intl.bugsubmitlist.count.Bonus.extra': "Extra Bonus",
    'intl.bugsubmitlist.count.Reputation': "Reputation",
    'intl.bugsubmitlist.count.Reputation.tips': "It shows the total amount of the Reputation. Credits can be checked on the ",
    'intl.bugsubmitlist.count.Reputation.link': "Rewards Details",
    'intl.bugsubmitlist.count.Reputation.punctuation': ".",
    // 'intl.bugsubmitlist.count.Reputation.extra': "Extra Reputation",
    'intl.bugsubmitlist.count.Approved': "Valid",
    'intl.bugsubmitlist.count.Submitted': "Submitted",
    'intl.bugsubmitlist.vulnerabilitylist.title': "Reports List",
    'intl.bugsubmitlist.table.columns.id': "ID",
    'intl.bugsubmitlist.table.columns.vulnerability': "Summary",
    'intl.bugsubmitlist.table.columns.vulnerabilityno': "Report ID",
    'intl.bugsubmitlist.table.columns.severity': "Severity",
    'intl.bugsubmitlist.table.columns.status': "Status",
    'intl.bugsubmitlist.table.columns.payouts': "Base Bonus",
    'intl.bugsubmitlist.table.columns.reputation': "Reputation",
    'intl.bugsubmitlist.table.columns.submittime': "Submission Time",
    'intl.bugsubmitlist.table.total': "Total",
    'intl.bugsubmitlist.table.totalnum': " ",
    'intl.bugsubmitlist.status.leak_status_submited': "New",
    'intl.bugsubmitlist.status.leak_status_pending_review': "Pending Review",
    'intl.bugsubmitlist.status.leak_status_need_more_info': "Needs More Info",
    'intl.bugsubmitlist.status.leak_status_pending_fix': "Triaged",
    'intl.bugsubmitlist.status.leak_status_fixed': "Fixed",
    'intl.bugsubmitlist.status.leak_status_no_risk': "Informative",
    'intl.bugsubmitlist.status.leak_status_duplicate': "Duplicate",
    'intl.bugsubmitlist.status.leak_status_out_of_scope': "Out of Scope",
    'intl.bugsubmitlist.status.leak_status_not_reproducible': "Not Reproducible",
    'intl.bugsubmitlist.status.leak_status_spam': "Spam",
    'intl.bugsubmitlist.status.leak_status_ignore': "Ignored",

    //Bug Submit Detail
    'intl.bugsubmitdetail.title': "My Reports",
    'intl.bugsubmitdetail.processingflow': "Processing flow",
    'intl.bugsubmitdetail.processingflow.title': "Description of vulnerability process status",
    'intl.bugsubmitdetail.table.search': "Report ID / Summary",
    'intl.bugsubmitdetail.panel.vulnerabilityinfo': "Initial Report",
    'intl.bugsubmitdetail.panel.reviewconclusion': "Review Conclusion",
    'intl.bugsubmitdetail.panel.comments': "Comments",
    'intl.bugsubmitdetail.vulnerabilityinfo.type': "Type",
    'intl.bugsubmitdetail.vulnerabilityinfo.subtype': "Subtype",
    'intl.bugsubmitdetail.vulnerabilityinfo.severity': "Severity",
    'intl.bugsubmitdetail.vulnerabilityinfo.rewards': "Rewards",
    'intl.bugsubmitdetail.vulnerabilityinfo.url': "URL",
    'intl.bugsubmitdetail.vulnerabilityinfo.apk': 'APK',
    'intl.bugsubmitdetail.vulnerabilityinfo.apkVersionCode': 'Version number',
    'intl.bugsubmitdetail.vulnerabilityinfo.activity': "Activity",
    'intl.bugsubmitdetail.vulnerabilityinfo.summary': "Summary",
    'intl.bugsubmitdetail.vulnerabilityinfo.vulnerabilityinfo': "Description",
    'intl.bugsubmitdetail.vulnerabilityinfo.attachment': "Attachment",
    'intl.bugsubmitdetail.reviewconclusion.conclusion': "Conclusion",
    'intl.bugsubmitdetail.reviewconclusion.conclusion.rating': "Rating",
    'intl.bugsubmitdetail.reviewconclusion.conclusion.reputation': "Reputation",
    'intl.bugsubmitdetail.reviewconclusion.conclusion.payouts': "Bonus",
    'intl.bugsubmitdetail.reviewconclusion.saferfedback': "Comment",
    'intl.bugsubmitdetail.reviewconclusion.reviewtime': "Review Time",
    'intl.bugsubmitdetail.status.leak_status_submited': "New",
    'intl.bugsubmitdetail.status.leak_status_pending_fix': "Triaged",
    'intl.bugsubmitdetail.status.leak_status_fixed': "Fixed",
    'intl.bugsubmitdetail.status.leak_status_ignore': "Ignored",
    'intl.bugsubmitdetail.button.fold': "Fold",
    'intl.bugsubmitdetail.button.quote': "Quote",
    'intl.bugsubmitdetail.button.addAttachment': "Add Attachment",
    'intl.bugsubmitdetail.button.send': "Send",
    'intl.bugsubmitdetail.button.cancel': "Cancel",
    'intl.bugsubmitdetail.prompt.attachments': "Attachments",
    'intl.bugsubmitdetail.prompt.format': "Format",
    'intl.bugsubmitdetail.prompt.noComments': "No comments",
    'intl.bugsubmitdetail.prompt.totalBonus': "Total Bonus",
    'intl.bugsubmitdetail.prompt.baseBonus': "Base Bonus",
    'intl.bugsubmitdetail.prompt.activityBonus': "Activity Bonus",
    'intl.bugsubmitdetail.prompt.excellentBonus': "Excellent Bonus",
    'intl.bugsubmitdetail.prompt.totalReputation': "Total Reputation",
    'intl.bugsubmitdetail.prompt.baseReputation': "Base Reputation",
    'intl.bugsubmitdetail.prompt.activityReputation': "Activity Reputation",
    'intl.bugsubmitdetail.prompt.excellentReputation': "Excellent Reputation",
    'intl.bugsubmitdetail.prompt.toPendingReview': "This comment completely complements the report and changes the status of the report to [Pending review]",
    'intl.bugsubmitdetail.prompt.downloadTips': "Exercise caution when downloading attachments as they may be accompanied by unintentional consequences.",
    'intl.bugsubmitdetail.prompt.Download': "Download",
    //Blog
    'intl.blog.title': "Resources",
    'intl.blog.introduce': "Check out the latest reward rules, guidelines, events and security technology articles here.",
    'intl.blog.placeholder.search': "reward rules",
    'intl.blog.type.all': "All",
    'intl.blog.type.rules': "Rules",
    'intl.blog.type.news': "News",
    'intl.blog.type.blog': "Blogs",
    'intl.blog.type.events': "Activities",
    'intl.blog.type.rules': "Rules",
    'intl.blog.button.viewdetails': "View details",
    'intl.blog.table.total': "Total",
    'intl.blog.table.totalnum': " ",

    //Blog Detail
    'intl.blogdetail.breadcrumb.blog': "Resources",
    'intl.blogdetail.breadcrumb.detail': "Detail",
    'intl.blogdetail.type.news': "News",
    'intl.blogdetail.type.blog': "Blogs",
    'intl.blogdetail.type.events': "Activities",
    'intl.blogdetail.type.rules': "Rules",
    'intl.blogdetail.more.title': "More",
    'intl.blogdetail.more.up': "Previous",
    'intl.blogdetail.more.down': "Next",

    //Security Updates
    'intl.securityupdates.title': "TECNO Security Updates",
    'intl.securityupdates.type.devicescope': "Device Scope",
    'intl.securityupdates.type.firmwareupdates': "Firmware Updates",
    'intl.securityupdates.type.securityAdvisory': "Security Advisory",
    'intl.securityupdates.devicescope.tip': "TECNO attaches great importance to the security of its products and services. We will release monthly security updates for the listed devices. Please note that the delivery time of security patches may vary depending on the regions and models. Any questions about updates, please visit",
    'intl.securityupdates.devicescope.carlcare': " Carlcare",
    'intl.securityupdates.firmwareupdates.tip': "TECNO will focus on Android public vulnerability remediations and release security updates for our Android devices regularly. Here, we will publish fixed Android public vulnerability information. For detailed information about security patches, please view",
    'intl.securityupdates.firmwareupdates.android': " Android Security Bulletins",
    'intl.securityupdates.securityAdvisory.tip': "TECNO Security Response Center investigates all reports of security vulnerabilities affecting TECNO products carefully, and releases security advisories to help you manage security risks and keep your products protected. We also welcome security researchers to ",
    'intl.securityupdates.securityAdvisory. apply': "apply for CVE IDs",
    'intl.securityupdates.securityAdvisory.nodata': "No Data",
    'intl.securityupdates.securityAdvisory.apply': "Apply for your CVE ID",
    'intl.securityupdates.placeholder.year': "Year",
    'intl.securityupdates.placeholder.quarter': "Month",

    //Message Center
    'intl.messagecenter.title': "Message Center",
    'intl.messagecenter.type.all': "All",
    'intl.messagecenter.type.systemnotifications': "Push Notifications",
    'intl.messagecenter.type.reviewresults': "Submissions Related",
    'intl.messagecenter.type.orderRelated': "Order Related",
    'intl.messagecenter.read.all': "All",
    'intl.messagecenter.read.yes': "Read",
    'intl.messagecenter.read.no': "Unread",
    'intl.messagecenter.button.readall': "Read All",
    'intl.messagecenter.button.read': "Read",
    'intl.messagecenter.button.viewdetails': "view details",
    'intl.messagecenter.pagination.total': "Total",
    'intl.messagecenter.pagination.totalnum': " ",

    //Leaderboards
    'intl.leaderboards.title': "Hall of Fame",
    'intl.leaderboards.type.alltime': "All",
    'intl.leaderboards.type.monthly': "Monthly",
    'intl.leaderboards.type.annual': "Annually",
    'intl.leaderboards.table.columns.ranking': "Rank",
    'intl.leaderboards.table.columns.country': "Country",
    'intl.leaderboards.table.columns.nickname': "Nickname",
    'intl.leaderboards.table.columns.nicknamecard.reputation': "Reputation",
    'intl.leaderboards.table.columns.nicknamecard.submitted': "Submitted",
    'intl.leaderboards.table.columns.reputation': "Base Reputation",
    'intl.leaderboards.pagination.total': "Total",
    'intl.leaderboards.pagination.totalnum': " ",
    'intl.leaderboards.Acknowkledgements': 'TECNO Security Response Center appreciates every security researcher for your contribution to safeguarding TECNO ',//newly added
    'intl.leaderboards.Acknowkledgements.Button': 'Read the Acknowledgements',//newly added Acknowledgements


    //PersonalInfo
    'intl.personalinfo.title': "Personal Profile",
    'intl.personalinfo.type.personalinfo': "Personal Info",
    'intl.personalinfo.type.payoutmethod': "Payout Method",
    'intl.personalinfo.type.loginsecurity': "Login & Security",
    'intl.personalinfo.type.personalinfo.button.edit': "Edit",
    'intl.personalinfo.type.personalinfo.button.add': "Add",
    'intl.personalinfo.type.personalinfo.button.submit': "Submit",
    'intl.personalinfo.type.personalinfo.button.cancel': "Cancel",
    'intl.personalinfo.type.personalinfo.placeholder.nameedit': "Enter your social profile link",
    'intl.personalinfo.type.personalinfo.notset': "Not set yet",
    'intl.personalinfo.type.personalinfo.reputation': "Reputation",
    'intl.personalinfo.type.personalinfo.submitted': "Submitted",
    'intl.personalinfo.type.personalinfo.bonusalltitle': "Bonus",
    'intl.personalinfo.type.personalinfo.bonusbasetitle': "Base",
    'intl.personalinfo.type.personalinfo.bonusextratitle': "Extra",
    'intl.personalinfo.type.personalinfo.honoraryalltitle': "Reputation",
    'intl.personalinfo.type.personalinfo.honorarybasetitle': "Base",
    'intl.personalinfo.type.personalinfo.honoraryextratitle': "Extra",
    'intl.personalinfo.type.personalinfo.modal.title': "Edit",
    'intl.personalinfo.message.img.type': "Only .jpg, .png, .jpeg files are supported",
    'intl.personalinfo.message.img.size': "Image size cannot exceed 1 MB",
    'intl.personalinfo.message.namelength': "Must be less than 20 characters",
    'intl.personalinfo.type.personalinfo.email': "Email",
    'intl.personalinfo.type.personalinfo.socialprofile': "Social Profile",
    'intl.personalinfo.type.personalinfo.tip': "Fill in your Facebook or Twitter account so that we can learn more about you",
    'intl.personalinfo.type.loginsecurity.button.edit': "Edit",
    'intl.personalinfo.type.loginsecurity.button.submit': "Submit",
    'intl.personalinfo.type.loginsecurity.button.cancel': "Cancel",
    'intl.personalinfo.type.loginsecurity.notset': "Not set yet",
    'intl.personalinfo.type.loginsecurity.hasset': "Setted",
    'intl.personalinfo.type.loginsecurity.modal.title': "Change password",
    'intl.personalinfo.type.loginsecurity.password.title': "Password",
    'intl.personalinfo.type.loginsecurity.password.tip': "Changing your password regularly helps keep your account secure",
    'intl.personalinfo.type.loginsecurity.facebook.title': "Facebook",
    'intl.personalinfo.type.loginsecurity.facebook.button': " Continue with Facebook",
    'intl.personalinfo.type.loginsecurity.facebook.tip': "You'll be able to sign in with Facebook",
    'intl.personalinfo.type.loginsecurity.signout.title': "Delete account",
    'intl.personalinfo.type.loginsecurity.signout.tip': "You'll lose all the data and content in this account. To delete the account, please contact the Security Response Center Team via your registered email address (security.tecno@tecno-mobile.com).",
    'intl.personalinfo.type.loginsecurity.oldpassword': "Current password",
    'intl.personalinfo.type.loginsecurity.placeholder.oldpassword': "Current password",
    'intl.personalinfo.type.loginsecurity.newpassword': "New password",
    'intl.personalinfo.type.loginsecurity.placeholder.newpassword': "New password",
    'intl.personalinfo.type.loginsecurity.verifynewpassword': "Confirm new password",
    'intl.personalinfo.type.loginsecurity.placeholder.verifynewpassword': "Enter new password again",
    'intl.personalinfo.type.loginsecurity.error.twicepassword': "The two passwords do not match.",
    'intl.personalinfo.type.loginsecurity.success.changepassword': "Set successfully",
    'intl.personalinfo.type.loginsecurity.success.bindfacebook': "Set successfully",
    'intl.personalinfo.type.loginsecurity.error.bindfacebook': "Setting failed",
    'intl.personalinfo.type.payoutmethod.title': "PayPal",
    'intl.personalinfo.type.payoutmethod.button.edit': "Edit",
    'intl.personalinfo.type.payoutmethod.button.delete': "Delete",
    'intl.personalinfo.type.payoutmethod.button.deleteMessage': "After deletion, we will confirm the collection method with you by email before paying the bonus, which will not affect your receipt of the bonus.",
    'intl.personalinfo.type.payoutmethod.button.submit': "Submit",
    'intl.personalinfo.type.payoutmethod.button.cancel': "Cancel",
    'intl.personalinfo.type.payoutmethod.notset': "Not Set",
    'intl.personalinfo.type.payoutmethod.nopaypal': "When the vulnerability is approved, we will confirm the payment method of the reward with you through official email before paying the bonus.",
    'intl.personalinfo.type.payoutmethod.notsetpaypal': "In order to reduce unnecessary collection of privacy information, the PayPal account entry is opened only after you have received the vulnerability bonus.",
    'intl.personalinfo.type.payoutmethod.hasnotsetpaypal': "Set your PayPal account for receiving bonus here.",
    'intl.personalinfo.type.payoutmethod.hassetpaypal': "Please make sure this account information is accurate and owned by you.TECNO will take this account as your default payout method.",
    'intl.personalinfo.type.payoutmethod.modal.title': "PayPal Account",
    'intl.personalinfo.type.payoutmethod.paypal': "PayPal",
    'intl.personalinfo.type.payoutmethod.placeholder.paypal': "Enter your PayPal account",
    'intl.personalinfo.type.payoutmethod.password': "Password",
    'intl.personalinfo.type.payoutmethod.placeholder.password': "TECNO SRC login password",
    'intl.personalinfo.type.payoutmethod.captcha': "Captcha",
    'intl.personalinfo.type.payoutmethod.placeholder.captcha': "Enter the Captcha",
    'intl.personalinfo.header.messageCenter': "Message Center",//newly added
    'intl.personalinfo.header.reportEntry': "Security Reporting",//newly added
    'intl.personalinfo.header.viewreportEntry': "My report",//newly added
    'intl.personalinfo.content.quickEntranceTitle': "Quick Entrance",//newly added
    'intl.personalinfo.content.quickEntranceMenu.personalInfo': "My Homepage",//newly added
    'intl.personalinfo.content.quickEntranceMenu.achievement': "My Achievement",//newly added
    'intl.personalinfo.content.quickEntranceMenu.payoutMethod': "Payout Method",//newly added
    'intl.personalinfo.content.quickEntranceMenu.rewardsDetails': "Rewards Details",//newly added
    'intl.personalinfo.content.quickEntranceMenu.myRedemption': "My Redemption",//newly added
    'intl.personalinfo.content.settingEntry': 'Settings',//newly added
    'intl.personalinfo.content.profileTitle': 'My Homepage',//newly added
    'intl.personalinfo.content.publicProfileEntry': 'Go to Solo Exhibition >',//newly added
    'intl.personalinfo.content.statistics': 'Statistics',//newly added

    'intl.personalinfo.content.statistics.bonusTitle': 'Bonus',//newly added
    'intl.personalinfo.content.statistics.bonusContent': 'Total bonus, including base bonus and incentive bonus.',//newly added
    'intl.personalinfo.content.statistics.reputationTitle': 'Reputation',//newly added
    'intl.personalinfo.content.statistics.reputationContent': 'Reputation can be used to calculate the ranking of HOF.',//newly added
    'intl.personalinfo.content.statistics.badgesTitle': 'Badges',//newly added
    'intl.personalinfo.content.statistics.badgesContent': 'Total number of badges you earned.',//newly added
    'intl.personalinfo.content.statistics.creditTitle': 'Credit',//newly added
    'intl.personalinfo.content.statistics.creditcardTitle': 'Credit Balance',//newly added
    'intl.personalinfo.content.statistics.creditContent': 'Credits can be used to redeem gifts in swagshop.',//newly added
    'intl.personalinfo.content.statistics.vulnerabilityTitle': 'Valid/Submitted Vulnerability',//newly added
    'intl.personalinfo.content.statistics.vulnerabilityContent': 'Total number of valid vulnerabilities and submitted vulnerabilities.',//newly added
    'intl.personalinfo.content.statistics.accuracyTitle': 'Accuracy',//newly added
    'intl.personalinfo.content.statistics.accuracyContent': 'Percentage of valid and submitted vulnerability number.',//newly added
    'intl.personalinfo.content.statistics.rankingTitle': 'Hall of Fame · All',//newly added
    'intl.personalinfo.content.statistics.rankingContent': 'The current ranking in overall Hall of Fame.',//newly added
    'intl.personalinfo.content.statistics.reportTitle': 'Status of My Reports',//newly added
    'intl.personalinfo.content.statistics.severityTitle': 'Severity of My Reports',//newly added

    'intl.personalinfo.content.statisticsChart.open': 'Open',
    'intl.personalinfo.content.statisticsChart.tiaged': 'Triaged',
    'intl.personalinfo.content.statisticsChart.fixed': 'Fixed',
    'intl.personalinfo.content.statisticsChart.rejected': 'Rejected',
    'intl.personalinfo.content.statisticsChart.suspended': 'Suspended',
    'intl.personalinfo.content.statisticsChart.critical': 'Critical',
    'intl.personalinfo.content.statisticsChart.high': 'High',
    'intl.personalinfo.content.statisticsChart.medium': 'Medium',
    'intl.personalinfo.content.statisticsChart.low': 'Low',
    'intl.personalinfo.content.statisticsChart.non': 'Non',

    'intl.personalinfo.content.achievementTitle': 'Achievement',//newly added
    'intl.personalinfo.content.achievement.leftTitle': 'Recent Badges',//newly added
    'intl.personalinfo.content.achievement.rightEntry': 'More Badges >',//newly added
    'intl.personalinfo.medalCard.userCount': " users possess this badge",//newly added
    'intl.personalinfo.medalCard.wearingTag': "Wearing",//newly added
    'intl.personalinfo.medalCard.expiredTag': "Expired",//newly added
    'intl.personalinfo.medalCard.unlockedTime': "Unlocked at ",//newly added
    'intl.personalinfo.medalCard.expiredTime': "Valid untill ",//newly added
    'intl.personalinfo.medalCard.locked': "Locked",//newly added
    'intl.personalinfo.medalCard.wearBadge': "Wear the badge",//newly added
    'intl.personalinfo.medalCard.takeOffBadge': "Take off the badge",//newly added
    'intl.personalinfo.medalCard.longTerm': "Long term",//newly added
    'intl.personalinfo.breadCrumb.personalInfo': "My Homepage",//newly added
    'intl.personalinfo.breadCrumb.myRedemption': "My Redemption",//newly added
    'intl.personalinfo.breadCrumb.rewardsDetails': "Rewards Details",//newly added
    'intl.personalinfo.breadCrumb.achievement': "Achievement",//newly added
    'intl.personalinfo.breadCrumb.payoutMethod': "Settings",//newly added
    'intl.personalinfo.breadCrumb.quickEntrance': "Quick Entrance",//newly added
    'intl.personalinfo.achievement.badgeRules': "TECNO Security Response Center appreciates every security researcher for your contribution to safeguarding TECNO",//newly added
    'intl.personalinfo.achievement.badgeRules.button': "Read Detailed Instructions for TECNO SRC Badges",//newly added
    'intl.personalinfo.settings.personalInfo.title': "Personal Information",//newly added
    'intl.personalinfo.settings.personalInfo.avatar': "Profile Photo：",//newly added
    'intl.personalinfo.settings.personalInfo.nickname': "Nickname：",//newly added
    'intl.personalinfo.settings.personalInfo.country': "Country/Region：",//newly added
    'intl.personalinfo.settings.personalInfo.email': "E-mail：",//newly added
    'intl.personalinfo.settings.personalInfo.registDate': "Registration date：",//newly added
    'intl.personalinfo.settings.personalInfo.edit': "Edit",//newly added
    'intl.personalinfo.settings.payoutMethod.title': "Payout Method",//newly added
    'intl.personalinfo.settings.payoutMethod.desc': "The information we collected is only used for the vulnerability bonus and will not be used for other purposes.",//newly added
    'intl.personalinfo.settings.payoutMethod.link': "Notes on Adding a Bank Account >",//newly added
    'intl.personalinfo.settings.payoutMethod.addBank': "Add a bank account",//newly added
    'intl.personalinfo.settings.payoutMethod.addBankModal.title': "Add Bank Account",//newly added
    'intl.personalinfo.settings.payoutMethod.editBankModal.title': "Edit Bank Account",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.hint': "The information we collected is only used for the vulnerability bonus and will not be used for other purposes.",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.hintLink': "Read the Notes on Adding a Bank Account.",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.country': "Bank Account Country",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.payeeName': "Payee's Name",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.payeeAccount': "Payee's Account Number",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankName': "Bank Name",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.swift': "Bank's SWIFT CODE",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankCountry': "Bank Country",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankcity': "Bank City",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankAddress': "Bank Address",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.routingNumber': "Bank Routing Number",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.interMediary': "Intermediary Bank Name",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.interMediarySwift': "Intermediary Bank's SWIFT CODE",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.ID': "ID Number",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.phoneNumber': "Phone Number",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.ifSign': "haolingong",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.signOption.yes': "Yes",//newly added
    'intl.personalinfo.settings.payoutMethod.bankModal.signOption.no': "No",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.edit': "Edit",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove': "Remove",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove.modalTitle': "Are you sure to remove the bank account?",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove.modalContent': "After removing the bank account, you will not be able to receive the vulnerability bonus.",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove.cancelBtn': "No",//newly added
    'intl.personalinfo.settings.payoutMethod.bankCard.Remove.confirmBtn': "Yes",//newly added

    'intl.personalinfo.settings.socialMedia.title': "Social Media Account",//newly added
    'intl.personalinfo.settings.socialMedia.desc': "Fill in your Facebook or Twitter account so that we can learn more about you.",//newly added
    'intl.personalinfo.settings.socialMedia.editSave': "Save",//newly added
    'intl.personalinfo.settings.socialMedia.editCancel': "Cancel",//newly added
    'intl.personalinfo.settings.socialMedia.edit': "Edit",//newly added
    'intl.personalinfo.settings.socialMedia.notSet': "Not set yet",//newly added
    'intl.personalinfo.settings.socialMedia.addAccount': "Add Other Social Media Account",//newly added
    'intl.personalinfo.settings.socialMedia.deleteBtn': "Delete",//newly added

    'intl.personalinfo.settings.publicProfile.title': "Solo Exhibition",//newly added
    'intl.personalinfo.settings.publicProfile.desc': "Design your own Solo Exhibition and make friends with other users!",//newly added
    'intl.personalinfo.settings.publicProfile.link': "Set Solo Exhibition >",//newly added

    'intl.personalinfo.settings.login.title': "Login & Security",//newly added
    'intl.personalinfo.settings.login.password.title': "Password：",//newly added
    'intl.personalinfo.settings.login.password.hint': "Changing your password regularly helps keep your account secure.",//newly added
    'intl.personalinfo.settings.login.email.title': "E-mail：",//newly added
    'intl.personalinfo.settings.login.facebook.title': "Sign in with Facebook：",//newly added
    'intl.personalinfo.settings.login.facebook.hint': "You'll be able to sign in with Facebook.",//newly added
    'intl.personalinfo.settings.login.deleteAccount.title': "Delete account",//newly added
    'intl.personalinfo.settings.login.deleteAccount.hint': "You'll lose all the data and content in this account. To delete the account, please contact the Security Response Center Team via your registered email address (security.tecno@tecno-mobile.com).",//newly added

    //No Data
    'intl.nodata.label': "No Data",

    //Public Profile
    'intl.publicProfile.title': "Solo Exhibition", //newly added
    'intl.publicProfile.title.button.edit': "Set Solo Exhibition", //newly added
    'intl.publicProfile.title.button.save': "Save", //newly added
    'intl.publicProfile.title.button.cancel': "Cancel", //newly added
    'intl.publicProfile.statistics.title': "Statistics", //newly added

    'intl.publicProfile.achievements.title': "Achievement", //newly added
    'intl.publicProfile.socialAccount.title': "Social Media Account", //newly added
    'intl.publicProfile.editMode.switch.showText': "Displaying", //newly added
    'intl.publicProfile.editMode.switch.unshowText': "Undisplay", //newly added


    //apk download
    'intl.apkDownload.title': "APK Download",
    'intl.apkDownload.search.placeholder': "APK",
    'intl.apkDownload.type.all': "All",
    'intl.apkDownload.type.googlePlay': "Published to Google Play",
    'intl.apkDownload.type.others': "Others",
    'intl.apkDownload.sort.default': "Default sorting",
    'intl.apkDownload.sort.timeSort': "Update time",
    'intl.apkDownload.spinning.tips': "Downloading...",
    'intl.personalInfo.noData.badge.front': 'No badge was found. ',
    'intl.personalInfo.noData.badge.latter': 'How to earn a badge?',
    'intl.dashboard.medalCard.jumpText': 'More Badges >',
    'intl.personalInfo.header.registerDate': 'Joined ',
    'intl.personalInfo.noData.hint': 'No Data',
    'intl.personalInfo.noData.jump.bug': 'Report a vulnerability',


    'intl.bugSubmitList.tableTitle.all': "All",
    'intl.bugSubmitList.tableTitle.open': "Open",
    'intl.bugSubmitList.tableTitle.new': "New",
    'intl.bugSubmitList.tableTitle.pendingReview': "Pending Review",
    'intl.bugSubmitList.tableTitle.needsMoreInfo': "Needs More Info",
    'intl.bugSubmitList.tableTitle.Accepted': "Accepted",
    'intl.bugSubmitList.tableTitle.Triaged': "Triaged",
    'intl.bugsubmitlist.status.leak_status_suspended': "Suspended",
    'intl.bugSubmitList.tableTitle.Suspended': "Suspended",//
    'intl.bugSubmitList.tableTitle.Fixed': "Fixed",
    'intl.bugSubmitList.tableTitle.Rejected': "Rejected",
    'intl.bugSubmitList.tableTitle.Informative': "Informative",
    'intl.bugSubmitList.tableTitle.Duplicate': "Duplicate",
    'intl.bugSubmitList.tableTitle.outOfScope': "Out of Scope",
    'intl.bugSubmitList.tableTitle.notReproducible': "Not Reproducible",
    'intl.bugSubmitList.tableTitle.Spam': "Spam",
    'intl.bugSubmitList.tableTitle.Ignored': "Ignored",
    'intl.personalinfo.settings.APKTips': "*The application version provided on this page is for reference only. The highest version available in your region will be used as the triaged and acceptance standard.",
    
    //Swagshop
    'intl.swagshop.title': "Swagshop",
    'intl.swagshop.placeholder.search': "Search for gift",
    'intl.swagshop.filterBox.sortedBy':'Sorted by',
    'intl.swagshop.filterBox.default':'Default',
    'intl.swagshop.filterBox.popular':'Popular',
    'intl.swagshop.filterBox.newIn':'New in',
    'intl.swagshop.filterBox.priceHigh':'Price (Low to high)',
    'intl.swagshop.filterBox.priceLow':'Price (High to low)',
    'intl.swagshop.filterBox.creditsRangeTitle':'Credits Range',
    'intl.swagshop.filterBox.creditsRange':'All',
    'intl.swagshop.filterBox.onlyMy':'Show I can afford only',
    'intl.swagshop.articleLink':'Complete Guide To Redeeming TECNO SRC Credits >',
    'intl.swagshop.cashCoupon':'Cash Coupon',
    'intl.swagshop.credits':'credits',
    'intl.swagshop.questionnaire.comingSoon':'New surprises and gifts are on the horizon, stay tuned for updates!',
    'intl.swagshop.questionnaire.inventory':'Write down your gift wish list',
    'intl.swagshop.table.total': "Total",
    'intl.swagshop.table.totalnum': " ",
     
    //swagshopdetail
    'intl.swagshopdetail.swagshop':'Swagshop',
    'intl.swagshopdetail.details':'Details',
    'intl.swagshopdetail.mattersNeedAttention':'Attention: If you submit the order before the 8th of each month, the reward will be issued in the current month; while after the 8th of each month, it will be issued in the next month. If you have any questions after submitting the order, please contact us via email at ',
    'intl.swagshopdetail.punctuation':'.',
    'intl.swagshopdetail.cashCoupon':'Cash Coupon',
    'intl.swagshopdetail.credits':'Credits',
    'intl.swagshopdetail.briefIntroduction':'Cash rewards will be issued by bank card transfer. Please ensure that the bank card information is complete and accurate when submitting the order.',
    'intl.swagshopdetail.quantity':'Quantity',
    'intl.swagshopdetail.totalPrice':'Total Price',
    'intl.swagshopdetail.creditsBalance':'Credits Balance',
    'intl.swagshopdetail.payoutMethod':'Payout Method',
    'intl.swagshopdetail.bankInfo':'You can also go to',
    'intl.swagshopdetail.bankLink':'My Homepage > Payout Method',
    'intl.swagshopdetail.bankTips':'to manage your bank account information.',
    'intl.swagshopdetail.redeem':'Redeem',
    'intl.swagshopdetail.tips.notCredits':'Oops, you don’t have enough credits to make this redemption.',
    'intl.swagshopdetail.tips.notBank':'Oops, you have not filled in your bank card information.',
    'intl.swagshopdetail.tips.order':"You've submitted the order!",

    //My Redemption
    'intl.myRedemption.myRedemption': 'My Redemption',
    'intl.myRedemption.dataCardmy.creditsBalance': 'Credits Balance',
    'intl.myRedemption.dataCardmy.totalCredits': 'Total Credits',
    'intl.myRedemption.dataCardmy.redeemedCredits': 'Redeemed Credits',
    'intl.myRedemption.skipButton.goTo': 'Go to',
    'intl.myRedemption.skipButton.swagshop': 'Swagshop >',
    'intl.myRedemption.skipButton.swagshopTips': 'Exquisite gifts are waiting for you',
    'intl.myRedemption.skipButton.getMore': 'Get More',
    'intl.myRedemption.skipButton.credits': 'Credits >',
    'intl.myRedemption.skipButton.creditsTips': 'The latest activities to get credits',
    'intl.myRedemption.filterBox.Search':'Search for order ID or gifts',
    'intl.myRedemption.filterBox.startTime':'Start Time',
    'intl.myRedemption.filterBox.to':'to',
    'intl.myRedemption.filterBox.endTime':'End Time',
    'intl.myRedemption.filterBox.status':'Status',
    'intl.myRedemption.table.status.reviewing': 'Reviewing',
    'intl.myRedemption.table.status.processing': 'Processing',
    'intl.myRedemption.table.status.cancelled': 'Cancelled',
    'intl.myRedemption.table.status.complete': 'Complete',
    'intl.myRedemption.table.status.rejected': 'Rejected',
    'intl.myRedemption.table.columns.orderID': 'Order ID',
    'intl.myRedemption.table.columns.gifts': 'Gifts',
    'intl.myRedemption.table.columns.quantity': 'Quantity',
    'intl.myRedemption.table.columns.unitPrice': 'Unit Price',
    'intl.myRedemption.table.columns.totlePrice': 'Total Price',
    'intl.myRedemption.table.columns.date': 'Date',
    'intl.myRedemption.table.columns.note': 'Note',
    'intl.myRedemption.table.columns.status': 'Status',
    'intl.myRedemption.table.columns.cancel': 'Cancel',
    'intl.myRedemption.table.columns.operate': 'Operate',
    'intl.myRedemption.table.popconfirm.title': 'Are you sure you want to cancel this order?',
    'intl.myRedemption.table.popconfirm.content': "After clicking 'Yes', the order will be cancelled and will not process it.",
    'intl.myRedemption.table.popconfirm.yes': "Yes,I want to cancel it",
    'intl.myRedemption.table.popconfirm.no': "No",
    'intl.myRedemption.table.popconfirm.message': "Operate Successfully",
    'intl.myRedemption.table.total':'Total',
    'intl.myRedemption.table.totalnum':' ',

   //Rewards Details
   'intl.rewardsDetails.filterBox.all':'All',
   'intl.rewardsDetails.filterBox.bonusDetails':'Bonus Details',
   'intl.rewardsDetails.filterBox.reputationDetails':'Reputation Details',
   'intl.rewardsDetails.filterBox.creditDetails':'Credit Details',
   'intl.rewardsDetails.filterBox.year':'Year',
   'intl.rewardsDetails.filterBox.month':'Month',
   'intl.rewardsDetails.filterBox.startTime':'Start Time',
   'intl.rewardsDetails.filterBox.to':'to',
   'intl.rewardsDetails.filterBox.endTime':'End Time',
   'intl.rewardsDetails.filterBox.type':'Type',
   'intl.rewardsDetails.filterBox.searchPlaceholder':'Search for change details',
   'intl.rewardsDetails.bonusDataCard.totalBonus':'Total Bonus',
   'intl.rewardsDetails.bonusDataCard.baseBonus':'Base Bonus',
   'intl.rewardsDetails.bonusDataCard.incentivesBonus':'Incentives Bonus',
   'intl.rewardsDetails.bonusDataCard.submitReport':'Submit Report >',
   'intl.rewardsDetails.bonusDataCard.findActivities':'Find Activities >',
   'intl.rewardsDetails.reputationDataCard.reputation':'Reputation',
   'intl.rewardsDetails.reputationDataCard.hOFAll':'HOF · All',
   'intl.rewardsDetails.reputationDataCard.st':'1st',
   'intl.rewardsDetails.reputationDataCard.hOFYear':'HOF ·',
   'intl.rewardsDetails.reputationDataCard.rd':'3rd',
   'intl.rewardsDetails.creditsDataCard.creditsBalance':'Credits Balance',
   'intl.rewardsDetails.creditsDataCard.totalCredits':'Total Credits',
   'intl.rewardsDetails.creditsDataCard.redeemedCredits':'Redeemed Credits',
   'intl.rewardsDetails.creditsDataCard.goToRedeem':'Go to Redeem >',
   'intl.rewardsDetails.creditsDataCard.getCredits':'Get Credits >',
   'intl.rewardsDetails.creditsDataCard.myRedemption':'My Redemption >',
   'intl.rewardsDetails.creditsDataCard.note':'Note: When filtering by time, your balance may appear negative, which means that you redeem more credits than you get during that time.',
   'intl.rewardsDetails.pieChart.creditsRatio':'The composition ratio of Credits',
   'intl.rewardsDetails.pieChart.vulnerabilityReward':'Vulnerability Reward',
   'intl.rewardsDetails.pieChart.activityReward':'Activity Reward',
   'intl.rewardsDetails.pieChart.securityAuthorReward':'Security Author Reward',
   'intl.rewardsDetails.table.columns.vulnerabilityRewardAndActivityReward':'Vulnerability Reward And Activity Reward',
   'intl.rewardsDetails.table.columns.changeDate': 'Change Date',
   'intl.rewardsDetails.table.columns.type': 'Type',
   'intl.rewardsDetails.table.columns.creditRedemption': 'Credit Redemption',
   'intl.rewardsDetails.table.columns.vulnerabilityReward': 'Vulnerability Reward',
   'intl.rewardsDetails.table.columns.activityReward': 'Activity Reward',
   'intl.rewardsDetails.table.columns.securityAuthorReward': 'Security Author Reward',
   'intl.rewardsDetails.table.columns.changeDetails': 'Change Details',
   'intl.rewardsDetails.table.columns.baseBonus': 'Base Bonus',
   'intl.rewardsDetails.table.columns.incentivesBonus': 'Incentives Bonus',
   'intl.rewardsDetails.table.columns.reputations': 'Reputations',
   'intl.rewardsDetails.table.columns.orderID': 'OrderID：',
   'intl.rewardsDetails.table.columns.product': 'Product：',
   'intl.rewardsDetails.table.columns.note': 'Note：',
   'intl.rewardsDetails.table.columns.activity': 'Activity：',
   'intl.rewardsDetails.table.columns.vulnerability': 'Vulnerability：',
   'intl.rewardsDetails.table.columns.article': 'Article：',
   'intl.rewardsDetails.table.columns.credits': 'Credits',
   'intl.rewardsDetails.table.columns.operate': 'Operate',
   'intl.rewardsDetails.table.columns.noteTips': 'The order had been cancelled.',
   'intl.rewardsDetails.table.total':'Total',
   'intl.rewardsDetails.table.totalnum':' ',               
}

export default en_US;

// import {useIntl} from 'react-intl';
// const intl = useIntl();
// {
//     intl.formatMessage({
//         id: 'intl.footer.securityupdate'
//     })
// }