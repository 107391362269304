import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import './style/footer.css';
import {Select, Popover} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import store from '../../store/index';
import {detailLink} from '../../common/detailLink';
import {goLang} from '../../action/lang/langAction';
import icFacebook from '../../assets/svg/ic-Facebook.svg';
import icFacebookClick from '../../assets/svg/ic-Facebook-点击.svg';
import icEmail from '../../assets/svg/ic-邮箱.svg';
import icEmailClick from '../../assets/svg/ic-邮箱-点击.svg';
import icChangeLanguage from '../../assets/svg/ic-语言切换.svg';
import icChangeLanguageHover from '../../assets/svg/ic-语言切换hover.svg';
import icTwitter from '../../assets/svg/icon_x.svg';
import icTwitterClick from '../../assets/svg/icon_xHover.svg';
import icCarlcare from '../../assets/svg/icon_carlcare.svg';
import icCarlcareHover from '../../assets/svg/icon_carlcareHover.svg';

export default (function (props) {
	const intl = useIntl();
	const [state, setState] = useState(store.getState().Lang);
	const [changeLanguageimg, setChangeLanguageimg] =
		useState(icChangeLanguage);
	const [facebookimg, setFacebookimg] = useState(icFacebook);
	const [emailimg, setEmailimg] = useState(icEmail);
	const [twitterimg, setTwitterimg] = useState(icTwitter);
	const [carlcalreimg, setCarlcalreimg] = useState(icCarlcare);
	const history = useHistory();
	useEffect(() => {
		store.subscribe(storeChange);
    // sessionStorage.setItem("lang", 'en_US');
	}, []);
	const storeChange = () => {
		setState(store.getState().Lang);
	};

	const changeLang = (value) => {
		goLang({lang: value});
		if (sessionStorage.getItem('routerPosition') == 'blogdetail') {
			history.push('/SRC/blog');
			window.location.reload();
		} else {
			window.location.reload();
		}
	};

	const goOtherWebsite = (url) => {
		window.open(url);
	};

	const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
	};

	const contentLangGlobal = (
		<div>
			<li className='LangGlobalLi' onClick={() => changeLang('en_US')}>
				English
			</li>
			<li className='LangGlobalLi' onClick={() => changeLang('zh_CN')}>
				中文
			</li>
		</div>
	);

	return (
		<div className='footerContainer'>
			<div className='footerContent'>
				<div className='footerContentFlex'>
					<div className='footerNav'>
						<label className='footerNavLabel'>
							{intl.formatMessage({
								id: 'intl.footer.menu'
							})}
						</label>
						<Link className='footerNavLink' to='/dashboard'>
							{intl.formatMessage({
								id: 'intl.footer.home'
							})}
						</Link>
						<Link className='footerNavLink' to='/SRC/blog'>
							{intl.formatMessage({
								id: 'intl.footer.blog'
							})}
						</Link>
						<Link className='footerNavLink' to='/SRC/leaderboards'>
							{intl.formatMessage({
								id: 'intl.footer.halloffame'
							})}
						</Link>
						<Link
							className='footerNavLink'
							to='/SRC/securityUpdates'>
							{intl.formatMessage({
								id: 'intl.footer.securityupdate'
							})}
						</Link>
					</div>
					<div className='footerDoc'>
						<label className='footerNavLabel'>
							{intl.formatMessage({
								id: 'intl.footer.document'
							})}
						</label>
						<p
							className='footerNavLink'
							onClick={() => {
								goDetailLink('SubmissionGuidelines');
							}}>
							{intl.formatMessage({
								id: 'intl.footer.submit'
							})}
						</p>
						<p
							className='footerNavLink'
							onClick={() => {
								goDetailLink('ProcessFlow');
							}}>
							{intl.formatMessage({
								id: 'intl.footer.process'
							})}
						</p>
						<p
							className='footerNavLink'
							onClick={() => {
								goDetailLink('RewardRules');
							}}>
							{intl.formatMessage({
								id: 'intl.footer.award'
							})}
						</p>
						<p
							className='footerNavLink'
							onClick={() => {
								goDetailLink('ResearchDevice');
							}}>
							{intl.formatMessage({
								id: 'intl.footer.apply'
							})}
						</p>
						<p
							className='footerNavLink'
							onClick={() => {
								goDetailLink('FAQ');
							}}>
							{intl.formatMessage({
								id: 'intl.footer.normalquestion'
							})}
						</p>
					</div>
					<div className='footerContact'>
						<label className='footerNavLabel'>
							{intl.formatMessage({
								id: 'intl.footer.contact'
							})}
						</label>
						{/* <a href="mailto:sample@fly63.com?subject=test&cc=sample@hotmail.com&subject=主题&body=内容"> */}
						<a
							className='footerNavIconLink'
							href='mailto:security.tecno@tecno-mobile.com'>
							<img
								alt='emailIcon'
								className='footerNavIconLink'
								src={emailimg}
								onMouseEnter={() => setEmailimg(icEmailClick)}
								onMouseLeave={() => setEmailimg(icEmail)}
							/>
						</a>
						<a
							className='footerNavIconLink'
							target='_blank'
							href='https://www.facebook.com/TECNO-Security-Response-Center-110516374648867'>
							<img
								alt='facebookIcon'
								className='footerNavIconLink'
								src={facebookimg}
								onMouseEnter={() =>
									setFacebookimg(icFacebookClick)
								}
								onMouseLeave={() => setFacebookimg(icFacebook)}
							/>
						</a>
						<a
							className='footerNavIconLink'
							target='_blank'
							href='https://twitter.com/TecnoSRC'>
							<img
								alt='twitterIcon'
								className='footerNavIconLink'
								src={twitterimg}
								onMouseEnter={() =>
									setTwitterimg(icTwitterClick)
								}
								onMouseLeave={() => setTwitterimg(icTwitter)}
							/>
						</a>
						<div className='footerNavDivider'></div>
						<div className='footerNavCarlcareText'>
							{intl.formatMessage({
								id: 'intl.footer.contactCarlcare'
							})}
						</div>
						<div
							className='footerNavCarlcareBtn'
							onClick={() =>
								window.open(
									'https://www.tecno-mobile.com/carlcare-global/'
								)
							}
							onMouseEnter={() =>
								setCarlcalreimg(icCarlcareHover)
							}
							onMouseLeave={() => setCarlcalreimg(icCarlcare)}>
							<div
								style={{
									cursor: 'pointer',
									marginBottom: '-2px'
								}}>
								{intl.formatMessage({
									id: 'intl.footer.visitCarlcare'
								})}
							</div>

							<img
								alt='navIcon'
								className='footerNavCarlcareIcon'
								src={carlcalreimg}
							/>
						</div>
					</div>
				</div>
				<div className='footerLang'>
					<label
						className='footerLangPrivacy'
						onClick={() =>
							goOtherWebsite(
								'/privacyPolicy/' +
									sessionStorage.getItem('lang')
							)
						}>
						{intl.formatMessage({
							id: 'intl.footer.privacypolicy'
						})}
					</label>
					<label
						className='footerLangTecno'
						onClick={() =>
							goOtherWebsite(
								'/agreement/' + sessionStorage.getItem('lang')
							)
						}>
						{intl.formatMessage({
							id: 'intl.signup.agreement.plateform'
						})}
					</label>
					<label
						className='footerLangTecno'
						onClick={() =>
							goOtherWebsite(
								'https://www.tecno-mobile.com/home/#/'
							)
						}>
						{intl.formatMessage({
							id: 'intl.footer.tecnoweb'
						})}
					</label>
					{/* <label className='footerLangLine'>｜</label>
					<Popover content={contentLangGlobal} trigger='click'>
						<div
							className='LangGlobalDiv'
							onMouseEnter={() =>
								setChangeLanguageimg(icChangeLanguageHover)
							}
							onMouseLeave={() =>
								setChangeLanguageimg(icChangeLanguage)
							}>
							<img
								src={changeLanguageimg}
								className='changeLanguageimg'
							/>
							<label className='footerLangGlobal'>
								{(sessionStorage.getItem('lang') == 'en_US' &&
									'English') ||
									(sessionStorage.getItem('lang') ==
										'zh_CN' &&
										'中文')}
							</label>
						</div>
					</Popover> */}
					{/* <Select
                        className="footerLangSelect"
                        showSearch
                        defaultValue= {sessionStorage.getItem('lang')}
                        size="small"
                        style={{ width: 200 }}
                        placeholder="Select a language"
                        optionFilterProp="children"
                        onChange={onChange}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="en_US">en_US</Option>
                        <Option value="zh_CN">zh_CN</Option>
                    </Select> */}
					<label className='footerLangTecnoM'>© TECNO Mobile</label>
				</div>
			</div>
			{/* {state.langInfo.lang}
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <Option value="en">en</Option>
                <Option value="zh">zh</Option>
            </Select> */}
		</div>
	);
});
