import { fromJS } from 'immutable';
import { RES_ROUTER ,RES_LANG} from '../../constants/routerpositionTypes';

// 初始化state数据
const initialState = {
    routerPositionInfo: {routerPosition:''}
};

/**
 * 登录界面reducer
 * @return
 */
const RouterPosition = (state = initialState, action) => {
    switch(action.type) {
      
        case RES_ROUTER: // 切换路由成功
            return fromJS(state).merge({routerPositionInfo: action.res}).toJS();
        default:
            return state;
    }
}

export default RouterPosition;