/**
 * 提交漏洞列表界面action
 * @return
 */
import { RES_GETLEADERBOARDSLIST,RES_CHANGEPAGINATION,RES_CHANGELEADERBOARDSTYPE,RES_CHANGETOTAL,RES_CHANGEYEARSELECT,RES_CHANGEMONTHSELECT } from '../../constants/leaderboardsListTypes';
import store from '../../store/index'
import LeaderboardsService from '../../services/leaderboardsService';
 
 /**
  * 提交漏洞列表
  * @return
  */
const resGetLeaderboardsList = (res) => {
    return {
        type: RES_GETLEADERBOARDSLIST,
        res
    }
}
const resChangePagination = (res) => {
    return {
        type: RES_CHANGEPAGINATION,
        res
    }
}
const resChangeleaderboardsType = (res) => {
    return {
        type: RES_CHANGELEADERBOARDSTYPE,
        res
    }
}
const resChangeYearSelect = (res) => {
    return {
        type: RES_CHANGEYEARSELECT,
        res
    }
}
const resChangeMonthSelect = (res) => {
    return {
        type: RES_CHANGEMONTHSELECT,
        res
    }
}

export const getLeaderboardsList = () => {
    const params = {}
    if(store.getState().Leaderboards.leaderboardsType == 'All Time') {
        params["Type"] = "total"
    } else
    if(store.getState().Leaderboards.leaderboardsType == 'Monthly') {
        params["Type"] = "month"
        params["year"] = store.getState().Leaderboards.yearSelect
        params["month"] = store.getState().Leaderboards.monthSelect
    } else if (store.getState().Leaderboards.leaderboardsType == 'Annual') {
        params["Type"] = "year"
        params["year"] = store.getState().Leaderboards.yearSelect
    }
    LeaderboardsService.getLeaderboardsListData(params).then (res => {
        store.dispatch(resGetLeaderboardsList(res.data))
        store.dispatch(resChangePagination({
            total:res.data.length,
            showSizeChanger:true,
            showQuickJumper:true,
        }))
    }).catch(error => {
        console.log(error)
    })
}

export const changeleaderboardsType = (e) => {
    store.dispatch(resChangeleaderboardsType(e.target.value));
    getLeaderboardsList()
}
export const changeYearSelect = (value) => {
    store.dispatch(resChangeYearSelect(value));
    getLeaderboardsList()
}
export const changeMonthSelect = (value) => {
    store.dispatch(resChangeMonthSelect(value));
    getLeaderboardsList()
}