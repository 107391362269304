import { fromJS } from 'immutable';
import { LOADING } from '../constants/dispatchTypes';
import Login from './login/loginReducer'; // 登录界面
import MessageCenter from './MessageCenter/messageCenterReducer'; // 公告界面
import Blog from './Blog/blogReducer'; // 公告界面
import BugSubmitList from './BugSubmitList/bugSubmitListReducer'; // 提交bug列表
import BugSubmitDetail from './BugSubmitDetail/bugSubmitDetailReducer'; // 提交bug详情
import BlogDetail from './BlogDetail/blogdetailReducer'; // 公告界面
import Leaderboards from './Leaderboards/leaderboardsReducer'; // 公告界面
import Lang from './Lang/langReducer'; // 语言切换界面
import RouterPosition from './RouterPosition/routerpositionReducer'; // 路由切换界面

// 初始化state数据
const initialState = {
    loading: false
};

/**
 * 公共reducer
 * @return
 */
const Common = (state = initialState, action) => {
    switch(action.type) {
        case LOADING: // 用于页面和区块的加载中状态
            return fromJS(state).merge({loading: action.loading}).toJS();
        default:
            return state;
    }
}

export {Common,Login,Lang,RouterPosition,BugSubmitList,BugSubmitDetail,Blog,BlogDetail,Leaderboards,MessageCenter};