import React, { useEffect,useState } from 'react';
import './style/bindEmailPwd.css';
import {useIntl} from 'react-intl';
import { scrollToTop } from '../../common/topScorll'
import { goRouterPosition } from '../../action/routerposition/routerpositionAction'
import {Input,Popover,Progress,Select,Checkbox} from 'antd'
import { useHistory } from 'react-router-dom'
import BindEmailPwdService from '../../services/bindEmailPwdService'
import { Link } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import store from '../../store/index'
import { JSEncrypt } from 'jsencrypt'

export default (function (props) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYpKIowTM/+WFirmaZuU9e0BK7zyQRBuPUG4CYWl6S/VsiDoAMlqKHdG/9G9+Wd2ZDIzAfQM+tZ2cutQotNlxyd/d7LZm71xy+zPvpcGJQo4iQ8cylZfjZ2gQO3+7nEqo11eVYlrI8MyOhgRLSzvpNHaBPlEPd9zKCh8baLF5NywIDAQAB");
    const intl = useIntl();
    const [ errorMsg,setErrorMsg ] = useState("")
    const [ countDownSecond,setCountDownSecond ] = useState(60)
    const [ countrySelectList,setCountrySelectList ] = useState([])
    const [ countrySelect,setCountrySelect ] = useState("")
    const [ captchaCode,setCaptchaCode ] = useState("")
    const [ captchaCodeToken,setCaptchaCodeToken ] = useState("")
    const [ emailAdressInput,setEmailAdressInput ] = useState("")
    const [ passwordInput,setPasswordInput ] = useState("")
    const [ passwordVerifyInput,setPasswordVerifyInput ] = useState("")
    const [ captchaCodeInput,setCaptchaCodeInput ] = useState("")
    const [ agreementCheckbox,setAgreementCheckbox ] = useState(false)
    const [ emailCaptchaCodeInput,setEmailCaptchaCodeInput ] = useState("")
    const [ percentProgress,setPercentProgress ] = useState(0)
    const { Option } = Select;
    const history = useHistory()
    useEffect(() => {
        (sessionStorage.getItem('lang') == 'zh_CN') && (document.title = '绑定邮箱密码') ||
        (sessionStorage.getItem('lang') == 'en_US') && (document.title = 'Bind Email & PassCode')
        scrollToTop();
        // sessionStorage.setItem("redirect", window.location.pathname)
        sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
        goRouterPosition({routerPosition:"bindEmailPwd"})
        BindEmailPwdService.getCaptchaCode().then (res => {
            setCaptchaCode(res.data.base64Img)
            setCaptchaCodeToken(res.data.cToken)
        }).catch(error => {
            console.log(error)
        })
        BindEmailPwdService.getCountryList({
            lang: sessionStorage.getItem('lang')
        }).then (res => {
            setCountrySelectList(res.data)
        }).catch(error => {
            console.log(error)
        })
    }, []);
    //60s倒计时
    const handleCountDown = (seconds = 60) => {
        let second = seconds;
        const countDown = ()=> {
            // timer && clearTimeout(timer); //清除计时器
            if( second > 0){
                second--;
                setCountDownSecond( second );
            }
            if( second === 0 ){
                second = 60;
                setCountDownSecond( second );
                // clearTimeout( timer ); //清除计时器
                return;
            }
            setTimeout( countDown,1000 );
        };
        setTimeout( countDown,1000 );
    };
    const bindEmailPwd = ()=> {
        if(countrySelect == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.bindemailpwd.message.error.choosecountry'
            }));
            return false
        } else if(emailAdressInput == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.bindemailpwd.message.error.email'
            }));
            return false
        } else if(emailCaptchaCodeInput == ""){
            setErrorMsg(intl.formatMessage({
                id: 'intl.bindemailpwd.message.error.emailcaptchacode'
            }));
            return false
        }
        let parmes = {}
        parmes={
            countryId: countrySelect,
            email: encrypt.encrypt(emailAdressInput),
            passCode: encrypt.encrypt(passwordInput),
            // passCodeConfirm: passwordVerifyInput,
            mCode: encrypt.encrypt(emailCaptchaCodeInput),
        }
        // if(passwordInput==passwordVerifyInput){
        if(agreementCheckbox){
            if(percentProgress == 100){
                BindEmailPwdService.goBindEmailPwd(parmes).then (res => {
                    history.push('/SRC/personalInfo')
                }).catch(error => {
                    changeCaptchaCode()
                    setErrorMsg(error)
                })
            }else {
                setErrorMsg(intl.formatMessage({
                    id: 'intl.bindemailpwd.message.error.passwordrule'
                }));
            }
        }else{
            setErrorMsg(intl.formatMessage({
                id: 'intl.bindemailpwd.message.error.chooseagreement'
            }));
        }
        // }else{
        //     message.error('两次密码不一致');
        // }
    }
    const getEmailCaptcha = ()=> {if(captchaCodeInput == ""){
        setErrorMsg(intl.formatMessage({
                id: 'intl.bindemailpwd.message.error.captchacode'
            }));
            return false
        }
        BindEmailPwdService.goEmailCaptcha({
            mail: emailAdressInput,
            cToken: captchaCodeToken,
            cCode: captchaCodeInput,
            type: "mail_bindmail"
        }).then (res => {
            handleCountDown();
        }).catch(error => {
            changeCaptchaCode()
            setErrorMsg(error)
        })
    }
    const changeCaptchaCode = ()=> {
        BindEmailPwdService.getCaptchaCode().then (res => {
            setCaptchaCode(res.data.base64Img)
            setCaptchaCodeToken(res.data.cToken)
        }).catch(error => {
            console.log(error)
        })
    }
    const countrySelectChange = (value)=> {
        setCountrySelect(value)
    }
    const emailAdressInputChange = (e)=> {
        setEmailAdressInput(e.target.value)
    }
    const agreementCheckboxChange = (e)=> {
        if (e.target.checked) {
            setErrorMsg("")
        }
        setAgreementCheckbox(e.target.checked)
    }
    const passwordInputChange = (e)=> {
        setPasswordInput(e.target.value)
        let precent = 0;
        console.log(e.target.value,"e.target.value")
        if (e.target.value.length > 7 && e.target.value.length < 17) {
            precent = 25
        }
        if (/\d/.test(e.target.value)) {
            precent = precent+25
        } //数字
        if ((/[a-z]/.test(e.target.value)) || (/[A-Z]/.test(e.target.value))) {
            precent = precent+25
        } //小写/大写
        if (/\W/.test(e.target.value)) {
            precent = precent+25
        } //特殊字符
        setPercentProgress(precent)
    }
    const passwordInputCheck = (e)=> {
        let precent = 0;
        if (e.target.value.length > 7 && e.target.value.length < 17) {
            precent = 25
        }
        if (/\d/.test(e.target.value)) {
            precent = precent+25
        } //数字
        if ((/[a-z]/.test(e.target.value)) || (/[A-Z]/.test(e.target.value))) {
            precent = precent+25
        } //小写/大写
        if (/\W/.test(e.target.value)) {
            precent = precent+25
        } //特殊字符
        if(precent != 100) {
            setErrorMsg(intl.formatMessage({
                id: 'intl.signup.password.rule'
            }))
        }else {
            setErrorMsg("");
        }
    }
    const passwordVerifyInputChange = (e)=> {
        setPasswordVerifyInput(e.target.value)
    }
    const captchaCodeInputChange = (e)=> {
        setCaptchaCodeInput(e.target.value)
    }
    const emailCaptchaCodeInputChange = (e)=> {
        setEmailCaptchaCodeInput(e.target.value)
    }
    const passwordPopoverTitle = <span className="PopoverProgressTitle">
        {
            intl.formatMessage({
                id: 'intl.bindemailpwd.password.title'
            })
        }
    </span>;
    const passwordPopover = (
        <div>
          <Progress
            status={(percentProgress<100)?"exception":"success"}
            percent={percentProgress}
            />
            <p className="PopoverProgress">
                {
                    intl.formatMessage({
                        id: 'intl.bindemailpwd.password.rule'
                    })
                }
            </p>
        </div>
    );
    return ( 
        <div className="bindEmailPwdContainer">
            <div className="backLogin">
                <Link className="backLoginLabel" to={`/SRC/personalInfo`}>
                    <LeftOutlined className="backIcon"/>
                    {
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.button.back'
                        })
                    }
                </Link>
            </div>
            <div className="bindEmailPwdContent">
                <p className="bindEmailPwdContentTitle">
                    {
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.title'
                        })
                    }
                </p>
                <p className="bindEmailPwdContentSubTitle">
                    {
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.subtitle'
                        })
                    }
                </p>
                <Select
                    onChange={countrySelectChange}
                    className="signUpSelect"
                    size="large"
                    showSearch
                    placeholder={
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.placeholder.country'
                        })
                    }
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        countrySelectList.map((data,index)=>(
                            <Option key={index} value={data.id}>{data.name}</Option>
                        ))
                    }
                </Select>
                <Input className="bindEmailPwdInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.placeholder.email'
                        })
                    } onChange={emailAdressInputChange}/>
                <Popover placement="right" overlayClassName='passwordPopoverOverlay' title={passwordPopoverTitle} content={passwordPopover} trigger="focus">
                    <Input.Password className="bindEmailPwdInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.placeholder.password'
                        })
                    } onChange={passwordInputChange} onBlur={passwordInputCheck}/>
                </Popover>
                {/* <Input.Password className="bindEmailPwdInput" placeholder="Verify the password" onChange={passwordVerifyInputChange}/> */}
                <Input className="bindEmailPwdInput captchaInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.placeholder.captcha.img'
                        })
                    } onChange={captchaCodeInputChange}/>
                <img className="captchaCode" onClick={changeCaptchaCode} src={ captchaCode }/>
                <Input className="bindEmailPwdInput captchaInput" placeholder={
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.placeholder.captcha.email'
                        })
                    } onChange={emailCaptchaCodeInputChange}/>
                <button className={countDownSecond == 60 ? "captchaButton" : "captchaButtonDis"} onClick={getEmailCaptcha} disabled={countDownSecond == 60 ? false : true}>
                    {  
                        countDownSecond == 60 ? intl.formatMessage({
                            id: 'intl.bindemailpwd.button.getcaptcha'
                        }) : `${countDownSecond}${intl.formatMessage({
                            id: 'intl.bindemailpwd.button.aftergetcaptcha'
                        })}`
                        
                    }
                </button>
                <Checkbox className="signUpCheckbox" onChange={agreementCheckboxChange}>
                    {
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.agreement.agree'
                        })
                    }
                    <Link className="LinkSignUp" onClick={()=>window.open("/privacyPolicy/"+sessionStorage.getItem('lang'))}>
                        {
                            intl.formatMessage({
                                id: 'intl.bindemailpwd.agreement.policy'
                            })
                        }
                    </Link>
                    {
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.agreement.and'
                        })
                    }
                    <Link className="LinkSignUp" onClick={()=>window.open("/agreement/"+sessionStorage.getItem('lang'))}>
                        {
                            intl.formatMessage({
                                id: 'intl.bindemailpwd.agreement.plateform'
                            })
                        }   
                    </Link>
                    {
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.agreement.content'
                        })
                    }
                </Checkbox>
                <p className="errorMessage">{errorMsg}</p>
                <p className="bindEmailPwdButton" onClick={bindEmailPwd}>
                    {
                        intl.formatMessage({
                            id: 'intl.bindemailpwd.button.gobindemailpwd'
                        })
                    }
                </p>
            </div> 
        </div> 
    );
})