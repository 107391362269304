export const RES_GETMESSAGECENTERLIST = 'RES_GETMESSAGECENTERLIST'; // 获取公告列表
export const RES_CHANGEMESSAGECENTERSEARCH = 'RES_CHANGEMESSAGECENTERSEARCH'; // 更改公告类型
export const RES_CHANGEMESSAGECENTERTYPE = 'RES_CHANGEMESSAGECENTERTYPE'; // 更改公告类型
export const RES_CHANGEMESSAGECENTERREADTYPE = 'RES_CHANGEMESSAGECENTERREADTYPE'; // 更改公告类型
export const RES_CHANGEPAGINATION = 'RES_CHANGEPAGINATION'; // 更改公告页码
export const RES_CHANGEPAGINATIONSIZE = 'RES_CHANGEPAGINATIONSIZE'; // 更改公告页码
export const RES_CHANGEPAGINATIONTOTAL = 'RES_CHANGEPAGINATIONTOTAL'; // 更改公告数量
export const RES_COUNTALL = 'RES_COUNTALL';
export const RES_COUNTSYSTEMNOTIFICATION = 'RES_COUNTSYSTEMNOTIFICATION';
export const RES_COUNTREVIEWRESULTS = 'RES_COUNTREVIEWRESULTS';
export const RES_COUNTREADALL = 'RES_COUNTREADALL';
export const RES_COUNTREAD = 'RES_COUNTREAD';
export const RES_COUNTUNREAD = 'RES_COUNTUNREAD';
export const RES_COUNTPROdDUCTORDERALL = 'RES_COUNTPROdDUCTORDERALL';