import { fromJS } from 'immutable';
import { RES_GETBUGSUBMITLIST,RES_GETLEAKINFO,RES_CHANGEPAGINATION,RES_CHANGEFILTERS,RES_CHANGESORTS,RES_CHANGETOTAL } from '../../constants/bugSubmitListTypes';

// 初始化state数据
const initialState = {
    Vulnerability: {},
    pagination: {
        current: 1,
        pageSize: 10,
        total:0,
        showSizeChanger:true,
        showQuickJumper:true,
    },
    filters: [],
    sorter:{
        order: "descend",
        field: "creatTime"
    },
    bugSubmitList:[]
};

/**
 * 提交bug列表界面reducer
 * @return
 */
const BugSubmitList = (state = initialState, action) => {
    switch(action.type) {
        case RES_GETBUGSUBMITLIST: // 获取提交bug列表
            return fromJS(state).merge({bugSubmitList: action.res}).toJS();
        case RES_GETLEAKINFO: // 获取提交Leak信息
            return fromJS(state).merge({Vulnerability: action.res}).toJS();
        case RES_CHANGEPAGINATION: // 更改页码
            return fromJS(state).merge({pagination: action.res}).toJS();
        case RES_CHANGEFILTERS: // 更改筛选
            return fromJS(state).merge({filters: action.res}).toJS();
        case RES_CHANGESORTS: // 更改排序
            return fromJS(state).merge({sorter: action.res}).toJS();
        case RES_CHANGETOTAL: // 更改总数
            return fromJS(state).merge({pagination: {total:action.res}}).toJS();
        default:
            return state;
    }
}

export default BugSubmitList;