import {React} from 'react';
import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import lds from 'lodash';
import {useIntl} from 'react-intl';
export default (function (props) {
	const chartRef = useRef();
  const intl = useIntl();
	let options = {
		tooltip: {
			trigger: 'item',
      textStyle: {
        fontFamily:'HurmeGeometricSans3',
      },
		},
		color: ['#9254DE', '#02AAF9', '#34C759', '#FAAD14','#D8D8D8'],
		legend: {
			top: '0%',
			right: '5%',
			orient: 'verticalAlign',
			icon: 'circle',
			itemWidth: 8,
			formatter: function (name) {
				let temp;
				if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.open'})) {
					temp = props.value?.leakResultOpen;
				} else if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.tiaged'})) {
					temp = props.value?.leakResultTriaged;
				} else if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.fixed'})) {
					temp = props.value?.leakResultFixed;
				} else if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.rejected'})) {
					temp = props.value?.leakResultRejected;
				}else if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.suspended'})) {
          temp = props.value?.leakResultSuspended||0;
        }
				var arr = ['{a|' + name + '}', '{c|' + temp + '}'];
				return arr.join('  ');
			},
			textStyle: {
				rich: {
					a: {
						fontSize: 14,
            fontFamily:'HurmeGeometricSans3',
						width: 30
					},
					c: {
						fontSize: 14,
            fontFamily:'HurmeGeometricSans3',
						padding: [0, 0, 0, 80]
					}
				}
			},

      emphasis: { 
        textStyle: {
            color: '#c23531', // 鼠标 hover 时的颜色
            backgroundColor: '#c23531' // 鼠标 hover 时的颜色
        }
    }
		},
		series: [
			{
				name: 'Status of my reports',
				type: 'pie',
				center: ['20%', '40%'],
				radius: ['50%', '70%'],
				avoidLabelOverlap: false,
				label: {
					show: false,
					position: 'center'
				},
				data: [
					{value: 0, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.open'})},
					{value: 0, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.tiaged'})},
					{value: 0, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.fixed'})},
					{value: 0, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.rejected'})},
					{value: 0, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.suspended'})},
				]
			}
		]
	};
	const initChartOption = () => {
		const tempOptions = lds.cloneDeep(options);
		tempOptions.series[0].data = [
			{value: props.value?.leakResultOpen, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.open'})},
			{value: props.value?.leakResultTriaged, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.tiaged'})},
			{value: props.value?.leakResultFixed, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.fixed'})},
			{value: props.value?.leakResultSuspended, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.suspended'})},
			{value: props.value?.leakResultRejected, name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.rejected'})}
		];
		return tempOptions;
	};
	useEffect(() => {
		let initOption = initChartOption();
		const chart = echarts.init(chartRef.current);
		chart.setOption(initOption);
		window.addEventListener('resize', () => {
			chart.resize();
		});
		return () => {};
	});
	return (
		<div
			ref={chartRef}
			style={{width: '100%', height: '100%', boxShadow: '0px 0px'}}></div>
	);
});
