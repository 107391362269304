import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class publicProfileService {
	getPersonalInfo(params) {
		return Xhr.get(
			`/userInfo/selectUserInfo?lang=${sessionStorage.getItem('lang')}`,
			{params: params}
		);
	}
	getUserStatistics(params) {
		return Xhr.get(
			`/userInfo/getStatistics?lang=${sessionStorage.getItem('lang')}`,
			{params: params}
		);
	}

	getStatistics(params) {
		return Xhr.get(
			`/userInfo/getStatistics?lang=${sessionStorage.getItem('lang')}`,
			{params: params}
		);
	}
	getAllMedals(params) {
		return Xhr.get(
			`/grantSafetyMedal/selectExist?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			{params: params}
		);
	}
	wearOrCancelMedal(params) {
		return Xhr.post(
			`/grantSafetyMedal/wearOrCancelMedal?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			params
		);
	}

	getSocialMedia(params) {
		return Xhr.get(
			`/userSocialMedia/selectSocialAccountInfo?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			{params: params}
		);
	}
	editPublicProfile(params) {
		return Xhr.post(
			`/personalEdit/edit?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	editSocialAccount(params) {
		return Xhr.post(
			`/userSocialMedia/batchProcessing?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			params
		);
	}
}

// 实例化再导出
export default new publicProfileService();
