/**
 * 切换语言界面action
 * @return
 */
import { RES_ROUTER } from '../../constants/routerpositionTypes';
import store from '../../store/index'

/**
 * 切换语言成功
 * @return
 */
const resRouterPosition = (res) => {
    return {
        type: RES_ROUTER,
        res
    }
}

/**
 * 登录界面	
 * @param {lang} 语言
 */

export const goRouterPosition = (params) => {
    sessionStorage.setItem("routerPosition",params.routerPosition)
    store.dispatch(resRouterPosition(params));
} 
