import { fromJS } from 'immutable';
import { RES_GETBLOGDETAIL,RES_GETBLOGDETAILUP,RES_GETBLOGDETAILDOWN } from '../../constants/blogdetailTypes';

// 初始化state数据
const initialState = {
    blogDetail:{
        title: '',
        label: '',
        time: '',
        viewCounts: 0,
        content:'',
        files: []
    },
    blogDetailUp: {
        title: '',
        label: '',
        time: '',
        viewCounts: 0,
        content:'',
        files: []
    },
    blogDetailDown: {
        title: '',
        label: '',
        time: '',
        viewCounts: 0,
        content:'',
        files: []
    },
};

/**
 * 登录界面reducer
 * @return
 */
const BlogDetail = (state = initialState, action) => {
    switch(action.type) {
        case RES_GETBLOGDETAIL: // 获取公告详情
            return fromJS(state).merge({blogDetail: action.res}).toJS();
        case RES_GETBLOGDETAILUP: // 获取公告详情上一篇
            return fromJS(state).merge({blogDetailUp: action.res}).toJS();
        case RES_GETBLOGDETAILDOWN: // 获取公告详情下一篇
            return fromJS(state).merge({blogDetailDown: action.res}).toJS();
        default:
            return state;
    }
}

export default BlogDetail;