/**
 * 公告界面界面action
 * @return
 */
import { RES_GETBLOGDETAIL,RES_GETBLOGDETAILUP,RES_GETBLOGDETAILDOWN } from '../../constants/blogdetailTypes';
import store from '../../store/index'
import sanitizeHtml from 'sanitize-html';
import BlogDetailService from '../../services/blogdetailService';
 
 /**
  * 更新公告
  * @return
  */
const resGetBlogDetail = (res) => {
    return {
        type: RES_GETBLOGDETAIL,
        res
    }
}
const resGetBlogDetailUp = (res) => {
    return {
        type: RES_GETBLOGDETAILUP,
        res
    }
}
const resGetBlogDetailDown = (res) => {
    return {
        type: RES_GETBLOGDETAILDOWN,
        res
    }
}
const getQueryString = (name)=> {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}

export const getBlogDetail = (id) => {
    const langtemp = getQueryString("lang")
    console.log(langtemp,"langtemp")
    BlogDetailService.getBlogDetailData({id:id,lang:langtemp}).then (res => {
        const temp = res.data
        // temp.content = sanitizeHtml(temp.content)
        temp.content = temp.content
        store.dispatch(resGetBlogDetail(temp))
    }).catch(error => {
        // store.dispatch(resGetBlogDetailUp());
        // store.dispatch(resGetBlogDetailDown());
        console.log(error)
    })
}

export const getBlogDetailPre = (id) => {
    BlogDetailService.getBlogDetailPreData({id:id}).then (res => {
        store.dispatch(resGetBlogDetailUp(res.data))
    }).catch(error => {
        // store.dispatch(resGetBlogDetailUp());
        // store.dispatch(resGetBlogDetailDown());
        console.log(error)
    })
}

export const getBlogDetailNext = (id) => {
    BlogDetailService.getBlogDetailNextData({id:id}).then (res => {
        store.dispatch(resGetBlogDetailDown(res.data))
    }).catch(error => {
        // store.dispatch(resGetBlogDetailUp());
        // store.dispatch(resGetBlogDetailDown());
        console.log(error)
    })
}

 