import React, {Fragment, useEffect, useState, useRef} from 'react';
import './style/dashboard.css';
import {useIntl} from 'react-intl';
import {Tag, Modal, Button, Popover} from 'antd';
import BlogService from '../../services/blogService';
import HeaderDashboard from '../../common/headerDashboard/index';
import Header from '../../common/header/index';
import {goRouterPosition} from '../../action/routerposition/routerpositionAction';
import {scrollToTop} from '../../common/topScorll';
import {detailLink} from '../../common/detailLink';
import Footer from '../../common/footer/index';
import {useHistory} from 'react-router-dom';
import goBlogIcon from '../../assets/svg/ic-跳转.svg';
import dashboardBg from '../../assets/svg/bg-dashboard.png';
import {Carousel, Icon} from 'antd';
import goBlogIconHover from '../../assets/svg/ic-跳转-hover.svg';
import awardsIcon from '../../assets/svg/ic-奖励政策插图.svg';
import submitGuideIcon from '../../assets/svg/ic-提交指引.svg';
import dealWayIcon from '../../assets/svg/ic-处理流程.svg';
import awardWayIcon from '../../assets/svg/ic-奖励政策.svg';
import applyPhoneIcon from '../../assets/svg/ic-样机申请.svg';
import normalQuestionIcon from '../../assets/svg/ic-常见问答.svg';
import {getElementError} from '@testing-library/react';
import avatar from '../../assets/svg/ic-个人中心-头像.svg';
import researchBg from '../../assets/svg/bg-research.png';
import lds from 'lodash';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import { use } from 'echarts';

export default (function (props) {
	const dashboardImgRef = useRef(null);
	const intl = useIntl();
	const history = useHistory();
	const [goBlogIconImg, setGoBlogIconImg] = useState(goBlogIcon);
	// const [ news,setNews ] = useState({})
	// const [ blog,setBlog ] = useState({})
	// const [ event,setEvent ] = useState({})
	const [resources, setResources] = useState([]);
	const [activityData, setActivityData] = useState([]);
	const [modelData, setModelData] = useState([]);
	const [modelShowData1, setModelShowData1] = useState([]);
	const [modelShowData2, setModelShowData2] = useState([]);
	const [monthlyRankData, setMonthlyRankData] = useState([]);
	const [researchHover, setResearchHover] = useState(false);
	const [bannerPicData, setBannerPicData] = useState([]);
	const [researchData, setResearchData] = useState();
	const [researchModalOpen, setResearchModalOpen] = useState(false);
	const textBannerColorList = [
		'rgba(255,203,123,0.25)',
		'rgba(75,188,204,0.25)',
		'rgba(212,96,154,0.25)'
	];
	const medalCardContent = (item, userId) => {
		return (
			<div className='mdealCardDiv'>
				<div className='medalCardTitleDiv'>
					<img
						src={item.showMedalIconOss}
						className='medalCardTitleImg'
						alt='img'
					/>
					<div className='medalCardTitleText'>
						<div className='medalCardTitleNameDiv'>
							<div className='dashBoardMedalCardTitleName'>
								<div>{item.safetyMedalI18n?.medalName}</div>
								<div
									style={{
										color: '#0264fa',
										cursor: 'pointer',
										fontSize: '12px'
									}}
									onClick={() => {
										window.open(
											userId
												? `/SRC/publicProfile/?userId=${userId}`
												: `/SRC/publicProfile`
										);
									}}>
									{intl.formatMessage({
										id: 'intl.dashboard.medalCard.jumpText'
									})}
								</div>
							</div>
						</div>
						{item.safetyMedalI18n?.activityName && (
							<div
								className='medalCardTitleTag'
								onClick={() =>
									window.open(
										item.safetyMedalI18n?.activityUrl
									)
								}>
								# {item.safetyMedalI18n?.activityName}
							</div>
						)}

						<div className='medalCardTitleUserCount'>
							{item.safetyMedalUserCount}
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.userCount'
							})}
						</div>
						{!item.safetyMedalI18n?.activityName && (
							<div style={{height: '20px'}}></div>
						)}
						<div className='medalCardTitleObtainStatus'>
							<label>
								{intl.formatMessage({
									id: 'intl.personalinfo.medalCard.unlockedTime'
								})}
								{item.cardCreateTime}
							</label>
							<label style={{margin: '0 8px'}}>|</label>
							<label>
								{item.cardEndTime ? (
									<>
										{intl.formatMessage({
											id: 'intl.personalinfo.medalCard.expiredTime'
										})}
										{item.cardEndTime}
									</>
								) : (
									<>
										{intl.formatMessage({
											id: 'intl.personalinfo.medalCard.longTerm'
										})}
									</>
								)}
							</label>
						</div>
					</div>
				</div>
				<div className='medalCardIntro'>
					{item.safetyMedalI18n?.medalIntro}
				</div>
			</div>
		);
	};
	const goSingUp = () => {
		history.push('/SRC/signUp');
	};
	const goBugSubmit = () => {
		if (sessionStorage.getItem('token') != undefined) {
			history.push('/SRC/bugSubmit');
		} else {
			history.push('/SRC/login');
		}
	};
	const goBlogDetail = (id) => {
		history.push(
			`/SRC/blogdetail/${id}?lang=${sessionStorage.getItem('lang')}`
		);
	};
	const jumpToAcknowledgements = () => {
		sessionStorage.setItem('blogType', 'News');
		if (sessionStorage.getItem('lang') == 'zh_CN') {
			sessionStorage.setItem('subType', '致谢');
		} else {
			sessionStorage.setItem('subType', 'Acknowledgements');
		}
		history.push('/SRC/blog');
	};
	const goDetailLink = (linkContent) => {
		// console.log(detailLink)
		// console.log(linkContent)
		// console.log(detailLink[sessionStorage.getItem('lang')][linkContent])
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
		// window.open(`${detailLink}.${sessionStorage.getItem('lang')}.${linkContent}`)
	};
	const calDetailClamp = (title, detail) => {
		let titleDoc = document.getElementById(title);
		let titleHeight = window
			.getComputedStyle(titleDoc)
			.getPropertyValue('height')
			.split('px')[0];
		let detailDoc = document.getElementById(detail);
		if (title == 'fourthTitle') {
			if (titleHeight >= 135 - 12) {
				titleDoc.style.setProperty('-webkit-line-clamp', 5);
				titleDoc.style.setProperty('overflow', 'hidden');
				titleDoc.style.setProperty('text-overflow', 'ellipsis');
				titleDoc.style.setProperty('-webkit-box-orient', 'vertical');
				titleDoc.style.setProperty('display', '-webkit-box');
				detailDoc.style.setProperty('display', 'none');
			} else {
				let detailHeight = Math.floor((135 - 12 - titleHeight) / 22);
				detailDoc.style.setProperty('-webkit-line-clamp', detailHeight);
				detailDoc.style.setProperty('height', detailHeight * 22 + 'px');
			}
		} else {
			if (titleHeight >= 216 - 12) {
				titleDoc.style.setProperty('-webkit-line-clamp', 5);
				titleDoc.style.setProperty('overflow', 'hidden');
				titleDoc.style.setProperty('text-overflow', 'ellipsis');
				titleDoc.style.setProperty('-webkit-box-orient', 'vertical');
				titleDoc.style.setProperty('display', '-webkit-box');
				detailDoc.style.setProperty('display', 'none');
			} else {
				let detailHeight = Math.floor((216 - 12 - titleHeight) / 22);
				detailDoc.style.setProperty('-webkit-line-clamp', detailHeight);
				detailDoc.style.setProperty('height', detailHeight * 22 + 'px');
			}
			let detailHeight = Math.floor((216 - 12 - titleHeight) / 22);
			detailDoc.style.setProperty('-webkit-line-clamp', detailHeight);
			detailDoc.style.setProperty('height', detailHeight * 22 + 'px');
		}
	};
	const scrollActiveModel = () => {
		let speed = 18;
		let MyMar = null;
		let scroll_begin = document.getElementById('scroll_begin');
		let scroll_end = document.getElementById('scroll_end');
		let scroll_div = document.getElementById('dashboardScrollDiv');
		if (modelShowData2.length == 0) {
			scroll_end.innerHTML = scroll_begin.innerHTML;
		}
		function Marquee() {
			if (scroll_end.offsetWidth - scroll_div.scrollLeft <= 0) {
				scroll_div.scrollLeft = -1440;
			} else {
				scroll_div.scrollLeft++;
			}
		}
		MyMar = setInterval(Marquee, speed);
	};
	const getShowModel = () => {
		BlogService.getShow().then((res) => {
			let tempData1 = [];
			let tempData2 = [];
			setModelData(res.data);
			if (res.data.length <= 15) {
				for (let i = 0; i < 15; i++) {
					tempData1.push(res.data[i % res.data.length]);
				}
				setModelShowData1(tempData1);
				setModelShowData2([]);
			} else if (res.data.length > 15) {
				for (let i = 0; i < 15; i++) {
					tempData1.push(res.data[i % res.data.length]);
				}
				for (let i = 15; i < 30; i++) {
					tempData2.push(res.data[i % res.data.length]);
				}
				setModelShowData1(tempData1);
				setModelShowData2(tempData2);
			}
		});
	};
	const jumpBannerUrl = (url) => {
		if (url) {
			window.open(url);
		}
	};
	const handlePrev = () => {
		dashboardImgRef.current.prev();
	};
	const handleNext = () => {
		dashboardImgRef.current.next();
	};

	const carouselSettings = {
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 5000
	};
	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = '首页')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'Dashboard'));
		scrollToTop();
		// sessionStorage.setItem('redirect', window.location.pathname);
		sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		goRouterPosition({routerPosition: 'dashboard'});
		getShowModel();
		BlogService.getDashBoardBannerData().then((res) => {
			setBannerPicData(res.data);
		});
		BlogService.getBlogListData({
			current: 1,
			pageSize: 10,
			lang: sessionStorage.getItem('lang')
		})
			.then((res) => {
				// res.data.list[0] && setNews(res.data.list[0]);
				// res.data.list[1] && setBlog(res.data.list[1])
				// res.data.list[2] && setEvent(res.data.list[2])
				let tempArray = [];
				res.data.list[0] && tempArray.push(res.data.list[0]);
				res.data.list[1] && tempArray.push(res.data.list[1]);
				res.data.list[2] && tempArray.push(res.data.list[2]);
				setResources(tempArray);
			})
			.catch((error) => {
				console.log(error);
			});
		BlogService.getActivity().then((res) => {
			setActivityData(res.data);
		});
		let date = new Date();
		BlogService.getLeaderboardsListData({
			Type: 'month',
			year: date.getFullYear() + '',
			month: date.getMonth() + 1 + ''
		}).then((res) => {
			setMonthlyRankData(res.data);
		});
		BlogService.getResearchData().then((res) => {
    //   console.log(res.data)
			setResearchData(res.data);
    if(!sessionStorage.getItem('isShow')){
      JSON.stringify(res.data) !== '{}' && openResearchModal();
      sessionStorage.setItem('isShow',true)
    }
			
		});
	}, []);

	const openResearchModal = () => {
		setResearchModalOpen(true);
	};
	useEffect(() => {
		if (activityData.length > 0) {
			calDetailClamp('firstTitle', 'firstDetail');
			calDetailClamp('secondTitle', 'secondDetail');
			calDetailClamp('thirdTitle', 'thirdDetail');
			calDetailClamp('fourthTitle', 'fourthDetail');
		}
	}, [activityData]);
	useEffect(() => {
		if (modelData.length > 0) {
			scrollActiveModel();
		}
	}, [modelShowData2]);
	return (
		<div className='dashboardContainer'>
			{/* <Header className="headerDiv"></Header> */}
			<HeaderDashboard className='headerDiv'></HeaderDashboard>
			<div className='dashboardBanner'>
				{bannerPicData.length > 1 && (
					<LeftOutlined
						className='dashboardLeftArrow'
						onClick={() => {
							handlePrev();
						}}
					/>
				)}
				<Carousel
					{...carouselSettings}
					ref={dashboardImgRef}
					style={{height: '616px'}}>
					{bannerPicData.length > 0 &&
						bannerPicData.map((item, index) => (
							<Fragment key={item.id}>
								<div
									onClick={() => {
										jumpBannerUrl(item.skipUrl);
									}}
									style={
										item.skipUrl != ''
											? {
													cursor: 'pointer',
													backgroundImage: `url(${item.ossPicUrl})`
											  }
											: {
													backgroundImage: `url(${item.ossPicUrl})`
											  }
									}
									className={'dashboardBannerDiv'}>
									{index == 0 && (
										<div className='dashboardContentDiv'>
											<p className='dashboardBannerTitle'>
												{intl.formatMessage({
													id: 'intl.dashboard.banner.title'
												})}
											</p>
											<p className='dashboardBannerSubTitle'>
												{intl.formatMessage({
													id: 'intl.dashboard.banner.subtitle'
												})}
											</p>
											<p
												className='dashboardBannerSubmitButton'
												onClick={goBugSubmit}>
												{intl.formatMessage({
													id: 'intl.dashboard.banner.submitbutton'
												})}
											</p>
											<p
												className='dashboardBannerViewRulesButton'
												onClick={() =>
													goDetailLink(
														'RewardProgramV1'
													)
												}>
												{intl.formatMessage({
													id: 'intl.dashboard.banner.viewRulesButton'
												})}
											</p>
										</div>
									)}
								</div>
							</Fragment>
						))}
				</Carousel>
				{JSON.stringify(researchData) !== '{}' && researchData && (
					<div
						className={'dashboardResearchDiv'}
						onClick={() => openResearchModal()}
						onMouseEnter={() => setResearchHover(true)}
						onMouseLeave={() => setResearchHover(false)}>
						<div className='dashboardResearchRightText'>
							{researchData.name}
						</div>

						{researchHover && (
							<img
								src={researchData.ossIconUrl}
								alt='img'
								style={{
									width: '32px',
									height: '32px',
									marginLeft: '6px'
								}}
							/>
						)}
					</div>
				)}
				{bannerPicData.length > 1 && (
					<RightOutlined
						className='dashboardRightArrow'
						onClick={() => {
							handleNext();
						}}
					/>
				)}
			</div>
			{/* <div className="dashboardBannerDiv">
                <div className="dashboardContentDiv">
                    <p className="dashboardBannerTitle">{
                        intl.formatMessage({
                            id: 'intl.dashboard.banner.title'
                        })
                    }</p>
                    <p className="dashboardBannerSubTitle">{
                        intl.formatMessage({
                            id: 'intl.dashboard.banner.subtitle'
                        })
                    }</p>
                    <p className="dashboardBannerSubmitButton" onClick={goBugSubmit}>{
                        intl.formatMessage({
                            id: 'intl.dashboard.banner.submitbutton'
                        })
                    }</p>
                    <p className="dashboardBannerViewRulesButton" onClick={() => goDetailLink("RewardProgramV1")}>{
                        intl.formatMessage({
                            id: 'intl.dashboard.banner.viewRulesButton'
                        })
                    }</p>
                </div>
            </div> */}
			<div className='dashboardResourcesMainDiv'>
				<div className='dashboardResourcesDiv'>
					<div className='dashboardResourcesTitle'>
						{intl.formatMessage({
							id: 'intl.dashboard.blogevent.title'
						})}
					</div>
					<div className='dashboardResourcesSubTitle'>
						{intl.formatMessage({
							id: 'intl.dashboard.blogevent.subTitle'
						})}
					</div>
					<div className='dashboardResourcesContent'>
						{resources &&
							resources.map((item, index) => (
								<div
									className='dashboardResourcesDetail'
									key={index}
									onClick={() => goBlogDetail(item.id)}>
									{(item?.type?.name == 'News' && (
										<Tag
											className='dashboardResourcesBlogEventstag'
											color='orange'>
											{intl.formatMessage({
												id: 'intl.blog.type.news'
											})}
										</Tag>
									)) ||
										(item?.type?.name == 'Blogs' && (
											<Tag
												className='dashboardResourcesBlogEventstag'
												color='cyan'>
												{intl.formatMessage({
													id: 'intl.blog.type.blog'
												})}
											</Tag>
										)) ||
										(item?.type?.name == 'Events' && (
											<Tag
												className='dashboardResourcesBlogEventstag'
												color='blue'>
												{intl.formatMessage({
													id: 'intl.blog.type.events'
												})}
											</Tag>))||
										(item?.type?.name == 'Rules' && (
											<Tag
												className='dashboardResourcesBlogEventstag'
												color='red'>
												{intl.formatMessage({
													id: 'intl.blog.type.rules'
												})}
											</Tag>
                      
										))
                    }
                    
                    
									<p className='dashboardResourcesBlogTitle'>
										{item.title}
									</p>
									<div className='dashboardResourcesblogEventsContentDiv'>
										<p className='dashboardResourcesblogEventsContent'>
											{item.preface}
										</p>
									</div>
									<img
										src={goBlogIconImg}
										onClick={() => goBlogDetail(item.id)}
										className='dashboardResourcesblogEventsGoButton'
										onMouseEnter={() =>
											setGoBlogIconImg(goBlogIconHover)
										}
										onMouseLeave={() =>
											setGoBlogIconImg(goBlogIcon)
										}
									/>
								</div>
							))}
					</div>
				</div>
			</div>
			<div className='dashboardActivityDiv'>
				<div className='dashboardActivityContentDiv'>
					<div className='dashboardActivityTitleDiv'>
						<div className='dashboardActivityTitle'>
							{intl.formatMessage({
								id: 'intl.dashboard.activity.title'
							})}
						</div>
						<div className='dashboardActivitySubTitle'>
							{intl.formatMessage({
								id: 'intl.dashboard.activity.subTitle'
							})}
						</div>
					</div>
					<div
						className='dashboardActivityContentFirst'
						onClick={() => {
							goBlogDetail(activityData[0].id);
						}}>
						{activityData.length > 0 && (
							<div className='dashboardActivityContent'>
								<div
									className='dashboardActivityContentTitle'
									id='firstTitle'>
									{activityData[0]?.title}
								</div>
								<div
									className='dashboardActivityContentDetail'
									id='firstDetail'>
									{activityData[0]?.content
										.replace(/<[^>]+>/g, '')
										.replace(/&nbsp;/gi, ' ')}
								</div>
							</div>
						)}
					</div>
					<div
						className='dashboardActivityContentSecond'
						onClick={() => {
							goBlogDetail(activityData[1].id);
						}}>
						{activityData.length > 0 && (
							<div className='dashboardActivityContent'>
								<div
									className='dashboardActivityContentTitle'
									id='secondTitle'>
									{activityData[1]?.title}
								</div>
								<div
									className='dashboardActivityContentDetail'
									id='secondDetail'>
									{activityData[1]?.content
										.replace(/<[^>]+>/g, '')
										.replace(/&nbsp;/gi, ' ')}
								</div>
							</div>
						)}
					</div>
					<div
						className='dashboardActivityContentThird'
						onClick={() => {
							goBlogDetail(activityData[2].id);
						}}>
						{activityData.length > 0 && (
							<div className='dashboardActivityContent'>
								<div
									className='dashboardActivityContentTitle'
									id='thirdTitle'>
									{activityData[2]?.title}
								</div>
								<div
									className='dashboardActivityContentDetail'
									id='thirdDetail'>
									{activityData[2]?.content
										.replace(/<[^>]+>/g, '')
										.replace(/&nbsp;/gi, ' ')}
								</div>
							</div>
						)}
					</div>
					<div
						className='dashboardActivityContentFourth'
						onClick={() => {
							goBlogDetail(activityData[3].id);
						}}>
						{activityData.length > 0 && (
							<div className='dashboardActivityContentDivFourth'>
								<div
									className='dashboardActivityContentTitle'
									id='fourthTitle'>
									{activityData[3]?.title}
								</div>
								<div
									className='dashboardActivityContentDetail'
									id='fourthDetail'>
									{activityData[3]?.content
										.replace(/<[^>]+>/g, '')
										.replace(/&nbsp;/gi, ' ')}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className='dashboardFameHallMainDiv'>
				<div className='dashboardFameHallDiv'>
					<div className='dashboardFameHallTitle'>
						{intl.formatMessage({
							id: 'intl.dashboard.fameHall.title'
						})}
					</div>
					<div className='dashboardFameHallLink'>
						{intl.formatMessage({
							id: 'intl.dashboard.fameHall.linkHint1'
						})}
						<label
							className='dashboardFameHallLinkHover'
							onClick={() => {
								jumpToAcknowledgements();
							}}
							style={{
								textDecoration: 'underline',
								cursor: 'pointer'
							}}>
							{intl.formatMessage({
								id: 'intl.dashboard.fameHall.linkHint2'
							})}
						</label>
						{sessionStorage.getItem('lang') == 'en_US' ? (
							<label>.</label>
						) : (
							<label>。</label>
						)}
					</div>
					<div className='dashboardFameHallAwards'>
						<div className='dashboardFameHallAwardsSecondPlace'>
							{monthlyRankData[1] ? (
								<>
									<div className='dashboardFameHallAwardsSecondAvatar' />
									<img
										src={
											monthlyRankData[1].pictureUrl
												? monthlyRankData[1].pictureUrl
												: avatar
										}
										alt='avatar'
										className='dashboardFameHallAvatarImg'
									/>
									<div
										className='dashboardFameHallAwardsDetailTitle'
										onClick={() => {
											window.open(
												monthlyRankData[1].userId
													? `/SRC/publicProfile/?userId=${monthlyRankData[1].userId}`
													: `/SRC/publicProfile`,
												monthlyRankData[1].userId
											);
										}}>
										{monthlyRankData[1].alienName}
										{monthlyRankData[1]?.grantSafetyMedal
											?.medalIconOss && (
											<Popover
												color='#f8f8f8'
												placement='right'
												content={medalCardContent(
													monthlyRankData[1]
														?.grantSafetyMedal,
													monthlyRankData[1].userId
												)}
												title={null}>
												<img
													src={
														monthlyRankData[1]
															?.grantSafetyMedal
															?.medalIconOss
													}
													alt='medal'
													className='dashBoardFameHallMedalImg'
												/>
											</Popover>
										)}
									</div>
									<div className='dashboardFameHallAwardsDetailValue'>
										{intl.formatMessage({
											id: 'intl.dashboard.fameHall.value'
										})}
										{monthlyRankData[1].honorValue}
									</div>
								</>
							) : (
								<>
									<div
										style={{visibility: 'hidden'}}
										className='dashboardFameHallAwardsSecondAvatar'
									/>
									<img
										style={{visibility: 'hidden'}}
										className='dashboardFameHallAvatarImg'
									/>
									<div className='dashboardFameHallAwardsDetailTitleNo'>
										-
									</div>
									<div className='dashboardFameHallAwardsDetailValue'>
										{intl.formatMessage({
											id: 'intl.dashboard.fameHall.value'
										})}
										0
									</div>
								</>
							)}
						</div>
						<div className='dashboardFameHallAwardsFirstPlace'>
							{monthlyRankData[0] ? (
								<>
									<div className='dashboardFameHallAwardsFirstAvatar' />
									<img
										src={
											monthlyRankData[0].pictureUrl
												? monthlyRankData[0].pictureUrl
												: avatar
										}
										alt='avatar'
										className='dashboardFameHallAvatarImg'
									/>
									<div
										className='dashboardFameHallAwardsDetailTitle'
										onClick={() => {
											window.open(
												monthlyRankData[0].userId
													? `/SRC/publicProfile/?userId=${monthlyRankData[0].userId}`
													: `/SRC/publicProfile`
											);
										}}>
										{monthlyRankData[0].alienName}
										{monthlyRankData[0]?.grantSafetyMedal
											?.medalIconOss && (
											<Popover
												color='#f8f8f8'
												placement='right'
												content={medalCardContent(
													monthlyRankData[0]
														?.grantSafetyMedal,
													monthlyRankData[0].userId
												)}
												title={null}>
												<img
													src={
														monthlyRankData[0]
															?.grantSafetyMedal
															?.medalIconOss
													}
													alt='medal'
													className='dashBoardFameHallMedalImg'
												/>
											</Popover>
										)}
									</div>
									<div className='dashboardFameHallAwardsDetailValue'>
										{intl.formatMessage({
											id: 'intl.dashboard.fameHall.value'
										})}
										{monthlyRankData[0].honorValue}
									</div>
								</>
							) : (
								<>
									<div
										style={{visibility: 'hidden'}}
										className='dashboardFameHallAwardsFirstAvatar'
									/>
									<img
										style={{visibility: 'hidden'}}
										className='dashboardFameHallAvatarImg'
									/>
									<div className='dashboardFameHallAwardsDetailTitleNo'>
										-
									</div>
									<div className='dashboardFameHallAwardsDetailValue'>
										{intl.formatMessage({
											id: 'intl.dashboard.fameHall.value'
										})}
										0
									</div>
								</>
							)}
						</div>
						<div className='dashboardFameHallAwardsThirdPlace'>
							{monthlyRankData[2] ? (
								<>
									<div className='dashboardFameHallAwardsThirdAvatar' />
									<img
										src={
											monthlyRankData[2].pictureUrl
												? monthlyRankData[2].pictureUrl
												: avatar
										}
										alt='avatar'
										className='dashboardFameHallAvatarImg'
									/>
									<div
										className='dashboardFameHallAwardsDetailTitle'
										onClick={() => {
											window.open(
												monthlyRankData[2].userId
													? `/SRC/publicProfile/?userId=${monthlyRankData[2].userId}`
													: `/SRC/publicProfile`
											);
										}}>
										{monthlyRankData[2].alienName}
										{monthlyRankData[2]?.grantSafetyMedal
											?.medalIconOss && (
											<Popover
												color='#f8f8f8'
												placement='right'
												content={medalCardContent(
													monthlyRankData[2]
														?.grantSafetyMedal,
													monthlyRankData[2].userId
												)}
												title={null}>
												<img
													src={
														monthlyRankData[2]
															?.grantSafetyMedal
															?.medalIconOss
													}
													alt='medal'
													className='dashBoardFameHallMedalImg'
												/>
											</Popover>
										)}
									</div>
									<div className='dashboardFameHallAwardsDetailValue'>
										{intl.formatMessage({
											id: 'intl.dashboard.fameHall.value'
										})}
										{monthlyRankData[2].honorValue}
									</div>
								</>
							) : (
								<>
									<div
										style={{visibility: 'hidden'}}
										className='dashboardFameHallAwardsThirdAvatar'
									/>
									<img
										style={{visibility: 'hidden'}}
										className='dashboardFameHallAvatarImg'
									/>
									<div className='dashboardFameHallAwardsDetailTitleNo'>
										-
									</div>
									<div className='dashboardFameHallAwardsDetailValue'>
										{intl.formatMessage({
											id: 'intl.dashboard.fameHall.value'
										})}
										0
									</div>
								</>
							)}
						</div>
					</div>

					<p
						className='fameHallJumpLinkButton'
						onClick={() => {
							goDetailLink('RankList');
						}}>
						{intl.formatMessage({
							id: 'intl.dashboard.fameHall.jumplink'
						})}
					</p>
				</div>
			</div>
			<div className='dashboardActiveModelBackgroundDiv'>
				<div className='dashboardActiveModelContent'>
					<div className='dashboardActiveModelBanner'>
						<div className='maskLeft'></div>
						<div id='dashboardScrollDiv'>
							<div id='scroll_begin'>
								<div className='scrollModelName1'>
									{modelShowData1.length > 0 &&
										modelShowData1
											.slice(0, 5)
											.map((item, index) => (
												<div
													key={index}
													style={{
														color: textBannerColorList[
															index % 3
														]
													}}>
													{item?.name}
												</div>
											))}
								</div>
								<div className='scrollModelName2'>
									{modelShowData1.length > 0 &&
										modelShowData1
											.slice(5, 10)
											.map((item, index) => (
												<div
													key={index + 5}
													style={{
														color: textBannerColorList[
															(index + 1) % 3
														]
													}}>
													{item?.name}
												</div>
											))}
								</div>
								<div className='scrollModelName3'>
									{modelShowData1.length > 0 &&
										modelShowData1
											.slice(10, 15)
											.map((item, index) => (
												<div
													key={index + 10}
													style={{
														color: textBannerColorList[
															(index + 2) % 3
														]
													}}>
													{item?.name}
												</div>
											))}
								</div>
							</div>
							<div id='scroll_end'>
								<div className='scrollModelName1'>
									{modelShowData2.length > 0 &&
										modelShowData2
											.slice(0, 5)
											.map((item, index) => (
												<div
													key={index}
													style={{
														color: textBannerColorList[
															index % 3
														]
													}}>
													{item?.name}
												</div>
											))}
								</div>
								<div className='scrollModelName2'>
									{modelShowData2.length > 0 &&
										modelShowData2
											.slice(5, 10)
											.map((item, index) => (
												<div
													key={index + 5}
													style={{
														color: textBannerColorList[
															(index + 1) % 3
														]
													}}>
													{item?.name}
												</div>
											))}
								</div>
								<div className='scrollModelName3'>
									{modelShowData2.length > 0 &&
										modelShowData2
											.slice(10, 15)
											.map((item, index) => (
												<div
													key={index + 10}
													style={{
														color: textBannerColorList[
															(index + 2) % 3
														]
													}}>
													{item?.name}
												</div>
											))}
								</div>
							</div>
						</div>
						<div className='maskRight'></div>
					</div>

					<div className='dashboardActiveModelTitle'>
						{intl.formatMessage({
							id: 'intl.dashboard.activeModel.title1'
						})}
						<label style={{color: '#0264FA'}}>
							{intl.formatMessage({
								id: 'intl.dashboard.activeModel.title2'
							})}
						</label>
						！
					</div>
					<div className='dashboardActiveModelJumpLink'>
						<label
							className='dashboardActiveModelJumpLinkHover'
							onClick={() => {
								goDetailLink('ResearchDevice');
							}}
							style={{
								textDecoration: 'underline',
								cursor: 'pointer'
							}}>
							{intl.formatMessage({
								id: 'intl.dashboard.activeModel.jumpLink2'
							})}
						</label>
					</div>
				</div>
			</div>
			{/* <div className="dashboardBannerMenuOutDiv">
                <div className="dashboardBannerMenuDiv">
                    <div className="dashboardBannerMenu" style={{textAlign:'left',paddingLeft:'16px'}}>
                        <a href="#blogEventsDiv" className="dashboardBannerMenuLabel">{
                            intl.formatMessage({
                                id: 'intl.dashboard.banner.menu.blogevent'
                            })
                        }</a>
                    </div>
                    <div className="dashboardBannerMenu">
                        <a href="#awardsDiv" className="dashboardBannerMenuLabel">{
                            intl.formatMessage({
                                id: 'intl.dashboard.banner.menu.awards'
                            })
                        }</a>
                    </div>
                    <div className="dashboardBannerMenu" style={{textAlign:'right',paddingRight:'16px'}}>
                        <a href="#guideDiv" className="dashboardBannerMenuLabel">{
                            intl.formatMessage({
                                id: 'intl.dashboard.banner.menu.guide'
                            })
                        }</a>
                    </div>
                </div>
            </div>
            <div id="blogEventsDiv" className="blogEventsDiv">
                <div className="dashboardContentDiv">
                    <p className="blogEventsLabel">{
                        intl.formatMessage({
                            id: 'intl.dashboard.blogevent.title'
                        })
                    }</p>
                    <div className="blogEventsLatestAll">
                        { news &&
                            <div className="blogEventsLatestDiv" onClick={()=>goBlogDetail(news.id)}>
                                {
                                    (news?.type?.name == "News") && <Tag className="blogEventstag" color="orange">
                                        {
                                            intl.formatMessage({
                                                id: 'intl.blog.type.news'
                                            })
                                        }
                                    </Tag> ||
                                    (news?.type?.name == "Blog") && <Tag className="blogEventstag" color="cyan">
                                        {
                                            intl.formatMessage({
                                                id: 'intl.blog.type.blog'
                                            })
                                        }
                                    </Tag> ||
                                    (news?.type?.name == "Events") && <Tag className="blogEventstag" color="magenta">
                                        {
                                            intl.formatMessage({
                                                id: 'intl.blog.type.events'
                                            })
                                        }
                                    </Tag> 
                                }
                                <p className="blogEventsTitle">{news.title}</p>
                                <div className="blogEventsContentDiv">
                                    <p className="blogEventsContent">{news.preface}</p>
                                </div>
                                <img
                                    src={goBlogIconImg}
                                    onClick={()=>goBlogDetail(news.id)}
                                    className="blogEventsGoButton"
                                    onMouseEnter={() => setGoBlogIconImg(goBlogIconHover)}
                                    onMouseLeave={() => setGoBlogIconImg(goBlogIcon)}/>
                            </div>
                        }
                        <div className="blogEventsLatestDiv" style={{marginLeft:"24px",marginRight:"24px"}} onClick={()=>goBlogDetail(blog.id)}>
                            {
                                (blog?.type?.name == "News") && <Tag className="blogEventstag" color="orange">
                                    {
                                        intl.formatMessage({
                                            id: 'intl.blog.type.news'
                                        })
                                    }
                                </Tag> ||
                                (blog?.type?.name == "Blog") && <Tag className="blogEventstag" color="cyan">
                                    {
                                        intl.formatMessage({
                                            id: 'intl.blog.type.blog'
                                        })
                                    }
                                </Tag> ||
                                (blog?.type?.name == "Events") && <Tag className="blogEventstag" color="magenta">
                                    {
                                        intl.formatMessage({
                                            id: 'intl.blog.type.events'
                                        })
                                    }
                                </Tag> 
                            }
                            <p className="blogEventsTitle">{blog.title}</p>
                            <div className="blogEventsContentDiv">
                                <p className="blogEventsContent">{blog.preface}</p>
                            </div>
                            <img
                                src={goBlogIconImg}
                                onClick={()=>goBlogDetail(blog.id)}
                                className="blogEventsGoButton"
                                onMouseEnter={() => setGoBlogIconImg(goBlogIconHover)}
                                onMouseLeave={() => setGoBlogIconImg(goBlogIcon)}/>
                        </div>
                        <div className="blogEventsLatestDiv" onClick={()=>goBlogDetail(event.id)}>
                            {
                                (event?.type?.name == "News") && <Tag className="blogEventstag" color="orange">
                                    {
                                        intl.formatMessage({
                                            id: 'intl.blog.type.news'
                                        })
                                    }
                                </Tag> ||
                                (event?.type?.name == "Blog") && <Tag className="blogEventstag" color="cyan">
                                    {
                                        intl.formatMessage({
                                            id: 'intl.blog.type.blog'
                                        })
                                    }
                                </Tag> ||
                                (event?.type?.name == "Events") && <Tag className="blogEventstag" color="magenta">
                                    {
                                        intl.formatMessage({
                                            id: 'intl.blog.type.events'
                                        })
                                    }
                                </Tag> 
                            }
                            <p className="blogEventsTitle">{event.title}</p>
                            <div className="blogEventsContentDiv">
                                <p className="blogEventsContent">{event.preface}</p>
                            </div>
                            <img
                                src={goBlogIconImg}
                                onClick={()=>goBlogDetail(event.id)}
                                className="blogEventsGoButton"
                                onMouseEnter={() => setGoBlogIconImg(goBlogIconHover)}
                                onMouseLeave={() => setGoBlogIconImg(goBlogIcon)}/>
                        </div>
                    </div>
                </div>
           </div>
            <div id="awardsDiv" className="awardsDiv">
                <div className="dashboardContentDiv">
                    <div className="awardsIntroduceDiv">
                        <div className="awardsIntroduce">
                            <p className="awardsTitle">{
                                intl.formatMessage({
                                    id: 'intl.dashboard.awards.title'
                                })
                            }</p>
                            <p className="awardsSubTitle">{
                                intl.formatMessage({
                                    id: 'intl.dashboard.awards.subtitle'
                                })
                            }</p>
                            <p className="awardsSubContent">
                            {
                                intl.formatMessage({
                                    id: 'intl.dashboard.awards.subcontent1'
                                })
                            }<br/>{
                                intl.formatMessage({
                                    id: 'intl.dashboard.awards.subcontent2'
                                })
                            }
                            </p>
                            <p className="awardsRule" onClick={()=>{goDetailLink("RewardProgramV1")}}>
                            {
                                intl.formatMessage({
                                    id: 'intl.dashboard.awards.rule'
                                })
                            }
                            </p>
                            <p className="bugsubmitButton" onClick={goBugSubmit}>
                            {
                                intl.formatMessage({
                                    id: 'intl.dashboard.awards.submitbutton'
                                })
                            }
                            </p>
                        </div>
                        <img src={awardsIcon} className="awardsIntroduceImg"/>
                    </div>
                </div>
           </div>
            <div id="guideDiv" className="guideDiv">
                <div className="dashboardContentDiv">
                    <p className="guideLabel">{
                        intl.formatMessage({
                            id: 'intl.dashboard.guide.title'
                        })
                    }</p>
                    <div className="guideMenuDiv">
                        <div className="guideMenu">
                            <img src={submitGuideIcon} className="guideMenuImg"/>
                            <p className="guideMenuButton" onClick={()=>{goDetailLink("SubmissionGuidelines")}}>
                                {
                                    intl.formatMessage({
                                        id: 'intl.dashboard.guide.submit'
                                    })
                                }
                            </p>
                        </div>
                        <div className="guideMenu">
                            <img src={dealWayIcon} className="guideMenuImg"/>
                            <p className="guideMenuButton" onClick={()=>{goDetailLink("ProcessFlow")}}>
                                {
                                    intl.formatMessage({
                                        id: 'intl.dashboard.guide.deal'
                                    })
                                }
                            </p>
                        </div>
                        <div className="guideMenu">
                            <img src={awardWayIcon} className="guideMenuImg"/>
                            <p className="guideMenuButton" onClick={()=>{goDetailLink("RewardRules")}}>
                                {
                                    intl.formatMessage({
                                        id: 'intl.dashboard.guide.awards'
                                    })
                                }
                            </p>
                        </div>
                        <div className="guideMenu">
                            <img src={applyPhoneIcon} className="guideMenuImg"/>
                            <p className="guideMenuButton" onClick={()=>{goDetailLink("ResearchDevice")}}>
                                {
                                    intl.formatMessage({
                                        id: 'intl.dashboard.guide.apply'
                                    })
                                }
                            </p>
                        </div>
                        <div className="guideMenu">
                            <img src={normalQuestionIcon} className="guideMenuImg"/>
                            <p className="guideMenuButton" onClick={()=>{goDetailLink("FAQ")}}>
                                {
                                    intl.formatMessage({
                                        id: 'intl.dashboard.guide.normalquestion'
                                    })
                                }
                            </p>
                        </div>
                    </div>
                </div>
           </div>
           { (sessionStorage.getItem('token')==undefined) && <div className="sginupDiv">
                <div className="dashboardContentDiv">
                    <p className="sginupLabel">    {
                        intl.formatMessage({
                            id: 'intl.dashboard.signup.tip'
                        })
                    }</p>
                    <p className="sginupButton" onClick={goSingUp}>
                        {
                            intl.formatMessage({
                                id: 'intl.dashboard.signup.button'
                            })
                        }
                    </p>
                </div>
           </div> } */}
			{researchData && researchModalOpen && (
				<Modal
					visible={researchModalOpen}
					maskClosable={true}
					width={591}
					style={{top: '200px'}}
					onCancel={() => setResearchModalOpen(false)}
					footer={null}>
					<div
						className={'dashboardModalResearchDiv'}
						style={{backgroundImage: `url(${researchBg})`}}>
						<div className={'dashboardModalResearchTitle'}>
							{researchData.introductionTitle}
						</div>
						<div className={'dashboardModalResearchIntroText'}>
							<div className="blogdetailcontentA"
								dangerouslySetInnerHTML={{
									__html: researchData.introductionText
								}}></div>
						</div>
						<Button
							style={{marginTop: '18px'}}
							onClick={() => {
								window.open(researchData.jumpUrl);
							}}
							type='primary'>
							{researchData.jumpButtonName}
						</Button>
					</div>
				</Modal>
			)}
			<Footer></Footer>
		</div>
	);
});
