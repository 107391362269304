import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class PersonalInfoService {
	/**
	 * 登录界面
	 * @param {username} 用户名
	 * @param {password} 密码
	 * @return {登录信息}
	 */
	getCaptchaCode(params) {
		return Xhr.get(`/captcha?lang=${sessionStorage.getItem('lang')}`);
	}
	getPersonalLeak() {
		return Xhr.get(`/userLeak?lang=${sessionStorage.getItem('lang')}`);
	}
	getPersonalInfo() {
		return Xhr.get(
			`/userInfo/selectUserInfo?lang=${sessionStorage.getItem('lang')}`
		);
	}
	ChangePassword(params) {
		return Xhr.post(
			`/userInfo/changePassword?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	ChangeName(params) {
		return Xhr.post(
			`/userInfo/alienName?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	ChangeSocialProfile(params) {
		return Xhr.post(
			`/userInfo/social?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	BindFacebook(params) {
		return Xhr.post(
			`/userInfo/bindFaceBook?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	EditPayPalInfo(params) {
		return Xhr.post(
			`/userInfo/payInfo?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	DeletePayPalInfo(params) {
		return Xhr.delete(
			`/userInfo/payInfo?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	goEmailCaptcha(params) {
		return Xhr.post(
			`/mail/validCode?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	getStatistics(params) {
		return Xhr.get(
			`/userInfo/getStatistics?lang=${sessionStorage.getItem('lang')}`,
			{params: params}
		);
	}
	getAllMedals(params) {
		return Xhr.get(
			`/grantSafetyMedal/selectExist?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			{params: params}
		);
	}
	wearOrCancelMedal(params) {
		return Xhr.post(
			`/grantSafetyMedal/wearOrCancelMedal?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			params
		);
	}
	getAllMedalType(params) {
		return Xhr.get(
			`/safetyMedal/selectAllMedal?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			{params: params}
		);
	}
	getSocialMedia(params) {
		return Xhr.get(
			`/userSocialMedia/selectSocialAccountInfo?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			{params: params}
		);
	}
	getBankAccountData() {
		return Xhr.get(
			`/userAccount/getAccountCardInfo?lang=${sessionStorage.getItem(
				'lang'
			)}`
		);
	}
	getBankCountryOptions() {
		return Xhr.get(
			`/userAccount/getUserCountry?lang=${sessionStorage.getItem('lang')}`
		);
	}
	updateBankAccount(params) {
		return Xhr.post(
			`/userAccount/insertOrUpdate?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			params
		);
	}
	getBankAccountDetail() {
		return Xhr.get(
			`/userAccount/getAccountInfo?lang=${sessionStorage.getItem('lang')}`
		);
	}
	getCountryList(params) {
		return Xhr.get(`/country?lang=${sessionStorage.getItem('lang')}`);
	}
	deleteBankAccount(params) {
		return Xhr.delete(
			`/userAccount/delAccount?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	updateSocialMedia(params) {
		return Xhr.post(
			`/userSocialMedia/batchProcessing?lang=${sessionStorage.getItem(
				'lang'
			)}`,
			params
		);
	}
}

// 实例化再导出
export default new PersonalInfoService();
