import {React} from 'react';
import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import lds from 'lodash';
import {useIntl} from 'react-intl';
export default (function (props) {
  const intl = useIntl();
	const chartRef = useRef();
	let options = {
		title: {
			text: ''
		},
		tooltip: {
			// trigger: 'axis',
      trigger: 'item',
			axisPointer: {
				type: 'shadow'
			},
      textStyle: {
        fontFamily:'HurmeGeometricSans3',
      },
		},
		legend: {
			top: '0%',
			right: '10%',
			orient: 'verticalAlign',
			icon: 'circle',
			itemWidth: 8,
			formatter: function (name) {
				let temp;
				if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.critical'})) {
					temp = props.value?.leakLevelCritical;
					// temp = 12;
				} else if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.high'})) {
					temp = props.value?.leakLevelHigh;
					// temp = 15;
				} else if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.medium'})) {
					temp = props.value?.leakLevelMed;
					// temp = 3;
				} else if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.low'})) {
					temp = props.value?.leakLevelLow;
					// temp = 6;
				} else if (name === intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.non'})) {
					temp = props.value?.leakLevelNon;
					// temp = 0;
				}
				var arr = ['{a|' + name + '}', '{c|' + temp + '}'];
				return arr.join('  ');
			},
			textStyle: {
				rich: {
					a: {
						fontSize: 14,
						fontFamily: 'HurmeGeometricSans3',
						width: 50
					},
					c: {
						fontSize: 14,
						fontFamily: 'HurmeGeometricSans3',
						padding: [0, 0, 0, 40]
					}
				}
			}
		},
		grid: {
			left: -25,
			top:25,
			right: '45%',
			bottom: '15%',
			containLabel: true,
		},
		xAxis: {
			show: false,
			type: 'category',
			data: ['Severity of my Reports']
		},
		yAxis: {
			show: false,
			type: 'value'
		},
		series: [
			{
				name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.critical'}),
				barGap: 0,
				type: 'bar',
				barWidth: 46,
				data: [6],
				label: {
					//柱体上显示数值
					show: true, //开启显示
					position: 'top', //在上方显示
					textStyle: {
						//数值样式
						fontSize: '14px',
						color: 'rgba(0,0,0,0.65)'
					},
					formatter: '{c}'
				},
				itemStyle: {
					normal: {
						color: '#CCA4FD'
					}
				}
			},
			{
				name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.high'}),
				barGap: 0,
				type: 'bar',
				data: [15],
				barWidth: 46,
				label: {
					//柱体上显示数值
					show: true, //开启显示
					position: 'top', //在上方显示
					textStyle: {
						//数值样式
						fontSize: '14px',
						color: 'rgba(0,0,0,0.65)'
					},
					formatter: '{c}'
				},
        emphasis: {
          itemStyle: {
   
            opacity: 0.8, // 设置当前项的透明度
            // shadowBlur: 10, // 设置当前项的阴影模糊大小
        
          },
        },
				itemStyle: {
					normal: {
						color: '#FF4D4F'
					}
				}
			},
			{
				name:intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.medium'}),
				barGap: 0,
				type: 'bar',
				data: [9],
				barWidth: 46,
				label: {
					//柱体上显示数值
					show: true, //开启显示
					position: 'top', //在上方显示
					textStyle: {
						//数值样式
						fontSize: '14px',
						color: 'rgba(0,0,0,0.65)'
					},
					formatter: '{c}'
				},
				itemStyle: {
					normal: {
						color: '#FAAD14'
					}
				}
			},
			{
				name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.low'}),
				type: 'bar',
				data: [5],
				barWidth: 46,
				label: {
					//柱体上显示数值
					show: true, //开启显示
					position: 'top', //在上方显示
					textStyle: {
						//数值样式
						fontSize: '14px',
						color: 'rgba(0,0,0,0.65)'
					},
					formatter: '{c}'
				},
				itemStyle: {
					normal: {
						color: '#34C759'
					}
				}
			},
			{
				name: intl.formatMessage({id: 'intl.personalinfo.content.statisticsChart.non'}),
				barGap: 0,
				type: 'bar',
				data: [2],
				barWidth: 46,
				label: {
					//柱体上显示数值
					show: true, //开启显示
					position: 'top', //在上方显示
					textStyle: {
						//数值样式
						fontSize: '14px',
						color: 'rgba(0,0,0,0.65)'
					},
					formatter: '{c}'
				},
				itemStyle: {
					normal: {
						color: '#D9D9D9'
					}
				}
			}
		]
	};
	const initChartOption = () => {
		const tempOptions = lds.cloneDeep(options);
		// tempOptions.series[0].data = [12];
		// tempOptions.series[1].data = [15];
		// tempOptions.series[2].data = [3];
		// tempOptions.series[3].data = [6];
		// tempOptions.series[4].data = [3];
		tempOptions.series[0].data = [props.value?.leakLevelCritical];
		tempOptions.series[1].data = [props.value?.leakLevelHigh];
		tempOptions.series[2].data = [props.value?.leakLevelMed];
		tempOptions.series[3].data = [props.value?.leakLevelLow];
		tempOptions.series[4].data = [props.value?.leakLevelNon];
		return tempOptions;
	};
	useEffect(() => {
		let initOption = initChartOption();
		const chart = echarts.init(chartRef.current);
		chart.setOption(initOption);
		window.addEventListener('resize', () => {
			chart.resize();
		});
		return () => {};
	});
	return <div ref={chartRef} style={{width: '100%', height: '100%'}}></div>;
});
