import { fromJS } from 'immutable';
import { RES_CHANGEMESSAGECENTERTYPE,
    RES_CHANGEMESSAGECENTERREADTYPE,
    RES_CHANGEPAGINATION,
    RES_CHANGEPAGINATIONSIZE,
    RES_CHANGEPAGINATIONTOTAL,
    RES_GETMESSAGECENTERLIST,
    RES_COUNTALL,
    RES_COUNTSYSTEMNOTIFICATION,
    RES_COUNTREVIEWRESULTS,
    RES_COUNTREADALL,
    RES_COUNTREAD,
    RES_COUNTUNREAD,
    RES_COUNTPROdDUCTORDERALL
   } from '../../constants/messageCenterTypes';

// 初始化state数据
const initialState = {
    messageCenterType: 'All',
    messageCenterReadType: 'All',
    currentPagination: 1,
    pageSizePagination: 10,
    totalPagination: 0,
    messageCenterList:[],
    messageCenterTypeAll: 0,
    messageCenterTypeSystemNotifications: 0,
    messageCenterTypeReviewResults: 0,
    messageCenterTypeProductOrder:0,
    messageCenterReadTypeAll: 0,
    messageCenterReadTypeRead: 0,
    messageCenterReadTypeUnread: 0,
};

/**
 * 登录界面reducer
 * @return
 */
const MessageCenter = (state = initialState, action) => {
    switch(action.type) {
        case RES_GETMESSAGECENTERLIST: // 获取公告
            return fromJS(state).merge({messageCenterList: action.res}).toJS();
        case RES_CHANGEMESSAGECENTERTYPE: // 更改公告类型
            return fromJS(state).merge({messageCenterType: action.res}).toJS();
        case RES_CHANGEMESSAGECENTERREADTYPE: // 更改已读未读
            return fromJS(state).merge({messageCenterReadType: action.res}).toJS();
        case RES_CHANGEPAGINATION: // 更改公告页码
            return fromJS(state).merge({currentPagination: action.res}).toJS();
        case RES_CHANGEPAGINATIONSIZE: // 更改公告页码大小
            return fromJS(state).merge({pageSizePagination: action.res}).toJS();
        case RES_CHANGEPAGINATIONTOTAL: // 更改公告数量
            return fromJS(state).merge({totalPagination: action.res}).toJS();
        case RES_COUNTALL:
            return fromJS(state).merge({messageCenterTypeAll: action.res}).toJS();
        case RES_COUNTSYSTEMNOTIFICATION:
            return fromJS(state).merge({messageCenterTypeSystemNotifications: action.res}).toJS();
        case RES_COUNTREVIEWRESULTS:
            return fromJS(state).merge({messageCenterTypeReviewResults: action.res}).toJS();
        case RES_COUNTPROdDUCTORDERALL:
            return fromJS(state).merge({messageCenterTypeProductOrder: action.res}).toJS();
        case RES_COUNTREADALL:
            return fromJS(state).merge({messageCenterReadTypeAll: action.res}).toJS();
        case RES_COUNTREAD:
            return fromJS(state).merge({messageCenterReadTypeRead: action.res}).toJS();
        case RES_COUNTUNREAD:
            return fromJS(state).merge({messageCenterReadTypeUnread: action.res}).toJS();
        default:
            return state;
    }
}

export default MessageCenter;