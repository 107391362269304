import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class BlogService {
	/**
	 * 登录界面
	 * @param {current} 当前页码
	 * @param {pageSize} 一页数量
	 * @param {type} 公告类型
	 * @param {search} 搜索内容
	 * @return {公告列表}
	 */
	getBlogListData(params) {
		return Xhr.get(`/article?lang=${sessionStorage.getItem('lang')}`, {
			params: params
		});
	}
	getBlogSubLabel(params) {
		return Xhr.get(`/article/getSecondTag`, {params: params});
	}
	getActivity() {
		return Xhr.get(
			`/article/getPushHomeArticle?lang=${sessionStorage.getItem('lang')}`
		);
	}
	getShow() {
		return Xhr.get(`/active/show`);
	}
	getLeaderboardsListData(params) {
		return Xhr.get(
			`/leakRank/${params.Type}?lang=${sessionStorage.getItem('lang')}`,
			{params: params}
		);
	}
	getDashBoardBannerData() {
		return Xhr.get(`/banner?lang=${sessionStorage.getItem('lang')}`);
	}
	getResearchData() {
		return Xhr.get(
			`/userSurvey/selectOnDisplay?lang=${sessionStorage.getItem('lang')}`
		);
	}
}

// 实例化再导出
export default new BlogService();
