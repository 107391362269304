import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class BindEmailPwdService {
	/**
	 * 登录界面
	 * @param {username} 用户名
	 * @param {password} 密码
	 * @return {登录信息}
	 */
	goBindEmailPwd(params) {
		return Xhr.post(
			`/user/perfectUser?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	goEmailCaptcha(params) {
		return Xhr.post(
			`/mail/mailCode?lang=${sessionStorage.getItem('lang')}`,
			params
		);
	}
	getCaptchaCode(params) {
		return Xhr.get(`/captcha?lang=${sessionStorage.getItem('lang')}`);
	}
	getCountryList(params) {
		return Xhr.get(`/country?lang=${sessionStorage.getItem('lang')}`, {
			params: params
		});
	}
}

// 实例化再导出
export default new BindEmailPwdService();
