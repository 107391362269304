import {React, useState} from 'react';
import '../PayoutMethod/style/payoutMethod.css';
import {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {detailLink} from '../../../common/detailLink';
import PersonalInfoService from '../../../services/personalInfoService';
import lds from 'lodash';
import {JSEncrypt} from 'jsencrypt';
import {Input, Modal, Button, Form, Select, Radio, message} from 'antd';

export default (function ({bankModalVis, type, closeModal, refresh}) {
	const intl = useIntl();
	const encrypt = new JSEncrypt();
	encrypt.setPublicKey(
		'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYpKIowTM/+WFirmaZuU9e0BK7zyQRBuPUG4CYWl6S/VsiDoAMlqKHdG/9G9+Wd2ZDIzAfQM+tZ2cutQotNlxyd/d7LZm71xy+zPvpcGJQo4iQ8cylZfjZ2gQO3+7nEqo11eVYlrI8MyOhgRLSzvpNHaBPlEPd9zKCh8baLF5NywIDAQAB'
	);
	const [bankDataForm] = Form.useForm();
	const [countryId, setCountryId] = useState();
	const [submitLoading, setSubmitLoading] = useState(false);
	const [countrySelectList, setCountrySelectList] = useState([]);
	const submitData = () => {
		bankDataForm.validateFields().then((data) => {
			setSubmitLoading(true);
			if (type === 'edit') {
				if (idChange) {
					data.idCardUpdate = true;
					data.idCard = encrypt.encrypt(data.idCard);
				} else {
					data.idCardUpdate = false;
					data.idCard = undefined;
				}
				if (accountNumberChange) {
					data.accountNumberUpdate = true;
					data.accountNumber = encrypt.encrypt(data.accountNumber);
				} else {
					data.accountNumberUpdate = false;
					data.accountNumber = undefined;
				}
			} else {
				data.accountNumber = encrypt.encrypt(data.accountNumber);
				if (data.idCard) {
					data.idCard = encrypt.encrypt(data.idCard);
				}
			}

			PersonalInfoService.updateBankAccount(data)
				.then((res) => {
					if (res.code === 200) {
						message.success(res.data);
						refresh();
						closeModal();
					}
				})
				.finally(() => {
					setSubmitLoading(false);
				});
		});
	};
	const [idChange, setIdChange] = useState(false);
	const [accountNumberChange, setAccountNumberchange] = useState(false);
	const clearInput = (name) => {
		if (type === 'edit') {
			if (name === 'idCard' && !idChange) {
				bankDataForm.setFieldsValue({idCard: ''});
				setIdChange(true);
			}
			if (name === 'accountNumber' && !accountNumberChange) {
				bankDataForm.setFieldsValue({accountNumber: ''});
				setAccountNumberchange(true);
			}
		}
	};
	const initForm = () => {
		PersonalInfoService.getBankAccountDetail().then((res) => {
			setCountryId(res.data.payeeCountryId);
			if (res.data.payeeCountryId === 48) {
				bankDataForm.setFieldsValue({
					payeeCountryId: res.data.payeeCountryId,
					accountName: res.data.accountName,
					bankName: res.data.bankName,
					accountNumber: res.data.accountNumber,
					// accountNumber: encrypt.decrypt(res.data.accountNumber),
					idCard: res.data.idCard,
					// idCard: encrypt.decrypt(res.data.idCard),
					phoneNumber: res.data.phoneNumber,
					ifSigning: res.data.ifSigning
				});
			} else {
				bankDataForm.setFieldsValue({
					payeeCountryId: res.data.payeeCountryId,
					accountName: res.data.accountName,
					bankName: res.data.bankName,

					accountNumber: res.data.accountNumber,
					bankRoutingNumber: res.data.bankRoutingNumber,
					swiftCode: res.data.swiftCode,
					bankCountry: res.data.bankCountry,
					bankCity: res.data.bankCity,
					bankAddress: res.data.bankAddress,
					intermediaryBankName: res.data.intermediaryBankName,
					intermediaryBankSwift: res.data.intermediaryBankSwift
				});
			}
		});
	};
	const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
	};
	useEffect(() => {
		PersonalInfoService.getBankCountryOptions().then((res) => {
			setCountryId(res.data.countryId);
		});
		PersonalInfoService.getCountryList()
			.then((res) => {
				setCountrySelectList(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		if (type === 'edit') {
			initForm();
		}
		return () => {
			setCountryId();
		};
	}, []);
	return (
		<Modal
			visible={bankModalVis}
			width={595}
			title={
				type === 'add'
					? intl.formatMessage({
							id: 'intl.personalinfo.settings.payoutMethod.addBankModal.title'
					  })
					: intl.formatMessage({
							id: 'intl.personalinfo.settings.payoutMethod.editBankModal.title'
					  })
			}
			onCancel={() => {
				closeModal();
			}}
			footer={[
				<Button
					key='submit'
					type='primary'
					onClick={submitData}
					loading={submitLoading}>
					{intl.formatMessage({
						id: 'intl.personalinfo.type.personalinfo.button.submit'
					})}
				</Button>,
				<Button
					key='back'
					onClick={() => {
						closeModal();
					}}>
					{intl.formatMessage({
						id: 'intl.personalinfo.type.personalinfo.button.cancel'
					})}
				</Button>
			]}>
			<div className='bankModalContainer'>
				<div className='bankModalHint'>
					<label>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.payoutMethod.bankModal.hint'
						})}
					</label>
					<label
						style={{
							color: '#1890FF',
							marginLeft: '2px',
							cursor: 'pointer'
						}}
						onClick={() => {
							goDetailLink('AddBankAccount');
						}}>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.payoutMethod.bankModal.hintLink'
						})}
					</label>
				</div>
				{countryId && (
					<Form
						form={bankDataForm}
						name='bankDataForm'
						labelCol={{span: 11}}
						wrapperCol={{span: 14}}
						initialValues={{payeeCountryId: countryId}}>
						<Form.Item
							label={intl.formatMessage({
								id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.country'
							})}
							name='payeeCountryId'
							rules={[{required: true}]}>
							<Select
								value={countryId}
								onChange={(e) => setCountryId(e)}
								showSearch
								placeholder={intl.formatMessage({
									id: 'intl.bindemailpwd.placeholder.country'
								})}
								filterOption={(input, option) =>
									option.label
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								options={countrySelectList.map((item) => {
									return {
										value: item.id,
										label: item.name
									};
								})}
							/>
						</Form.Item>
						{countryId === 48 ? (
							<>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.payeeName'
									})}
									name='accountName'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankName'
									})}
									name='bankName'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>

								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.payeeAccount'
									})}
									name='accountNumber'
									rules={[{required: true}]}>
									<Input
										onFocus={() => {
											clearInput('accountNumber');
										}}
									/>
								</Form.Item>

								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.ID'
									})}
									name='idCard'
									rules={[{required: true}]}>
									<Input
										onFocus={() => {
											clearInput('idCard');
										}}
									/>
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.phoneNumber'
									})}
									name='phoneNumber'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.ifSign'
									})}
									name='ifSigning'
									rules={[{required: true}]}>
									<Radio.Group>
										<Radio value={true}>
											{intl.formatMessage({
												id: 'intl.personalinfo.settings.payoutMethod.bankModal.signOption.yes'
											})}
										</Radio>
										<Radio value={false}>
											{intl.formatMessage({
												id: 'intl.personalinfo.settings.payoutMethod.bankModal.signOption.no'
											})}
										</Radio>
									</Radio.Group>
								</Form.Item>
							</>
						) : (
							<>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.payeeName'
									})}
									name='accountName'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.payeeAccount'
									})}
									name='accountNumber'
									rules={[{required: true}]}>
									<Input
										onFocus={() => {
											clearInput('accountNumber');
										}}
									/>
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankName'
									})}
									name='bankName'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.swift'
									})}
									name='swiftCode'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>

								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankCountry'
									})}
									name='bankCountry'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>

								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankcity'
									})}
									name='bankCity'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.bankAddress'
									})}
									name='bankAddress'
									rules={[{required: true}]}>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.routingNumber'
									})}
									name='bankRoutingNumber'>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.interMediary'
									})}
									name='intermediaryBankName'>
									<Input />
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.personalinfo.settings.payoutMethod.bankModal.formTitle.interMediarySwift'
									})}
									name='intermediaryBankSwift'>
									<Input />
								</Form.Item>
							</>
						)}
					</Form>
				)}
			</div>
		</Modal>
	);
});
