import { fromJS } from 'immutable';
import { RES_GETVULNERABILITYINFORMATION,RES_GETREVIEWCONCLUSION } from '../../constants/bugSubmitDetailTypes';

// 初始化state数据
const initialState = {

    VulnerabilityInformation:{},
    ReviewConclusion: {
        Conclusion: {
            Rating: "",
            Reputation: "",
            Payouts: ""
        },
        SaferFedback: "",
        Reviewtime: ""
    }
};

/**
 * 提交bug列表界面reducer
 * @return
 */
const BugSubmitDetail = (state = initialState, action) => {
    switch(action.type) {
        case RES_GETVULNERABILITYINFORMATION: // 获取提交bug列表
            return fromJS(state).merge({VulnerabilityInformation: action.res}).toJS();
        case RES_GETREVIEWCONCLUSION: // 获取提交bug列表
            return fromJS(state).merge({ReviewConclusion: action.res}).toJS();
        default:
            return state;
    }
}

export default BugSubmitDetail;