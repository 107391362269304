import Xhr from './xhr/index';
import store from '../store/index'

/**
 * 封装ajax请求
 * @param {any}
 */

class BugSubmitListService {

    /**
     * 登录界面
     * @param {current} 当前页码
     * @param {pageSize} 一页数量
     * @return {BUG提交列表}
     */
     getPersonalLeakData() {
        return Xhr.get(`/userLeak?lang=${sessionStorage.getItem('lang')}`);
    }
     getBugSubmitListData(params) {
        return Xhr.post(`/leak/my/?lang=${sessionStorage.getItem('lang')}`, params);
    }

    getTotalMenu(search, timeRange) {
        let startTime = timeRange && timeRange[0];
        let endTime = timeRange && timeRange[1];
        return Xhr.get(`/leak/getCountByStatus/?search=${search || ''}&startTime=${startTime || ''}&endTime=${endTime || ''}`);
    }
}

// 实例化再导出
export default new BugSubmitListService();