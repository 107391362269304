/**
 * 公告界面界面action
 * @return
 */
import { RES_CHANGEMESSAGECENTERTYPE,
    RES_CHANGEMESSAGECENTERREADTYPE,
    RES_CHANGEPAGINATION,
    RES_CHANGEPAGINATIONSIZE,
    RES_CHANGEPAGINATIONTOTAL,
    RES_GETMESSAGECENTERLIST,
    RES_COUNTALL,
    RES_COUNTSYSTEMNOTIFICATION,
    RES_COUNTREVIEWRESULTS,
    RES_COUNTREADALL,
    RES_COUNTREAD,
    RES_COUNTUNREAD,
    RES_COUNTPROdDUCTORDERALL } from '../../constants/messageCenterTypes';
import store from '../../store/index'
import MessageCenterService from '../../services/messageCenterService';
 
 /**
  * 更新公告
  * @return
  */
const resGetMessageCenterList = (res) => {
    return {
        type: RES_GETMESSAGECENTERLIST,
        res
    }
}
 /**
  * 切换公告类型
  * @return
  */
const resChangeMessageCenterType = (res) => {
    return {
        type: RES_CHANGEMESSAGECENTERTYPE,
        res
    }
}
const resChangeMessageCenterReadType = (res) => {
    return {
        type: RES_CHANGEMESSAGECENTERREADTYPE,
        res
    }
}
  /* 切换公告页码
  * @return
  */
const resChangePagination = (res) => {
    return {
        type: RES_CHANGEPAGINATION,
        res
    }
}
const resChangePaginationSize = (res) => {
    return {
        type: RES_CHANGEPAGINATIONSIZE,
        res
    }
}
  /* 更新公告数量
  * @return
  */
const resChangePaginationTotal = (res) => {
    return {
        type: RES_CHANGEPAGINATIONTOTAL,
        res
    }
}

const resCountAll = (res) => {
    return {
        type: RES_COUNTALL,
        res
    }
}
const resCountSystemNotification = (res) => {
    return {
        type: RES_COUNTSYSTEMNOTIFICATION,
        res
    }
}
const resCountReviewResults = (res) => {
    return {
        type: RES_COUNTREVIEWRESULTS,
        res
    }
}
const resCountProductOrderAll = (res) => {
    return {
        type: RES_COUNTPROdDUCTORDERALL,
        res
    }
}
const resCountReadAll = (res) => {
    return {
        type: RES_COUNTREADALL,
        res
    }
}
const resCountRead = (res) => {
    return {
        type: RES_COUNTREAD,
        res
    }
}
const resCountUnRead = (res) => {
    return {
        type: RES_COUNTUNREAD,
        res
    }
}

export const getMessageCenterCount = () => {
    MessageCenterService.getMessageCenterListData({
        current: 1,
        pageSize: 10,
    }).then (res => {
        store.dispatch(resCountAll(res.data.total));
    }).catch(error => {
        console.log(error)
    })
    MessageCenterService.getMessageCenterListData({
        current: 1,
        pageSize: 10,
        type: 'system'
    }).then (res => {
        store.dispatch(resCountSystemNotification(res.data.total));
    }).catch(error => {
        console.log(error)
    })
    MessageCenterService.getMessageCenterListData({
        current: 1,
        pageSize: 10,
        type: 'leak_check'
    }).then (res => {
        store.dispatch(resCountReviewResults(res.data.total));
    }).catch(error => {
        console.log(error)
    })
    MessageCenterService.getMessageCenterListData({
        current: 1,
        pageSize: 10,
        type: 'product_order'
    }).then (res => {
        store.dispatch(resCountProductOrderAll(res.data.total));
    }).catch(error => {
        console.log(error)
    })
    if(store.getState().MessageCenter.messageCenterType != "All") {
        MessageCenterService.getMessageCenterListData({
            current: 1,
            pageSize: 10,
            type: store.getState().MessageCenter.messageCenterType,
        }).then (res => {
            store.dispatch(resCountReadAll(res.data.total));
        }).catch(error => {
            console.log(error)
        })
        MessageCenterService.getMessageCenterListData({
            current: 1,
            pageSize: 10,
            type: store.getState().MessageCenter.messageCenterType,
            isRead: true
        }).then (res => {
            store.dispatch(resCountRead(res.data.total));
        }).catch(error => {
            console.log(error)
        })
        MessageCenterService.getMessageCenterListData({
            current: 1,
            pageSize: 10,
            type: store.getState().MessageCenter.messageCenterType,
            isRead: false
        }).then (res => {
            store.dispatch(resCountUnRead(res.data.total));
        }).catch(error => {
            console.log(error)
        })
    }else {
        MessageCenterService.getMessageCenterListData({
            current: 1,
            pageSize: 10,
        }).then (res => {
            store.dispatch(resCountReadAll(res.data.total));
        }).catch(error => {
            console.log(error)
        })
        MessageCenterService.getMessageCenterListData({
            current: 1,
            pageSize: 10,
            isRead: true
        }).then (res => {
            store.dispatch(resCountRead(res.data.total));
        }).catch(error => {
            console.log(error)
        })
        MessageCenterService.getMessageCenterListData({
            current: 1,
            pageSize: 10,
            isRead: false
        }).then (res => {
            store.dispatch(resCountUnRead(res.data.total));
        }).catch(error => {
            console.log(error)
        })
    }
}

export const getMessageCenterList = (current,pageSize) => {
    store.dispatch(resChangePagination(current));
    store.dispatch(resChangePaginationSize(pageSize));
    const params = {};
    params["current"] = current
    params["pageSize"] = pageSize
    if(store.getState().MessageCenter.messageCenterType != "All") {
        params["type"] = store.getState().MessageCenter.messageCenterType
    }
    if(store.getState().MessageCenter.messageCenterReadType == "Unread") {
        params["isRead"] = false
    }else if (store.getState().MessageCenter.messageCenterReadType == "Read") {
        params["isRead"] = true
    }
    MessageCenterService.getMessageCenterListData(params).then (res => {
        store.dispatch(resGetMessageCenterList(res.data.list));
        store.dispatch(resChangePaginationTotal(res.data.total));
        console.log(res)
    }).catch(error => {
        console.log(error)
    })
    getMessageCenterCount();
}
 
 /**
  * 切换公告类型
*/
 
export const changeMessageCenterType = (e) => {
    store.dispatch(resChangePagination(1));
    store.dispatch(resChangeMessageCenterType(e.target.value));
    getMessageCenterList(1,store.getState().MessageCenter.pageSizePagination);
} 
export const changeMessageCenterReadType = (e) => {
    store.dispatch(resChangePagination(1));
    store.dispatch(resChangeMessageCenterReadType(e));
    getMessageCenterList(1,store.getState().MessageCenter.pageSizePagination);
} 
 
 
 /**
  * 切换公告页码
*/
 
export const changePagination = (pageNumber) => {
    store.dispatch(resChangePagination(pageNumber));
    getMessageCenterList(pageNumber,store.getState().MessageCenter.pageSizePagination);
} 
export const changePaginationSize = (current, pageSize) => {
    store.dispatch(resChangePagination(current));
    store.dispatch(resChangePaginationSize(pageSize));
    getMessageCenterList(current, pageSize);
} 
 