import React, {useEffect, useState} from 'react';
import {
	Table,
	Tooltip,
	Tag,
	Badge,
	Input,
	DatePicker,
	Popover,
	Space
} from 'antd';
import {QuestionCircleFilled} from '@ant-design/icons';
import './style/BugSubmitList.css';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {scrollToTop} from '../../common/topScorll';
import {
	getBugSubmitList,
	getPersonalLeak,
	getTotalMenu
} from '../../action/bugSubmitList/bugSubmitListAction';
import {goRouterPosition} from '../../action/routerposition/routerpositionAction';
import store from '../../store/index';
import Reward from '../../assets/svg/ic-漏洞列表-累计奖励金额.svg';
import Honorary from '../../assets/svg/ic-漏洞列表-荣誉称号.svg';
import Reputation from '../../assets/svg/ic-漏洞列表-贡献值.svg';
import Approved from '../../assets/svg/ic-漏洞列表-已采纳.svg';
import Submitted from '../../assets/svg/ic-漏洞列表-已提交.svg';
import icBugListNewMsg from '../../assets/svg/ic-列表留言-新提醒.svg';
import icBugListMsg from '../../assets/svg/ic-列表留言.svg';
import QuestionCircle from '../../assets/svg/ic-漏洞详情-流程说明.svg';
import FlowHelpImgZh from '../../assets/svg/状态说明-中文版@2x.png';
import FlowHelpImgEn from '../../assets/svg/状态说明-英文版@2x.png';
import SwagshopService from '../../services/swagshopService';
let timer;
const {Search} = Input;
const {RangePicker} = DatePicker;
const statusMap = {
	leak_status_submited: '#9254DE',
	leak_status_need_more_info: '#FF2DAA',
	leak_status_pending_review: '#02AAF9',
	leak_status_pending_fix: '#10D6BE',
	leak_status_fixed: '#52C41A',
  leak_status_suspended:'#FAAD14',
	leak_status_ignore: '#8D8F93',
	leak_status_no_risk: '#8D8F93',
	leak_status_not_reproducible: '#8D8F93',
	leak_status_duplicate: '#8D8F93',
	leak_status_out_of_scope: '#8D8F93',
	leak_status_spam: '#8D8F93'
};

export default (function (props) {
  const [creditsData , setCreditsData] = useState([]);
	const intl = useIntl();
	const [state, setState] = useState(store.getState().BugSubmitList);
	const [totalMenuData, setTotalMenuData] = useState([]);
	const [preSelectedStatus, setPreSelectedStatus] = useState();
	const [search, setSearch] = useState();
	const [timeRange, setTimeRange] = useState([]);
	const [status, setStatus] = useState([]);
  // const getCreditsData=()=>{
  //   SwagshopService.rewardStatistics({ 
  //     search:undefined,
  //     createTimeStart:undefined,
  //     createTimeEnd:undefined,
  //     year:undefined,
  //     month:undefined,
  //     type:undefined,
  //     dataType:undefined
  //   }).then(res=>{
  //     if(res.code==200){
  //       setCreditsData(res.data?.list[0])}
  //   })
  // }
	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = '漏洞提交列表')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'My Reports'));
		scrollToTop();
		// sessionStorage.setItem('redirect', window.location.pathname);
    sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		goRouterPosition({routerPosition: 'bugSubmitList'});
		getBugSubmitList();
		getPersonalLeak();
		handleGetTotalMenu();
    // getCreditsData()
		store.subscribe(storeChange);
    // console.log('99',store.subscribe(storeChange))
    // console.log('99',state)
	}, []);

	const handleGetTotalMenu = (search, timeRange) => {
		getTotalMenu(search, timeRange).then((res) => {
			const {
				leak_status_fixed,
        leak_status_suspended,
				leak_status_out_of_scope,
				leak_status_duplicate,
				leak_status_need_more_info,
				leak_status_pending_fix,
				leak_status_ignore,
				leak_status_not_reproducible,
				leak_status_submited,
				leak_status_no_risk,
				leak_status_pending_review,
				leak_status_spam
			} = res.data;
			let all = Object.values(res.data).reduce(
				(a, b) => parseInt(a) + parseInt(b)
			);
			let open =
				leak_status_submited +
				leak_status_pending_review +
				leak_status_need_more_info;
			let accepted = leak_status_pending_fix + leak_status_fixed+ leak_status_suspended;
			let rejected = all - open - accepted;

			let menuData = [
				{
					id: 'all',
					label: `${intl.formatMessage({
						id: 'intl.bugSubmitList.tableTitle.all'
					})}`,
					value: all,
					child: []
				},
				{
					id: 'open',
					label: `${intl.formatMessage({
						id: 'intl.bugSubmitList.tableTitle.open'
					})}`,
					value: open,
					child: [
						{
							id: 'leak_status_submited',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.new'
							})}`,
							value: leak_status_submited
						},
						{
							id: 'leak_status_pending_review',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.pendingReview'
							})}`,
							value: leak_status_pending_review
						},
						{
							id: 'leak_status_need_more_info',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.needsMoreInfo'
							})}`,
							value: leak_status_need_more_info
						}
					]
				},
				{
					id: 'accepted',
					label: `${intl.formatMessage({
						id: 'intl.bugSubmitList.tableTitle.Accepted'
					})}`,
					value: accepted,
					child: [
						{
							id: 'leak_status_pending_fix',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.Triaged'
							})}`,
							value: leak_status_pending_fix
						},
						{
							id: 'leak_status_fixed',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.Fixed'
							})}`,
							value: leak_status_fixed
						},
            {
							id: 'leak_status_suspended',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.Suspended'
							})}`,
							value: leak_status_suspended
						}
					]
				},
				{
					id: 'rejected',
					label: `${intl.formatMessage({
						id: 'intl.bugSubmitList.tableTitle.Rejected'
					})}`,
					value: rejected,
					child: [
						{
							id: 'leak_status_no_risk',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.Informative'
							})}`,
							value: leak_status_no_risk
						},
						{
							id: 'leak_status_duplicate',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.Duplicate'
							})}`,
							value: leak_status_duplicate
						},
						{
							id: 'leak_status_out_of_scope',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.outOfScope'
							})}`,
							value: leak_status_out_of_scope
						},
						{
							id: 'leak_status_not_reproducible',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.notReproducible'
							})}`,
							value: leak_status_not_reproducible
						},
						{
							id: 'leak_status_spam',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.Spam'
							})}`,
							value: leak_status_spam
						},
						{
							id: 'leak_status_ignore',
							label: `${intl.formatMessage({
								id: 'intl.bugSubmitList.tableTitle.Ignored'
							})}`,
							value: leak_status_ignore
						}
					]
				}
			];
			setTotalMenuData(menuData);
		});
	};

	const storeChange = () => {
		setState(store.getState().BugSubmitList);
	};

	const handleSearch = (e) => {
		setSearch(e.target.value);
		let _;
		clearTimeout(timer);
		timer = setTimeout(() => {
			getBugSubmitList(_, _, _, _, e.target.value, timeRange, status);
			handleGetTotalMenu(e.target.value, timeRange);
		}, 500);
	};

	const handleChangeRange = (date, dateString) => {
		setTimeRange(dateString);
		let _;
		getBugSubmitList(_, _, _, _, search, dateString, status);
		handleGetTotalMenu(search, dateString);
	};

	const contentFlowHelp = (
		<div>
			{(sessionStorage.getItem('lang') == 'en_US' && (
				<img className='FlowHelpImg' src={FlowHelpImgEn} />
			)) ||
				(sessionStorage.getItem('lang') == 'zh_CN' && (
					<img className='FlowHelpImg' src={FlowHelpImgZh} />
				))}
		</div>
	);

	const columns = [
		{
			title: intl.formatMessage({
				id: 'intl.bugsubmitlist.table.columns.vulnerabilityno'
			}),
			dataIndex: 'identifier',
			align: 'left',
			width: 140,
			fixed: 'left',
			render: (text, record) => (
				<div style={{display: 'flex', alignItems: 'center'}}>
					{record.newMessage ? (
						<img src={icBugListNewMsg} style={{marginRight: 8}} />
					) : (
						<img src={icBugListMsg} style={{marginRight: 8}} />
					)}
					<Link
						className='BugSubmitListVulnerability'
						to={`/SRC/BugSubmitDetail/${record.id}`}>
						{text}
					</Link>
				</div>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.bugsubmitlist.table.columns.vulnerability'
			}),
			dataIndex: 'title',
			ellipsis: {
				showTitle: false
			},
			width: 215,
			align: 'left',
			render: (text, record) => (
				<Tooltip placement='topLeft' title={text}>
					<Link
						className='BugSubmitListVulnerability'
						to={`/SRC/BugSubmitDetail/${record.id}`}>
						<span className='BugSubmitActivity'>
							{record?.leakReview?.activityRewardRule?.keyword}
						</span>
						{text}
					</Link>
				</Tooltip>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.bugsubmitlist.table.columns.status'
			}),
			dataIndex: 'status',
			width: 140,
			align: 'left',
			render: (text, record) => (
				<Badge
					color={statusMap[record.statusConst]}
					text={intl.formatMessage({
						id: `intl.bugsubmitlist.status.${record.statusConst}`
					})}
				/>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.bugsubmitlist.table.columns.severity'
			}),
			width: 80,
			align: 'left',
			render: (text, record) =>
				record?.leakReview?.leakReviewLevel ? (
					<Tag color={record?.leakReview?.leakReviewLevel?.color}>
						{record?.leakReview?.leakReviewLevel?.name}
					</Tag>
				) : (
					'?'
				)
		},
		{
			title: intl.formatMessage({
				id: 'intl.bugsubmitlist.table.columns.payouts'
			}),
			dataIndex: 'leakReview',
			width: 94,
			align: 'center',
			render: (text,record) => (
				<label className='tabelLabel'>
					{record.leakReview?.bonus ? `$${record.leakReview?.bonus}` : '-'}
				</label>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.bugsubmitlist.table.columns.reputation'
			}),
			dataIndex: 'leakReview',
			width: 104,
			align: 'center',
			render: (text) => (
				<label className='tabelLabel'>
					{text.score ? text.score : '-'}
				</label>
			)
		},
		{
			title: intl.formatMessage({
				id: 'intl.bugsubmitlist.table.columns.submittime'
			}),
			dataIndex: 'creatTime',
			width: 140,
			align: 'left',
			//fixed: 'right',
			render: (text) => <label className='tabelLabel'>{text}</label>,
			sorter: (a, b) => a.submitTime - b.submitTime
		}
	];

	const onChange = (pagination, filters, sorter, extra) => {
		console.log('params', pagination, filters, sorter, extra);
	};

	const handleSelectedStatus = (status, child) => {
		let _;
		let statusList = [];
		// 修改侧边状态栏样式
		preSelectedStatus &&
			document
				.getElementById(preSelectedStatus)
				.classList.remove('totalMenuLiSelected');
		document.getElementById(status).classList.add('totalMenuLiSelected');
		setPreSelectedStatus(status);
		// 获取需要请求的状态
		statusList = child ? child.map((i) => i.id) : [status];
		setStatus(statusList);
		getBugSubmitList(_, _, _, _, search, timeRange, statusList);
	};

	return (
		<div className='Container'>
			<div className='ContainerContent'>
				<label className='ContainerContentTitle'>
					{intl.formatMessage({
						id: 'intl.bugsubmitlist.vulnerability.title'
					})}
				</label>
				<div className='ContainerContentVulnerability'>
					<div className='VulnerabilityDiv'>
						<img src={Reward} className='VulnerabilityImg' />
						<div className='VulnerabilityTitleCountDiv'>
							<label className='VulnerabilityTitle'>
								{intl.formatMessage({
									id: 'intl.bugsubmitlist.count.Reward'
								})}
							</label>
							<label className='VulnerabilityCount'>
								{state?.Vulnerability?.userRewardItem?.baseBonus || 0}
								{/* {creditsData?.userRewardItem?.bonus || 0} */}
							</label>
						</div>
						<Popover
							arrowPointAtCenter
							title={intl.formatMessage({
								id: 'intl.bugsubmitlist.count.Note'
							})}
							placement='bottomLeft'
							trigger='hover'
							content={
                <p style={{width: '180px',textAlign:'justify'}}>
                  {intl.formatMessage({id: 'intl.bugsubmitlist.count.Bonus.tips'})}
                  <a href='/SRC/rewardsDetails'>{intl.formatMessage({id: 'intl.bugsubmitlist.count.Bonus.link'})}</a>
                  {intl.formatMessage({id: 'intl.bugsubmitlist.count.Bonus.punctuation'})}
                </p>
            }
              >
							<QuestionCircleFilled className='BonusDetailPopover' />
						</Popover>
					</div>
				</div>
				<div
					className='ContainerContentVulnerability'
					style={{marginLeft: '16px'}}>
					<div className='VulnerabilityDiv'>
						<img src={Reputation} className='VulnerabilityImg' />
						<div className='VulnerabilityTitleCountDiv'>
							<label className='VulnerabilityTitle'>
								{intl.formatMessage({
									id: 'intl.bugsubmitlist.count.Reputation'
								})}
							</label>
							<label className='VulnerabilityCount'>
								{state?.Vulnerability?.userRewardItem?.baseHonor || 0}
								{/* {creditsData?.userRewardItem?.baseHonor||0} */}
							</label>
						</div>
						<Popover
							arrowPointAtCenter
							title={intl.formatMessage({id: 'intl.bugsubmitlist.count.Note'})}
							placement='bottomLeft'
							trigger='hover'
							content={
                <p style={{width: '180px',textAlign:'justify'}}>
                {intl.formatMessage({id: 'intl.bugsubmitlist.count.Reputation.tips'})}
                <a href='/SRC/rewardsDetails'>{intl.formatMessage({id: 'intl.bugsubmitlist.count.Reputation.link'})}</a>
                {intl.formatMessage({id: 'intl.bugsubmitlist.count.Reputation.punctuation'})}
              </p>
              }>
							<QuestionCircleFilled className='ReputationDetailPopover' />
						</Popover>
					</div>
				</div>
				<div
					className='ContainerContentVulnerability'
					style={{marginLeft: '16px'}}>
					<div className='VulnerabilityDiv'>
						<img src={Approved} className='VulnerabilityImg' />
						<div className='VulnerabilityTitleCountDiv'>
							<label className='VulnerabilityTitle'>
								{intl.formatMessage({
									id: 'intl.bugsubmitlist.count.Approved'
								})}
							</label>
							<label className='VulnerabilityCount'>
								{state.Vulnerability.leakAcceptCount
									? state.Vulnerability.leakAcceptCount
									: 0}
							</label>
						</div>
					</div>
				</div>
				<div
					className='ContainerContentVulnerability'
					style={{marginLeft: '16px'}}>
					<div className='VulnerabilityDiv'>
						<img src={Submitted} className='VulnerabilityImg' />
						<div className='VulnerabilityTitleCountDiv'>
							<label className='VulnerabilityTitle'>
								{intl.formatMessage({
									id: 'intl.bugsubmitlist.count.Submitted'
								})}
							</label>
							<label className='VulnerabilityCount'>
								{state.Vulnerability.leakCount
									? state.Vulnerability.leakCount
									: 0}
							</label>
						</div>
					</div>
				</div>
				<div className='ContainerContentVulnerabilityList'>
					<label className='ContainerContentVulnerabilityTitle'>
						{intl.formatMessage({
							id: 'intl.bugsubmitlist.vulnerabilitylist.title'
						})}
					</label>
					<Popover
						placement='bottomRight'
						content={contentFlowHelp}
						title={intl.formatMessage({
							id: 'intl.bugsubmitdetail.processingflow.title'
						})}
						trigger='hover'>
						<div className='BugSubmitDetailStatusFlowHelpDiv'>
							<QuestionCircleFilled className='BugSubmitDetailStatusFlowHelpPopover' />
							<label className='BugSubmitDetailStatusFlowHelpLabel'>
								{intl.formatMessage({
									id: 'intl.bugsubmitdetail.processingflow'
								})}
							</label>
						</div>
					</Popover>
					<div style={{marginBottom: 24}}>
						<Space size={8}>
							<Search
								placeholder={intl.formatMessage({id: 'intl.bugsubmitdetail.table.search'})}
								allowClear
								onChange={handleSearch}
							/>
							<RangePicker onChange={handleChangeRange} />
						</Space>
					</div>
					<div style={{display: 'flex'}}>
						<div className='totalMenu'>
							{totalMenuData.map((i) => (
								<div>
									<div
										id={i.id}
										key={i.id}
										onClick={() =>
											handleSelectedStatus(i.id, i.child)
										}
										className='totalMenuLi'>
										<label>{i.label}</label>
										<label>{i.value}</label>
									</div>
									{i.child &&
										i.child.map((child) => (
											<div
												id={child.id}
												key={child.id}
												onClick={() =>
													handleSelectedStatus(
														child.id,
														child.child
													)
												}
												className='totalMenuSubLi'>
												<span>{child.label}</span>
												<span>{child.value}</span>
											</div>
										))}
								</div>
							))}
						</div>
						<div style={{width: 'calc(100% - 234px)'}}>
							<Table
								columns={columns}
								rowKey={(record) => record.id}
								dataSource={state.bugSubmitList}
								pagination={state.pagination}
								scroll={{x: 1300}}
								onChange={(
									pagination,
									filters,
									sorter,
									extra
								) =>
									getBugSubmitList(
										pagination,
										filters,
										sorter,
										extra,
										search,
										timeRange,
										status
									)
								}
							/>
							{state.bugSubmitList.length != 0 && (
								<label className='ListTotal'>
									{intl.formatMessage({
										id: 'intl.bugsubmitlist.table.total'
									})}{' '}
									{state.pagination.total}
									{intl.formatMessage({
										id: 'intl.bugsubmitlist.table.totalnum'
									})}
								</label>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
