import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class BugSubmitService {

    /**
     * 登录界面	
     * @param {username} 用户名
     * @param {password} 密码
     * @return {登录信息}
     */

    goBugSubmit(params) {
        return Xhr.post(`/leak?lang=${sessionStorage.getItem('lang')}`, params);
    }
    getCaptchaCode(params) {
        return Xhr.get(`/captcha?lang=${sessionStorage.getItem('lang')}`);
    }
    getLevel(params) {
        return Xhr.get(`/leak/level?lang=${sessionStorage.getItem('lang')}`, {params:params});
    }
    getType(params) {
        return Xhr.get(`/leak/type?lang=${sessionStorage.getItem('lang')}`, {params:params});
    }
    getSubType(params) {
        return Xhr.get(`/leak/subType?lang=${sessionStorage.getItem('lang')}`, {params:params});
    }
    getActivity(params) {
        return Xhr.get(`/activityRewardRule/all?lang=${sessionStorage.getItem('lang')}`, {params:params});
    }
    getActivityUrl(params) {
        return Xhr.get(`/activityRewardRule/${params.id}?lang=${sessionStorage.getItem('lang')}`);
    }
    getApkOptions() {
        return Xhr.get(`/leak/Apk`);
    }
    getUrlOptions() {
        return Xhr.get(`/leak/Url`);
    }
}

// 实例化再导出
export default new BugSubmitService();