import React, {useEffect, useState} from 'react';
import './style/achievements.css';
import {Button, Popover, message} from 'antd';
import {useIntl} from 'react-intl';
import {detailLink} from '../../../common/detailLink';
import PersonalInfoService from '../../../services/personalInfoService';
import QuickEntryDiv from '../components/quickEntryDiv';
import {goRouterPosition} from '../../../action/routerposition/routerpositionAction';
export default (function (props) {
	const intl = useIntl();
	const [medalList, setMedalList] = useState([]);

	const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
	};
	const medalCardContent = (item) => {
		return (
			<div className='mdealCardDiv'>
				<div className='medalCardTitleDiv'>
					<img
						src={item.showMedalIconOss}
						className='medalCardTitleImg'
						alt='img'
					/>
					<div className='medalCardTitleText'>
						<div className='medalCardTitleNameDiv'>
							<div className='medalCardTitleName'>
								{item.safetyMedalI18n?.medalName}
							</div>
							{item.status === 2 && (
								<div className='medalWearingTag'>
									{intl.formatMessage({
										id: 'intl.personalinfo.medalCard.wearingTag'
									})}
								</div>
							)}
							{item.status === 3 && (
								<div className='medalExpiredTag'>
									{intl.formatMessage({
										id: 'intl.personalinfo.medalCard.expiredTag'
									})}
								</div>
							)}
						</div>
						{item.safetyMedalI18n?.activityName && (
							<div
								className='medalCardTitleTag'
								onClick={() =>
									window.open(
										item.safetyMedalI18n?.activityUrl
									)
								}>
								# {item.safetyMedalI18n?.activityName}
							</div>
						)}

						<div className='medalCardTitleUserCount'>
							{item.safetyMedalUserCount}
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.userCount'
							})}
						</div>

						{!item.safetyMedalI18n?.activityName && (
							<div style={{height: '20px'}}></div>
						)}
						{item.status !== 4 ? (
							<div className='medalCardTitleObtainStatus'>
								<label>
									{intl.formatMessage({
										id: 'intl.personalinfo.medalCard.unlockedTime'
									})}
									{item.cardCreateTime}
								</label>
								<label style={{margin: '0 8px'}}>|</label>
								<label>
									{item.cardEndTime ? (
										<>
											{intl.formatMessage({
												id: 'intl.personalinfo.medalCard.expiredTime'
											})}
											{item.cardEndTime}
										</>
									) : (
										<>
											{intl.formatMessage({
												id: 'intl.personalinfo.medalCard.longTerm'
											})}
										</>
									)}
								</label>
							</div>
						) : (
							<div className='medalCardTitleObtainStatus'>
								{intl.formatMessage({
									id: 'intl.personalinfo.medalCard.locked'
								})}
							</div>
						)}
					</div>
				</div>
				<div className='medalCardIntro'>
					{item.safetyMedalI18n?.medalIntro}
				</div>
				{item.status === 1 && (
					<div className='medalCardOperateDiv'>
						<Button
							onClick={() => wearBadge(item.id, true)}
							loading={wearBtnLoading}>
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.wearBadge'
							})}
						</Button>
					</div>
				)}
				{item.status === 2 && (
					<div className='medalCardOperateDiv'>
						<Button
							onClick={() => wearBadge(item.id, false)}
							loading={wearBtnLoading}>
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.takeOffBadge'
							})}
						</Button>
					</div>
				)}
			</div>
		);
	};
	const [wearBtnLoading, setWearBtnLoading] = useState(false);
	const wearBadge = (id, ifWear) => {
		let formData = new FormData();
		formData.append('grantMedalId', id);
		formData.append('ifWear', ifWear);
		setWearBtnLoading(true);
		PersonalInfoService.wearOrCancelMedal(formData).then((res) => {
			if (res.code === 200) {
				if (ifWear) {
					message.success('You’ve worn the badge!');
				} else {
					message.success('You’ve taken off the badge!');
				}
				init();
			} else {
				message.error('Error');
			}
		});
	};
	const init = () => {
		PersonalInfoService.getAllMedalType()
			.then((res) => {
				setMedalList(res.data);
			})
			.finally(() => {
				setWearBtnLoading(false);
			});
		return () => {};
	};
	useEffect(() => {
    goRouterPosition({routerPosition: 'achievements'});
		init();
	}, []);

	return (
		<div className='achievementsContainer'>
       <QuickEntryDiv
            title={intl.formatMessage({ id: 'intl.personalinfo.breadCrumb.achievement'})}
            chosenType='achievement'
          />
			{medalList?.length > 0 &&
				medalList.map((item, index) => (
					<div key={index} className='achievementsMedalTypeDiv'>
						<div className='achievementsMedalTypeTitle'>
							{item.sortName}
						</div>
						<div className='achievementsMedalTypeIntro'>
							{item.sortIntro}
						</div>
						<div className='achievementsMedalListDiv'>
							{item.grantSafetyMedalList?.length > 0 &&
								item.grantSafetyMedalList.map((i, index) => (
									<Popover
										color='#f8f8f8'
										placement='right'
										content={medalCardContent(i)}
										title={null}>
										<div
											key={index}
											className='achievementMedalDiv'>
											<img
												src={i.medalIconOss}
												className='achievementMedalImg'
												alt='img'
											/>
											{i.status === 2 && (
												<div className='achievementMedalFlag achievementMedalFlagLeft'>
													{intl.formatMessage({
														id: 'intl.personalinfo.medalCard.wearingTag'
													})}
												</div>
											)}
											<div className='achievementMedalTitle'>
												{i.safetyMedalI18n?.medalName}
											</div>
											{i.label && (
												<div className='achievementMedalLabel'>
													{i.label}
												</div>
											)}
											<div className='achievementMedalGetTime'>
												{i.status === 1 ||
												i.status === 2 ? (
													<>{i.createTime}</>
												) : (
													<>
														{intl.formatMessage({
															id: 'intl.personalinfo.medalCard.locked'
														})}
													</>
												)}
											</div>
										</div>
									</Popover>
								))}
						</div>
					</div>
				))}
			<div className='achievementsBadgesRuleDiv'>
				<div className='achievementsBadgesRuleText'>
					{intl.formatMessage({
						id: 'intl.personalinfo.achievement.badgeRules'
					})}
				</div>
				<Button
					type='primary'
					onClick={() => {
						goDetailLink('EarnBadge');
					}}>
					{intl.formatMessage({
						id: 'intl.personalinfo.achievement.badgeRules.button'
					})}
				</Button>
			</div>
		</div>
	);
});
