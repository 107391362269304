import { fromJS } from 'immutable';
import { RES_CHANGEBLOGTYPE,RES_CHANGEBLOGSEARCH,RES_CHANGEPAGINATION,RES_CHANGEPAGINATIONSIZE,RES_CHANGEPAGINATIONTOTAL,RES_GETBLOGLIST,RES_CHANGESUBLABELLIST,RES_CHANGESUBTYPE,RES_CHANGESUBTYPEID } from '../../constants/blogTypes';

// 初始化state数据
const initialState = {
    blogType: 'All',
    searchContent: '',
    currentPagination: 1,
    pageSizePagination: 5,
    totalPagination: 0,
    blogList:[],
    subLabelList:[],
    subType: 'All',
    chosenSubTagId:0
};

/**
 * 登录界面reducer
 * @return
 */
const Blog = (state = initialState, action) => {
    switch(action.type) {
        case RES_GETBLOGLIST: // 获取公告
            return fromJS(state).merge({blogList: action.res}).toJS();
        case RES_CHANGEBLOGSEARCH: // 更改公告搜索
            return fromJS(state).merge({searchContent: action.res}).toJS();
        case RES_CHANGEBLOGTYPE: // 更改公告类型
            return fromJS(state).merge({blogType: action.res}).toJS();
        case RES_CHANGEPAGINATION: // 更改公告页码
            return fromJS(state).merge({currentPagination: action.res}).toJS();
        case RES_CHANGEPAGINATIONSIZE: // 更改公告页码大小
            return fromJS(state).merge({pageSizePagination: action.res}).toJS();
        case RES_CHANGEPAGINATIONTOTAL: // 更改公告数量
            return fromJS(state).merge({totalPagination: action.res}).toJS();
        case RES_CHANGESUBLABELLIST: // 更改二级标签列表
            return fromJS(state).merge({subLabelList: action.res}).toJS();
        case RES_CHANGESUBTYPE: // 更改选择的二级标签
            return fromJS(state).merge({subType: action.res}).toJS();
        case RES_CHANGESUBTYPEID: // 更改选择的二级标签id
            return fromJS(state).merge({chosenSubTagId: action.res}).toJS();
        default:
            return state;
    }
}

export default Blog;