import React, {useEffect, useState, useRef} from 'react';
import './style/BugSubmitDetail.css';
import {useIntl} from 'react-intl';
import {Editor} from '@tinymce/tinymce-react';
import {
	Breadcrumb,
	Badge,
	Collapse,
	Form,
	Tag,
	Avatar,
	Button,
	Space,
	Upload,
	Checkbox,
	message,
	Input,
	Popover,
  Popconfirm,
  Spin
} from 'antd';
import {Link, useParams} from 'react-router-dom';
import {scrollToTop} from '../../common/topScorll';
import {
	getVulnerabilityInformation,
	submitComments,
	getCaptcha,
  download,
  downloadReview
} from '../../action/bugSubmitDetail/bugSubmitDetailAction';
import store from '../../store/index';
import {goRouterPosition} from '../../action/routerposition/routerpositionAction';
import MoreImg from '../../assets/svg/ic-漏洞详情-展开更多.svg';
import LessImg from '../../assets/svg/ic-漏洞详情-收起.svg';
import icFoldImg from '../../assets/svg/ic-收起.svg';
import icQuoteImg from '../../assets/svg/ic-列表留言.svg';
import icDoubleRightImg from '../../assets/svg/double right.svg';
import LessImgHover from '../../assets/svg/ic-漏洞详情-收起-hover.svg';
import MoreImgHover from '../../assets/svg/ic-漏洞详情-展开更多-hover.svg';
import icAddImg from '../../assets/svg/ic-rewards-add.svg';
import icEqualImg from '../../assets/svg/ic-rewards-equal.svg';
import icRideImg from '../../assets/svg/ic-rewards-ride.svg';
import {
	DownloadOutlined,
	UploadOutlined,
	CaretRightOutlined,
	DeleteOutlined
} from '@ant-design/icons';
import defaultAvatar from '../../assets/svg/ic-个人中心-头像.svg';

const statusMap = {
	leak_status_submited: '#9254DE',
  leak_status_need_more_info: '#FF2DAA',
	leak_status_pending_review: '#02AAF9',
	leak_status_pending_fix: '#10D6BE',
  leak_status_suspended:'#FAAD14',
	leak_status_fixed: '#52C41A',
	leak_status_ignore: '#8D8F93',
	leak_status_no_risk: '#8D8F93',
	leak_status_not_reproducible: '#8D8F93',
	leak_status_duplicate: '#8D8F93',
	leak_status_out_of_scope: '#8D8F93',
	leak_status_spam: '#8D8F93'
};

const hideStatusList = [
	'leak_status_pending_review',
	'leak_status_need_more_info',
	'leak_status_no_risk',
	'leak_status_duplicate',
	'leak_status_out_of_scope',
	'leak_status_not_reproducible',
	'leak_status_spam',
	'leak_status_ignore'
];

export default (function (props) {
	const intl = useIntl();
	const editorRef = useRef(null);
	const {Panel} = Collapse;
	const {id} = useParams();
	const [showMore, setShowMore] = useState(false);
	const [moreImgIcon, setMoreImgIcon] = useState(MoreImg);
	const [lessImgIcon, setLessImgIcon] = useState(LessImg);
	const [state, setState] = useState(store.getState().BugSubmitDetail);
	const [fileList, setFileList] = useState([]);
	const [contextDescription, setContextDescription] = useState('');
	const [lastReply, setLastReply] = useState({});
	const [needReview, setNeedReview] = useState(false);
	const [captcha, setCaptcha] = useState({});
	const [cCode, setCCode] = useState(null);
	const [visibleFoldButtonList, setVisibleFoldButtonList] = useState([]);
	const [disabledSend, setDisabledSend] = useState(false);
  const [ loadingSpin, setLoadingSpin] = useState(false);
	const medalCardContent = (item, userId) => {
		return (
			<div className='mdealCardDiv'>
				<div className='medalCardTitleDiv'>
					<img
						src={item.showMedalIconOss}
						className='medalCardTitleImg'
						alt='img'
					/>
					<div className='medalCardTitleText'>
						<div className='medalCardTitleNameDiv'>
							<div className='dashBoardMedalCardTitleName'>
								<div>{item.safetyMedalI18n?.medalName}</div>
								<div
									style={{
										color: '#0264fa',
										cursor: 'pointer',
										fontSize: '14px'
									}}
									onClick={() => {
										window.open(
											userId
												? `/SRC/publicProfile/?userId=${userId}`
												: `/SRC/publicProfile`
										);
									}}>
									{intl.formatMessage({
										id: 'intl.dashboard.medalCard.jumpText'
									})}
								</div>
							</div>
						</div>
						{item.safetyMedalI18n?.activityName && (
							<div
								className='medalCardTitleTag'
								onClick={() =>
									window.open(
										item.safetyMedalI18n?.activityUrl
									)
								}>
								# {item.safetyMedalI18n?.activityName}
							</div>
						)}

						<div className='medalCardTitleUserCount'>
							{item.safetyMedalUserCount}
							{intl.formatMessage({
								id: 'intl.personalinfo.medalCard.userCount'
							})}
						</div>
						{!item.safetyMedalI18n?.activityName && (
							<div style={{height: '20px'}}></div>
						)}
						<div className='medalCardTitleObtainStatus'>
							<label>
								{intl.formatMessage({
									id: 'intl.personalinfo.medalCard.unlockedTime'
								})}
								{item.cardCreateTime}
							</label>
							<label style={{margin: '0 8px'}}>|</label>
							<label>
								{item.cardEndTime ? (
									<>
										{intl.formatMessage({
											id: 'intl.personalinfo.medalCard.expiredTime'
										})}
										{item.cardEndTime}
									</>
								) : (
									<>
										{intl.formatMessage({
											id: 'intl.personalinfo.medalCard.longTerm'
										})}
									</>
								)}
							</label>
						</div>
					</div>
				</div>
				<div className='medalCardIntro'>
					{item.safetyMedalI18n?.medalIntro}
				</div>
			</div>
		);
	};
	const initObj = {
		height: 310,
		//width: 638,
		language: sessionStorage.getItem('lang')
			? sessionStorage.getItem('lang')
			: 'en_US',
		menubar: false,
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount'
		],
		toolbar:
			'link ｜ image | undo redo | fontsizeselect|' +
			'bold italic backcolor forecolor | alignleft aligncenter ' +
			'alignright alignjustify | bullist numlist outdent indent | ' +
			'removeformat | help',
		content_style: 'body,p{ font-size:16px;margin:0px; }',
		fontsize_formats: '10px 12px 14px 16px 18px 20px 24px 36px',
		images_upload_handler: function (blobInfo, succFun, failFun) {
			let base64 = 'data:image/png;base64,' + blobInfo.base64();
			succFun(base64);
		}
	};

	const onRemove = (file) => {
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
	};
	const beforeUpload = (file) => {
		let fileType = file.name.split('.');
		const fileDate = fileType.slice(-1);
		if (
			[
				'doc',
				'7z',
				'zip',
				'gz',
				'bz2',
				'png',
				'jpg',
				'jpeg',
				'pdf',
				'xls',
				'mp4',
				'wmv'
			].indexOf(fileDate[0]) < 0
		) {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.file.type'
				})
			);
			setFileList([file]);
			onRemove(file);
			return false;
		}
		const isLt20M = file.size / 1024 / 1024 < 10;
		if (!isLt20M) {
			message.error(
				intl.formatMessage({
					id: 'intl.bugsubmit.message.file.size'
				})
			);
			setFileList([file]);
			onRemove(file);
			return false;
		}
		setFileList([file]);
		return false;
	};

	const storeChange = () => {
		setState(store.getState().BugSubmitDetail);
	};
	const showMoreChange = () => {
		if (showMore) {
			setShowMore(false);
		} else {
			setShowMore(true);
		}
	};
	const handleEditorChange = (content, editor) => {
		setContextDescription(editorRef.current.getContent());
	};
	const layout = {
		labelCol: {span: 4},
		wrapperCol: {span: 20}
	};

	const handleToEditor = (leakReply) => {
		setLastReply(leakReply);
		document
			.querySelector('#comments')
			.scrollIntoView({behavior: 'smooth'});
	};

	const handleSubmit = () => {
		if (contextDescription) {
			const formData = new FormData();
			formData.append('leakId', id);
			lastReply?.id && formData.append('lastReplyId', lastReply?.id);
			formData.append('content', contextDescription);
			formData.append('needReview', needReview);
			fileList.forEach((file) => {
				formData.append('files', file);
			});
			captcha?.cToken && formData.append('cToken', captcha.cToken);
			cCode && formData.append('cCode', cCode);

			if (captcha?.cToken && !cCode) {
				message.error('请填写验证码');
			} else {
				setDisabledSend(true);
				submitComments(formData)
					.then((res) => {
						message.success('提交成功');
						getVulnerabilityInformation(id);
						handleCancel();
					})
					.catch((err) => {
						if (err === 'Enter the captcha.') {
							handleGetCaptcha();
						} else {
							message.error(err);
						}
					})
					.finally(() => {
						setDisabledSend(false);
					});
			}
		} else {
			message.error('请先添加评论');
		}
	};

	const handleCancel = () => {
		setContextDescription('');
		setFileList([]);
		setLastReply({});
		setNeedReview(false);
		setCaptcha({});
		setCCode();
	};

	const handleGetCaptcha = () => {
		getCaptcha().then((res) => {
			setCaptcha(res.data);
		});
	};

	const handleChangeCode = (e) => {
		setCCode(e.target.value);
	};

	const handleExtend = (id) => {
		let commentContent = document.getElementById(`commentContent${id}`);
		let imgFold = document.getElementById(`imgFold${id}`);

		if (commentContent.className === 'commentItemContent') {
			commentContent.className = 'commentItemContentExtend';
			imgFold.style.transform = 'rotate(0deg)';
		} else {
			commentContent.scrollTop = 0;
			commentContent.className = 'commentItemContent';
			imgFold.style.transform = 'rotate(180deg)';
		}
	};

	const handleShowFoldButton = () => {
		let _list = [];
		state.VulnerabilityInformation.leakReply.map((i) => {
			// 计算 引用框、附件 的高度
			let quoteTitle = document.getElementById(`quoteTitle${i.id}`);
			let attachments = document.getElementById(`attachments${i.id}`);
			let innerHtml = document.getElementById(`innerHtml${i.id}`);
			let quoteTitleHeight = quoteTitle
				? Number(
						window
							.getComputedStyle(quoteTitle)
							.getPropertyValue('height')
							.split('px')[0]
				  ) + 24
				: 0; // 24 是margin
			let attachmentsHeight = attachments
				? Number(
						window
							.getComputedStyle(attachments)
							.getPropertyValue('height')
							.split('px')[0]
				  ) + 16
				: 0; // 16 是margin
			let innerHtmlHeight = Number(
				window
					.getComputedStyle(innerHtml)
					.getPropertyValue('height')
					.split('px')[0]
			);
			if (quoteTitleHeight + attachmentsHeight + innerHtmlHeight > 120) {
				_list.push(i.id);
			}
		});
		setVisibleFoldButtonList(_list);
	};

	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = '漏洞详情')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'Security Reporting Detail'));
		scrollToTop();
		sessionStorage.setItem('redirect2', window.location.pathname);
    sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		goRouterPosition({routerPosition: 'bugSubmitDetail'});
		store.subscribe(storeChange);
		getVulnerabilityInformation(id);
	}, []);

	useEffect(() => {
		if (state.VulnerabilityInformation.leakReply) {
			handleShowFoldButton();
		}
	}, [state.VulnerabilityInformation]);
const downloadBlob=(res)=> {
    // const contentDisposition = res.headers['cache-control']
    // const type = res.headers['content-type']
    const type ='application/pdf'
    // let fileName
    // if (contentDisposition.indexOf("filename*=utf-8''") !== -1) {
    //   fileName = decodeURIComponent(
    //     contentDisposition.split("filename*=utf-8''")[1]
    //   )
    // } else {
    //   fileName = decodeURIComponent(contentDisposition.split('filename=')[1])
    // }
    const blob = new Blob([res.data], { type: type })
    const a = document.createElement('a')
    const URL = window.URL || window.webkitURL
    const herf = URL.createObjectURL(blob)
    a.href = herf
    a.download = state.VulnerabilityInformation?.attachments[0]?.fileName
    // a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(herf)
  }
const Download = () => {
  setLoadingSpin(true)
  download({id:id}).then(res => {
    downloadBlob(res)
    setLoadingSpin(false)
  }).catch(error => {
    console.log(error)
    setLoadingSpin(false)
  })
}

const DownloadReview = () => {
  setLoadingSpin(true)
  downloadReview({id:state.VulnerabilityInformation?.leakReply[0]?.id}).then(res => {
    downloadBlob(res)
    setLoadingSpin(false)
  }).catch(error => {
    console.log(error)
    setLoadingSpin(false)
  })
}
const Content=(a)=>{
  return (<div style={{width:'370px'}}>
     {intl.formatMessage({id:'intl.bugsubmitdetail.prompt.downloadTips'})}
  </div>)
}
	return (
		<div className='BugSubmitDetailContainer'>
      <Spin spinning={loadingSpin}>
			<div className='BugSubmitDetailHeader'>
				<div className='BugSubmitDetailHeaderContent'>
					<Breadcrumb className='BreadcrumbLink'>
						<Breadcrumb.Item>
							<Link to={`/SRC/bugSubmitList`}>
								<label className='BreadcrumbLinkVulnerability'>
									{intl.formatMessage({
										id: 'intl.bugsubmitdetail.title'
									})}
								</label>
							</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<label className='BreadcrumbLinkInfo'>
								{state.VulnerabilityInformation?.identifier}
							</label>
						</Breadcrumb.Item>
					</Breadcrumb>
				</div>
			</div>
			<div
				className='BugSubmitDetailContent'
				style={{
					borderTopColor:
						statusMap[state.VulnerabilityInformation?.status]
				}}>
				<div className='BugSubmitDetailStatusDiv'>
					{
						<Badge
							color={
								statusMap[
									state.VulnerabilityInformation?.status
								]
							}
							text={intl.formatMessage({
								id: `intl.bugsubmitlist.status.${state.VulnerabilityInformation?.status}`
							})}
						/>
					}
				</div>
				<p className='BugSubmitDetailTitle'>
					<span className='BugSubmitDetailActivity'>
						{
							state.VulnerabilityInformation?.leakReview
								?.activityRewardRule?.i18ns[0]?.keyword
						}
					</span>
					{state.VulnerabilityInformation?.title}
				</p>
				<div className='BugSubmitDetailTagsDiv'>
					<div>{state.VulnerabilityInformation?.identifier}</div>
					<div>{state.VulnerabilityInformation?.creatTime}</div>
					<div>
						<Space size={8}>
							<Tag
								color={
									state.VulnerabilityInformation?.leakLevel
										?.color
								}>
								{
									state.VulnerabilityInformation?.leakLevel
										?.name
								}
							</Tag>
							<img src={icDoubleRightImg} />
							<Tag
								color={
									state.VulnerabilityInformation?.leakReview
										?.leakReviewLevel?.color
								}>
								{state.VulnerabilityInformation?.leakReview
									?.leakReviewLevel?.name || '?'}
							</Tag>
						</Space>
					</div>
				</div>
			</div>

			{!['leak_status_submited'].includes(
				state.VulnerabilityInformation.status
			) && (
				<div className='BugSubmitDetailCollapse'>
					<Collapse
						ghost
						defaultActiveKey={['Review Conclusion']}
						expandIcon={({isActive}) => (
							<CaretRightOutlined rotate={isActive ? 90 : 0} />
						)}>
						<Panel
							header={intl.formatMessage({
								id: 'intl.bugsubmitdetail.panel.reviewconclusion'
							})}
							key='Review Conclusion'>
							<div>
								<Form
									{...layout}
									name='VulnerabilityInformationForm'>
									{!hideStatusList.includes(
										state.VulnerabilityInformation.status
									) && (
										<>
											<Form.Item
												label={intl.formatMessage({
													id: 'intl.bugsubmitdetail.vulnerabilityinfo.severity'
												})}
												name='Severity'>
												<div className='BugSubmitDetailTagsDiv'>
													<div>
														<Space size={8}>
															<Tag
																color={
																	state
																		.VulnerabilityInformation
																		?.leakReview
																		?.leakReviewLevel
																		?.color
																}>
																{state
																	.VulnerabilityInformation
																	?.leakReview
																	?.leakReviewLevel
																	?.name ||
																	'?'}
															</Tag>
														</Space>
													</div>
												</div>
											</Form.Item>
											<Form.Item
												label={intl.formatMessage({
													id: 'intl.bugsubmitdetail.vulnerabilityinfo.rewards'
												})}
												name='Rewards'>
												<div className='rewards'>
													<div className='rewardsFormula'>
														<div>
															<div className='rewardsTitle'>
																{intl.formatMessage(
																	{
																		id: 'intl.bugsubmitdetail.prompt.totalBonus'
																	}
																)}
															</div>
															<div>{`$${state.VulnerabilityInformation?.leakReview?.totalBonus}`}</div>
														</div>
														{(state
															.VulnerabilityInformation
															?.leakReview
															?.activityRewardRule
															?.bonusCoefficient >
															0 ||
															state
																.VulnerabilityInformation
																?.leakReview
																?.extraSpecialBonus >
																0) && (
															<>
																<img
																	src={
																		icEqualImg
																	}
																/>
																<div>
																	<div className='rewardsTitle'>
																		{intl.formatMessage(
																			{
																				id: 'intl.bugsubmitdetail.prompt.baseBonus'
																			}
																		)}
																	</div>
																	<div>
																		{
																			state
																				.VulnerabilityInformation
																				?.leakReview
																				?.bonus
																		}
																	</div>
																</div>
															</>
														)}
														{state
															.VulnerabilityInformation
															?.leakReview
															?.activityRewardRule
															?.bonusCoefficient >
															0 && (
															<>
																<img
																	src={
																		icRideImg
																	}
																/>
																<div>
																	<div className='rewardsTitle'>
																		{intl.formatMessage(
																			{
																				id: 'intl.bugsubmitdetail.prompt.activityBonus'
																			}
																		)}
																	</div>
																	<div
																		style={{
																			display:
																				'flex'
																		}}>
																		{
																			state
																				.VulnerabilityInformation
																				?.leakReview
																				?.activityRewardRule
																				?.bonusCoefficient
																		}
																		<div
																			className='rewardsActivity'
																			onClick={() =>
																				window.open(
																					state
																						.VulnerabilityInformation
																						?.leakReview
																						?.activityRewardRule
																						?.i18ns[0]
																						?.newsUrl
																				)
																			}></div>
																	</div>
																</div>
															</>
														)}
														{state
															.VulnerabilityInformation
															?.leakReview
															?.extraSpecialBonus >
															0 && (
															<>
																<img
																	src={
																		icAddImg
																	}
																/>
																<div>
																	<div className='rewardsTitle'>
																		{intl.formatMessage(
																			{
																				id: 'intl.bugsubmitdetail.prompt.excellentBonus'
																			}
																		)}
																	</div>
																	<div>
																		{
																			state
																				.VulnerabilityInformation
																				?.leakReview
																				?.extraSpecialBonus
																		}
																	</div>
																</div>
															</>
														)}
													</div>
													<div className='rewardsFormula'>
														<div>
															<div className='rewardsTitle'>
																{intl.formatMessage(
																	{
																		id: 'intl.bugsubmitdetail.prompt.totalReputation'
																	}
																)}
															</div>
															<div>
																{
																	state
																		.VulnerabilityInformation
																		?.leakReview
																		?.totalScore
																}
															</div>
														</div>
														{(state
															.VulnerabilityInformation
															?.leakReview
															?.activityRewardRule
															?.honorCoefficient >
															0 ||
															state
																.VulnerabilityInformation
																?.leakReview
																?.extraSpecialScore >
																0) && (
															<>
																<img
																	src={
																		icEqualImg
																	}
																/>
																<div>
																	<div className='rewardsTitle'>
																		{intl.formatMessage(
																			{
																				id: 'intl.bugsubmitdetail.prompt.baseReputation'
																			}
																		)}
																	</div>
																	<div>
																		{
																			state
																				.VulnerabilityInformation
																				?.leakReview
																				?.score
																		}
																	</div>
																</div>
															</>
														)}
														{state
															.VulnerabilityInformation
															?.leakReview
															?.activityRewardRule
															?.honorCoefficient >
															0 && (
															<>
																<img
																	src={
																		icRideImg
																	}
																/>
																<div>
																	<div className='rewardsTitle'>
																		{intl.formatMessage(
																			{
																				id: 'intl.bugsubmitdetail.prompt.activityReputation'
																			}
																		)}
																	</div>
																	<div
																		style={{
																			display:
																				'flex'
																		}}>
																		{
																			state
																				.VulnerabilityInformation
																				?.leakReview
																				?.activityRewardRule
																				?.honorCoefficient
																		}
																		<div
																			className='rewardsActivity'
																			onClick={() =>
																				window.open(
																					state
																						.VulnerabilityInformation
																						?.leakReview
																						?.activityRewardRule
																						?.i18ns[0]
																						?.newsUrl
																				)
																			}></div>
																	</div>
																</div>
															</>
														)}
														{state
															.VulnerabilityInformation
															?.leakReview
															?.extraSpecialScore >
															0 && (
															<>
																<img
																	src={
																		icAddImg
																	}
																/>
																<div>
																	<div className='rewardsTitle'>
																		{intl.formatMessage(
																			{
																				id: 'intl.bugsubmitdetail.prompt.excellentReputation'
																			}
																		)}
																	</div>
																	<div>
																		{
																			state
																				.VulnerabilityInformation
																				?.leakReview
																				?.extraSpecialScore
																		}
																	</div>
																</div>
															</>
														)}
													</div>
												</div>
											</Form.Item>
										</>
									)}
									<Form.Item
										label={intl.formatMessage({
											id: 'intl.bugsubmitdetail.reviewconclusion.saferfedback'
										})}
										name='SaferFedback'>
										<label className='VulnerabilityInformationFormLabel'>
											{
												state.VulnerabilityInformation
													?.leakReview?.comment
											}
										</label>
									</Form.Item>
									<Form.Item
										label={intl.formatMessage({
											id: 'intl.bugsubmitdetail.reviewconclusion.reviewtime'
										})}
										name='Reviewtime'>
										<label className='VulnerabilityInformationFormLabel'>
											{
												state.VulnerabilityInformation
													?.leakReview?.dateLastModify
											}
										</label>
									</Form.Item>
								</Form>
							</div>
						</Panel>
					</Collapse>
				</div>
			)}
 
			<div className='BugSubmitDetailCollapse'>
				<Collapse
					bordered={false}
					defaultActiveKey={['Initial Report']}
					expandIcon={({isActive}) => (
						<CaretRightOutlined rotate={isActive ? 90 : 0} />
					)}>
					<Panel
						header={intl.formatMessage({
							id: 'intl.bugsubmitdetail.panel.vulnerabilityinfo'
						})}
						key='Initial Report'>
						<div className='VulnerabilityInformationDiv'>
							<Form
								{...layout}
								name='VulnerabilityInformationForm'>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.bugsubmitdetail.vulnerabilityinfo.type'
									})}
									name='Type'>
									<label className='VulnerabilityInformationFormLabel'>
										{
											state.VulnerabilityInformation
												?.leakReview?.leakType?.name
										}
									</label>
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.bugsubmitdetail.vulnerabilityinfo.subtype'
									})}
									name='Subtype'>
									<label className='VulnerabilityInformationFormLabel'>
										{
											state.VulnerabilityInformation
												?.leakReview?.leakType
												?.leakSubtype?.name
										}
									</label>
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.bugsubmitdetail.vulnerabilityinfo.severity'
									})}
									name='Severity'>
									{
										<Tag
											color={
												state.VulnerabilityInformation
													?.leakLevel?.color
											}>
											{
												state.VulnerabilityInformation
													?.leakLevel?.name
											}
										</Tag>
									}
								</Form.Item>
								{state.VulnerabilityInformation?.leakReview
									?.leakType?.id === 4 && (
									<Form.Item
										label={intl.formatMessage({
											id: 'intl.bugsubmitdetail.vulnerabilityinfo.url'
										})}
										name='URL'>
										<label className='VulnerabilityInformationFormLabel'>
											{
												state.VulnerabilityInformation
													?.lurl
											}
										</label>
									</Form.Item>
								)}
								{state.VulnerabilityInformation?.leakReview
									?.leakType?.id === 3 && (
									<Form.Item
										label={intl.formatMessage({
											id: 'intl.bugsubmitdetail.vulnerabilityinfo.apk'
										})}
										name='apkName'>
										<label className='VulnerabilityInformationFormLabel'>
											{
												state.VulnerabilityInformation
													?.apkName
											}
										</label>
									</Form.Item>
								)}
								{state.VulnerabilityInformation?.leakReview
									?.leakType?.id === 3 && (
									<Form.Item
										label={intl.formatMessage({
											id: 'intl.bugsubmitdetail.vulnerabilityinfo.apkVersionCode'
										})}
										name='apkVersionNumber'>
										<label className='VulnerabilityInformationFormLabel'>
											{
												state.VulnerabilityInformation
													?.apkVersionNumber
											}
										</label>
									</Form.Item>
								)}
								{state.VulnerabilityInformation?.leakReview
									?.activityRewardRule && (
									<Form.Item
										label={intl.formatMessage({
											id: 'intl.bugsubmitdetail.vulnerabilityinfo.activity'
										})}
										name='Activity'>
										<label className='VulnerabilityInformationFormLabel'>
											<a
												href={
													state
														.VulnerabilityInformation
														?.leakReview
														?.reviewActivityRewardRule
														?.i18ns[0]?.newsUrl
												}
												target='_blank'>
												{
													state
														.VulnerabilityInformation
														?.leakReview
														?.reviewActivityRewardRule
														?.i18ns[0]?.keyword
												}
											</a>
										</label>
									</Form.Item>
								)}
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.bugsubmitdetail.vulnerabilityinfo.summary'
									})}
									name='Summary'>
									<label className='VulnerabilityInformationFormLabel'>
										{state.VulnerabilityInformation?.title}
									</label>
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'intl.bugsubmitdetail.vulnerabilityinfo.vulnerabilityinfo'
									})}
									name='Vulnerability Info'>
									<div className='VulnerabilityInfoDiv'>
										<div
											className={ 
												showMore
													? 'VulnerabilityInfoShow'
													: 'VulnerabilityInfoNotShow'
											}
											dangerouslySetInnerHTML={{
												__html: state
													.VulnerabilityInformation
													?.content
											}}></div>
										{!showMore && (
											<img
												className='VulnerabilityInfoMoreImg'
												onClick={showMoreChange}
												src={moreImgIcon}
												onMouseEnter={() =>
													setMoreImgIcon(MoreImgHover)
												}
												onMouseLeave={() =>
													setMoreImgIcon(MoreImg)
												}
											/>
										)}
										{showMore && (
											<img
												className='VulnerabilityInfoMoreImg'
												onClick={showMoreChange}
												src={lessImgIcon}
												onMouseEnter={() =>
													setLessImgIcon(LessImgHover)
												}
												onMouseLeave={() =>
													setLessImgIcon(LessImg)
												}
											/>
										)}
									</div>
								</Form.Item>
								{state.VulnerabilityInformation
									?.attachments && (
									<Form.Item
										label={intl.formatMessage({
											id: 'intl.bugsubmitdetail.vulnerabilityinfo.attachment'
										})}
										name='Attachment'>
										{state.VulnerabilityInformation
											?.attachments[0]?.fileName && (

                      <Popconfirm
                      cancelText={intl.formatMessage({id:'intl.bugsubmitdetail.button.cancel'})}
                      key="wipeOff"
                      okText={intl.formatMessage({id:'intl.bugsubmitdetail.prompt.Download'})}
                      onConfirm={Download}
                      // title={intl.formatMessage({id:'intl.bugsubmitdetail.prompt.downloadTips'})}
                      title={Content()}
                    >
											<Tag
												className='Attachmentfile'
												icon={<DownloadOutlined />}
												color='default'>
												{
													state
														.VulnerabilityInformation
														?.attachments[0]
														?.fileName
												}
											</Tag>
                      </Popconfirm>
         
										)}
									</Form.Item>
								)}
							</Form>
						</div>
					</Panel>
				</Collapse>
			</div>
  
			<div className='BugSubmitDetailCollapse' id='comments'>
				<Collapse
					ghost
					defaultActiveKey={['comments']}
					expandIcon={({isActive}) => (
						<CaretRightOutlined rotate={isActive ? 90 : 0} />
					)}>
					<Panel
						header={intl.formatMessage({
							id: 'intl.bugsubmitdetail.panel.comments'
						})}
						key='comments'>
						{lastReply?.id && (
							<Space size={36}>
								<Avatar
									size='large'
									src={
										lastReply?.user?.userInfo?.pictureUrl ||
										defaultAvatar
									}
								/>
								<div
									className='quoteTitle'
									style={{marginBottom: lastReply?.id && 0}}>
									{`Quote ${lastReply?.user?.userInfo?.alienName} description released at ${lastReply?.ctime}`}
								</div>
								<Button
									danger
									type='link'
									icon={<DeleteOutlined />}
									onClick={() => setLastReply({})}
								/>
							</Space>
						)}
						<div style={{marginLeft: 60, marginTop: 29}}>
							<Editor
								apiKey='dk2m69on93lmu80zzouc0a0hwg0uwcg3hom6lbjnl3zz8pms'
								onInit={(evt, editor) =>
									(editorRef.current = editor)
								}
								init={{...initObj}}
								value={contextDescription}
								onEditorChange={handleEditorChange}
							/>
							<Upload
								onRemove={onRemove}
								beforeUpload={beforeUpload}
								fileList={fileList}>
								<Button
									icon={<UploadOutlined />}
									style={{margin: '16px 0px'}}>
									{intl.formatMessage({
										id: 'intl.bugsubmitdetail.button.addAttachment'
									})}
								</Button>
							</Upload>
							<div style={{color: 'rgba(0,0,0,0.45)'}}>
								<label>
									{intl.formatMessage({
										id: 'intl.bugsubmitdetail.prompt.format'
									})}
									:{' '}
								</label>
								<span>
									.doc, .xls, .pdf，.7z, .zip, .mp4, .gz,
									.bz2, .png, .jpg, .wmv and .jpeg; Size: Less
									than 10 MB
								</span>
							</div>
							{state.VulnerabilityInformation?.status ===
								'leak_status_need_more_info' && (
								<div>
									<Checkbox
										checked={needReview}
										onChange={(e) =>
											setNeedReview(e.target.checked)
										}
										style={{marginTop: 16}}>
										{intl.formatMessage({
											id: 'intl.bugsubmitdetail.prompt.toPendingReview'
										})}
									</Checkbox>
								</div>
							)}
							{captcha?.cToken && (
								<div style={{marginTop: 20}}>
									<Space>
										<Input
											placeholder='请填写验证码'
											onChange={handleChangeCode}
											allowClear
										/>
										<img
											src={captcha?.base64Img}
											onClick={() => handleGetCaptcha()}
											style={{cursor: 'pointer'}}
										/>
									</Space>
								</div>
							)}
							<Space style={{marginTop: 20}}>
								<Button
									type='primary'
									onClick={handleSubmit}
									disabled={disabledSend}>
									{intl.formatMessage({
										id: 'intl.bugsubmitdetail.button.send'
									})}
								</Button>
								<Button onClick={handleCancel}>
									{intl.formatMessage({
										id: 'intl.bugsubmitdetail.button.cancel'
									})}
								</Button>
							</Space>
						</div>

						{state.VulnerabilityInformation?.leakReply?.length >
						0 ? (
							<div className='comments'>
								{state.VulnerabilityInformation?.leakReply.map(
									(i) => (
										<div className='commentItem'>
											<Avatar
												size='large'
												src={
													i?.user?.userInfo
														?.pictureUrl ||
													defaultAvatar
												}
											/>
											<div
												style={{
													flex: 1,
													marginLeft: 20
												}}
												id={`comment${i?.id}`}>
												<div className='commentItemHeader'>
													<div
														style={{
															display: 'flex',
															alignItems: 'center'
														}}>
														<span className='username'>
															{
																i?.user
																	?.userInfo
																	?.alienName
															}
														</span>

														<Popover
															color='#f8f8f8'
															placement='right'
															content={medalCardContent(
																i?.user
																	?.userInfo
																	?.grantSafetyMedal,
																i?.user?.id
															)}
															title={null}>
														{	i?.user
																		?.userInfo
																		?.grantSafetyMedal
																		?.medalIconOss &&(
                                      <img
																src={
																	i?.user
																		?.userInfo
																		?.grantSafetyMedal
																		?.medalIconOss
																}
																alt='img'
																style={{
																	width: '24px',
																	height: '24px',
																	marginRight:
																		'20px',
																	marginTop:
																		'-4px'
																}}
															/>
                                    )}	
														</Popover>

														<span className='releaseTime'>
															{i?.ctime}
														</span>
													</div>
													<div
														style={{
															marginLeft: 'auto',
															display: 'flex'
														}}>
														{visibleFoldButtonList.includes(
															i?.id
														) && (
															<div
																className='fold'
																onClick={() =>
																	handleExtend(
																		i?.id
																	)
																}>
																<img
																	src={
																		icFoldImg
																	}
																	id={`imgFold${i?.id}`}
																	style={{
																		transform:
																			'rotate(180deg)'
																	}}
																/>
																{intl.formatMessage(
																	{
																		id: 'intl.bugsubmitdetail.button.fold'
																	}
																)}
															</div>
														)}
														<div
															className='quote'
															onClick={() =>
																handleToEditor(
																	i
																)
															}>
															<img
																src={icQuoteImg}
															/>
															{intl.formatMessage(
																{
																	id: 'intl.bugsubmitdetail.button.quote'
																}
															)}
														</div>
													</div>
												</div>
												<div
													className='commentItemBody'
													style={{
														background:
															!i?.user
																?.isSuperuser &&
															'#F0F5FB'
													}}>
													<div
														id={`commentContent${i?.id}`}
														className='commentItemContent'>
														{i?.lastReply && (
															<div
																id={`quoteTitle${i?.id}`}
																className='quoteTitle'
																onClick={() =>
																	document
																		.querySelector(
																			`#comment${i?.lastReplyId}`
																		)
																		.scrollIntoView(
																			{
																				behavior:
																					'smooth'
																			}
																		)
																}>
																{`Quote ${i?.lastReply?.user?.userInfo?.alienName} description released at ${i?.lastReply?.ctime}`}
															</div>
														)}
														{i.replyAttachments
															.length > 0 && (
															<div
																className='attachments'
																id={`attachments${i?.id}`}>
																<div
																	style={{
																		marginBottom: 16
																	}}>
																	{intl.formatMessage(
																		{
																			id: 'intl.bugsubmitdetail.prompt.attachments'
																		}
																	)}
																</div>
																{i.replyAttachments.map(
																	(file) => (
                                    <Popconfirm
                                    cancelText={intl.formatMessage({id:'intl.bugsubmitdetail.button.cancel'})}
                                    key="wipeOff"
                                    okText={intl.formatMessage({id:'intl.bugsubmitdetail.prompt.Download'})}
                                    onConfirm={DownloadReview}
                                    title={Content()}
                                  >
                                    <Button
																			icon={
																				<DownloadOutlined />
																			}
																			style={{
																				marginRight: 8
																			}}
																			>
																			{
																				file?.fileName
																			}
																		</Button>
                                    </Popconfirm>
																		
																	)
																)}
															</div>
														)}
														<div  className="blogdetailcontentA"
															id={`innerHtml${i?.id}`}
															dangerouslySetInnerHTML={{
																__html: i?.content
															}}></div>
													</div>
												</div>
											</div>
										</div>
									)
								)}
							</div>
						) : (
							<div className='noComments'>
								{intl.formatMessage({
									id: 'intl.bugsubmitdetail.prompt.noComments'
								})}
							</div>
						)}
					</Panel>
				</Collapse>
			</div>
      </Spin>
		</div>
	);
});
