import React, {useEffect, useState} from 'react';
import './style/payoutMethod.css';
import {Link, useHistory} from 'react-router-dom';
import {Input, Modal, Button, Upload, message, Form} from 'antd';
import {scrollToTop} from '../../../common/topScorll';
import {detailLink} from '../../../common/detailLink';
import FacebookLoginSecurity from 'react-facebook-login';
import ImgCrop from 'antd-img-crop';
import Xhr from '../../../services/xhr/index';
import PersonalInfoService from '../../../services/personalInfoService';
import avatar from '../../../assets/svg/ic-个人中心-头像.svg';
import {useIntl} from 'react-intl';
import {PlusOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import facebookIcon from '../../../assets/svg/icon_facebook.svg';
import twitterIcon from '../../../assets/svg/icon_socialXLogo.svg';
import otherIcon from '../../../assets/svg/icon_otherSocial.svg';
import BankAccountModal from '../components/bankAccountModal';
import {JSEncrypt} from 'jsencrypt';
import _ from 'lodash';
import QuickEntryDiv from '../components/quickEntryDiv';
import {goRouterPosition} from '../../../action/routerposition/routerpositionAction';
const {confirm} = Modal;

export default (function (props) {
	const intl = useIntl();
	const history = useHistory();
	const [personalInfo, setPersonalInfo] = useState();
	const [visibleEditImg, setVisibleEditImg] = useState(false);
	const [uploadFile, setUploadFile] = useState(undefined);
	const [fileList, setFileList] = useState([]);
	const [fileUrl, setFileUrl] = useState(null);
	const [captchaCode, setCaptchaCode] = useState('');
	const [captchaCodeToken, setCaptchaCodeToken] = useState('');
	const [facebookInfo, setFacebookInfo] = useState('');
	const [changePasswordVisible, setChangePasswordVisible] = useState('');
	const [oldPasswordInput, setOldPasswordInput] = useState('');
	const [newPasswordInput, setNewPasswordInput] = useState('');
	const [verifyNewPasswordInput, setVerifyNewPasswordInput] = useState('');
	const [captchaCodeInput, setCaptchaCodeInput] = useState('');
	const [saveLoading, setSaveLoading] = useState(false);
	const onImgCropChange = ({fileList: newFileList}) => {
		setFileList(newFileList);
	};
	const goDetailLink = (linkContent) => {
		window.open(detailLink[sessionStorage.getItem('lang')][linkContent]);
	};
	const onImgCropRemove = (file) => {
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
		setUploadFile(undefined);
		setFileUrl(null);
	};
	const beforeImgCropUpload = (file) => {
		setUploadFile(file);
		const imageUrl = URL.createObjectURL(file);
		setFileUrl(imageUrl);
		return false;
	};
	const propsImgCrop = {
		width: 200, //裁剪宽度
		height: 200, //裁剪高度
		resize: false, //裁剪是否可以调整大小
		resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
		modalTitle: '图片裁剪', //弹窗标题
		modalWidth: 465 //弹窗宽度
	};
	const changeEditImg = () => {
		let fileType = uploadFile.name.split('.');
		const fileDate = fileType.slice(-1);
		if (['png', 'jpg', 'jpeg'].indexOf(fileDate[0]) < 0) {
			message.error(
				intl.formatMessage({
					id: 'intl.personalinfo.message.img.type'
				})
			);
			onImgCropRemove();
			return false;
		}
		const isLt20M = uploadFile.size / 1024 / 1024 < 1;
		if (!isLt20M) {
			message.error(
				intl.formatMessage({
					id: 'intl.personalinfo.message.img.size'
				})
			);
			onImgCropRemove();
			return false;
		}
		const formData = new FormData();
		formData.append('file', uploadFile);
		Xhr.post('/userInfo/picture', formData)
			.then((res) => {
				PersonalInfoService.getPersonalInfo()
					.then((res) => {
						setPersonalInfo(res.data);
					})
					.catch((error) => {
						console.log(error);
					});
				res?.code == 200 && setVisibleEditImg(false);
			})
			.catch((error) => {
				setVisibleEditImg(false);
				console.log(error);
			});
	};
	const [nameEdit, setNameEdit] = useState(false);
	const [nameValue, setNameValue] = useState('');
	const nameInputChange = (e) => {
		setNameValue(e.target.value);
	};
	const goChangeName = () => {
		if (nameValue.length > 20) {
			message.error(
				intl.formatMessage({
					id: 'intl.personalinfo.message.namelength'
				})
			);
		} else {
			PersonalInfoService.ChangeName({
				alienName: nameValue
			})
				.then((res) => {
					PersonalInfoService.getPersonalInfo()
						.then((res) => {
							setPersonalInfo(res.data);
						})
						.catch((error) => {
							console.log(error);
						});
					message.success(
						intl.formatMessage({
							id: 'intl.personalinfo.type.loginsecurity.success.changepassword'
						})
					);
					setNameEdit(false);
				})
				.catch((error) => {
					message.error(error);
				});
		}
	};
	const oldPasswordInputChange = (e) => {
		setOldPasswordInput(e.target.value);
	};

	const newPasswordInputChange = (e) => {
		setNewPasswordInput(e.target.value);
	};

	const verifyNewPasswordInputChange = (e) => {
		setVerifyNewPasswordInput(e.target.value);
	};

	const CaptchaCodeInputChange = (e) => {
		setCaptchaCodeInput(e.target.value);
	};
	const changeCaptchaCode = () => {
		PersonalInfoService.getCaptchaCode()
			.then((res) => {
				setCaptchaCode(res.data.base64Img);
				setCaptchaCodeToken(res.data.cToken);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const goChangePassword = () => {
		if (newPasswordInput == verifyNewPasswordInput) {
			PersonalInfoService.ChangePassword({
				password: oldPasswordInput,
				newPassword: newPasswordInput,
				cToken: captchaCodeToken,
				cCode: captchaCodeInput
			})
				.then((res) => {
					message.success(
						intl.formatMessage({
							id: 'intl.personalinfo.type.loginsecurity.success.changepassword'
						})
					);
					setChangePasswordVisible(false);
				})
				.catch((error) => {
					message.error(error);
					changeCaptchaCode();
				});
		} else {
			message.error(
				intl.formatMessage({
					id: 'intl.personalinfo.type.loginsecurity.error.twicepassword'
				})
			);
			changeCaptchaCode();
		}
	};
	const responseFacebook = (response) => {
		PersonalInfoService.BindFacebook({
			accessToken: response.accessToken
		})
			.then((res) => {
				message.success(
					intl.formatMessage({
						id: 'intl.personalinfo.type.loginsecurity.success.bindfacebook'
					})
				);
				PersonalInfoService.getPersonalInfo()
					.then((res) => {
						setPersonalInfo(res.data);
						setFacebookInfo(
							_.find(res.data.thirdUsers, {type: 'FaceBook'})
						);
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				message.error(
					intl.formatMessage({
						id: 'intl.personalinfo.type.loginsecurity.error.bindfacebook'
					})
				);
			});
	};
	const [isEditSocial, setIsEditSocial] = useState(false);
	const [fbValue, setFbValue] = useState('');
	const [twiValue, setTwiValue] = useState('');
	const [allSocialData, setAllSocialData] = useState('');
	const addSocialMedia = () => {
		let tempData = _.cloneDeep(allSocialData);
		tempData.push({
			name:
				sessionStorage.getItem('lang') === 'en_US'
					? 'Other Social Media Account'
					: '其他社交帐号',
			link: '',
			ifShow: true
		});
		setAllSocialData(tempData);
	};
	const [showDeleteHint, setShowDeleteHint] = useState(false);
	const socialMediaValueChange = (e, id, type, flag) => {
		let tempData = _.cloneDeep(allSocialData);
		if (id === 'FaceBook' || id === 'Twitter') {
			let index = _.findIndex(tempData, ['name', id]);
			tempData[index].link = e.target.value;
		} else {
			if (flag) {
				let index = _.findIndex(tempData, ['id', id]);
				if (type === 'link') {
					tempData[index].link = e.target.value;
				} else if (type === 'name') {
					tempData[index].name = e.target.value;
				}
			} else {
				if (type === 'link') {
					tempData[id].link = e.target.value;
				} else if (type === 'name') {
					tempData[id].name = e.target.value;
				}
			}
		}

		setAllSocialData(tempData);
	};
	const deleteSocialMedia = (index) => {
		let tempData = _.cloneDeep(allSocialData);
		tempData.splice(index, 1);
		setAllSocialData(tempData);
	};
	const saveSocialMedia = () => {
		let temp = _.cloneDeep(allSocialData);
		setSaveLoading(true);
		PersonalInfoService.updateSocialMedia(temp)
			.then((res) => {
				if (res.code === 200) {
					message.success('Set Successfully!');
					getSocialData();
					setIsEditSocial(false);
				}
			})
			.finally(() => {
				setSaveLoading(false);
			});
	};
	const getSocialData = () => {
		PersonalInfoService.getSocialMedia({personalHome: true}).then((res) => {
			setFbValue(_.find(res.data?.accountList, {name: 'FaceBook'}));
			setTwiValue(_.find(res.data?.accountList, {name: 'Twitter'}));
			setAllSocialData(res.data?.accountList);
		});
	};
	const [bankData, setBankData] = useState();
	const handleGetBankAccountData = () => {
		PersonalInfoService.getBankAccountData()
			.then((res) => {
				setBankData(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const [bankModalVis, setBankModalVis] = useState(false);
	const [bankModalType, setBankModalType] = useState();
	const openBankModal = (type) => {
		setBankModalType(type);
		setBankModalVis(true);
	};
	const closeBankModal = () => {
		setBankModalVis(false);
	};
	const confirmRemoveAccount = () => {
		PersonalInfoService.deleteBankAccount()
			.then((res) => {
				message.success(res.data);
				handleGetBankAccountData();
				Modal.destroyAll();
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const confirmDeleteAccount = () => {
		confirm({
			title: (
				<label className='settingBankCardRemoveHintTitle'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove.modalTitle'
					})}
				</label>
			),
			icon: <ExclamationCircleOutlined />,
			content: (
				<label className='settingBankCardRemoveHintContent'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove.modalContent'
					})}
				</label>
			),
			okText: intl.formatMessage({
				id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove.cancelBtn'
			}),
			cancelText: intl.formatMessage({
				id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove.confirmBtn'
			}),

			onCancel() {
				confirmRemoveAccount();
			}
		});
	};
	const scrollToPayout = () => {
		const target = document.getElementById('href');
		target.click();
		localStorage.setItem('payoutJump', false);
		// console.log(target);
		// window.scrollTo({
		// 	left: 0,
		// 	top: target,
		// 	behavior: 'smooth' //或'auto'
		// });
	};
	useEffect(() => {
    goRouterPosition({routerPosition: 'payoutMethod'});
		PersonalInfoService.getPersonalInfo()
			.then((res) => {
				setPersonalInfo(res.data);
				setFacebookInfo(
					_.find(res.data.thirdUsers, {type: 'FaceBook'})
				);
			})
			.catch((error) => {
				console.log(error);
			});
		getSocialData();
		PersonalInfoService.getCaptchaCode()
			.then((res) => {
				setCaptchaCode(res.data.base64Img);
				setCaptchaCodeToken(res.data.cToken);
			})
			.catch((error) => {
				console.log(error);
			});

		handleGetBankAccountData();
		setTimeout(() => {
			// console.log(localStorage.getItem('payoutJump'));
			if (localStorage.getItem('payoutJump') === 'true') {
				scrollToPayout();
			}
		}, 200);

		return () => {
			setPersonalInfo();
			setFacebookInfo();
			setCaptchaCode();
			setCaptchaCodeToken();
		};
	}, []);

	return (
		<div className='payoutMethodContainer'>
      <QuickEntryDiv
            title={intl.formatMessage({ id: 'intl.personalinfo.breadCrumb.payoutMethod'})}
            chosenType='payoutMethod'
          />
			<div className='settingPersonalInfoTitle'>
				{intl.formatMessage({
					id: 'intl.personalinfo.settings.personalInfo.title'
				})}
			</div>
			<div className='settingPersonalInfoDiv'>
				<div className='settingPersonalInfoFormDiv settingPersonalInfoFormDiv2'>
					<div className='settingPersonalInfoFormTitle '>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.personalInfo.avatar'
						})}
					</div>

					<img
						className='avatarImg'
						alt='avatar'
						src={
							personalInfo?.pictureUrl
								? personalInfo?.pictureUrl
								: avatar
						}
					/>

					<div
						// style={{position: 'relative', top: '64px'}}
						className='settingPersonalInfoFormEditBtn'
						onClick={() => setVisibleEditImg(true)}>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.personalInfo.edit'
						})}
					</div>
          
				</div>

				<div className='settingPersonalInfoFormDiv'>
					<div className='settingPersonalInfoFormTitle'>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.personalInfo.nickname'
						})}
					</div>
					{(nameEdit && (
						<div>
							<Input
								className='nameEditInput'
								placeholder='Change is allowed only once a year'
								onChange={nameInputChange}
								defaultValue={personalInfo?.alienName}
							/>
							<label
								className='nameEditBtn'
								onClick={() => goChangeName()}>
								{intl.formatMessage({
									id: 'intl.personalinfo.type.personalinfo.button.submit'
								})}
							</label>
							<label
								className='nameEditCancelBtn'
								onClick={() => setNameEdit(false)}>
								{intl.formatMessage({
									id: 'intl.personalinfo.type.personalinfo.button.cancel'
								})}
							</label>
						</div>
					)) || (
						<div style={{display: 'flex'}}>
							<div className='settingPersonalInfoFormText'>
								{personalInfo?.alienName}
							</div>
							<div
								className='settingPersonalInfoFormEditBtn'
								onClick={() => setNameEdit(true)}>
								{intl.formatMessage({
									id: 'intl.personalinfo.settings.personalInfo.edit'
								})}
							</div>
						</div>
					)}
				</div>
				<div className='settingPersonalInfoFormDiv'>
					<div className='settingPersonalInfoFormTitle'>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.personalInfo.country'
						})}
					</div>
					<div className='settingPersonalInfoFormText'>
						{personalInfo?.countryName}
					</div>
				</div>
				<div
					className='settingPersonalInfoFormDiv'
					style={{marginBottom: '0'}}>
					<div className='settingPersonalInfoFormTitle'>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.personalInfo.registDate'
						})}
					</div>
					<div className='settingPersonalInfoFormText'>
						{personalInfo?.dateJoined}
					</div>
				</div>
			</div>
			<a id='href' href='#bankCard'></a>
			<div
				id='bankCard'
				className='settingPersonalInfoTitle'
				style={{marginTop: '32px'}}>
				{intl.formatMessage({
					id: 'intl.personalinfo.settings.payoutMethod.title'
				})}
			</div>
			<div className='settingPayoutDespDiv'>
				<div className='settingPayoutDespText'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.payoutMethod.desc'
					})}
				</div>
				<div
					className='settingPayoutDespLink'
					onClick={() => {
						goDetailLink('AddBankAccount');
					}}>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.payoutMethod.link'
					})}
				</div>
			</div>
			<div className='settingPayoutMainDiv'>
				{JSON.stringify(bankData) !== '{}' ? (
					<div className='settingShowBankCard'>
						<div className='settingShowBankCardBankName'>
							{bankData?.bankName}
						</div>
						<div className='settingShowBankCardBankNumber'>
							{bankData?.accountNumber}
						</div>
						<div className='settingShowBankCardBankPayeeName'>
							{bankData?.accountName}
						</div>
						<div className='settingShowBankCardBankActionDiv'>
							<label
								className='settingShowBankCardBankActionText'
								style={{marginRight: '40px'}}
								onClick={() => openBankModal('edit')}>
								{intl.formatMessage({
									id: 'intl.personalinfo.settings.payoutMethod.bankCard.edit'
								})}
							</label>
							<label
								className='settingShowBankCardBankActionText'
								onClick={() => confirmDeleteAccount()}>
								{intl.formatMessage({
									id: 'intl.personalinfo.settings.payoutMethod.bankCard.Remove'
								})}
							</label>
						</div>
					</div>
				) : (
					<div
						className='settingAddBankCardDiv'
						onClick={() => openBankModal('add')}>
						<PlusOutlined />
						<div style={{marginTop: '8px'}}>
							{intl.formatMessage({
								id: 'intl.personalinfo.settings.payoutMethod.addBank'
							})}
						</div>
					</div>
				)}
			</div>
			<div
				className='settingSocialMediaTitle'
				style={{marginTop: '32px'}}>
				{intl.formatMessage({
					id: 'intl.personalinfo.settings.socialMedia.title'
				})}
			</div>
			<div className='settingPayoutDespDiv'>
				<div className='settingPayoutDespText'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.socialMedia.desc'
					})}
				</div>
				{isEditSocial ? (
					<div style={{marginTop: '-18px', display: 'flex'}}>
						<Button
							type='primary'
							style={{marginRight: '8px'}}
							loading={saveLoading}
							onClick={() => saveSocialMedia()}>
							{intl.formatMessage({
								id: 'intl.personalinfo.settings.socialMedia.editSave'
							})}
						</Button>
						<Button
							onClick={() => {
								setIsEditSocial(false);
								getSocialData();
							}}>
							{intl.formatMessage({
								id: 'intl.personalinfo.settings.socialMedia.editCancel'
							})}
						</Button>
					</div>
				) : (
					<Button
						type='primary'
						style={{marginTop: '-18px'}}
						onClick={() => setIsEditSocial(true)}>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.socialMedia.edit'
						})}
					</Button>
				)}
			</div>
			{isEditSocial ? (
				<>
					<div className='settingSocialMediaDiv'>
						<img
							src={facebookIcon}
							className='socialMediaIconImg'
							alt='img'
						/>
						<div>
							<div className='settingSocialMediaName'>
								Facebook
							</div>
							<Input
								style={{width: '1000px'}}
								defaultValue={fbValue.link}
								placeholder={intl.formatMessage({
									id: 'intl.personalinfo.settings.socialMedia.notSet'
								})}
								onChange={(e) =>
									socialMediaValueChange(
										e,
										'FaceBook',
										'link'
									)
								}
							/>
						</div>
					</div>
					<div className='settingSocialMediaDiv'>
						<img
							src={twitterIcon}
							className='socialMediaIconImg'
							alt='img'
						/>
						<div>
							<div className='settingSocialMediaName'>
								Twitter
							</div>
							<Input
								style={{width: '1000px'}}
								defaultValue={fbValue.link}
								placeholder={intl.formatMessage({
									id: 'intl.personalinfo.settings.socialMedia.notSet'
								})}
								onChange={(e) =>
									socialMediaValueChange(e, 'Twitter', 'link')
								}
							/>
						</div>
					</div>
					{allSocialData?.length > 2 &&
						allSocialData.map((item, index) => {
							return (
								item.name !== 'FaceBook' &&
								item.name !== 'Twitter' && (
									<div
										className='settingSocialMediaDiv'
										key={index}>
										<img
											src={otherIcon}
											className='socialMediaIconImg'
											alt='img'
										/>
										<div>
											<div className='settingSocialMediaName'>
												<Input
													style={{width: '1000px'}}
													defaultValue={item.name}
													value={item.name}
													placeholder={intl.formatMessage(
														{
															id: 'intl.personalinfo.settings.socialMedia.notSet'
														}
													)}
													onChange={(e) =>
														socialMediaValueChange(
															e,
															item.id
																? item.id
																: index,
															'name',
															item.id
																? true
																: false
														)
													}
												/>
											</div>
											<Input
												style={{width: '1000px'}}
												defaultValue={item.link}
												value={item.link}
												placeholder={intl.formatMessage(
													{
														id: 'intl.personalinfo.settings.socialMedia.notSet'
													}
												)}
												onChange={(e) =>
													socialMediaValueChange(
														e,
														item.id
															? item.id
															: index,
														'link',
														item.id ? true : false
													)
												}
											/>
										</div>
										<div
											className='socialMediaDeleteBtn'
											onClick={() =>
												deleteSocialMedia(index)
											}>
											{intl.formatMessage({
												id: 'intl.personalinfo.settings.socialMedia.deleteBtn'
											})}
										</div>
									</div>
								)
							);
						})}
					{allSocialData?.length < 5 && (
						<div
							className='socialAddDiv'
							onClick={() => addSocialMedia()}>
							<PlusOutlined />

							<div>
								{intl.formatMessage({
									id: 'intl.personalinfo.settings.socialMedia.addAccount'
								})}
							</div>
						</div>
					)}
				</>
			) : (
				<>
					<div className='settingSocialMediaDiv'>
						<img
							src={facebookIcon}
							className='socialMediaIconImg'
							alt='img'
						/>
						<div>
							<div className='settingSocialMediaName'>
								Facebook
							</div>
							<div className='settingSocialMediaDesc'>
								{fbValue?.link ? (
									fbValue?.link
								) : (
									<>
										{intl.formatMessage({
											id: 'intl.personalinfo.type.loginsecurity.notset'
										})}
									</>
								)}
							</div>
						</div>
					</div>
					<div className='settingSocialMediaDiv'>
						<img
							src={twitterIcon}
							className='socialMediaIconImg'
							alt='img'
						/>
						<div>
							<div className='settingSocialMediaName'>
								Twitter
							</div>
							<div className='settingSocialMediaDesc'>
								{twiValue?.link ? (
									twiValue?.link
								) : (
									<>
										{intl.formatMessage({
											id: 'intl.personalinfo.type.loginsecurity.notset'
										})}
									</>
								)}
							</div>
						</div>
					</div>
					{allSocialData?.length > 2 &&
						allSocialData.map((item, index) => {
							return (
								item.name !== 'FaceBook' &&
								item.name !== 'Twitter' && (
									<div
										className='settingSocialMediaDiv'
										key={index}>
										<img
											src={otherIcon}
											className='socialMediaIconImg'
											alt='img'
										/>
										<div>
											<div className='settingSocialMediaName'>
												{item.name}
											</div>
											<div className='settingSocialMediaDesc'>
												{item.link ? (
													item.link
												) : (
													<>
														{intl.formatMessage({
															id: 'intl.personalinfo.type.loginsecurity.notset'
														})}
													</>
												)}
											</div>
										</div>
									</div>
								)
							);
						})}
				</>
			)}

			<div
				className='settingSocialMediaTitle'
				style={{marginTop: '32px'}}>
				{intl.formatMessage({
					id: 'intl.personalinfo.settings.publicProfile.title'
				})}
			</div>
			<div className='settingPayoutDespDiv'>
				<div className='settingPayoutDespText'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.publicProfile.desc'
					})}
				</div>
				<div
					className='settingPayoutDespLink'
					onClick={() => {
						window.open('/SRC/publicProfile', '_blank');
						// history.push('/SRC/publicProfile');
					}}>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.publicProfile.link'
					})}
				</div>
			</div>
			<div
				className='settingSocialMediaTitle'
				style={{marginTop: '32px'}}>
				{intl.formatMessage({
					id: 'intl.personalinfo.settings.login.title'
				})}
			</div>
			<div className='settingLoginDetailDiv'>
				<div className='settingLoginDetailFormDiv'>
					<div className='settingLoginDetailFormTitle'>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.login.password.title'
						})}
					</div>
					<div className='settingLoginDetailFormText'>
						{personalInfo?.haveEmail
							? '********'
							: intl.formatMessage({
									id: 'intl.personalinfo.type.loginsecurity.notset'
							  })}
					</div>
					{(personalInfo?.haveEmail && (
						<div
							className='settingPersonalInfoFormEditBtn'
							onClick={() => setChangePasswordVisible(true)}>
							{intl.formatMessage({
								id: 'intl.personalinfo.settings.personalInfo.edit'
							})}
						</div>
					)) || (
						<Link
							className='SecurityInformationButton'
							to={`/SRC/bindEmailPwd`}>
							{intl.formatMessage({
								id: 'intl.personalinfo.type.loginsecurity.button.edit'
							})}
						</Link>
					)}
				</div>
				<div className='settingLoginDetailHintDiv'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.login.password.hint'
					})}
				</div>
				<div
					className='settingLoginDetailFormDiv'
					style={{marginBottom: '16px'}}>
					<div className='settingLoginDetailFormTitle'>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.login.email.title'
						})}
					</div>
					<div className='settingLoginDetailFormText'>
						{personalInfo?.haveEmail ? personalInfo?.email
						 : (
							<label className='SecurityInformationContent'>
								{intl.formatMessage({
									id: 'intl.personalinfo.type.loginsecurity.notset'
								})}
							</label>
						)}
					</div>
				</div>
				<div className='settingLoginDetailFormDiv'>
					<div className='settingLoginDetailFormTitle'>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.login.facebook.title'
						})}
					</div>
					<div className='settingLoginDetailFormText'>
						{(facebookInfo && (
							<label className='SecurityInformationContent'>
								{intl.formatMessage({
									id: 'intl.personalinfo.type.loginsecurity.hasset'
								})}
							</label>
						)) || (
							<label className='SecurityInformationContent'>
								{intl.formatMessage({
									id: 'intl.personalinfo.type.loginsecurity.notset'
								})}
							</label>
						)}
					</div>
					{!facebookInfo && (
						<FacebookLoginSecurity
							appId='456290888761210'
							// autoLoad={true}
							// response_type="code"
							fields='name,email,picture'
							scope='public_profile'
							cssClass='settingBtnFacebook'
							callback={responseFacebook}
							icon='fa-facebook'
							textButton={intl.formatMessage({
								id: 'intl.personalinfo.type.loginsecurity.facebook.button'
							})}
						/>
					)}
				</div>
				<div className='settingLoginDetailHintDiv'>
					{intl.formatMessage({
						id: 'intl.personalinfo.settings.login.facebook.hint'
					})}
				</div>
				<div className='settingLoginDetailFormDiv'>
					<div
						className='settingLoginDetailFormTitle'
						style={{cursor: 'pointer'}}
						onMouseEnter={() => setShowDeleteHint(true)}
						onMouseLeave={() => setShowDeleteHint(false)}>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.login.deleteAccount.title'
						})}
					</div>
				</div>
				{showDeleteHint && (
					<div className='settingLoginDetailDeleteHintDiv'>
						{intl.formatMessage({
							id: 'intl.personalinfo.settings.login.deleteAccount.hint'
						})}
					</div>
				)}
			</div>
			<Modal
				visible={visibleEditImg}
				title={intl.formatMessage({
					id: 'intl.personalinfo.type.personalinfo.modal.title'
				})}
				onCancel={() => {
					setVisibleEditImg(false);
					onImgCropRemove();
				}}
				footer={[
					<Button key='submit' type='primary' onClick={changeEditImg}>
						{intl.formatMessage({
							id: 'intl.personalinfo.type.personalinfo.button.submit'
						})}
					</Button>,
					<Button
						key='back'
						onClick={() => {
							setVisibleEditImg(false);
							onImgCropRemove();
						}}>
						{intl.formatMessage({
							id: 'intl.personalinfo.type.personalinfo.button.cancel'
						})}
					</Button>
				]}>
				<div className='ImgCropDiv'>
					{!uploadFile && (
						<ImgCrop {...propsImgCrop}>
							<Upload
								listType='picture'
								fileList={fileList}
								onChange={onImgCropChange}
								onRemove={onImgCropRemove}
								beforeUpload={beforeImgCropUpload}
								// onPreview={onPreview}
							>
								{fileList.length < 1 && (
									<div className='ImgCropButtonDiv'>
										<label className='ImgCropButton'>
											+
										</label>
									</div>
								)}
								{/* {fileList.length < 1 && '+ Upload'} */}
							</Upload>
						</ImgCrop>
					)}
					{fileUrl && <img className='previewAvstar' src={fileUrl} />}
				</div>
			</Modal>
			<Modal
				title={intl.formatMessage({
					id: 'intl.personalinfo.type.loginsecurity.modal.title'
				})}
				visible={changePasswordVisible}
				onOk={() => setChangePasswordVisible(false)}
				onCancel={() => setChangePasswordVisible(false)}
				width={575}
				footer={[
					<Button
						key='submit'
						type='primary'
						onClick={goChangePassword}>
						{intl.formatMessage({
							id: 'intl.personalinfo.type.loginsecurity.button.submit'
						})}
					</Button>,
					<Button
						key='back'
						onClick={() => setChangePasswordVisible(false)}>
						{intl.formatMessage({
							id: 'intl.personalinfo.type.loginsecurity.button.cancel'
						})}
					</Button>
				]}>
				<Form
					name='ChangePassword'
					labelCol={{span: 8}}
					wrapperCol={{span: 14}}>
					<Form.Item
						label={intl.formatMessage({
							id: 'intl.personalinfo.type.loginsecurity.oldpassword'
						})}
						name='oldPassword'>
						<Input.Password
							className='oldPasswordInput'
							onChange={oldPasswordInputChange}
							placeholder={intl.formatMessage({
								id: 'intl.personalinfo.type.loginsecurity.placeholder.oldpassword'
							})}
						/>
					</Form.Item>
					<Form.Item
						label={intl.formatMessage({
							id: 'intl.personalinfo.type.loginsecurity.newpassword'
						})}
						name='newPassword'>
						<Input.Password
							className='newPasswordInput'
							onChange={newPasswordInputChange}
							placeholder={intl.formatMessage({
								id: 'intl.personalinfo.type.loginsecurity.placeholder.newpassword'
							})}
						/>
					</Form.Item>
					<Form.Item
						label={intl.formatMessage({
							id: 'intl.personalinfo.type.loginsecurity.verifynewpassword'
						})}
						name='verifyNewPassword'>
						<Input.Password
							className='verifyNewPasswordInput'
							onChange={verifyNewPasswordInputChange}
							placeholder={intl.formatMessage({
								id: 'intl.personalinfo.type.loginsecurity.placeholder.verifynewpassword'
							})}
						/>
					</Form.Item>
					<Form.Item label='Captcha' name='Captcha'>
						<Input
							className='CaptchaInput'
							placeholder={intl.formatMessage({
								id: 'intl.bugsubmit.placeholder.captcha.img'
							})}
							onChange={CaptchaCodeInputChange}
						/>
						<img
							className='CaptchaImg'
							onClick={changeCaptchaCode}
							src={captchaCode}
							alt='img'
						/>
					</Form.Item>
				</Form>
			</Modal>
			{bankModalVis && (
				<BankAccountModal
					bankModalVis={bankModalVis}
					type={bankModalType}
					closeModal={closeBankModal}
					refresh={handleGetBankAccountData}
				/>
			)}
		</div>
	);
});
