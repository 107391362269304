import Xhr from './xhr/index';

/**
 * 封装ajax请求
 * @param {any}
 */

class LeaderboardsService {
	/**
	 * 登录界面
	 * @param {current} 当前页码
	 * @param {pageSize} 一页数量
	 * @return {BUG提交列表}
	 */
	getDeviceScopeListData(params) {
		return Xhr.get(`/deviceScope?lang=${sessionStorage.getItem('lang')}`, {
			params: params
		});
	}
	getFirmwareUpdatesListData(params) {
		return Xhr.get(`/patchInfo?lang=${sessionStorage.getItem('lang')}`, {
			params: params
		});
	}
	getYearListData() {
		return Xhr.get(
			`/patchInfo/years?lang=${sessionStorage.getItem('lang')}`
		);
	}
	getQuarterListData(params) {
		return Xhr.get(`patchInfo/quarters?year=${params.year}`);
	}
	getDeviceYearList() {
		return Xhr.get(
			`/deviceScope/getDistinctYear?lang=${sessionStorage.getItem(
				'lang'
			)}`
		);
	}
	getCveYearList() {
		return Xhr.get(
			`/cveInfo/years?lang=${sessionStorage.getItem(
				'lang'
			)}`
		);
	}
	getCveMonthList(params) {
		return Xhr.get(
			`/cveInfo/months?year=${params.year}&lang=${sessionStorage.getItem(
				'lang'
			)}`
		);
	}
	getCveDataList (params) {
		return Xhr.get(
			`/cveInfo?lang=${sessionStorage.getItem(
				'lang'
			)}`, {
				params: params
			}
		);
	}
}

// 实例化再导出
export default new LeaderboardsService();
