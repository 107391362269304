import { fromJS } from 'immutable';
import { RES_GETLEADERBOARDSLIST,RES_CHANGEPAGINATION,RES_CHANGELEADERBOARDSTYPE,RES_CHANGETOTAL,RES_CHANGEYEARSELECT,RES_CHANGEMONTHSELECT } from '../../constants/leaderboardsListTypes';

// 初始化state数据
const initialState = {
    pagination: {
        total:0,
        showSizeChanger:true,
        showQuickJumper:true,
        // key:'-1'
    },
    leaderboardsList:[],
    leaderboardsType:'Monthly',
    yearSelect: "",
    monthSelect: ""
};

/**
 * 提交bug列表界面reducer
 * @return
 */
const Leaderboards = (state = initialState, action) => {
    switch(action.type) {
        case RES_GETLEADERBOARDSLIST: // 获取提交bug列表
            return fromJS(state).merge({leaderboardsList: action.res}).toJS();
        case RES_CHANGEPAGINATION: // 更改页码
            return fromJS(state).merge({pagination: action.res}).toJS();
        case RES_CHANGELEADERBOARDSTYPE: // 更改筛选
            return fromJS(state).merge({leaderboardsType: action.res}).toJS();
        case RES_CHANGEYEARSELECT: 
            return fromJS(state).merge({yearSelect: action.res}).toJS();
        case RES_CHANGEMONTHSELECT:
            return fromJS(state).merge({monthSelect: action.res}).toJS();
        default:
            return state;
    }
}

export default Leaderboards;