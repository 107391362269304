import React from 'react';
import '../style/agreement.css';
export default (function (props) {
    return (
        <div className="AgreementDiv">
            <h2 className="Agreementh2">TECNO Security Response Center Terms of Use</h2>
            <p className="Agreementp">Last updated: September 2022</p>
            <p className="Agreementp">Head and Preamble</p>
            <p className="Agreementp">Thank you for choosing TECNO Security Response Center!</p>
            <p className="Agreementp">The Terms of Use of TECNO Security Response Center (hereinafter referred to as "the Agreement") is a legal agreement between you and Shanghai Transsion Co., Ltd. (hereinafter referred to as "TECNO Security Response Center", "We", "Us", or "Our") for the use license and operation management services of TECNO Security Response Center. We hereby advise you to carefully read and fully comprehend the terms of the Agreement, especially those pertaining to the exemption and limitation of liabilities, privacy protection, use rules, applicable law, and dispute resolution, as well as other bold or capitalized clauses that require your attention. You must be of legal age of majority as defined by applicable law in your jurisdiction to access and sign the Agreement. If you are below the age limit, please read and fully understand the Agreement with your legal guardian, and use the TECNO Security Response Center platform and the Services with your guardian's explicit consent.</p>
            <p className="Agreementp">You are deemed to have read, understood, and agreed to be bound by the Agreement by your voluntary choice to click on the web page/pop-up window to agree and/or actually use the TECNO Security Response Center software and services. If you do not agree to the Agreement, do not use any TECNO Security Response Center platform.</p>
            <p className="Agreementp">If you have any questions or suggestions regarding the Agreement, please communicate with us through the contact form attached to the Agreement and provide us with feedback, and we will be happy to answer your questions. </p>
            <p className="Agreementp">Contact: [security.tecno@tecno-mobile.com]</p>

            <h3 className="Agreementh4">I.Overview</h3>
            <p className="Agreementp">1.1 Services: We provide you with account services and security vulnerability reporting services through TECNO Security Response Center.</p>
            <p className="Agreementp">1.2 Scope of Application: The Agreement applies to all the products and services provided by TECNO Security Response Center, including applications, mini-programs, Web pages, software development kits (SDKs), and application programming interfaces (APIs) for third-party websites and applications used on computers and mobile devices, or any other forms that may emerge in the future, which are subject to the specific services available on the TECNO Security Response Center platform.</p>
            <p className="Agreementp">1.3 Protection of Minors: If you are under the age of 18 (or if you are under the legal age of majority in the jurisdiction where you are using the TECNO Security Response Center platform), please read and fully understand the Agreement with your parent or legal guardian. By agreeing to and actually using the TECNO Security Response Center platform and services, you acknowledge that you have read the Agreement with your parent or legal guardian and that you and your parent or guardian understand and agree to the Agreement. If you are a parent or guardian who allows a minor to use TECNO Safety Response Center, you agree to: (i) supervise the use of TECNO Security Response Center by the minor; (ii) assume all risks associated with the minor's use of the TECNO Secure Response Center, including, but not limited to, the risk of transmission of content to and from third parties via the Internet; (iii) assume any liability arising from the use of TECNO Security Response Center by the minor; (iv) ensure the accuracy and authenticity of all the information submitted by the minor; (v) assume the responsibilities under the Agreement for the use of TECNO Security Response Center by the minor and be bound by the Agreement.</p>
            <p className="Agreementp">1.4 Agreement Update: In order to improve your experience or enhance the safety performance of our products, we will continuously strive to develop new software and services and provide you with software updates (these updates include, but are not limited to, software version upgrades, modifications, functional improvements, and other forms) or fixes in a timely manner, and we have the right to modify the Agreement when necessary in compliance with applicable laws and regulations. The updated terms shall replace the original Agreement and shall become effective upon the expiration of the period prescribed by applicable laws or, if not specifically provided for by applicable laws, this Agreement shall become effective as of the date of the update. The updated terms of the Agreement will be posted on the TECNO Security Response Center software and services, which you can review at any time. If you continue to use TECNO Security Response Center software and services after the Agreement has been updated, you are deemed to have accepted the updated agreement, and if you do not accept the updated agreement, you have the right to stop using TECNO Security Response Center products and services. </p>
            <h3 className="Agreementh4">II.Services of TECNO Security Response Center</h3>
            <p className="Agreementp">2.1 The services provided by TECNO Security Response Center include but are not limited to:</p>
            <p className="Agreementp1">(1) Sign up and Sign in; </p>
            <p className="Agreementp1">(2) Application for test prototypes and submission of product security vulnerabilities; </p>
            <p className="Agreementp1">(3) Collection of vulnerability feedback rewards according to the platform rules</p>
            <p className="Agreementp">Please understand and be aware that our services may be adjusted and updated from time to time based on your needs or our operational needs, and please refer to the service features that we display in real time on the Software webpage or other service interfaces (if any). </p>
            <p className="Agreementp">2.2 You understand and agree that during your use of the Software and the Services, we may send you relevant information, advertising, or brand promotions, and we will display commercial advertisements, promotions, or information (commercial or non-commercial) of the Software and the Services and/or those from our third-party suppliers and partners in the Software and the Services. If you do not want to receive personalized push advertising, you can deactivate the function, but you may still receive non-personalized advertising during the use of our product. If you receive advertisements by email or SMS, you have the right to request to unsubscribe.</p>
            <p className="Agreementp">2.3 You understand and agree that we may suspend, discontinue, or stop providing the Software and the Services due to our business development needs or in accordance with the requirements of judicial, regulatory, and supervisory authorities.</p>
            <p className="Agreementp">2.4 Unless permitted by applicable law and as authorized by us in writing, you are not allowed to use or otherwise export or re-export the Software and the Services.</p>
            
            
            <h3 className="Agreementh4">III.Restrictions on Use</h3>
            <p className="Agreementp">3.1 During the use of the Software and the Services, you shall not (including but not limited to):</p>
            <p className="Agreementp1">(1) publish, transmit, disseminate, and store contents that violate law and regulations, policies, social public order and good customs, and social morality, such as insults, libels, violence, and violations of religion; or publish, transmit, disseminate, and store false information and advertising that mislead, deceive, and negatively affect others.</p>
            <p className="Agreementp1">(2) lease, lend, copy, modify, link, repost, compile, publish, release the Software or conduct any similar acts.</p>
            <p className="Agreementp1">(3) carry out jailbreaking, reverse engineering, reverse assembly, reverse compilation on the Software, or any other attempts to damage the Software; or use plug-ins, add-ons, or third-party tools/services not approved by us to access or interfere with the Software.</p>
            <p className="Agreementp1">(4) copy, modify, add, delete, or mount on the data released into any device memory from the Software/the Services or during the operation of the Software, the interactive data between the client and the server during the operation of the Software, and the system data necessary for the operation of the Software, or create any derivatives of the Software; modify or forge the instructions and data in the operation of the Software; add, delete, or change the functions or operation effects of the Software; or operate or disseminate the Software and methods employed for the aforementioned purposes to the public, regardless of whether such acts are commerce-oriented.</p>
            <p className="Agreementp1">(5) engage in other acts that violate law, regulations, policies, public order and good customs, or social morality, or that are in the absence of our written permission.</p>
            <p className="Agreementp">3.2 You shall not, and agree not to motivate others to conduct any duplication (other than as expressly permitted by the License), reverse compilation, reverse engineering, disassembly, attempt to export the source code, decoding, and modification pertaining to the TECNO Security Response Center software, the Services, or any part thereof (except those conducted as per applicable law or the terms of the license of an open source component that may be included in the TECNO Security Response Center software) or create any other derivative works. You agree not to delete, block, or alter any proprietary notification (including but not limited to trademarks and copyright notices) that may be attached to or included in the TECNO Security Response Center software.</p>
            <h3 className="Agreementh4">IV.Intellectual Property Rights and Ownership</h3>
            <p className="Agreementp">We are entitled to the intellectual property rights, ownership rights and all rights not expressly granted to you, including but not limited to copyrights, trademarks and patents, in the Platform and all information content (including but not limited to UI design, images, fonts, audio, etc.), and are protected by applicable intellectual property, anti-unfair competition and other laws and regulations, as well as relevant international treaties. If you sell, copy, or license the Software to any third party for commercial use without the express authorization of the Agreement, you shall obtain our written permission separately. </p>
            <h3 className="Agreementh4">V.Confidentiality</h3>
            <p className="Agreementp">5.1 You acknowledge and agree that you shall not reveal to the public or use the information disclosed to you (hereinafter referred to as Confidential Information) by the TECNO Security Response Center platform in any way for any other purposes without the consent of TECNO Security Response Center.</p>
            <p className="Agreementp">5.2 We will communicate with you about the vulnerability after you submit it. Except as otherwise required by law, to ensure the security of end users of the product, you undertake not to provide or disclose details of the vulnerability to the public until the security breach has been fully fixed. If, under special circumstances, it is necessary to provide and disclose details of vulnerabilities to the public for reasons of user safety and social interest, the TECNO Security Response Center platform should decide whether to disclose and how to do so.</p>
            <p className="Agreementp">5.3 After the vulnerability has been fixed, if you need to disclose the security vulnerability for personal reasons, the TECNO Security Response Center platform should review the content of the disclosure.</p>
            <p className="Agreementp">5.4 You acknowledge and agree that you will file a vulnerability report in good faith and in a responsible manner, and shall not make use of the vulnerability report to gain benefits other than the vulnerability feedback rewards and contributors list points.</p>
            <p className="Agreementp">5.5 If you obtain or access product end-user data in the course of testing for security vulnerabilities, you may not modify, delete, transfer or make available such data to the public in any way or collect or process such data without authorization.</p>
            <p className="Agreementp">5.6 You acknowledge and agree that you are under an indefinite obligation of confidentiality until the TECNO Security Response Center platform declares the declassification or the actual disclosure of the confidential information.</p>
            <p className="Agreementp">5.7 You acknowledge and agree that the User Center of the TECNO Security Response Center platform has the right to request the deletion, destruction, or return of your confidential information and to request proof or a written undertaking that you have deleted or destroyed the confidential information.</p>
            <p className="Agreementp">5.8 You acknowledge and agree that the rights and obligations agreed to in the confidentiality provisions of Article V of the Agreement shall continue in effect and shall not be terminated or discharged for any reason (including, but not limited to, termination, rescission, etc.) unless otherwise agreed.</p>
            <p className="Agreementp">5.9 You acknowledge and agree that you shall indemnify TECNO Security Response Center Platform for any financial loss in the event that you breach the confidentiality obligations of the Agreement.</p>
            <h3 className="Agreementh4">VI.Precautions for Use</h3>
            <p className="Agreementp">6.1 You fully understand and agree that relevant permissions and interfaces of your device may be required by a specific service of the Software, and some services may even request your consent to an additional user agreement. Please read the aforementioned agreement and relevant rules carefully before using the services.</p>
            <p className="Agreementp">6.2 We reserve the right to display various information to you in the course of providing the Services, including, but not limited to, authorization requests, display of advertising information, and promotional information, which may appear in the form of system messages or pop-up windows, etc. If you do not agree to receive such messages, you have the right to cancel the services involved, which may render relevant services or functions unavailable.</p>
            <p className="Agreementp">6.3 You understand and agree that we will use commercially reasonable efforts to safeguard the security of your data storage during your use of the Services, but that we cannot provide complete warranties in this regard, as in the following cases:</p>
            <p className="Agreementp1">(1) To the maximum extent permitted by law, we will not take responsibility for deleting or failing to store data in the Software and the Services.</p>
            <p className="Agreementp1">(2) We will store the information required to conduct business according to the minimum storage period required, unless you agree to extend the retention period or are permitted by law, and we also have the right to determine the maximum storage space for your data in the Software, etc. You may back up the relevant data in the Software and the Services as you see fit.</p>
            <p className="Agreementp">6.4 When you use the Services, the specific content displayed may differ depending on the version of the software you are using. You understand and agree that, when you use certain versions of the Software or enter the application-related pages developed by us, in order to provide you with a better service experience, the installation location, size, UI interface and other necessary information of the installed applications on your terminal device may be adjusted, depending on the version we actually provide. </p>
            <p className="Agreementp">6.5 You fully understand and agree that if you need to create an account in order to use the Software, you are responsible for keeping your account information and account password secure and that you are responsible for actions that occur under your account, including any content posted by you and any consequences arising therefrom. </p>
            <p className="Agreementp">6.6 You shall exercise your own judgment regarding the content to which you are exposed when using the Services, and you assume all risks arising from the use of the content, including, but not limited to, risks arising from reliance on the accuracy, completeness, or usefulness of the content. We cannot and will not be liable to you for any loss or damage arising from the foregoing risks. </p>
            <p className="Agreementp">6.7 If we find or receive reports from others that you have violated the Agreement, we have the right at any time without notice to: delete or block the relevant content; suspend or terminate your use of the corresponding account or all services; pursue legal responsibility, among other measures. You shall be liable for any damages incurred by any third party arising from your breach of the Agreement. You shall also compensate us for any damages (including, but not limited to, possible administrative penalties, attorneys' fees, and investigation and evidence costs) that we may suffer as a result. </p>
            <h3 className="Agreementh4">VII.Services From Third Parties</h3>
            <p className="Agreementp">7.1 In order to facilitate your use of the TECNO Security Response Center software and services, the Software or the Services may use third-party services and the results of the use or access are provided by such third parties (including but not limited to services and content provided by third parties that you are redirected to through the Software or the Services, services and content accessed by third parties through the TECNO Security Response Center open platform, etc.). When you use the services provided by a third party in the Software, in addition to complying with the Agreement, you shall also abide by the user agreement of the third party. We cannot guarantee the security, accuracy, effectiveness, or other uncertainties of the services and contents provided by the third party. To the maximum extent permitted by applicable laws, you shall bear the risks and responsibilities involved.</p>
            <p className="Agreementp">7.2 Whether the third-party services are pre-built in the TECNO Security Response Center platform or are opened or subscribed by you, you understand and agree that we cannot provide any express or implied warranty for the security, accuracy, effectiveness, and other uncertainties of the services and content provided by third parties. </p>
            <p className="Agreementp">7.3 Any dispute between you and the above-mentioned third parties that provide software and services shall be settled by you and the third parties, and the relevant responsibilities shall be borne by you.  </p>
            <h3 className="Agreementh4">VIII.Privacy Policy</h3>
            <p className="Agreementp">We may need to collect your personal data for you to use TECNO Security Response Center and the Services. We take your personal data seriously. We have developed the TECNO Security Response Center Privacy Policy (refer to Privacy Policy for details), which sets out detailed information on how TECNO Security Response Center collects, uses, stores, transfers, and discloses your personal data. Also, you may go to <strong>TECNO Security Response Center</strong> &gt; the bottom of the official website &gt; <strong>Privacy Policy</strong> to see the details. The Privacy Policy is part of the Agreement. By downloading, installing, or using TECNO Security Response Center and the Services, you agree to be bound by the terms of the Agreement and the Privacy Policy, and we will protect your privacy in accordance with the Privacy Policy.</p>
            <h3 className="Agreementh4">IX.Termination and Liability for Breach of the Agreement</h3>
            <p className="Agreementp">We have the right to judge whether your behavior complies with the terms of the Agreement. If you are found to have violated the relevant laws and regulations or any of the terms and conditions of the Agreement and related rules, depending on the severity of your violation, TECNO Security Response Center and its licensors have the right to remove the offending information, restrict, suspend or terminate the use of the Software and Services without notice. In addition, you will be liable for your wrongdoings. After the Agreement terminates, we will retain all sorts of information that you keep in the TECNO Security Response Center, and delete the information generated during your use of the Software from our database within a reasonable period of time in accordance with the requirements of applicable laws and regulations. You agree that, to the fullest extent permitted by applicable laws, we shall not be liable for the removal of such information.</p>
            
            <h3 className="Agreementh4">X.Indemnity</h3>
            <p className="Agreementp">You agree to indemnify, defend, and hold harmless us, our licensors, our agents, and all our officers, directors, and employees against and from any and all third-party claims, lawsuits, losses, damages, liabilities, judgments, costs, and expenses (including reasonable attorney fees) arising from: (i) any failure by you or anyone you permit to use the Services to follow the provisions of the Agreement during use; (ii) any violation by you or anyone you permit to use the Services of the provisions of the Agreement; or (iii) any violation by you or anyone you permit to use the Services of laws, regulations or the rights of any third party.</p>
            <h3 className="Agreementh4">XI.Disclaimer</h3>
            <p className="Agreementp"><strong>11.1 To the fullest extent permitted by applicable laws, the Software and the Services are offered on an "as is" and "as available" basis, and we hereby expressly disclaim any guarantees or other undertakings, whether express, implied, or statutory, including (but not limited to) implied guarantees, liabilities, or conditions (if any) of merchantability, fitness for a particular purpose, accuracy or completeness of response, results, exquisite achievements, freedom from viruses and negligence with respect to the Software and the Services.</strong></p>
            <p className="Agreementp"><strong>11.2 The Software does not contain any malicious code aimed at destroying your device data or accessing your private information. We will ensure the safe, timely, effective, accurate, reliable, and continuous operation of the Software and the Services to the best of our ability. Nevertheless, due to the limitation of existing technology, the Software, and the Services may be affected or interfered with by various unstable factors. We cannot guarantee that the Software and the Services are perfectly compatible with all software and hardware or completely free of any errors or interference, or that any errors thereof can be corrected. In the event of incompatibility and software errors, please contact us for technical support. If the compatibility issue cannot be resolved, you may delete the Software. We will be exempted from liability for any loss caused to you to the maximum extent permitted by applicable laws.</strong></p>
            <h3 className="Agreementh4">XII.Limitation of Liability</h3>
            <p className="Agreementp"><strong>To the extent not prohibited by applicable laws, in no event shall we and our affiliates, officers, directors, employees, and agents be liable to you for any indirect, incidental, special, consequential, or punitive damages (even if we are aware of the possibility of such damages) due to the reasons below, including but not limited to loss of profits, damage to goodwill, loss of use, loss of data, loss of business interruption, or any other intangible loss, regardless of the cause of the formation and the theory of liability (in contract, tort, or others). The reasons include: (1) your use of or inability to use the Services; (2) unauthorized access to or alteration of content or data transmitted by you; (3) deletion, corruption, or inability to use of content or data transmitted by you through the Services; (4) acts done by any third party in connection with the Services; and (5) any other matter relating to the Services. This limitation may not extend to you if you are in a country or region that does not allow exemption of liability for personal injury and incidental or consequential damages, nor permit limitation on additional liabilities.</strong></p>
            <h3 className="Agreementh4">XIII.Governing Law and Dispute Resolution</h3>
            <p className="Agreementp">13.1 Governing Law: The confirmation, performance, interpretation, modification, and dispute resolution of the Agreement shall be governed by the laws of the Hong Kong Special Administrative Region of the People's Republic of China without regard to its conflicts of laws provisions. You agree that the Agreement is signed in Hong Kong.</p>
            <p className="Agreementp">13.2 Arbitration: Any dispute or controversy between you and us in relation to the Agreement shall be first settled through friendly negotiations. If such negotiations fail, you unconditionally agree to submit any dispute or controversy (whether in contract, tort, or other disputes and controversies) arising from or related to us, our directors, agents, and employees to the Hong Kong International Arbitration Centre (HKIAC) for arbitration in accordance with the applicable rules of HKIAC. The arbitration shall be conducted by one (1) arbitrator. The arbitration shall be conducted in Hong Kong, China, the arbitration proceedings and all pleadings and documentary evidence shall be in English, and the award rendered by the arbitrator shall be enforceable in any court of competent jurisdiction. Any arbitration under the Agreement will be conducted on an individual basis and you shall not have the right to initiate any arbitration on a collective or representative basis, and you will not bring or participate in any class action against us.</p>
            <h3 className="Agreementh4">XIV.General Provisions</h3>
            <p className="Agreementp">14.1 Headings: The headings of all the terms of the Agreement are for reference only and have no actual meanings and cannot be regarded as a basis for interpreting the Agreement.</p>
            <p className="Agreementp">14.2 Severability: If a term or provision of the Agreement is held by any judicial or administrative organ to be partially invalid or unenforceable for any reason, such determination shall not affect the remaining terms and provisions of the Agreement, which shall remain valid and be interpreted accordingly.</p>
            <p className="Agreementp">14.3 Language: If this Agreement is available in more than one language, such as English and Arabic, the English version shall prevail for any inconsistency.</p>
            <p className="Agreementp">14.4 Non-Waiver: In any event, our failure to enforce any provision related to services of the TECNO Security Response Center, or our failure to require you to fulfill any provision related to services of the TECNO Security Response Center, shall in no way be construed as a current or future waiver of such provision, nor shall it in any way affect our right to enforce such provision thereafter. Our express waiver of any provision, condition, or requirement related to services of TECNO Security Response Center does not constitute a waiver of fulfilling any future obligation under such provision, condition, or requirement.</p>
            <p className="Agreementp">14.5 Entire Agreement: The Agreement constitutes the entire agreement between you and us regarding the use of the Software and supersedes any prior agreement between you and TECNO Security Response Center regarding the use of the Software. You may also be subject to other terms and conditions when you use or purchase open-source software, third-party content or other services.</p>

        </div>
    );
})