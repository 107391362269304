import React, {useEffect, useState} from 'react';
import './style/messageCenter.css';
import {useIntl} from 'react-intl';
import {scrollToTop} from '../../common/topScorll';
import {
	getMessageCenterList,
	changeMessageCenterType,
	changeMessageCenterReadType,
	changePagination,
	changePaginationSize
} from '../../action/messageCenter/messageCenterAction';
import {Radio, Pagination} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import store from '../../store/index';
import MessageCenterService from '../../services/messageCenterService';
import {goRouterPosition} from '../../action/routerposition/routerpositionAction';
import messageCheckUnread from '../../assets/svg/ic-消息中心-审核结果-未读.svg';
import messageCheckRead from '../../assets/svg/ic-消息中心-审核结果-已读.svg';
import messageSystemUnread from '../../assets/svg/ic-消息中心-系统消息-未读.svg';
import messageSystemRead from '../../assets/svg/ic-消息中心-系统消息-已读.svg';
import icNoData from '../../assets/svg/ic-空.svg';
import orderUnReadIcon from '../../assets/svg/订单消息通知-未读.svg';
import orderReadIcon from '../../assets/svg/订单消息通知-已读.svg';

export default (function (props) {
	const intl = useIntl();
	const history = useHistory();
	const [state, setState] = useState(store.getState().MessageCenter);
	useEffect(() => {
		(sessionStorage.getItem('lang') == 'zh_CN' &&
			(document.title = '消息中心')) ||
			(sessionStorage.getItem('lang') == 'en_US' &&
				(document.title = 'Message Center'));
		scrollToTop();
		// sessionStorage.setItem('redirect', window.location.pathname);
    sessionStorage.setItem('redirect',`${window.location.pathname}${window.location.search}`);
		goRouterPosition({routerPosition: 'messageCenter'});
		store.subscribe(storeChange);
		getMessageCenterList(1, 10);
	}, []);
	const storeChange = () => {
		setState(store.getState().MessageCenter);
	};
	const readBlog = (id) => {
		MessageCenterService.readBlogData({id: id})
			.then((res) => {
				console.log(res);
				getMessageCenterList(1, 10);
			})
			.catch((error) => {
				console.log(error);
			});
	};
  
	const goShowDetail = (data) => {
		if (data.notice.type == 'system') {
			history.push(
				`/SRC/blogdetail/${data.notice.thirdId}?lang=${data.notice.languageCode}`
			);
			readBlog(data.id);
		} else if (data.notice.type == 'manual_reward') {
			history.push(`/SRC/personalInfo`);
			readBlog(data.id);
		} else if (data.notice.type == 'grant_safety_medal') {
			history.push(`/SRC/personalInfo`);
			sessionStorage.setItem('personalInfoType', 'achievement');
			readBlog(data.id);
		} else if(data.notice.type == 'product_order'){
      let result = data.notice.i18n.title.includes("银行卡") || data.notice.i18n.title.includes("bank")
      if(result){
        window.open('/SRC/payoutMethod#bankCard')
      }else{
     window.open(`/SRC/myRedemption?search=${data.notice.orderNumber}`)}
    }
     else {
			history.push(
				`/SRC/bugSubmitdetail/${data.notice.thirdId}?lang=${data.notice.languageCode}`
			);
			readBlog(data.id);
		}
	};
	const readAllBlog = () => {
		const type = state.messageCenterType;
		if (type == 'All') {
			MessageCenterService.readAllBlogData({})
				.then((res) => {
					console.log(res);
					getMessageCenterList(1, 10);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			MessageCenterService.readAllBlogData({type: type})
				.then((res) => {
					console.log(res);
					getMessageCenterList(1, 10);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	return (
		<div className='messageCenterContainer'>
			<div className='messageCenterTitleDiv'>
				<p className='messageCenterTitle'>
					{intl.formatMessage({
						id: 'intl.messagecenter.title'
					})}
				</p>

				{/* <div className="messageCenterTitleTypeRadio">
                    <Radio.Group value={state.messageCenterType} onChange={changeMessageCenterType}>
                        <Radio.Button value="All">All ({messageCenterTypeAll})</Radio.Button>
                        <Radio.Button value="System Notifications">System Notifications ({messageCenterTypeSystemNotifications})</Radio.Button>
                        <Radio.Button value="Review Results">Review Results ({messageCenterTypeReviewResults})</Radio.Button>
                    </Radio.Group>
                    <Link className="messageCenterMarkAll">Mark all as Read</Link>
                </div>
                <div className="messageCenterTitleReadDiv">
                    <label className={ (state.messageCenterReadType == "All") ? "messageCenterTitleReadLabelCheck" : "messageCenterTitleReadLabelUncheck" } onClick={()=>changeMessageCenterReadType("All")}>All <label className="messageCenterTitleReadLabelNum">{messageCenterReadTypeAll}</label></label>
                    <label className={ (state.messageCenterReadType == "Unread") ? "messageCenterTitleReadLabelCheck" : "messageCenterTitleReadLabelUncheck" } onClick={()=>changeMessageCenterReadType("Unread")}>Unread <label className="messageCenterTitleReadLabelNum">{messageCenterReadTypeUnread}</label></label>
                    <label className={ (state.messageCenterReadType == "Read") ? "messageCenterTitleReadLabelCheck" : "messageCenterTitleReadLabelUncheck" } onClick={()=>changeMessageCenterReadType("Read")}>Read <label className="messageCenterTitleReadLabelNum">{messageCenterReadTypeRead}</label></label>
                </div> */}
			</div>
			<div className='messageCenterContent'>
				<div className='messageCenterTitleReadDiv'>
					<Radio.Group
						value={state.messageCenterType}
						onChange={changeMessageCenterType}>
						<Radio.Button value='All'>
							{intl.formatMessage({
								id: 'intl.messagecenter.type.all'
							})}
							({state.messageCenterTypeAll})
						</Radio.Button>
						<Radio.Button value='system'>
							{intl.formatMessage({
								id: 'intl.messagecenter.type.systemnotifications'
							})}
							({state.messageCenterTypeSystemNotifications})
						</Radio.Button>
						<Radio.Button value='leak_check'>
							{intl.formatMessage({
								id: 'intl.messagecenter.type.reviewresults'
							})}
							({state.messageCenterTypeReviewResults})
						</Radio.Button>
            <Radio.Button value='product_order'>
							{intl.formatMessage({
								id: 'intl.messagecenter.type.orderRelated'
							})}
							({state.messageCenterTypeProductOrder})
						</Radio.Button>
					</Radio.Group>
				</div>
				<div className='messageCenterTitleTypeDiv'>
					<label
						className={
							state.messageCenterReadType == 'All'
								? 'messageCenterTitleReadLabelCheck'
								: 'messageCenterTitleReadLabelUncheck'
						}
						onClick={() => changeMessageCenterReadType('All')}>
						{intl.formatMessage({
							id: 'intl.messagecenter.read.all'
						})}
						<label className='messageCenterTitleReadLabelNum'>
							{state.messageCenterReadTypeAll}
						</label>
					</label>
					<label
						className={
							state.messageCenterReadType == 'Unread'
								? 'messageCenterTitleReadLabelCheck'
								: 'messageCenterTitleReadLabelUncheck'
						}
						onClick={() => changeMessageCenterReadType('Unread')}>
						{intl.formatMessage({
							id: 'intl.messagecenter.read.no'
						})}
						<label className='messageCenterTitleReadLabelNum'>
							{state.messageCenterReadTypeUnread}
						</label>
					</label>
					<label
						className={
							state.messageCenterReadType == 'Read'
								? 'messageCenterTitleReadLabelCheck'
								: 'messageCenterTitleReadLabelUncheck'
						}
						onClick={() => changeMessageCenterReadType('Read')}>
						{intl.formatMessage({
							id: 'intl.messagecenter.read.yes'
						})}
						<label className='messageCenterTitleReadLabelNum'>
							{state.messageCenterReadTypeRead}
						</label>
					</label>
					<Link
						className='messageCenterMarkAll'
						onClick={() => readAllBlog()}>
						{intl.formatMessage({
							id: 'intl.messagecenter.button.readall'
						})}
					</Link>
				</div>
				{/* <div className="messageCenterTitleReadDiv">
                    <label className={ (state.messageCenterType == "All") ? "messageCenterTitleReadLabelCheck" : "messageCenterTitleReadLabelUncheck" } onClick={()=>changeMessageCenterType("All")}>
                        {
                            intl.formatMessage({
                                id: 'intl.messagecenter.type.all'
                            })
                        }
                        <label className="messageCenterTitleReadLabelNum">{state.messageCenterTypeAll}</label>
                    </label>
                    <label className={ (state.messageCenterType == "system") ? "messageCenterTitleReadLabelCheck" : "messageCenterTitleReadLabelUncheck" } onClick={()=>changeMessageCenterType("system")}>
                        {
                            intl.formatMessage({
                                id: 'intl.messagecenter.type.systemnotifications'
                            })
                        }
                        <label className="messageCenterTitleReadLabelNum">{state.messageCenterTypeSystemNotifications}</label>
                    </label>
                    <label className={ (state.messageCenterType == "leak_check") ? "messageCenterTitleReadLabelCheck" : "messageCenterTitleReadLabelUncheck" } onClick={()=>changeMessageCenterType("leak_check")}>
                        {
                            intl.formatMessage({
                                id: 'intl.messagecenter.type.reviewresults'
                            })
                        }
                        <label className="messageCenterTitleReadLabelNum">{state.messageCenterTypeReviewResults}</label>
                    </label>
                </div>
                <div className="messageCenterTitleTypeRadio">
                    <Radio.Group value={state.messageCenterReadType} onChange={changeMessageCenterReadType}>
                        <Radio.Button value="All">
                            {
                                intl.formatMessage({
                                    id: 'intl.messagecenter.read.all'
                                })
                            }
                            ({state.messageCenterReadTypeAll})</Radio.Button>
                        <Radio.Button value="Unread">
                            {
                                intl.formatMessage({
                                    id: 'intl.messagecenter.read.no'
                                })
                            }
                            ({state.messageCenterReadTypeUnread})</Radio.Button>
                        <Radio.Button value="Read">
                            {
                                intl.formatMessage({
                                    id: 'intl.messagecenter.read.yes'
                                })
                            }
                            ({state.messageCenterReadTypeRead})</Radio.Button>
                    </Radio.Group>
                    <Link className="messageCenterMarkAll" onClick={()=>readAllBlog()}>
                        {
                            intl.formatMessage({
                                id: 'intl.messagecenter.button.readall'
                            })
                        }
                    </Link>
                </div> */}
				<div className='messageCenterContentList'>
					{(state.totalPagination == 0 && (
						<div className='noDataDiv'>
							<img className='noDataImg' src={icNoData} />
							<p className='noDataLabel'>
								{intl.formatMessage({
									id: 'intl.nodata.label'
								})}
							</p>
						</div>
					)) || (
						<ul className='messageCenterContentListul'>
							{state.messageCenterList.map((data, index) => (
								<li
									key={index}
									className='messageCenterContentListli'>
									<div className='messageCenterContentListIconDiv'>
										{(data.isRead &&(data.notice.type == 'leak_check' || data.notice.type =='manual_reward') && (
											  <img
											  	src={messageCheckRead} 
                          alt=''
											  	className='messageCenterContentListIcon'
											  />)) ||
											(!data.isRead &&(data.notice.type =='leak_check' ||data.notice.type =='manual_reward') && (
													<img
                            alt=''
														src={messageCheckUnread}
														className='messageCenterContentListIcon'
													/>)) ||
											(data.isRead &&(data.notice.type == 'system' ||data.notice.type =='grant_safety_medal') && (
													<img
                            alt=''
														src={messageSystemRead}
														className='messageCenterContentListIcon'
													/>)) ||
											(!data.isRead &&(data.notice.type == 'system' ||data.notice.type =='grant_safety_medal') && (
													<img
                            alt=''
														src={messageSystemUnread}
														className='messageCenterContentListIcon'
													/>)) ||
                          // 新增
                      (data.isRead &&(data.notice.type == 'product_order') && (
                          <img
                           alt=''
                           src={orderReadIcon}
                           className='messageCenterContentListIcon'
                          />))||
                      (!data.isRead&&(data.notice.type=='product_order') &&(
                        <img
                        alt=''
                        src={orderUnReadIcon}
                        className='messageCenterContentListIcon'
                        />
                      ))    
                    }
									</div>
									<div className='messageCenterContentListlititlediv'>
										<p
											className='messageCenterContentListlititle'
											onClick={() => goShowDetail(data)}>
											{data.notice.i18n.title}
										</p>
										<p className='messageCenterContentListlicontent'>
											{data.notice.i18n.content}
										</p>
										<p className='messageCenterContentListliTime'>
											{data.notice.creatTime}
										</p>
									</div>
									<div className='messageCenterContentListliBtndiv'>
										{!data.isRead && (
											<Link
												onClick={() =>
													readBlog(data.id)
												}
												className='messageCenterContentListlimark'>
												{intl.formatMessage({
													id: 'intl.messagecenter.button.read'
												})}
											</Link>
										)}
										{/* {
                                            (data.notice.type == "system") && <Link className="messageCenterContentListliviewdetail" to={`/SRC/blogdetail/${data.notice.thirdId}?lang=${data.notice.languageCode}`} onClick={()=>readBlog(data.id)}>
                                                {
                                                    intl.formatMessage({
                                                        id: 'intl.messagecenter.button.viewdetails'
                                                    })
                                                }
                                            </Link> || <Link className="messageCenterContentListliviewdetail" to={`/SRC/bugSubmitdetail/${data.notice.thirdId}`} onClick={()=>readBlog(data.id)}>
                                                {
                                                    intl.formatMessage({
                                                        id: 'intl.messagecenter.button.viewdetails'
                                                    })
                                                }
                                            </Link>
                                        } */}
										{/* <label className="messageCenterContentListliviewdetail">view details</label> */}
									</div>
								</li>
							))}
						</ul>
					)}
					{state.messageCenterList.length != 0 && (
						<div className='messageCenterContentListPaginationdiv'>
							<label className='messageCenterContentListTotal'>
								{intl.formatMessage({
									id: 'intl.messagecenter.pagination.total'
								})}{' '}
								{state.totalPagination}{' '}
								{intl.formatMessage({
									id: 'intl.messagecenter.pagination.totalnum'
								})}
							</label>
							<Pagination
								className='messageCenterContentListPagination'
								showSizeChanger
								showQuickJumper
								key={state.currentPagination}
								current={state.currentPagination}
								defaultPageSize={state.pageSizePagination}
								total={state.totalPagination}
								onChange={changePagination}
								onShowSizeChange={changePaginationSize}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
});
