import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Menu, Button, Dropdown, Popover} from 'antd';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import './style/headerDashboard.css';
import store from '../../store/index';
// import logo from '../../assets/svg/logo_white.svg'
import logoEN from '../../assets/svg/en_logo_white.svg';
import logoCN from '../../assets/svg/cn_logo_white.png';
import avstar from '../../assets/svg/ic-头像.svg';
import {goLang} from '../../action/lang/langAction';
import icChangeLanguage from '../../assets/svg/ic-语言切换.svg';
import icChangeLanguageHover from '../../assets/svg/ic-语言切换hover.svg';
import icChangeLanguageLogin from '../../assets/svg/icon_loginLanguage.svg';
import menupersonal from '../../assets/svg/ic-个人中心.svg';
import menumybug from '../../assets/svg/ic-我的漏洞.svg';
import menuexit from '../../assets/svg/ic-退出.svg';
import avstarhover from '../../assets/svg/ic-头像-hover.svg';
import icmessagenew from '../../assets/svg/ic-有新消息.svg';
import icmessagenewhover from '../../assets/svg/ic-有新消息-hover.svg';
import icmessageno from '../../assets/svg/ic-无新消息.svg';
import icmessagenohover from '../../assets/svg/ic-无新消息-hover.svg';
import MessageCenterService from '../../services/messageCenterService';
import LoginService from '../../services/loginService';
import icbuglist from '../../assets/svg/ic-漏洞列表.svg';
import avatar from '../../assets/svg/ic-个人中心-头像.svg';
import Right from '../../assets/svg/Right.svg';
import Solo from '../../assets/svg/solo.svg';
import achievementIcon from '../../assets/svg/icon_achievement.svg';
import personalInfoIcon from '../../assets/svg/icon_personalInfo.svg';
import payoutMethodIcon from '../../assets/svg/icon_payoutMethod.svg';
import { set } from 'lodash';
import PersonalInfoService from '../../services/personalInfoService';
import JSEncrypt from 'jsencrypt';
import SwagshopService from '../../services/swagshopService';
import RewardsDetailsIcon from '../../assets/svg/icon_rewardsDetails.svg';
function HeaderDashboard() {
  // const [creditsData , setCreditsData] = useState([]);
	const [state, setState] = useState(store.getState().RouterPosition);
	const [messageCount, setMessageCount] = useState(0);
	const [icAvstarImg, setIcAvstarImg] = useState(avstar);
	const [icMessageNewImg, setIcMessageNewImg] = useState(icmessagenew);
	const [icMessageNoImg, setIcMessageNoImg] = useState(icmessageno);
	const [changeLanguageimg, setChangeLanguageimg] = useState(
		icChangeLanguageHover
	);
  const [changeLanguage, setChangeLanguage] = useState('')
  const [personalInfo, setPersonalInfo] = useState('');
	const [personalStatistics, setPersonalStatistics] = useState();
	const changeLang = (value) => {
		goLang({lang: value});
		if (sessionStorage.getItem('routerPosition') == 'blogdetail') {
			history.push('/SRC/blog');
			window.location.reload();
		} else {
			window.location.href = removeLang(window.location.href)
		}
	};

	const contentLangGlobal = (
		<div>
			<li className='LangGlobalLi' onClick={() => changeLang('en_US')}>
				English
			</li>
			<li className='LangGlobalLi' onClick={() => changeLang('zh_CN')}>
				中文
			</li>
		</div>
	);
  // const getCreditsData=()=>{
  //   SwagshopService.rewardStatistics({ 
  //     search:undefined,
  //     createTimeStart:undefined,
  //     createTimeEnd:undefined,
  //     year:undefined,
  //     month:undefined,
  //     type:undefined,
  //     dataType:undefined
  //   }).then(res=>{
  //     if(res.code==200){
  //       setCreditsData(res.data?.list[0])}
  //   })
  // }
  const token=sessionStorage.getItem('token')
  useEffect(()=>{
    PersonalInfoService.getPersonalInfo()
    .then((res) => {
      setPersonalInfo(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
  PersonalInfoService.getStatistics({ personalHome: true }).then((res) => {
    setPersonalStatistics(res.data);
  });
  },[token])
	useEffect(() => {

		sessionStorage.getItem('token') != undefined &&
			MessageCenterService.getMessageCenterListData({
				current: 1,
				pageSize: 10,
				isRead: false
			})
				.then((res) => {
					setMessageCount(res.data.total);
				})
				.catch((error) => {
					console.log(error);
				});
		store.subscribe(storeChange);
	}, []);

	const storeChange = () => {
		setState(store.getState().RouterPosition);
	};
	const intl = useIntl();
	const history = useHistory();
	const goMessageCenter = () => {
		history.push('/SRC/messageCenter');
	};
	const exitLogin = () => {
		LoginService.goLogout()
			.then((res) => {
				sessionStorage.removeItem('routerPosition');
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('loginImg');
				sessionStorage.removeItem('redirect');
				history.push('/SRC/login');
				window.location.reload();
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const gotoPersonalInfo = (type) => {
		sessionStorage.setItem('personalInfoType', type);
		history.push('/SRC/personalInfo');
	};
	const tobugSubmit = () => {
		if (sessionStorage.getItem('token') != undefined) {
			history.push('/SRC/bugSubmit');
		} else {
			history.push('/SRC/login');
		}
	};
	const toLogin = () => {
		history.push('/SRC/login');
	};
	const toSignUp = () => {
		history.push('/SRC/signUp');
	};
	const goUrl = (url) => {
		history.push(url);
	};
	const handleGoToBugList = () => {
		history.push('/SRC/bugSubmitList');
	};
  
  useEffect(() => {

    const storedLanguage = sessionStorage.getItem('changeLanguage');
    if (storedLanguage) {
      setChangeLanguage(storedLanguage);
    } else {
      setChangeLanguage('CN');
    }
    // getCreditsData()
  }, []);
const removeLang = (path) => {
	let url = path;
	const indextoken1 = url.indexOf('?lang');
	const indextoken2 = url.indexOf('&token');
	if (indextoken1 != -1) {
	  url = url.substring(0, indextoken1);
	}
	if (indextoken2 != -1) {
	  url = url.substring(0, indextoken2);
	}
	return url;
  }
  const getLanguage=()=>{
     if(changeLanguage=='EN'){
      changeLang('en_US');
      setChangeLanguage('CN');
      sessionStorage.setItem('changeLanguage', 'CN');
     } 
     if(changeLanguage=='CN'){
      changeLang('zh_CN');
      setChangeLanguage('EN');
      sessionStorage.setItem('changeLanguage', 'EN');
     }
     
  }

	const menu = (
		<Menu className='menuDiv'>
			{/**
			 * 
			<Menu.Item onClick={()=>goUrl("/SRC/bugSubmitList")}>
				<div className="menuicondiv">
					<img src={menumybug} className="menuIcon" />
					<Link to={`/SRC/bugSubmitList`} className="menuLinklabel">
						{
							intl.formatMessage({
								id: 'intl.header.menu.vulnerability'
							})
						}
					</Link>
				</div>
			</Menu.Item>
			 */}
       <Menu.Item key='personalInfo'>
				<div className='menuicondiv2'>
					<div className='menuiconTopDiv' onClick={() => gotoPersonalInfo('personalInfo')}>
          <div className='menuiconTopLeftDiv'>
						<img
							src={personalInfo?.pictureUrl ? personalInfo?.pictureUrl : avatar}
							className='menuiconTopImg'
              alt=''
						/>
						<div>
							<p className='alieName'>{personalInfo?.alienName}</p>
							<div className='email'>{personalInfo?.email ? personalInfo?.email : (
							<label>
								{intl.formatMessage({
									id: 'intl.personalinfo.type.loginsecurity.notset'
								})}
							</label>
						)}</div>
						</div>
            </div>
            <img  src={Right} className='RightIcon'   alt=''/>
					</div>
					<div className='menuiconbottomDiv'>
						<div className='menuiconbottomitemDiv'
            onClick={() =>  window.location.href = '/SRC/rewardsDetails?type=bonus'}
            >
							<p className='particularsTitle'>
								{intl.formatMessage({
									id: 'intl.personalinfo.content.statistics.bonusTitle',
								})}
							</p>
							<p style={{ color: 'rgb(146, 84, 222)' }} className='medalCount Bonus1'>
								{'$' + (personalStatistics?.bonus || 0)}
							</p>
						</div>
						<div className='menuiconbottomitemDiv totalContributionDiv'
            onClick={() =>  window.location.href = '/SRC/rewardsDetails?type=reputation'}
            >
							<p className='particularsTitle'>
								{intl.formatMessage({
									id: 'intl.personalinfo.content.statistics.reputationTitle',
								})}
							</p>
							<p style={{ color: '#34C759' }} className='medalCount Reputation1'>
              {personalStatistics?.baseHonor || 0}
							</p>
						</div>
            <div className='menuiconbottomitemDiv CreditDiv' 
             onClick={() => window.location.href = '/SRC/rewardsDetails?type=credit'}>
							<p className='particularsTitle'>
								{intl.formatMessage({
									id: 'intl.personalinfo.content.statistics.creditTitle',
								})}
							</p>
							<p className='medalCount CreditDiv1'style={{ color:' #ff529f',padding: '0 2px' }}>{personalStatistics?.credit || 0}</p>
						</div>
						<div className='menuiconbottomitemDiv' 
            onClick={() => window.location.href = '/SRC/achievements'}>
							<p className='particularsTitle'>
								{intl.formatMessage({
									id: 'intl.personalinfo.content.statistics.badgesTitle',
								})}
							</p>
							<p className='medalCount medalCount1'style={{ color:' rgb(250, 173, 20)',padding: '0 2px' }}>{personalStatistics?.medalCount || 0}</p>
						</div>
					</div>
				</div>
			</Menu.Item>
			{/* <Menu.Item
				onClick={() => gotoPersonalInfo('personalInfo')}
				key='personalInfo'>
				<div className='menuicondiv'>
					<img src={personalInfoIcon} className='menuIcon' />
					<div
						// onClick={() => gotoPersonalInfo('personalInfo')}
						className='menuLinklabel'>
						{intl.formatMessage({
							id: 'intl.header.quickEntranceMenu.header'
						})}
					</div>
				</div>
			</Menu.Item> */}
      
			<Menu.Item
				onClick={() => 
          //gotoPersonalInfo('achievement')
          window.location.href = '/SRC/achievements'
        }
				key='achievement'>
				<div className='menuicondiv'>
					<img src={achievementIcon} className='menuIcon' />
					<div
						// onClick={() => gotoPersonalInfo('achievement')}
						className='menuLinklabel'>
						{intl.formatMessage({
							id: 'intl.header.quickEntranceMenu.achievement'
						})}
					</div>
				</div>
			</Menu.Item>
      <Menu.Item
				onClick={() => 
          window.location.href = '/SRC/rewardsDetails'
        }
				key='rewardsDetails'>
				<div className='menuicondiv'>
					<img src={RewardsDetailsIcon} className='menuIcon'   alt=''/>
					<div
						onClick={() => 
              // gotoPersonalInfo('achievement')
              window.location.href = '/SRC/rewardsDetails'

            }
            
						className='menuLinklabel'
					>
						{intl.formatMessage({
							id: 'intl.header.quickEntranceMenu.rewardsDetails',
						})}
					</div>
				</div>
			</Menu.Item>
			{/* <Menu.Item onClick={() => goUrl('/SRC/personalInfo')}>
				<div className='menuicondiv'>
					<img src={menupersonal} className='menuIcon' />
					<Link to={`/SRC/personalInfo`} className='menuLinklabel'>
						{intl.formatMessage({
							id: 'intl.header.menu.personalinfo'
						})}
					</Link>
				</div>
			</Menu.Item> */}
			<Menu.Item onClick={() => exitLogin()}>
				<div className='menuicondiv'>
					<img src={menuexit} className='menuIcon' />
					<Link onClick={exitLogin} className='menuLinklabel'>
						{intl.formatMessage({
							id: 'intl.header.menu.exit'
						})}
					</Link>
				</div>
			</Menu.Item>
		</Menu>
	);
	return (
		<div className='headerDashboard'>
			<div className='headerDashboardContent'>
				<img
					className='headerLogo'
          alt=''
					src={
						sessionStorage.getItem('lang') == 'en_US'
							? logoEN
							: logoCN
					}
				/>
				<ul className='menu'>
					<li
						className={
							state.routerPositionInfo.routerPosition ==
							'dashboard'
								? 'headerDashboardmenuChoose'
								: ''
						}>
						<Link
							to='/dashboard'
							className='headerDashboardmenuLink'>
							{intl.formatMessage({
								id: 'intl.header.home'
							})}
						</Link>
					</li>
					<li
						className={
							state.routerPositionInfo.routerPosition == 'blog'
								? 'headerDashboardmenuChoose'
								: ''
						}>
						<Link
							to='/SRC/blog'
							className='headerDashboardmenuLink'>
							{intl.formatMessage({
								id: 'intl.header.blog'
							})}
						</Link>
					</li>
					<li
						className={
							state.routerPositionInfo.routerPosition ==
							'leaderboards'
								? 'headerDashboardmenuChoose'
								: ''
						}>
						<Link
							to='/SRC/leaderboards'
							className='headerDashboardmenuLink'>
							{intl.formatMessage({
								id: 'intl.header.halloffame'
							})}
						</Link>
					</li>
					<li
						className={
							state.routerPositionInfo.routerPosition ==
							'securityUpdates'
								? 'headerDashboardmenuChoose'
								: ''
						}>
						<Link
							to='/SRC/securityUpdates'
							className='headerDashboardmenuLink'>
							{intl.formatMessage({
								id: 'intl.header.securityupdate'
							})}
						</Link>
					</li>
          <li
						className={
							state.routerPositionInfo.routerPosition == 'swagshop'	? 'headerDashboardmenuChoose': ''
						}
					>
						<Link to='/SRC/swagshop' className='menuLink'>
							{intl.formatMessage({
								id: 'intl.header.swagshop',
							})}
						</Link>
					</li>
				</ul>
				<div className='headerDashboardRight'>
					<label
						className='headerDashboardSRBtn'
						onClick={tobugSubmit}>
						{intl.formatMessage({
							id: 'intl.header.securityreporting'
						})}
					</label>
					{sessionStorage.getItem('token') == undefined && (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}>
							<label
								className='headerLogInButton'
								onClick={toLogin}>
								{intl.formatMessage({
									id: 'intl.header.login'
								})}
							</label>
							<label className='headerLine'>|</label>
							<label
								className='headerSignUpButton'
								onClick={toSignUp}>
								{intl.formatMessage({
									id: 'intl.header.signup'
								})}
							</label>

							<label className='headerLine'>|</label>
              <label className='headerLanguage' onClick={getLanguage}>{changeLanguage}</label>
							{/* <Popover
								content={contentLangGlobal}
								trigger='click'>
								<div
									className='headerChangeLanguage'
									onMouseEnter={() =>
										setChangeLanguageimg(icChangeLanguage)
									}
									onMouseLeave={() =>
										setChangeLanguageimg(
											icChangeLanguageHover
										)
									}>
									<img
										src={changeLanguageimg}
										className='changeLanguageimg'
									/>
									<label className='headerChangeLangText'>
										{(sessionStorage.getItem('lang') ==
											'en_US' &&
											'English') ||
											(sessionStorage.getItem('lang') ==
												'zh_CN' &&
												'中文')}
									</label>
								</div>
							</Popover> */}
						</div>
					)}
					{sessionStorage.getItem('token') != undefined && (
						<div style={{display: 'inline-block'}}>
							<img
								className='headerIcmessage'
								src={icbuglist}
								onClick={handleGoToBugList}
                alt=''
							/>
							{(messageCount > 0 && (
								<img
									className='headerIcmessage'
									onClick={goMessageCenter}
									src={icMessageNewImg}
                  alt=''
									onMouseEnter={() =>
										setIcMessageNewImg(icmessagenewhover)
									}
									onMouseLeave={() =>
										setIcMessageNewImg(icmessagenew)
									}
								/>
							)) || (
								<img
									className='headerIcmessage'
									onClick={goMessageCenter}
									src={icMessageNoImg}
                  alt=''
									onMouseEnter={() =>
										setIcMessageNoImg(icmessagenohover)
									}
									onMouseLeave={() =>
										setIcMessageNoImg(icmessageno)
									}
								/>
							)}
							<Dropdown overlay={menu} placement='bottomRight' overlayClassName='custom-dropdown' 
               mouseEnterDelay={1} 
              >
								{((sessionStorage.getItem('loginImg') ==
									undefined ||
									sessionStorage.getItem('loginImg') ==
										'undefined') && (
									<img
										className='headerAvstar'
										src={icAvstarImg}
                    alt=''
										onMouseEnter={() =>
											setIcAvstarImg(avstarhover)
										}
										onMouseLeave={() =>
											setIcAvstarImg(avstar)
										}
                    onClick={() => gotoPersonalInfo('personalInfo')}
									/>
								)) || (
									<img
										className='headerAvstar'
                    alt=''
										src={sessionStorage.getItem('loginImg')}
                    onClick={() => gotoPersonalInfo('personalInfo')}
									/>
								)}
							</Dropdown>
							<label className='headerIconDivider'></label>
              <label className='headerLanguage' onClick={getLanguage}>{changeLanguage}</label>
							{/* <Popover
								content={contentLangGlobal}
								trigger='click'>
								<img
									src={icChangeLanguageLogin}
									alt='loginChangelang'
									style={{
										width: '32px',
										height: '32px',
										cursor: 'pointer'
									}}
								/>
							</Popover> */}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
export default HeaderDashboard;
