import React, {useEffect, useState} from 'react';
import './style/quickEntryDiv.css';
import {Link, useHistory} from 'react-router-dom';
import {Input, Modal, Button, Upload, message, Dropdown} from 'antd';
// import {scrollToTop} from '../../../common/topScorll';
import FacebookLoginSecurity from 'react-facebook-login';
import ImgCrop from 'antd-img-crop';
import {useIntl} from 'react-intl';

import personalInfoIcon from '../../../../assets/svg/icon_personalInfo.svg';
import payoutMethodIcon from '../../../../assets/svg/icon_payoutMethod.svg';
import MyRedemptionIcon from '../../../../assets/svg/icon_redemptionRecord.svg';
import RewardsDetailsIcon from '../../../../assets/svg/icon_rewardsDetails.svg';
import achievementIcon from '../../../../assets/svg/icon_achievement.svg';
import Solo from '../../../../assets/svg/solo.svg';
import navIcon from '../../../../assets/svg/icon_nav.svg';
import _ from 'lodash';

const {confirm} = Modal;

export default (function (props) {
	const intl = useIntl();
  const { chosenType, chosenTypechange } = props;
  const menu = (
		<div className='quickEntryDivQuickEntranceDropDownMenuList'>
      	{props.chosenType != 'personalInfo' && (
				<div
					onClick={() => {
						sessionStorage.setItem('personalInfoType', 'personalInfo');
            window.location.href = '/SRC/personalInfo'
					}}
          className={'quickEntryDivQuickEntranceMenuListUnChosen'}
				>
					<img
						src={personalInfoIcon}
						alt='icon'
						className='quickEntryDivQuickEntranceIcon'
					/>
					{intl.formatMessage({
						id: 'intl.personalinfo.content.quickEntranceMenu.personalInfo',
					})}
				</div>
			)}
			<div
				onClick={() => {
					sessionStorage.setItem('personalInfoType', 'publicProfileEntry');
          window.location.href = '/SRC/publicProfile'
				}}
				className={'quickEntryDivQuickEntranceMenuListUnChosen'}
			>
				<img
					src={Solo}
					alt='icon'
					className='quickEntryDivQuickEntranceIcon'
				/>
				{intl.formatMessage({
					id: 'intl.personalinfo.settings.publicProfile.title',
				})}
			</div>
		
			{props.chosenType != 'achievement' && (
				<div
					onClick={() => {
						sessionStorage.setItem('personalInfoType', 'achievement');
            window.location.href = '/SRC/achievements'
					}}
          className={'quickEntryDivQuickEntranceMenuListUnChosen'}
				>
					<img
						src={achievementIcon}
						alt='icon'
						className='quickEntryDivQuickEntranceIcon'
					/>
					{intl.formatMessage({
						id: 'intl.personalinfo.content.quickEntranceMenu.achievement',
					})}
				</div>
			)}
			{props.chosenType != 'payoutMethod' && (
				<div
					onClick={() => {
						sessionStorage.setItem('personalInfoType', 'payoutMethod');
            window.location.href = '/SRC/payoutMethod'
					}}
					className={'quickEntryDivQuickEntranceMenuListUnChosen'}
				>
					<img
						src={payoutMethodIcon}
						alt='icon'
						className='quickEntryDivQuickEntranceIcon'
					/>
					{intl.formatMessage({
						id: 'intl.personalinfo.content.quickEntranceMenu.payoutMethod',
					})}
				</div>
			)}

			{props.chosenType != 'rewardsDetails' && (
				<div
					onClick={() => {
						sessionStorage.setItem('personalInfoType', 'rewardsDetails');
            window.location.href = '/SRC/rewardsDetails'
					}}
					className={'quickEntryDivQuickEntranceMenuListUnChosen'}
				>
					<img
						src={RewardsDetailsIcon}
						alt='icon'
						className='quickEntryDivQuickEntranceIcon'
					/>
					{intl.formatMessage({
						id: 'intl.personalinfo.content.quickEntranceMenu.rewardsDetails',
					})}
				</div>
			)}
			{props.chosenType != 'myRedemption' && (
				<div
					onClick={() => {
						sessionStorage.setItem('personalInfoType', 'myRedemption');
						;
            window.location.href = '/SRC/myRedemption'
					}}
					className={'quickEntryDivQuickEntranceMenuListUnChosen'}
				>
					<img
						src={MyRedemptionIcon}
						alt='icon'
						className='quickEntryDivQuickEntranceIcon'
					/>
					{intl.formatMessage({
						id: 'intl.personalinfo.content.quickEntranceMenu.myRedemption',
					})}
				</div>
			)}
		</div>
	);
  
	useEffect(() => {
	}, []);

	return (
		<div className='quickEntryDivContainer'>
		<div className='quickEntryDivOtherContainerHeader'>
						<div className='quickEntryDivOtherContainerHeaderLeft'>
							<label style={{ color: 'rgba(0,0,0,0.85)' }}>
              {props.title}
							</label>
						</div>
						<Dropdown overlay={menu} 
            //  mouseEnterDelay={0} mouseLeaveDelay={100}
             >
							<div className='quickEntryDivOtherContainerHeaderRight'>
								<img
									src={navIcon}
									alt='alt'
									style={{
										marginTop: '-2px',
										marginRight: '4px',
										width: '16px',
										height: '16px',
									}}
								/>
								<label
									style={{
										marginLeft: '4px',
										cursor: 'pointer',
									}}
								>
								{intl.formatMessage({ id: 'intl.personalinfo.breadCrumb.quickEntrance'})}
								</label>
							</div>
						</Dropdown>
					</div>
		</div>
	);
});
